<!--
# *****************************************************************
#
# Licensed Materials - Property of IBM
#
# (C) Copyright IBM Corp. 2019, 2020, 2021, 2022. All Rights Reserved.
#
# US Government Users Restricted Rights - Use, duplication or
# disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
#
# *****************************************************************
-->
<template>
  <div class="panel-overlay" v-show="displayPanel" @click="clickPanelOverlay">
    <div id="filter_panel_container" class="panel-container">
      <div class="panel-header">
        <p class="panel-header__label">{{ $t(viewKey) }}</p>
        <div class="panel-header__heading_contents">
          <p class="panel-header__heading1">{{ $t('filterpanel.view.filters') }}</p>
          <!-- <p class="panel-header__heading2">Learn how to apply filters</p> -->
          <cv-button kind="ghost" @click="learnToApplyFilter">{{ $t('learnApplyFilters') }}</cv-button>
        </div>
        <cv-button aria-label="Close" class="close-panel-button" kind="ghost" :icon="close_icon" @click="closePanel"/>
      </div>
      <div class="panel-content">        
        <fieldset>
          <legend>
            <p class="panel-header__label">{{ $t('show') }}</p>
          </legend>
          <div class="radio-group">
            <cv-checkbox
              class="radio-group-checkbox"
              :label="$t('runtimeCalls')"
              v-model="showEdgesSelection"
              @change="showEdgesSelectionChanged"
              value="1">
            </cv-checkbox>
            <cv-checkbox
              class="radio-group-checkbox"
              :label="$t('dataDependencies')"
              v-model="showEdgesSelection"
              @change="showEdgesSelectionChanged"
              value="3">
            </cv-checkbox>
            <cv-checkbox v-show="hasUtilityPartition"
              class="radio-group-checkbox"
              :label="$t('utilityCalls')"
              v-model="showEdgesSelection"
              @change="showEdgesSelectionChanged"
              value="2">
            </cv-checkbox>
          </div>
          <!-- <cv-radio-group class="radio-group">
            <cv-radio-button
              name="group-create"
              value="1"
              :label="$t('runtimeCalls')"
              v-model="showEdgesSelection"
              @change="showEdgesSelectionChanged"
            />
            <cv-radio-button
              name="group-create"
              value="2"
              :label="$t('dataDependencies')"
              v-model="showEdgesSelection"
              @change="showEdgesSelectionChanged"
            />
          </cv-radio-group> -->
        </fieldset>
        <div class="filters-container">
          <div class="filters-left-col filters-left-s-col">
            <div class="filters-lc-lcol">
              <section>
                <multi-select-drop-down-field
                  id="usecasesDropdown"
                  class="multi-select-field"
                  :dropdown="panelSummary.usecasesDropdown"
                  ref="ucdd"
                ></multi-select-drop-down-field>
              </section>
              <section>
                <multi-select-drop-down-field
                  id="microservicesDropdown"
                  class="multi-select-field-resp-bottom"
                  :dropdown="panelSummary.microservicesDropdown"
                  ref="msdd"
                ></multi-select-drop-down-field>
              </section>
            </div>
            <div class="filters-lc-rcol"> 
              <section>
                <multi-select-drop-down-field
                  id="labelsDropdown"
                  class="multi-select-field-resp"
                  :dropdown="panelSummary.labelsDropdown"
                  ref="labelsdd"
                ></multi-select-drop-down-field>
              </section>
            </div>
          </div>
          <div class="filters-right-col filters-right-s-col">
            <p class="panel-header__label">{{ $t('minimumRuntimeCalls') }}</p>
            <cv-number-input
              id="minimum-runtime-calls"
              class="text-input-field"
              :hideLabel="true"
              :min="minimumRuntimeCalls"
              :max="maximumRuntimeCalls"
              v-model="minimumRuntimeCallsInput"
              :placeholder="minimumRuntimeCalls"
              @keypress="preventNonDigitInput"
              @keyup="(evt) => runtimeFilterCornercase(evt, 'minimum-runtime-calls')"
              @input="minRuntimeCallChanged"
              :disabled="isShowingDataDependencies"
            >
              <template v-if="minInputError" slot="invalid-message">{{ minInputError }}</template>
            </cv-number-input>
            <p class="panel-header__label">{{ $t('maximumRuntimeCalls') }}</p>
            <cv-number-input
              id="maximum-runtime-calls"
              class="text-input-field-bottom"
              :min="minimumRuntimeCalls"
              :max="maximumRuntimeCalls"
              v-model="maximumRuntimeCallsInput"
              :placeholder='maximumRuntimeCalls'
              @keypress="preventNonDigitInput"
              @keyup="(evt) => runtimeFilterCornercase(evt, 'maximum-runtime-calls')"
              @input="maxRuntimeCallChanged"
              :disabled="isShowingDataDependencies"
            >
              <template v-if="maxInputError" slot="invalid-message">{{ maxInputError }}</template>
            </cv-number-input>
          </div>
        </div>
      </div>
      <div class="panel-footer">
        <cv-button
          id="reset-filters-button"
          class="footer-button"
          kind="secondary"
          :disabled="resetButtonDisabled"
          @click="resetFilters"
        >{{ $t('resetFilters') }}</cv-button>
        <cv-button
          id="apply-filters-button"
          class="footer-button"
          kind="primary"
          :disabled="applyButtonDisabled"
          @click="applyFilters"
        >{{ applyButtonLabel }}</cv-button>
      </div>
    </div>
  </div>
</template>

<script>
import apis from "../../lib/graph/graphAPIHandler";
import { debounce } from "lodash";
import utils from "../../lib/graph/graphUtil";
import MultiSelectDropDownField from "../MultiSelectDropDownField";
import messageUtils from "@/js/messageUtils";
import { eventBus } from "../../main.js";
import close_icon from "@carbon/icons-vue/es/close/20";
import constants from "../../lib/constants";

const _APPLY_STATE_APPLIED = 0;
const _APPLY_STATE_DISABLED = 1;
const _APPLY_STATE_ENABLED = 2;

export default {
  name: "FilterPanel",
  components: {
    MultiSelectDropDownField,
  },
  // watch: {
  //   "panelSummary.microservicesDropdown.selectedOption": {
  //     handler: function(val) {
  //       const microservicesInput = document.querySelector("#microservicesDropdown input")
  //       if (!microservicesInput) return;
  //       if (val.length > 0) {
  //         microservicesInput.classList.add("white-placeholder");
  //       } else {
  //         microservicesInput.classList.remove("white-placeholder");
  //       }
  //     },
  //   },
  //   "panelSummary.usecasesDropdown.selectedOption": {
  //     handler: function(val) {
  //       const usecasesInput = document.querySelector("#usecasesDropdown input")
  //       if (!usecasesInput) return;
  //       if (val.length > 0) {
  //         usecasesInput.classList.add("white-placeholder");
  //       } else {
  //         usecasesInput.classList.remove("white-placeholder");
  //       }
  //     }
  //   }
  // },
  computed: {
    applyButtonDisabled() {
      return this.applyButtonState !== _APPLY_STATE_ENABLED;
    },
    applyButtonLabel() {
      return this.applyButtonState === _APPLY_STATE_APPLIED ? this.$t('filtersApplied') : this.$t('applyFilters');
    },
    isShowingDataDependencies() {
      return this.showEdgesSelection.includes(constants.showDataDependencies);
    },
  },
  data: () => ({
    viewKey: "",
    resetPanel: true,
    updateFilterTypes: [],
    updateFilterSelectionTypes: [],
    clearFilterInputs: false,
    displayPanel: false,
    // one of the three: 'disabled', 'ready', 'applied'
    applyButtonState: _APPLY_STATE_DISABLED,
    resetButtonDisabled: true,
    minimumRuntimeCalls: 0,
    maximumRuntimeCalls: 0,
    minimumRuntimeCallsInput: NaN,
    maximumRuntimeCallsInput: NaN,
    minInputError: null,
    maxInputError: null,
    close_icon,
    open: [false], // this variable maybe not taking any effects, needs further investigation
    panelSummary: {
      microservicesDropdown: {
        id: "microservicesDropdown",
        placeholder: messageUtils.getMessage("microservicesSelectPlaceholder"),
        // the value is an array where index 0 is the default placeholder with (length)
        // index 1 is the placeholder when some choices are selected (i.e. without length) specified in locales.
        placeHolderKeys: ["microservicesSelectPlaceholder", "microservicesSelectedPlaceholder"],
        selectionFeedback: "fixed",
        clusterColorMap: [],
        selectedOption: [],
        onChangeEvent: "FilterPanel.microserviceOnChange",
        options: [],
        title: messageUtils.getMessage('microservicesSelectedPlaceholder')
      },
      usecasesDropdown: {
        id: "usecasesDropdown",
        placeholder: messageUtils.getMessage("usecasesSelectPlaceholder", [0]),
        placeHolderKeys: ["usecasesSelectPlaceholder", "useCases"],
        selectionFeedback: "fixed",
        selectedOption: [],
        onChangeEvent: "FilterPanel.usecaseOnChange",
        options: [],
        title: messageUtils.getMessage('useCases')
      },
      labelsDropdown: {
        id: "labelsDropdown",
        placeholder: messageUtils.getMessage("labelsPlaceholder", [0]),
        placeHolderKeys: ["labelsPlaceholder", "labels"],
        selectionFeedback: "fixed",
        selectedOption: [],
        onChangeEvent: "FilterPanel.labelOnChange",
        options: [],
        title: messageUtils.getMessage('labels')
      },      
    },
    showEdgesSelection: [constants.showRuntimeCalls],
    changedFilters: {},
    filtersStatus: {},
    hasUtilityPartition: false,
  }),
  methods: {
    showPanel(show) {
      if (show === true) {
        if (this.resetPanel === true) {
          this.viewKey = this.$store.getters.getKey;
          this.updateFilterTypes = utils.getAllFilterClasses();
          this.updateFilterSelectionTypes = utils.getAllFilterClasses();
          this.clearFilterInputs = true;
          this.panelSummary.microservicesDropdown.selectedOption.splice(0);
          this.panelSummary.usecasesDropdown.selectedOption.splice(0);
          this.panelSummary.labelsDropdown.selectedOption.splice(0);
          this.changedFilters = {};
          this.resetFiltersStatus();
          this.resetPanel = false;
        }
        this.updateFilterPanel();
        // register a listener to Esc key
        // window.addEventListener('keydown', this.handleEscKey);
      } else {
        // window.removeEventListener('keydown', this.handleEscKey);
      }
      this.displayPanel = show;
    },
    clickPanelOverlay(evt) {
      if (evt && evt.target !== evt.currentTarget) { // clicking on children
        return;
      }
      this.closePanel();
    },
    closePanel() {
      eventBus.$emit("Toolbar.closeFilterPanel");
      this.showPanel(false);
    },
    handleEscKey(e) {
      if (e.key == "Escape") {
        this.closePanel();
      }
    },
    updatePartitionsDropdown() {
      const partitionInfo = apis.getCurrentViewPartitionInfo();
      const allPartitions = partitionInfo.names.sort((a, b) => a.localeCompare(b));
      // format the options to the required format for multiSelect
      this.panelSummary.microservicesDropdown.options = allPartitions.map((item) => {
        return {
          name: item,
          label: item, // used for display
          value: item, // used for selected
        };
      });
      this.panelSummary.microservicesDropdown.clusterColorMap = partitionInfo.colorMap;
      this.panelSummary.microservicesDropdown.placeholder = this.$t('microservicesSelectPlaceholder', {0: allPartitions.length});
    },
    updateUsecasesDropdown() {
      const usecases = apis.getCurrentViewAllUseCases().sort((a, b) => a.localeCompare(b));
      const tempUsecases = usecases.map(uc => ({label: uc, name: uc, value: uc}));
      this.panelSummary.usecasesDropdown.options = tempUsecases;
      this.panelSummary.usecasesDropdown.placeholder = this.$t('usecasesSelectPlaceholder', {0: usecases.length});
    },
    updateLabelsDropdown() {
      const labels = apis.getAllClassLabels().sort((a, b) => a.localeCompare(b));
      const tempLabels = labels.map(label => ({label: label, name: label, value: label}));
      tempLabels.push({
        label: this.$t('table-unobserved-label-tag'),
        name: constants.unobservedLabelFilterValue,
        value: constants.unobservedLabelFilterValue
      });
      // check whether current graph data contains "Utility" classes
      if (this.$store.getters.getNumberOfUtilityClasses > 0) {
        tempLabels.push({
          label: this.$t('table-utility-label-tag'),
          name: constants.utilityLabelFilterValue,
          value: constants.utilityLabelFilterValue
        });
      }
      this.panelSummary.labelsDropdown.options = tempLabels;
      this.panelSummary.labelsDropdown.placeholder = this.$t('labelsPlaceholder', {0: labels.length});
    },    
    updateRuntimeCallRanges() {
      const runtimeCallRange = apis.getRuntimeCallRange();
      this.minimumRuntimeCalls = runtimeCallRange[0];
      this.maximumRuntimeCalls = runtimeCallRange[1];
    },
    updateShowEdgesSelection() {
      const filters = apis.getFilterInfo(constants.filterTypeShowEdge) || utils.getDefaultShowEdgeFilter();
      if (this.filtersStatus[constants.filterTypeShowEdge] === true) { // filter is changed, update the status
        if (utils.arraysEqual(filters, this.showEdgesSelection, true) !== true) {
          this.changedFilters[constants.filterTypeShowEdge] = this.showEdgesSelection;
        } else {
          this.filtersStatus[constants.filterTypeShowEdge] = false; // nothing changed
          delete this.changedFilters[constants.filterTypeShowEdge];
        }
      } else {  // no "unapplied" changes, sync with in-memory filters
        this.showEdgesSelection = filters;
      }
    },
    updateFilterPanel() {
      this.updateFilterLists();
      this.updateFilterSelections();
      this.updateShowEdgesSelection();      
      this.updateApplyButtonStatus();
      this.resetButtonDisabled = !apis.anyFiltersApplied();
    },
    updateFilterLists() {
      // update filter dropdown lists
      this.updateFilterTypes.forEach(type => {
        if (type === constants.filterTypePartition) { // update partition filter dropdown
          this.updatePartitionsDropdown();
          if (this.clearFilterInputs === true) {
            this.$refs.msdd.clearDropdownFilter(); // clean up text input
          }
        } else if (type === constants.filterTypeUseCase) {  // update usecase filter list
          this.updateUsecasesDropdown();
          if (this.clearFilterInputs === true) {
            this.$refs.ucdd.clearDropdownFilter(); // clean up text input
          }
        } else if (type === constants.filterTypeRuntimeCall) {  // set min/max runtime calls
          this.updateRuntimeCallRanges();
        } else if (type === constants.filterTypeLabel) {  // update class label filter list
          this.updateLabelsDropdown();
          if (this.clearFilterInputs === true) {
            this.$refs.labelsdd.clearDropdownFilter(); // clean up text input
          }
        }
        // let 'updateFilterSelections' to update the status
        // this.filtersStatus[type] = false; // reset status
        // delete this.changedFilters[type];
      });
      this.hasUtilityPartition = this.$store.getters.getNumberOfUtilityPartitions > 0;
      this.updateFilterTypes = [];
    },    
    /**
     * Update/sync filter components with in-memory filters
     * 
     *  @param {object} options Options to update filter components
     *    {
     *      filterTypes: [...],
     *    }
     * 
     */
    updateFilterSelections() {
      this.updateFilterSelectionTypes.forEach(type => {
        const filters = apis.getFilterInfo(type) || []
        if (type === constants.filterTypeRuntimeCall) {
          if (this.filtersStatus[type] === false) { // no input change, update them with new values
            this.minimumRuntimeCallsInput = filters[0] || NaN;
            this.maximumRuntimeCallsInput = filters[1] || NaN;
          } else {
            if (this.minimumRuntimeCallsInput === filters[0] && this.maximumRuntimeCallsInput === filters[1]) {
              this.filtersStatus[type] = false;
              delete this.changedFilters[type];
            }
          }
          this.maxInputError = this._getNumberInputErr(this.maximumRuntimeCallsInput);
          this.minInputError = this._getNumberInputErr(this.minimumRuntimeCallsInput);
        } else {
          let values, validList;
          if(type === constants.filterTypePartition) {
            values = this.panelSummary.microservicesDropdown.selectedOption;
            validList = this.panelSummary.microservicesDropdown.options;
          } else if (type === constants.filterTypeUseCase) {
            values = this.panelSummary.usecasesDropdown.selectedOption;
            validList = this.panelSummary.usecasesDropdown.options;
          } else if (type === constants.filterTypeLabel) {
            values = this.panelSummary.labelsDropdown.selectedOption;
            validList = this.panelSummary.labelsDropdown.options;
          }
          if (values && validList) {
            if (utils.arraysEqual(filters, values, true) !== true) {
              const selections = (this.filtersStatus[type] === true) ? 
                                    this._getMergedFilterSelections(filters, values, validList) : 
                                    filters;
              values.splice(0);
              selections.forEach(filter => {
                values.push(filter);
              });
            }
            if (this.filtersStatus[type] === true) { // filter is changed, update the status
              if (utils.arraysEqual(filters, values, true) !== true) {
                this.filtersStatus[type] = true;
                this.changedFilters[type] = values;
              } else {
                this.filtersStatus[type] = false; // nothing changed
                delete this.changedFilters[type];
              }
            }
          }
        }
      });
      this.updateFilterSelectionTypes = [];
    },
    _getMergedFilterSelections(tar, src, validList) {
      return tar.concat(src.filter((item) => tar.indexOf(item) < 0 && 
        validList.findIndex((validEle) => validEle.value === item) >= 0))
    },
    preventNonDigitInput(evt) {
      if (! /^[0-9]$/.test(evt.key)) {
        evt.preventDefault()
      }
    },
    // redirectToDocs() {
    //   window.open(
    //     'https://www.ibm.com/docs/en/mono2micro',
    //     '_blank'
    //   );
    //   this.showInformationDialog = false;
    // },
    showEdgesSelectionChanged() {
      this.processFilterChanges(constants.filterTypeShowEdge, this.showEdgesSelection);
      this.restoreRuntimeCallFilter();
      this.updateApplyButtonStatus();
    },
    restoreRuntimeCallFilter() {
      const filters = apis.getFilterInfo(constants.filterTypeRuntimeCall) || []
      this.minimumRuntimeCallsInput = filters[0] || NaN;
      this.maximumRuntimeCallsInput = filters[1] || NaN;
      this.maxInputError = null;
      this.minInputError = null;
      this.filtersStatus[constants.filterTypeRuntimeCall] = false;
      delete this.changedFilters[constants.filterTypeRuntimeCall];
    },
    // apply filters by calling the graph API
    applyFilters() {
      (async () => {
        if (Object.keys(this.changedFilters).length > 0) {
          apis.cleanupAllHighlights();
          const options = { 
            filters: this.changedFilters,
            showBusyIndicator: true,
          };
          // if (this.filtersStatus[_SHOW_EDGES_SELECTION] === true) {
          //   options.showDependencyOverlay = this.isShowingDataDependencies;
          // }
          await apis.applyFilters(options);
          this.changedFilters = {}; // reset it
          this.applyButtonState = _APPLY_STATE_APPLIED;
          // update 'Reset filters' button
          this.resetButtonDisabled = !apis.anyFiltersApplied();
          // reset filters status
          this.resetFiltersStatus();
          // notify TableView
          eventBus.$emit("TableView.resetTable");
          // update filter indicator
          eventBus.$emit("Toolbar.updateFilterIndicator");
        }
        this.closePanel();
      })();
    },
    // reset all the filters
    resetFilters(zoomToFit) {
      (async () => {
        apis.cleanupAllHighlights();
        const options = {
          showBusyIndicator: true,
          zoomToFit: zoomToFit,
        };
        await apis.resetFilters(options);
        if (zoomToFit === true) {
          eventBus.$emit("GraphNavigator.updateZoomConfig");
        }
        this.resetButtonDisabled = true;
        this.changedFilters = {}; // reset it
        this.applyButtonState = _APPLY_STATE_DISABLED;
        // clean up checkboxes
        this.updateFilterSelectionTypes = utils.getAllFilterClasses();
        // clean up dropdowns' filter
        this.$refs.msdd.clearDropdownFilter();
        this.$refs.ucdd.clearDropdownFilter();
        this.$refs.labelsdd.clearDropdownFilter();
        // reset filters status
        this.resetFiltersStatus();
        // notify TableView
        eventBus.$emit("TableView.resetTable");
        // update filter indicator
        eventBus.$emit("Toolbar.updateFilterIndicator");
        this.closePanel();
      })();
    },
    // combining the following two functions will cause undeterministic behaviour
    updateMinimumError: debounce(function() {
      this.minInputError = this._getNumberInputErr(this.minimumRuntimeCallsInput);
      if (this.maxInputError) {
        this.maxInputError = this._getNumberInputErr(this.maximumRuntimeCallsInput);
      }
      this.updateApplyButtonStatus();
    }, 500),
    updateMaximumError: debounce(function() {
      this.maxInputError = this._getNumberInputErr(this.maximumRuntimeCallsInput);
      if (this.minInputError) {
        this.minInputError = this._getNumberInputErr(this.minimumRuntimeCallsInput);
      }
      this.updateApplyButtonStatus();
    }, 500),
    // this function is to handle the situation where onchange events are not triggered 
    // when the input is switching between 0 and empty.
    runtimeFilterCornercase(evt, filterId) {
      const isBackspace = evt.key === "Backspace" || evt.keyCode === 8;
      const isZero = evt.key === "0" || evt.keyCode === 96;
      if (isBackspace && document.querySelector(`#${filterId}`).value === "") {
        if (filterId === "minimum-runtime-calls") {
          this.minimumRuntimeCallsInput = NaN;
          this.minInputError = null;
        }
        if (filterId === "maximum-runtime-calls") {
          this.maximumRuntimeCallsInput = NaN;
          this.maxInputError = null;
        }
      } else if (isZero && document.querySelector(`#${filterId}`).value === "0") {
        if (filterId === "minimum-runtime-calls") {
          this.minimumRuntimeCallsInput = "0";
          this.minInputError = this._getNumberInputErr(0);
        }
        if (filterId === "maximum-runtime-calls") {
          this.maximumRuntimeCallsInput = "0";
          this.maxInputError = this._getNumberInputErr(0);
        }
      }
    },
    _getNumberInputErr(input) {
      const isEmpty = isNaN(input.toString());
      if (isEmpty) return null;
      if (input < this.minimumRuntimeCalls) return this.$t("input-lt-min-error", { min: this.minimumRuntimeCalls });
      if (input > this.maximumRuntimeCalls) return this.$t("input-gt-max-error", { max: this.maximumRuntimeCalls });
      if (this.minimumRuntimeCallsInput > this.maximumRuntimeCallsInput) return this.$t("runtime-calls-input-error");
      return null;
    },
    processFilterChanges(type, filter) {
      // if (type === _SHOW_EDGES_SELECTION) {
      //   this.filtersStatus[type] = (this.isShowingDataDependencies !== utils.isShowingDataDependencies());
      // } else {
        this.filtersStatus[type] = apis.isFilterChanged(type, filter);
        if (this.filtersStatus[type] === true) {
          this.changedFilters[type] = filter;
        } else {
          delete this.changedFilters[type];
        }
      // }
    },
    updateApplyButtonStatus() {
      if (/*this.isShowingDataDependencies !== true &&*/ (this.minInputError || this.maxInputError)) {
        this.applyButtonState = _APPLY_STATE_DISABLED;
      } else {
        const me = this;
        const anyChanges = Object.keys(this.filtersStatus).some(key => {
          return me.filtersStatus[key];
        });
        if (anyChanges === true) {
          this.applyButtonState = _APPLY_STATE_ENABLED;
        } else if (apis.anyFiltersApplied() === true) {
          this.applyButtonState = _APPLY_STATE_APPLIED;
        } else {
          this.applyButtonState = _APPLY_STATE_DISABLED;
        }
      }
    },
    minRuntimeCallChanged(newValue) {
      const value = newValue || this.minimumRuntimeCalls;
      this.updateMinimumError();
      this.processFilterChanges(constants.filterTypeRuntimeCall, [value, this.maximumRuntimeCallsInput || this.maximumRuntimeCalls]);
    },
    maxRuntimeCallChanged(newValue) {
      const value = newValue || this.maximumRuntimeCalls;
      this.updateMaximumError();
      this.processFilterChanges(constants.filterTypeRuntimeCall, [this.minimumRuntimeCallsInput || this.minimumRuntimeCalls, value]);
    },
    resetFiltersStatus() {
      const keys = Object.keys(this.filtersStatus);
      const list = keys.length > 0 ? keys :  utils.getAllFilterClasses();
      list.forEach(type => {
        this.filtersStatus[type] = false;
      });
      // this.filtersStatus[_SHOW_EDGES_SELECTION] = false;
    },
    learnToApplyFilter() {
      window.open('https://www.ibm.com/docs/SS7H9Y/doc/m2m_rec_filters.html', '_blank');
    }    
  },
  mounted() {
    // var microservicesDropdownDiv = $("#main-content")[0];
    // this.microservicesDropdownObserver = new MutationObserver(function (mutations) {
    //   mutations.forEach(function(mutation) {
    //     if (mutation.attributeName === "class") {
    //       var grandParent = $(mutation.target).parent().parent();
    //       const gpId = grandParent.prop("id");
    //       const heightMap = {"microservicesDropdown": "200px", "usecasesDropdown": "250px"}
    //       if ( gpId in heightMap ) {
    //         if ($(mutation.target).hasClass("bx--list-box--expanded")) {
    //           grandParent.parent().css("min-height", heightMap[gpId]);
    //         } else {
    //           grandParent.parent().css("min-height", "");
    //         }
    //       }
    //     }
    //   });
    // });
    // this.microservicesDropdownObserver.observe(microservicesDropdownDiv, {
    //   attributes: true,
    //   childList: true,
    //   subtree: true,
    //   characterData: true
    // });
  },
  created() {
    // init filters status
    this.resetFiltersStatus();

    eventBus.$on("FilterPanel.showPanel", (show) => {
      this.showPanel(show);
    });
    eventBus.$on("FilterPanel.resetPanel", (reset) => {
      this.resetPanel = reset;
    });    
    eventBus.$on("FilterPanel.updatePanel", (updateData) => {
      if (updateData && this.resetPanel !== true) {
        if (updateData.reset === true) {
          this.resetPanel = true;
        } else if (updateData.filtersToBeUpdated) {
          this.updateFilterTypes = utils.mergeArrays(this.updateFilterTypes, updateData.filtersToBeUpdated);
          this.updateFilterSelectionTypes = utils.mergeArrays(this.updateFilterSelectionTypes, updateData.filtersToBeUpdated);
        }
      }
    });
    eventBus.$on("FilterPanel.updateFilterSelections", (options) => {
      if (this.resetPanel !== true) {
        this.updateFilterSelectionTypes = utils.mergeArrays(this.updateFilterSelectionTypes, options.filterTypes);
      }
    });
    eventBus.$on("FilterPanel.microserviceOnChange", (selectedMicroservices) => {
        if (selectedMicroservices !== undefined) {
          this.panelSummary.microservicesDropdown.selectedOption = selectedMicroservices;
        }
        // Pass in selected microservices
        // selectedMicroservices should be an array
        // UI is a drop down, so create an array for now
        //var selected = [selectedMicroservices];
        // apis.cleanupAllHighlights();
        // apis.greyoutNotSelectedPartitions(selectedMicroservices);
        this.processFilterChanges(constants.filterTypePartition, [...selectedMicroservices]);
        this.updateApplyButtonStatus();
      }
    );
    eventBus.$on("FilterPanel.usecaseOnChange", (selectedUseCases) => {
        this.panelSummary.usecasesDropdown.selectedOption = selectedUseCases;
        // apis.cleanupAllHighlights();
        // apis.filterNotSelectedUseCases(selectedUseCases);
        this.processFilterChanges(constants.filterTypeUseCase, [...selectedUseCases]);
        this.updateApplyButtonStatus();
    });
    eventBus.$on("FilterPanel.labelOnChange", (selectedLabels) => {
        this.panelSummary.labelsDropdown.selectedOption = selectedLabels;
        this.processFilterChanges(constants.filterTypeLabel, [...selectedLabels]);
        this.updateApplyButtonStatus();
    });    
    eventBus.$on("FilterPanel.resetFilters", (zoomToFit) => {
      this.resetFilters(zoomToFit);
    });    
  },
  beforeDestroy() {
    // if (this.microservicesDropdownObserver) {
    //   this.microservicesDropdownObserver.disconnect();
    // }
    eventBus.$off("FilterPanel.showPanel");
    eventBus.$off("FilterPanel.resetPanel");
    eventBus.$off("FilterPanel.updatePanel");
    eventBus.$off("FilterPanel.updateFilterSelections");
    eventBus.$off("FilterPanel.microserviceOnChange");
    eventBus.$off("FilterPanel.usecaseOnChange");
    eventBus.$off("FilterPanel.labelOnChange");
    eventBus.$off("FilterPanel.resetFilters");
  },
};
</script>

<style lang="scss" scoped>

  .panel-overlay {
    position: fixed;
    display: flex;
    width: 100vw;
    height: 100vh;
    background-color: var(--app-table-overlay-background-color);
    z-index: 15;

    .panel-container {
      // overflow: hidden;
      background-color: var(--app-panel-background-color);
      padding-left: 0px;
      padding-right: 0px;
      height: fit-content;
      width: 100%;
      border: 1px solid var(--app-page-header-bottom-border-color);
      box-shadow: 0 2px 6px 0 var(--app-panel-box-shadow-color);

      .panel-header__label {
        @include carbon--type-style("label-01");
          // margin-bottom: 0.25rem;
      }

  

      .panel-header {
        padding-top: $spacing-05;
        padding-left: $spacing-05;    
        // padding-right: 1rem;
        margin-bottom: $spacing-03;
        grid-column: 1/-1;
        grid-row: 1/1;

        .panel-header__heading_contents {
          display: flex;
          align-items: baseline;

          .panel-header__heading1 {
            @include carbon--type-style("productive-heading-03");
          }
        }

        .close-panel-button {
          position: absolute;
          top: $spacing-03;
          right: $spacing-03;
          justify-content: center;
          float: right;
          width: $spacing-07;
          height: $spacing-07;
          color: var(--app-toolbar-overflow-menu-icon-color);
        }
      }

      .panel-content {
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.42857;
        position: relative;
        padding-top: $spacing-05;
        padding-left: $spacing-06;
        padding-right: $spacing-06;
        margin-bottom: $spacing-09;
        grid-column: 1/-1;
        grid-row: 2/-2;
        // overflow-y: auto;

        .radio-group {
          padding-top: $spacing-03;
          padding-bottom: $spacing-06;
          display: flex;
          align-items: center;

          .radio-group-checkbox {
            max-width: fit-content;
            margin-right: 1rem;
          }
        }

        .filters-container {
          width: 100%;
          max-width: 111rem;
          height: 100%;
          // min-height: 18rem;
          display: flex;

          [class*="filters-left-"] {
            float: left;
            height: fit-content;
            padding-right: $spacing-06;
            border-right: 1px solid var(--app-page-header-bottom-border-color);

            .multi-select-field {
              // margin-top: $spacing-03;
              // padding-right: $spacing-06;
              margin-bottom: $spacing-06;
              display: flex;
              // max-width: 35.5rem;
            }
            // .multi-select-field-bottom {
            //   margin-top: $spacing-03;
            //   // padding-right: $spacing-06;
            //   // max-width: 35.5rem;
            // }
            .multi-select-field-resp {
              // margin-top: $spacing-03;
              @media only screen and (min-width: 66rem) {
                margin-bottom: $spacing-06;
                display: flex;
              }
            }
            .multi-select-field-resp-bottom {
              display: flex;
              // margin-top: $spacing-03;
              @media only screen and (max-width: 66rem) {
                margin-bottom: $spacing-06;
              }
            }
          }

          [class*="filters-right-"] {
            float: left;
            padding-left: $spacing-06;

            .text-input-field {
              margin-top: $spacing-03;
              // padding-right: $spacing-06;
              margin-bottom: $spacing-06;
              // max-width: 35.5rem;
            }

            .text-input-field-bottom {
              margin-top: $spacing-03;
              // padding-right: $spacing-06;
              // max-width: 35.5rem;
            }
          }

          @media only screen and (min-width: 66rem) {
            .filters-lc-lcol {
              width: 50%; 
              padding-right: 0.75rem;
            }

            .filters-lc-rcol {
              width: 50%; 
              padding-left: 0.75rem;
            }

            .filters-left-col {
              width: 66.667%;
              display: flex;
            }

            .filters-right-col {
              width: 33.333%;
            }
          }

          @media only screen and (max-width: 66rem) {
            .filters-left-s-col {
              width: 50%;
            }

            .filters-right-s-col {
              width: 50%;
            }
          }
        }
      }

      .panel-footer {
        display: flex;
        height: 4rem;
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end;
        margin-top: auto;
        grid-column: 1/-1;
        grid-row: -1/-1;
        border-top: 1px solid var(--app-page-header-bottom-border-color);

        .footer-button {
          width: 14rem;
          height: $spacing-10;
          box-shadow: -.0625rem 0 0 0 var(--app-carbon-modal-footer-disabled-btn-box-shadow-color);
        }
      }
    }
  }

</style>

<style lang="scss">

  .panel-container {
    .panel-header {
      .bx--btn--ghost {
        border-width: 0px;
        padding: 0 $spacing-05 0 $spacing-05;
      }
      .bx--btn {
        min-height: unset;
      }
      .close-panel-button {
        &.bx--btn--ghost .bx--btn__icon {
          margin-left: unset;
        }
      }
      .panel-header__heading_contents .bx--btn--ghost {
          &:hover {
            background-color: unset;
          }
          &:focus {
            border-color: unset;
            -webkit-box-shadow: unset;
            box-shadow: unset;
          }
          &:active {
            background-color: unset;
          }
      }
    }

    .panel-content {
      & .bx--label {
         @include carbon--type-style("label-01");
         color: unset;
      }

      // & .bx--number {
      //   width: 100%;
      // }

      & .bx--number input[type='number'] {
        height: $spacing-09;
      }

      & .multi-select-dropdown-field .bx--multi-select {
        height: 3rem;
      }

      & .bx--list-box {
        max-height: unset;
        // border-bottom: unset;
      }

      & .cv-multi-select {
        width: 100%;
        // margin-right: 1rem;
      }

      & .bx--form-item.bx--checkbox-wrapper:first-of-type {
        margin-top: unset;
      }
    }

    .filters-container {
      & .bx--number__control-btn {
        border-bottom: unset;
      }

      // & .bx--list-box--expanded .bx--list-box__menu {
      //   max-height: 7.5rem;
      // }
    }
  }

</style>
