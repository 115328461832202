<!--
# *****************************************************************
#
# Licensed Materials - Property of IBM
#
# (C) Copyright IBM Corp. 2020, 2021, 2022, 2023. All Rights Reserved.
#
# US Government Users Restricted Rights - Use, duplication or
# disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
#
# *****************************************************************
-->
<template>
  <div>
    <div class="slider-container">
      <div class="slider-comp">
        <cv-icon-button id="zoomOut" kind="ghost" class="zoom-btn" size="small" :icon="ZoomOut16" :label="$t('zoom-out')"
          tip-alignment="start" :aria-label="$t('zoom-out')" />
        <cv-slider class="slider"
          :min="zoomMin"
          :max="zoomMax"
          :min-label="minLabel"
          :max-label="maxLabel"
          :value="zoomValue"
          hideTextInput>
        </cv-slider>
        <cv-icon-button id="zoomIn" kind="ghost" class="zoom-btn" size="small" :icon="ZoomIn16" :label="$t('zoom-in')"
          tip-alignment="end" :aria-label="$t('zoom-in')" />
      </div>
    </div>
    <div class="minimap-spacer"/>
    <div class="navigator-container">
      <div id="graph-navigator" class="cytoscape-navigator"></div>
    </div>
  </div>
</template>

<script>
  //import { CvSlider } from "@carbon/vue";
  import ZoomIn16 from '@carbon/icons-vue/es/zoom--in/16';
  import ZoomOut16 from '@carbon/icons-vue/es/zoom--out/16';

  import apis from "../../lib/graph/graphAPIHandler";
  import { eventBus } from "../../main.js";

  let _cyRef;
  let _navigator = {
        options: {
          viewLiveFramerate: 0, // set false to update graph pan only on drag end; set 0 to do it instantly; set a number (frames per second) to update not more than N times per second
          thumbnailEventFramerate: 30, // max thumbnail's updates per second triggered by graph updates
          thumbnailLiveFramerate: false, // max thumbnail's updates per second. Set false to disable
          dblClickDelay: 200, // milliseconds
          removeCustomContainer: false, // destroy the container specified by user on plugin destroy
          rerenderDelay: 100, // ms to throttle rerender updates to the panzoom for performance
        }
      };
  let _zoomOptions = {
        zoomFactor: 0.05,     // zoom factor per zoom tick
        zoomDelay: 45,        // how many ms between zoom ticks
        minZoom: 0.1,         // min zoom level
        maxZoom: 10,          // max zoom level
      }
  let _zoomMinMaxLogScale;
  let _isZooming = false;
  let _zoomCenter = {};
  let _zoomInterval;
  let _thumbMouseMoveInterval;
  let _boundEvents = [];
  let _zoomElements = {
    'in': undefined, 
    'out': undefined, 
    'slider': undefined, 
    'thumb': undefined,
    'track': undefined,
  };
  let _currentZoomLevel = -1;
  
  export default {
    name: "GraphNavigator",
    //components: { CvSlider },
    data: () => ({
      minLabel: "",
      maxLabel: "",
      ZoomIn16,
      ZoomOut16,
      zoomMin: "1",
      zoomMax: "100",
      zoomValue: "10",
    }),
    methods: {
      getKeyByElement(element) {
        return Object.keys(_zoomElements).find(key => _zoomElements[key] === element);
      },
      bindEvent(evt, handler, element) {
        let key;
        if (element) {
          key = this.getKeyByElement(element);
          element.addEventListener(evt, handler);
        } else {
          document.addEventListener(evt, handler);
        }
        _boundEvents.push({ele: key, evt: evt, fn: handler});
      },
      unbindEvent(evt, handler, element) {
        let unbindEvent;
        let idx;
        const me = this;
        _boundEvents.find((e, i) => {
          if (e.evt === evt && e.fn === handler) {
            const key = (element) ? me.getKeyByElement(element) : undefined;
            if ((key && key === e.ele) || (!key && !e.ele)) {
              unbindEvent = e;
              idx = i;
              return true;
            }
          }
        });
        const ele = (element) ? _zoomElements[unbindEvent.ele] : document;
        ele.removeEventListener(unbindEvent.evt, unbindEvent.fn);
        _boundEvents.splice(idx, 1);
      },
      initNavigator() {
        // add 'mousemove' listener to overlay
        const naviContainer = document.getElementsByClassName("navigator-container");
        if (naviContainer && naviContainer.length > 0) {
          _navigator.mapContainer = naviContainer[0].parentElement;
          _navigator.mapContainer.addEventListener("mouseover", this.mapMouseOverHandler);
          // _navigator.mapContainer.addEventListener("mouseout", this.mapMouseOutHandler);
        }
        const btns = document.getElementsByClassName("zoom-btn");
        if (btns && btns.length > 1) {
          _zoomElements.out = btns[0];
          _zoomElements.in = btns[1];
          this.registerButton(_zoomElements.in, 1 + _zoomOptions.zoomFactor);
          this.registerButton(_zoomElements.out, 1 - _zoomOptions.zoomFactor);
        }
        const filledTrack = document.getElementsByClassName("bx--slider__filled-track");
        if (filledTrack && filledTrack.length > 0) {
          _zoomElements.track = filledTrack[0];
        }
        const sliders = document.getElementsByClassName("bx--slider__track");
        if (sliders && sliders.length > 0) {
          _zoomElements.slider = sliders[0];
          this.bindEvent("click", () => {
            if (_zoomElements.track) {
              const level = this.percentageToZoomLevel(parseFloat(_zoomElements.track.style.width) / 100);
              this.startZooming();
              this.zoomTo(level);
              this.endZooming();
            }
          }, _zoomElements.slider);
        }
        const sliderThumbs = document.getElementsByClassName("bx--slider__thumb");
        if (sliderThumbs && sliderThumbs.length > 0) {
          _zoomElements.thumb = sliderThumbs[0];

          //*** fix accessibility issue (workaround)
          _zoomElements.thumb.setAttribute("role","slider");
          _zoomElements.thumb.removeAttribute("aria-labelledby");
          _zoomElements.thumb.setAttribute("aria-label", "Zoom in or zoom out");
          const level = (_zoomElements.track) ? this.percentageToZoomLevel(parseFloat(_zoomElements.track.style.width) / 100) : 0.1;
          _zoomElements.thumb.setAttribute("aria-valuenow", level);
          //*** end

          this.bindEvent('keydown', (evt) => {
            // If keys other than the four arrow keys are pressed, do nothing.
            if (evt.key !== undefined) {
              if (!["ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight"].includes(evt.key)) return;
            } else if (evt.keyCode !== undefined) {
              if (![37, 38, 39, 40].includes(evt.keyCode)) return;
            }
            document.addEventListener("keyup", this.endZooming, { once: true });
            if (_zoomElements.track) {
              const level = this.percentageToZoomLevel(parseFloat(_zoomElements.track.style.width) / 100);
              this.startZooming();
              this.zoomTo(level);
              _currentZoomLevel = level;
            }
          }, _zoomElements.thumb);
          this.bindEvent("mousedown", () => {
            document.addEventListener("mouseup", this.thumbMouseUpHandler, { once: true });
            this.startZooming();
            _currentZoomLevel = -1;
            this.bindEvent("mousemove", this.thumbMouseMoveHandler, _zoomElements.thumb);
            return false;
          }, _zoomElements.thumb);
        }
      },
      zoomTo(level) {
        if(!_isZooming) { // for non-continuous zooming (e.g. click slider at pt)
          this.calculateZoomCenterPoint();
        }
        _cyRef.zoom({
          level: level,
          renderedPosition: { x: _zoomCenter.x, y: _zoomCenter.y }
        });
        if (_zoomElements.thumb) {
          _zoomElements.thumb.setAttribute("aria-valuenow", level);
        }
      },
      calculateZoomCenterPoint() {
        _zoomCenter.x = _cyRef.width() / 2;
        _zoomCenter.y = _cyRef.height() / 2;
      },
      startZooming() {
        _isZooming = true;
        this.calculateZoomCenterPoint();
      },
      endZooming() {
        _isZooming = false;
      },
      zoomLevelToPercentage(zoom) {
				const p = Math.log(zoom) / Math.log(_zoomOptions.maxZoom);
				return 100 - (1 - (p - _zoomMinMaxLogScale) / (1 - _zoomMinMaxLogScale)) * 100; 
      },
      percentageToZoomLevel(percent) {
        const x = Math.log(_zoomOptions.minZoom) / Math.log(_zoomOptions.maxZoom);
        const p = (1 - x) * percent + x;
        var z = Math.pow( _zoomOptions.maxZoom, p );
        if (z < _zoomOptions.minZoom) {
          z = _zoomOptions.minZoom;
        } else if (z > _zoomOptions.maxZoom) {
          z = _zoomOptions.maxZoom;
        }
        return z;
      },
      doZoom (factor) {
        var zoom = _cyRef.zoom();
        var level = _cyRef.zoom() * factor;
        if(level < _zoomOptions.minZoom) {
          level = _zoomOptions.minZoom;
        }
        if(level > _zoomOptions.maxZoom){
          level = _zoomOptions.maxZoom;
        }
        if((factor > 1 && level == _zoomOptions.maxZoom && zoom >= _zoomOptions.maxZoom) ||
           (factor < 1 && level == _zoomOptions.minZoom && zoom <= _zoomOptions.minZoom)) {
          return;
        }
        this.zoomValue = this.zoomLevelToPercentage(level).toString();
        this.zoomTo(level);
      },
      doZoomForSliding() {
        if (_zoomElements.track) {
          const level = this.percentageToZoomLevel(parseFloat(_zoomElements.track.style.width) / 100);
          if (level !== _currentZoomLevel) {
            this.zoomTo(level);
            _currentZoomLevel = level;
          }
        }
      },
      mapMouseOverHandler() {
        // if (_navigator.mapContainer) {
        //   if (!_navigator.containerBackground) {
        //     let bc = getComputedStyle(_navigator.mapContainer).backgroundColor;
        //     if (bc && bc.indexOf('rgba') === 0) {
        //       _navigator.containerBackground = bc.replace(/[^,]+(?=\))/, '1');
        //     } else {
        //       _navigator.containerBackground = null;
        //     }
        //   }
        //   if (_navigator.containerBackground !== null) {
        //     _navigator.mapContainer.style.backgroundColor  = _navigator.containerBackground;
        //   }
        // }
        apis.hideTooltip();
      },
      // mapMouseOutHandler() {
      //   if (_navigator.mapContainer) {
      //     _navigator.mapContainer.style.backgroundColor  = null;
      //   }
      // },
      mouseUpHandler() {
        if (_isZooming === true) {
          clearInterval(_zoomInterval);
          this.endZooming();
        }
      },
      thumbMouseUpHandler() {
        clearInterval(_thumbMouseMoveInterval);
        _thumbMouseMoveInterval = null;
        this.unbindEvent("mousemove", this.thumbMouseMoveHandler, _zoomElements.thumb);
        this.doZoomForSliding();
        this.endZooming();
      },
      thumbMouseMoveHandler() {
        if (!_thumbMouseMoveInterval) {
          this.doZoomForSliding();
          _thumbMouseMoveInterval = setInterval(this.doZoomForSliding, _zoomOptions.zoomDelay);
        }
      },
      cyZoomHandler() {
        if(_isZooming !== true) {
          const zoom = _cyRef.zoom();
          if (zoom <= _zoomOptions.minZoom) {
            this.zoomValue = "0";
          } else if (zoom >= _zoomOptions.maxZoom) {
            this.zoomValue = "100";
          } else {
            this.zoomValue = this.zoomLevelToPercentage(zoom).toString();
          }
        }
      },
      registerButton(button, factor) {
        this.bindEvent("mousedown", (evt) => {
          evt.preventDefault();
          evt.stopPropagation();
          if(evt.button !== 0) {  // left button only
            return;
          }
          document.addEventListener("mouseup", this.mouseUpHandler, { once: true });
          this.startZooming();
          this.doZoom(factor);
          _zoomInterval = setInterval(this.doZoom, _zoomOptions.zoomDelay, factor);
          return false;
        }, button);
      },
      destroyNavigator() {
        if (_navigator.instance) {
          _navigator.instance.destroy();
          delete _navigator.instance;
        }
        if (_navigator.overlay) {
          _navigator.overlay.removeEventListener("mousemove", this.navigatorViewMouseMoveHandler);
          delete _navigator.overlay;
        }
        if (_cyRef) {
          _cyRef.off('zoom', this.cyZoomHandler);
        }
      },
      navigatorViewMouseMoveHandler(evt) {
        const viewArea = _navigator.view.getBoundingClientRect();
        if (evt.x >= viewArea.left && evt.x <= viewArea.right && evt.y >= viewArea.top && evt.y <= viewArea.bottom) {
          _navigator.overlay.style.cursor = "grab";
        } else {
          _navigator.overlay.style.cursor = "default";
        }
      },
      updateZoomConfig(/*newConfig*/) {
        const czl = _cyRef.zoom();
        // if (newConfig) {
        //   _zoomOptions = newConfig;
        // } else {
          const cyExtent = _cyRef.extent();
          const bb = _cyRef.elements().boundingBox();
          const zoomScale = Math.min(cyExtent.h / bb.h, cyExtent.w / bb.w);
          _zoomOptions = {
            zoomFactor: 0.05,
            zoomDelay: 45,
            minZoom: czl * zoomScale * 0.38,
          };
          _zoomOptions.maxZoom = _zoomOptions.minZoom * 100;
          // set max/min zoom level to CY
          _cyRef.minZoom(_zoomOptions.minZoom);
          _cyRef.maxZoom(_zoomOptions.maxZoom);
        // }
        _zoomMinMaxLogScale = Math.log(_zoomOptions.minZoom) / Math.log(_zoomOptions.maxZoom);
        this.zoomValue = this.zoomLevelToPercentage(czl).toString();
      },
      updateNavigator(config) {
        this.destroyNavigator();
        _cyRef = config.cy;
        _cyRef.on('zoom', this.cyZoomHandler);
        // if (config.navigator) {
        //   _navigator.options = config.navigator;
        // }
        _navigator.options.container = "#graph-navigator";
        _navigator.instance = _cyRef.navigator(_navigator.options);
        // get size of the view
        const ele = document.getElementsByClassName("cytoscape-navigatorView");
        if (ele && ele[0]) {
          _navigator.view = ele[0];
        }
        // add 'mousemove' listener to overlay
        const overlayEle = document.getElementsByClassName("cytoscape-navigatorOverlay");
        if (overlayEle && overlayEle[0] && _navigator.view) {
          _navigator.overlay = overlayEle[0];
          _navigator.overlay.addEventListener("mousemove", this.navigatorViewMouseMoveHandler);
        }
        // configure for zooming, assuming the current cy rendering is fit and centered
        this.updateZoomConfig(config.zoom);
      },
    },
    mounted() {
      eventBus.$on("GraphNavigator.updateNavigator", this.updateNavigator);
      eventBus.$on("GraphNavigator.updateZoomConfig", this.updateZoomConfig);
      this.initNavigator();
    },
    beforeDestroy() {
      eventBus.$off("GraphNavigator.updateNavigator", this.updateNavigator);
      eventBus.$off("GraphNavigator.updateZoomConfig", this.updateZoomConfig);
      this.destroyNavigator();
      _boundEvents.forEach((e) => {
        const ele = (e.ele) ? _zoomElements[e.ele] : document;
        ele.removeEventListener(e.evt, e.fn );
      });
      _boundEvents = [];
      // _zoomElements = {};
      if (_navigator && _navigator.mapContainer) {
        _navigator.mapContainer.removeEventListener("mouseover", this.mapMouseOverHandler);
        // _navigator.mapContainer.removeEventListener("mouseout", this.mapMouseOutHandler);
      }
    },
  };
</script>

<style lang="scss">
  #minimap {
    .minimap-spacer {
      height: $spacing-03;
    }
    .slider-container {
      background: var(--app-minimap-background-color);
      width: 100%;
      min-height: $spacing-07;
      box-shadow: 0 2px 6px 0 var(--app-panel-box-shadow);
    }

    .slider-comp {
      background: var(--app-minimap-slider-background-color);
      width: 100%;
      // min-height: $spacing-07;
      display: inline-flex;
      align-items: center;
      display: flex;
      box-shadow: 0 2px 6px 0 var(--app-panel-box-shadow-color);
    }

    .slider-comp .zoom-btn {
      background-color: transparent;
      border: unset;
      padding: $spacing-03;
      /* color: var(--app-minimap-zoom-btn-color); */
    }

    .slider-comp .bx--btn .bx--btn__icon {
      width: unset;
      height: unset;
    }

    .slider-comp .bx--label {
      margin-bottom: unset;
    }

    .slider-comp .bx--slider-container {
      width: 100%;
    }

    .slider-comp .bx--slider {
      min-width: unset;
      margin: unset;
    }

    /* .slider-comp .bx--slider__track {
      background: var(--app-minimap-slider-track-background-color);
    } */

    .slider-comp .bx--slider-text-input {
      display: none;
    }

    .slider-comp .bx--slider__range-label:last-of-type {
      margin-right: unset;
    }

    /* .slider-comp .bx--slider__thumb {
      width: 0.7rem;
      height: 0.7rem;
    } */

    .slider-comp button:focus {
      outline:none;
      box-shadow: none;
    }

    .navigator-container {
      background: var(--app-minimap-background-color);
      /* background: rgba(23, 23, 23, 1); */
      // border: 4px solid transparent;  /*var(--app-minimap-container-border-color);*/
      width: 100%;
      height: 9.5rem;
      // overflow: hidden;
      box-shadow: 0 2px 6px 0 var(--app-panel-box-shadow-color);
      padding: calc(2 * #{$spacing-02});
      -webkit-padding-after: $spacing-03; // Chrome only?
    }

    .navigator-container .cytoscape-navigator {
      position: relative;
      /* background: var(--app-minimap-navigator-background-color); */
      width: 100%;
      height: 100%;
      /* width: 194px;
      height: 167px;
      bottom: 3px;
      right: 259px; */
      overflow: hidden;

      /* left: 0;
      bottom: 0;
      z-index: 99999; */
    }

    .navigator-container .cytoscape-navigator > img {
      position: absolute;
      max-width: 100%;
      max-height: 100%;
      /* pointer-events: none;
      user-select: none; */
      user-select: none;
      -moz-user-select: none;
      -webkit-user-drag: none;
      -webkit-user-select: none;
      -ms-user-select: none;
      
    }

    .navigator-container .cytoscape-navigator > canvas {
      position: absolute;
      /* bottom: 0;
      right: 256px; */
      z-index: 11;
    }

    .navigator-container .cytoscape-navigatorView {
      position: absolute;
      border: 1px solid var(--app-minimap-view-border-background-color);
      /* bottom: 0;
      right: 256px; */
      /* cursor: move; */
      background: var(--app-minimap-view-background-color); 
      mix-blend-mode: multiply;
      /* -moz-opacity: 0.50;
      opacity: 0.10;
      -ms-filter:"progid:DXImageTransform.Microsoft.Alpha"(Opacity=50); */
      z-index: 12;
    }

    .navigator-container .cytoscape-navigatorOverlay {
      position: absolute;
      width: 100%;
      height: 100%;
      /* top: 0;
      right: 0;
      bottom: 0;
      left: 0; */
      z-index: 13;
    }
  }
</style>