<!--
# *****************************************************************
#
# Licensed Materials - Property of IBM
#
# (C) Copyright IBM Corp. 2020, 2021, 2022. All Rights Reserved.
#
# US Government Users Restricted Rights - Use, duplication or
# disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
#
# *****************************************************************
-->
<template>
  <cv-file-uploader
    :label="getMessage(fileUploader.title)"
    :helperText="getMessage(fileUploader.helperText)"
    :drop-target-label="getMessage(fileUploader.uploadText)"
    accept=".json"
    :clear-on-reselect="true"
    :multiple="false"
    :removable="true"
    :ref="fileUploader.ref"
    @change="onFileUploaderChange"
  ></cv-file-uploader>
</template>

<script>
import { eventBus } from "../../main.js";
import $ from "jquery";
import constants from "../../lib/constants"

export default {
  name: "FileUploader",
  components: {},
  props: ["fileUploader"],
  // data: () => ({
  //   fileUploaderRef: ""
  // }),
  methods: {
    getMessage(messageKey, values = {}) {
      return this.$t(messageKey, values)
    },
    displayFileName() {
      let filename = this.$store.getters.getFilename;
      if (filename) {
        let fileExtensionPos = filename.indexOf(".json");
        if (fileExtensionPos > 0) {
          filename = filename.substring(0, fileExtensionPos);
        }
      }
      return filename;
    },
    processData(data) {
      if (data) {
        try {
          // // If currently selected view is the first view (i.e. business logic),
          // // should set resetSelectedView to true when calling drawGraph api in the GraphSummaryTopPanel.
          // // Emitting event, GraphSummaryTopPanel.shouldReset, essentially sets resetSelectedView to true for next api call.
          // const selectedView = document.querySelector("#graphViewDropdown .dropdown-text")
          // const firstView = document.querySelector("#graphViewDropdown ul li .dropdown-text")
          // if (selectedView && firstView && selectedView.title == firstView.title) {
          //   eventBus.$emit("GraphSummaryTopPanel.shouldReset")
          // }

          // we should always send "shouldRest" for changing JSON file?
          eventBus.$emit("GraphSummaryTopPanel.shouldReset")

          var keys = [];
          var json = JSON.parse(data);
          Object.keys(json).forEach(function(key) {
            if (key !== "version") {
              keys.push(key);
            }
          });

          this.$store.commit("setApps", keys);
          this.$store.commit("setSeries", json);
          this.drawGraph();
        } catch (e) {
          // TODO show error dialog
        }
      } else {
        // TODO show error dialog
      }
    },
    drawGraph() {
      let displayGraphName = this.displayFileName();

      let apps = this.$store.getters.getApps.filter(
        (app) => app !== "mono_run_time_call_graph"
      );

      let defaultKey = apps[0];
      if (apps.includes("micro_detail_partition_by_business_logic")) {
        // Set business logic as the default view if available.
        defaultKey = "micro_detail_partition_by_business_logic";
      } else {
        apps.forEach(function(key) {
          if (key.startsWith("micro")) {
            defaultKey = key;
          }
        });
      }
      // The set key will be called when the dropdown is created
      // this.$store.commit("setKey", { key: defaultKey });

      // build the dropdown menu with micro views first, followed by custom view with mono view last
      let graphViewDropDownMenu = [];
      let numMicroViews = 0;
      apps.forEach(function(key) {
        if (key === "micro_detail_partition_by_business_logic") {
          graphViewDropDownMenu.unshift(key); // put this key as first element in the array
          numMicroViews += 1;
        } else if (key.startsWith("micro")) {
          graphViewDropDownMenu.splice(numMicroViews, 0, key); // put the rest of micro view after the business logic key
          numMicroViews += 1;
        } else if (key === "custom_view") {
          graphViewDropDownMenu.splice(numMicroViews, 0, key);
        } else if (key.startsWith("mono")) {
          graphViewDropDownMenu.push(key); // put the mono view at the end of the array
        }
      });
      if (!apps.includes("custom_view")) {
        // custom view has not been created, add custom_create key after micro keys
        graphViewDropDownMenu.splice(numMicroViews, 0, "custom_create");
      }

      let numOfClasses = "";
      // if (this.$store.getters.getNodes) {
      //   numOfClasses = this.$store.getters.getNodes.length;
      // }
      let json = this.$store.getters.getJson;
      if (json && defaultKey && json[defaultKey] && json[defaultKey][constants.nodeId]) {
        numOfClasses = json[defaultKey][constants.nodeId].length;
      }

      let summaryTopPanel = {
        fileName: displayGraphName,
        graphViewDropdown: {
          selectedOption: defaultKey,
          options: graphViewDropDownMenu,
        },
        numClasses: numOfClasses,
      };
      eventBus.$emit("GraphSummaryTopPanel.showPanel", summaryTopPanel);

      // Set some Side Panel fields for the new file...
      // Determine if there is a custom view defined for this JSON file
      // Please note we are not sure if the graph instance is 100% ready passed this line.
      let includesCustomView = apps.includes("custom_view");
      eventBus.$emit("SidePanel.newFileInitialization", {
        includesCustomView: includesCustomView,
        numOfClasses: numOfClasses
      });
    },
    processFileUpload(file) {
      //console.log('--------- this.$refs.fileUploader in processFileUpload ', this.$refs[this.fileUploader.ref]);
      if (this.$refs[this.fileUploader.ref]) {
        this.$store.commit("setFilename", file[0].file.name);
        var fReader = new FileReader();
        fReader.readAsText(file[0].file);

        // Note: let the event be called when the upload dialog is shown instead of here to avoid seeing the content
        // change in the file upload dialog.
        // this.$refs[this.fileUploader.ref].clear();

        let self = this;
        fReader.onload = function(event) {
          var data = event.target.result;
          // Process loaded JSON file
          self.processData(data);
        };
      }
    },
    onFileUploaderChange(files) {
      // This is the onChange method for the cv-file-uploader.  It will be executed
      // whenever a file is selected AND also when a selected file is removed.

      let uploadFiles = files;
      let uploaderRef = this.$refs[this.fileUploader.ref]; // Ref to cv-file-uploader
      let error = false;

      // Error checking....
      if (uploadFiles.length > 1) {
        // too many files selected for upload
        let errorMsgTitle = this.getMessage("error-title");
        let onlyOneMsg = this.getMessage("only-one-file");
        for (let i = 0; i < uploadFiles.length; i++) {
          uploaderRef.setInvalidMessage(i, onlyOneMsg);
          uploaderRef.internalFiles[i].invalidMessageTitle = errorMsgTitle;
        }
        error = true;
      } else {
        if (uploadFiles.length == 1) {
          // only one file was selected for upload
          let filename = uploadFiles[0].file.name;
          if (filename) {
            if (/\.json$/i.test(filename)) {
              // File must have .json extenson
              // Remove any previous error messages that may exist for this file element
              uploaderRef.setInvalidMessage(0, "");
            } else {
              // Wrong filetype was selected for upload.
              let errorMsgTitle = this.getMessage("error-title");
              let jsonOnlyMsg = this.getMessage("json-only-file");
              uploaderRef.setInvalidMessage(0, jsonOnlyMsg);
              uploaderRef.internalFiles[0].invalidMessageTitle = errorMsgTitle;
              error = true;
            }
          }
        }
      }

      if (!error) {
        if (this.fileUploader.onChangeEvent) {
          // Request came from upload dialog.  Make associated changes on dialog.
          eventBus.$emit(this.fileUploader.onChangeEvent, uploadFiles);
        } else {
          // Request came from the landing page.
          if (uploadFiles.length === 1) {
            // Process selected file
            this.$router.push("graph");
            this.processFileUpload(files);
          }
        }
      }
    },
    processFileUploadEvent(file) {
      this.processFileUpload(file);
      eventBus.$emit("GraphSummaryTopPanel.closeFileUploadDialog");
    },
    clearSelectedFile() {
      if (this.$refs[this.fileUploader.ref]) {
        this.$refs[this.fileUploader.ref].clear();
      }
    },
    demo: function () {   
            var self = this;
            $.ajax({
                url:  '/demo_json'
            }).done(function (data) {
                    const type = (typeof data);
                    if ( type === "object" ) {
                      self.$router.push("graph");
                      self.$store.commit("setFilename", constants.demoFile);
                      self.$store.commit("setDemoMode");
                      eventBus.$emit("TopNav.demoMode", true);
                      self.processData(JSON.stringify(data)); 
                    }
                });
        }
  },
  created() {
    // Since eventBus.$off will destroy any instance with the same event name and FileUploader is used
    // in two different page spaces (DashboardPage and graphSummaryTopPanel), the event name has to be
    // unique to avoid destroying events that are still in use by an instance. Sequence of the sceanrio
    // leading to the problem if event name is not unquie for each instance:
    // 1. FileUploader is created for the DashboardPage
    // 2. After uploading a json using the dashboard FileUploader,
    //    3. FileUploader is created for the graphSummaryTopPanel
    //    4. Dashboard FileUploader is now destroyed causing the events for both FileUploader instances to be destroyed
    //
    // #3 and #4 could be a race condition. With the previous UI, I assume #4 happened before #3. Hence there was no
    // event problem. Constructing an unique event name for each instance would take care of the problem and doesn't
    // matter whether destroy in #4 comes after create in #3.
    eventBus.$on(this.fileUploader.ref + ".processFileUpload", (file) => {
      this.processFileUploadEvent(file)
    });

    eventBus.$on(this.fileUploader.ref + ".fileUploaderClosing", () => {
      this.clearSelectedFile();
    });

    eventBus.$on(this.fileUploader.ref + ".drawGraphCompleted", (parms) => {
      // Set some Side Panel fields for the new file...
      // Determine if there is a custom view defined for this JSON file
      let includesCustomView = parms.apps.includes("custom_view");
      eventBus.$emit("SidePanel.newFileInitialization", {
        includesCustomView: includesCustomView,
        numOfClasses: parms.numOfClasses
      });
    });
  },
  mounted() {
    console.log("mounted");
    this.demo();
  },
  beforeDestroy() {
    eventBus.$off(this.fileUploader.ref + ".processFileUpload");
    eventBus.$off(this.fileUploader.ref + ".fileUploaderClosing");
    eventBus.$off(this.fileUploader.ref + ".drawGraphCompleted");
  },
};
</script>

<style>
.bx--file__selected-file--invalid__wrapper,
.bx--file__selected-file--invalid {
  max-width: 100%;
}
</style>
