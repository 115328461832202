<!--
# *****************************************************************
#
# Licensed Materials - Property of IBM
#
# (C) Copyright IBM Corp. 2021, 2022, 2023. All Rights Reserved.
#
# US Government Users Restricted Rights - Use, duplication or
# disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
#
# *****************************************************************
-->
<template>
  <div class="move-classes-container" v-show="showMoveClassesPanel" ref="moveClassesContainer">
    <div class="move-classes-content-container">
    <div class="move-classes-heading">
      <h4>{{ $t("move-classes-title") }}</h4>
      <cv-button aria-label="Close" class="move-classes-close" kind="ghost" :icon="closeIcon" @click="onClosePanel(true)" />
    </div>
    <div class="move-classes-content-top">
      <div class="move-classes-description">
        {{$t("move-classes-description")}}
      </div>
      <drop-down-field
        id="move-classes-partition-dropdown"
        class="move-classes-dropdown"
        :dropdown="partitionDropdown"
        ref="moveClassesPartitionDropdown"
      ></drop-down-field>
      <cv-text-input
        class="move-classes-create-new-partition"
        v-show="showCreateNewPartition"
        :label="$t('move-classes-create-new-partition-input-title')"
        :placeholder="$t('move-classes-create-new-partition-input-placeholder')"
        v-model="newParitionName"
        @input="onNewPartitionInput"
        ref="newParitionNameInput"
      >
        <template v-if="newPartitionHelperTextSlot" slot="helper-text">{{ $t("move-classes-new-partition-name-rules") }}</template>
        <template v-if="newPartitionInvalidMessageSlot" slot="invalid-message">{{ invalidNameMessage }}</template>
      </cv-text-input>
      <div class="move-classes-selected">{{ $t("move-classes-selected-classes", {numOfSelectedClasses: selectedClasses.length}) }}</div>
    </div>
    <div class="move-classes-selected-list" ref="moveClassesList">
      <span
        v-for="(selectedClass, rowIndex) in selectedClasses"
          :key="`${rowIndex}`"
          :value="`${selectedClass}`"
          class="move-classes-selected-class"
      >
        {{ selectedClass }}
      </span>
    </div>
    </div>

    <cv-button
      size="lg"
      :disabled="disabledMove"
      class="move-classes-move-button"
      @click="onMoveClasses"
    >
      {{ $t("move-classes-title") }}
    </cv-button>
  </div>
</template>

<script>
import { eventBus } from "../../main.js";
import closeIcon from "@carbon/icons-vue/es/close/20";
import DropDownField from "../DropDownField";
import apis from "../../lib/graph/graphAPIHandler";
import utils from "@/lib/graph/graphUtil";
import constants from "../../lib/constants";

export default {
  name: "MoveClasses",
  components: {
    DropDownField
  },
  props: [
    "selectedClasses",
    "onMoveClassesEvent"
  ],
  data() {
    return {
      closeIcon,
      showMoveClassesPanel: false,
      showCreateNewPartition: false,
      moveToPartition: '',
      newParitionName: '',
      newPartitionHelperTextSlot: true,
      newPartitionInvalidMessageSlot: false,
      disabledMove: true,
      invalidNameMessage: '',
      partitionDropdown: {
        id: "partitionDropdown",
        label: "move-classes-partition-dropdown-label",
        placeholder: "move-classes-partition-dropdown-placeholder",
        onChangeEvent: "MoveClassesPanel.onPartitionChange",
        value: "",
        selectedOption: "",
        options: []
      },
    }
  },
  methods: {
    onClosePanel(deselectMoveButton) {
      this.showMoveClassesPanel = false;
      if (deselectMoveButton) {
        eventBus.$emit("TableView.deselectMoveClassesButton");
      }
    },
    onMoveClasses() {
      if (this.showCreateNewPartition) {
        this.moveToPartition = this.newParitionName;
      }
      eventBus.$emit(this.onMoveClassesEvent, this.moveToPartition);
      this.onClosePanel(false);
      // eventBus.$emit("TableView.resetTable", true);

      // always update partition filter, as there is no way to check for deleted partition.
      eventBus.$emit("FilterPanel.updatePanel", {
        filtersToBeUpdated: [constants.filterTypePartition],
      });
    },
    onNewPartitionInput() {
      if (this.newParitionName !== '') {
        // validate partition name
        const errors = this.validateParitionName();
        if (errors === '') {
          this.invalidNameMessage = "";
          this.disabledMove = false;
          this.newPartitionHelperTextSlot = true;
          this.newPartitionInvalidMessageSlot = false;
        } else {
          this.invalidNameMessage = errors;
          this.disabledMove = true;
          this.newPartitionHelperTextSlot = false;
          this.newPartitionInvalidMessageSlot = true;
        }
      }
    },
    setPanelFocus() {
      this.$refs.moveClassesPartitionDropdown.$refs.dropdownRef.focus();
    },
    validateParitionName() {
      let errorMsg = "";
      let nameValidation = apis.validatePartitionName(this.newParitionName);
      // nameValidation is an array of booleans. If the indexed entry is true, then
      // the associated error exists with the partition name.  Refer to the validate
      // partition name section of src/lib/constants.js to see what error each index
      // indicates.
      //
      // When nameValidation[0]==true it indicates the name passed validation.
      if (!nameValidation[0]) {
        // Name has an error ....set the message(s) in the UI
        const errorMsgs = [
          "",
          "rename-partition-duplicate-error",
          "rename-partition-invalid-error",
          "rename-partition-invalid-char-error",
          "rename-partition-unobserved-not-allowed",
        ];
        for (let x = 1; x < nameValidation.length; x++) {
          if (nameValidation[x]) {
            if (errorMsg !== "") {
              errorMsg += "\n";
            }
            errorMsg += this.$t(errorMsgs[x]);
          }
        }
      }
      return errorMsg;
    },
    showCreateNewPartitionInput(show) {
      if (show) {
        this.showCreateNewPartition = true;
      } else {
        this.showCreateNewPartition = false;
      }

      this.invalidNameMessage = "";
      this.newPartitionHelperTextSlot = true;
      this.newPartitionInvalidMessageSlot = false;
      this.newParitionName = "";
    },
    // adjustClassListHeight() {
    //   // adjust the classes list height
    //   const headingElement = document.getElementsByClassName("move-classes-heading");
    //   const contentTopElement = document.getElementsByClassName("move-classes-content-top");
    //   const moveButtonElement = document.getElementsByClassName("move-classes-move-button")
    //   const headingElementHeight = `calc(${Math.ceil(
    //     headingElement[0].getBoundingClientRect().height
    //   )}px)`;
    //   const contentTopElementHeight = `calc(${Math.ceil(
    //     contentTopElement[0].getBoundingClientRect().height
    //   )}px)`;
    //   const moveButtonElementHeight = `calc(${Math.ceil(
    //     moveButtonElement[0].getBoundingClientRect().height
    //   )}px)`;
    //   // 8px is for padding bottom outside the list so as to leave a space between the selected list and
    //   // the bottom button with or without scrolling bar
    //   this.$refs.moveClassesList.style.height = `calc(100% - ${headingElementHeight} - ${contentTopElementHeight} - ${moveButtonElementHeight} - 8px)`;
    // },
    updatePartitionDropdown() {
      this.partitionDropdown.options = apis.getPartitionNamesArray();
      // should not show "unobserved" partition for observed classes
      const nodes = apis.getNodesByNames(null, this.selectedClasses);
      if (nodes !== null) {
        const found = nodes.find(node => node.data(constants.unobservedId) !== true);
        if (found) {
          this.partitionDropdown.options = this.partitionDropdown.options.filter(function(p) {
            return !utils.isUnobservedCategory(p);
          });
        }
      }
      // sort the partition names
      this.partitionDropdown.options = this.partitionDropdown.options.sort((a, b) => a.localeCompare(b));
      // append create new partition to the dropdown
      this.partitionDropdown.options.push(this.$t("move-classes-create-new-partition"));
    }
  },
  mounted() {
    eventBus.$on("MoveClassesPanel.showPanel", (showPanel) => {
      this.showMoveClassesPanel = showPanel;
      // reset selected value, disable button, and create partition
      this.disabledMove = true;
      eventBus.$emit("DropDownField.clearSelected");
      this.newParitionName = "";
      this.showCreateNewPartition = false;

      if (showPanel) {
        // reset dropdown choices
        this.updatePartitionDropdown();
        this.$nextTick(function () {
          // use one scroll bar for the entire panel, no need to adjust selected class list height anymore.
          // this.adjustClassListHeight();

          setTimeout(this.setPanelFocus, 100);
        });
      }
    }),
    eventBus.$on("MoveClassesPanel.setPanelHeight", (heights) => {
        if (heights.topHeight) {
          // anchor it below the batch action toolbar
          this.$refs.moveClassesContainer.style.top = `calc(${heights.topHeight} + 3rem + 0.0625rem)`;
        }
        if (heights.panelHeight) {
          // subtract the batch action toolbar height
          this.$refs.moveClassesContainer.style.height = `calc(${heights.panelHeight} - 3rem - 0.0625rem)`;
          // this.$refs.moveClassesContainer.style.maxHeight = `calc(${heights.panelHeight} - 3rem - 0.0625rem)`;
        }
      //}
    }),
    eventBus.$on("MoveClassesPanel.onPartitionChange", (moveToPartition) => {
      // This event is a callback from the drop down component to notify us that a drop down item has
      // been selected
      if (moveToPartition === this.$t("move-classes-create-new-partition")) {
        this.showCreateNewPartitionInput(true);
        this.disabledMove = true;
      } else {
        this.moveToPartition = moveToPartition;
        this.disabledMove = false;
        this.showCreateNewPartitionInput(false);
      }

      // use one scroll bar for the entire panel, no need to adjust selected class list height anymore.
      // this.$nextTick(function() {
      //   this.adjustClassListHeight();
      // })
    }),
    eventBus.$on("MoveClassesPanel.updatePartitionDropdown", () => {
      this.$nextTick(function() {
        this.updatePartitionDropdown();
      })
    })
  },
  beforeDestroy() {
    eventBus.$off("MoveClassesPanel.showPanel");
    eventBus.$off("MoveClassesPanel.setPanelHeight");
    eventBus.$off("MoveClassesPanel.onPartitionChange");
    eventBus.$off("MoveClassesPanel.updatePartitionDropdown");
  }
}
</script>

<style lang="scss" scoped>
.move-classes-container {
  position: fixed;
  right: 0;
  //height: calc(100% - 232px);
  //top: calc(232px);
  width: 30rem;
  //padding: 0.5rem 0.5rem 0 1rem;
  z-index: 2;
  background: var(--app-move-classes-background-color);
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.30);

  .move-classes-content-container {
    height: calc(100% - 4rem); // to account for the drop down, otherwise the drop down list will be very short
    max-height: calc(100% - 4rem); // subtract the move classes button
    overflow-y: auto;
  }

  .move-classes-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 0.5rem 1rem 1rem;

    h4 {
      padding-top: 0.5rem;
      align-self: flex-start;
    }

    .move-classes-close {
      &.bx--btn--ghost {
        color: var(--app-move-classes-close-button-color);
        min-height: 2rem;;
        min-width: 2rem;
        padding: 0;
        margin-top: -0.125rem; // to achieve 0.5rem top padding per design
      }
    }
  }

  .move-classes-content-top {
    padding: 0 5rem 0 1rem;

    .move-classes-description {
      padding-bottom: 1.5rem;
    }

    // .move-classes-create-new-partition {
    //   padding-top: 1.5rem;

    //   .bx--text-input {
    //     width: 18.75rem;
    //     height: 3rem;
    //   }
    // }

    .move-classes-selected {
      padding-top: 1.5rem;
      padding-bottom: 0.5rem;
      font-weight: 600;
      line-height: 1.125rem;
    }
  }

  // .move-classes-dropdown {
  //   .bx--dropdown {
  //     max-height: 3rem;
  //     height: 3rem;
  //   }
  // }

  .move-classes-selected-list {
    padding: 0 1rem 1rem 1rem;
    // overflow: auto;
  
    .move-classes-selected-class {
      display: block;
      // padding-top: 0.5rem;
      line-height: 1.125rem;

      // &:last-of-type {
      //   padding-bottom: 0.5rem;
      // }
    }
  }

  .move-classes-move-button {
    bottom: 0;
    position: fixed;
    width: 100%;
    max-width: unset;
  }
}
</style>

<style lang="scss">
.move-classes-dropdown {
  .bx--dropdown__wrapper {
    width: 18.75rem !important; // to override an inline style width

    .bx--dropdown {
      max-height: 3rem;
      height: 3rem;

      .bx--dropdown-item {
        height: 3rem;
        //border-top: 1px solid rgb(224, 224, 224);
        //border-bottom: 1px solid transparent;
        //margin: 0px 1rem;
        //padding-top: 0.9375rem;
        //padding-bottom: 0.9375rem;

        .bx--dropdown-link {
          height: 3rem;
          padding-top: 0.9375rem;
          padding-bottom: 0.9375rem;
        }
      }

      li:last-child a span {
        color: var( --app-move-classes-create-new-partition-color);
      }

      // .bx--list-box__menu-icon {
      //   top: 25%;
      // }
    }

    // .bx--dropdown-item:first-of-type {
    //   border-top-color: transparent;
    // }
  }
}

.move-classes-create-new-partition {
  padding-top: 1.5rem;
  width: 18.75rem;

  .bx--text-input {
    height: 3rem;
  }
}

.move-classes-close {
  &.bx--btn--ghost .bx--btn__icon {
    width: 1.25rem;
    height: 1.25rem;
    margin-left: 0.25rem;
  }
}
</style>