/********************************************************************
# Licensed Materials - Property of IBM
#
# (C) Copyright IBM Corp. 2020, 2021, 2022, 2023. All Rights Reserved.
#
# US Government Users Restricted Rights - Use, duplication or
# disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
**********************************************************************/

import store from "../../store";
import constants from "../constants";
import utils from "./graphUtil";
// import ecUtils from "./expandCollapseUtil";
import highlighter from "./highlightHandler";
import partitionHandler from "./partitionHandler";
import tooltip from "../../lib/graph/tooltipHandler";

const _name_delimiter_ = "__M2M_NAME_DELIMITER__";

const graphAPIHandler = {
  activateDragAndDrop(enable) {
    const cyExtMap = store.getters.getCYExtensionMap;

    const dndExtension = cyExtMap[store.getters.getKey];
    if (enable) {
      dndExtension.enable();
    } else {
      dndExtension.disable();
    }
  },

  getCurrentViewPartitionInfo() {
    const info = {
      names: [],
      colorMap: {},
    };
    const cy = store.getters.getCYInstance();
    const partitions = cy.nodes(`[filepath='${constants.clusterIdentifier}']`);
    partitions.forEach((partition) => {
      const name = partition.data("name");
      info.names.push(name);
      info.colorMap[name] = partition.data(
        "ui_partitionColor"
      );
    });
    return info;
  },

  getPartitionNamesArray(param) {
    var key = "name";
    if (typeof param !== "undefined") {
      key = param;
    }
    var array = [];
    const cy = store.getters.getCYInstance();
    const partitions = cy.nodes(`[filepath='${constants.clusterIdentifier}']`);
    partitions.forEach((partition) => {
      array.push(partition.data(key));
    });

    return array;
  },

  getCurrentViewsClusterColorMap() {
    var clusterColorMap = {};
    const cy = store.getters.getCYInstance();
    // The following line returns the expanded node's parent.
    const partitions = cy.nodes(`[filepath='${constants.clusterIdentifier}']`);
    partitions.forEach((partition) => {
      clusterColorMap[partition.data("name")] = partition.data(
        "ui_partitionColor"
      );
    });

    return clusterColorMap;
  },

  /**
   * Collapse all partitions
   * 
   *  @param {int} myDelay delay in ms, if it's < constants.minFunctionCallDelay or undefined, it's a sync call without running icon, 
   *  otherwise async call with the icon, 0 base on number of elements
   */
   collapseAllPartition(myDelay) {
    const cy = store.getters.getCYInstance();
    const func = () => {
      cy.startBatch();
      // const expandCollapseExtension = cy.expandCollapse("get");
      // let nodes = expandCollapseExtension.collapsibleNodes();
      // ecUtils.collapse(nodes);
      // //expandCollapseExtension.collapseAll();  
      // partitionHandler.collapseEdgesBetweenNodes(
      //   cy.nodes("node.cy-expand-collapse-collapsed-node")
      // );
      const partitions = cy.nodes(":parent").not("node.cy-expand-collapse-collapsed-node");
      if (partitions.length > 0) {
        partitionHandler.collapsePartitions(partitions);
      }
      cy.endBatch();
    }
    // use '!=' for checking 'undefined' and 'null'
    const delay = (myDelay != null) ? myDelay : utils.getFunctionCallDelay(cy);
    return this.callFunctionWithLoadingIcon(func, [], delay);
  },

  collapsePresetPartitions() {
    const cy = store.getters.getCYInstance();
    cy.startBatch();
    var nodes = cy.nodes("[collapse]");
    // if (nodes.length > 0) {
    //   // expandCollapseExtension.collapse(
    //   //   cy.nodes("[collapse]", { layoutBy: null })
    //   // );
    //   ecUtils.collapse(nodes);
    // }
    // partitionHandler.collapseEdgesBetweenNodes(
    //   cy.nodes("node.cy-expand-collapse-collapsed-node")
    // );
    if (nodes.length > 0) {
      partitionHandler.collapsePartitions(nodes);
    }
    cy.endBatch();
  },

  /**
   * Expand all partitions
   * 
   *  @param {int} myDelay delay in ms, if it's < constants.minFunctionCallDelay or undefined, it's a sync call without running icon, 
   *  otherwise async call with the icon, 0 base on number of elements
   */
  expandAllPartition(myDelay) {
    const cy = store.getters.getCYInstance();
    const func = function() {
      cy.startBatch();
      partitionHandler.expandAllPartitions(cy);
      cy.endBatch();
    }
    // use '!=' for checking 'undefined' and 'null'
    const delay = (myDelay != null) ? myDelay : utils.getFunctionCallDelay(cy);
    return this.callFunctionWithLoadingIcon(func, [], delay);
  },

  /**
   * Expand or collapse a partition
   *
   *  @param {string|Object} partition Name of partition or partition node.
   *  @param {int} myDelay delay in ms, if it's < constants.minFunctionCallDelay or undefined, it's a sync call without running icon, 
   *  otherwise async call with the icon, 0 base on number of elements
   */
   expandCollapsePartition(partition, myDelay) {
    const cy = store.getters.getCYInstance();
    // const me = this;
    const func = function(partition) {
      const ele = typeof partition === "string"
              ? utils.getNodeByName(cy, partition)
              : partition;
      if (ele && utils.isParentNode(ele) === true && utils.isVisible(ele) === true) {
        cy.startBatch();
        partitionHandler.expandCollapsePartition(ele);
        cy.endBatch();
        if (ele.isParent() !== true && ele.hasClass("cy-expand-collapse-collapsed-node") === true) {  // collapsed partition
          // me.showNodeDependencies(ele);
          cy.startBatch();
          highlighter.highlight(ele);
          cy.endBatch();
        } else if (ele.isParent() === true && utils.hasFocusedNodes(cy) === true) {
          cy.startBatch();
          // ele.removeClass(utils.getHighlightClasses());
          utils.fadeInElements(cy);
          cy.endBatch();          
        }
      }
    };
    // use '!=' for checking 'undefined' and 'null'
    const delay = (myDelay != null) ? myDelay : utils.getFunctionCallDelay(cy);
    return this.callFunctionWithLoadingIcon(func, [partition], delay);
  },

  /**
   * Expand partition
   *
   *  @param {string|Object} partition Name of partition or partition node
   */
  expandPartition(partition, options) {
    const ele = typeof partition === "string"
        ? utils.getNodeByName(store.getters.getCYInstance(), partition)
        : partition;
    let visible = true;
    if (options && options.checkNodeVisibility === true) {
      visible = utils.isVisible(ele);
    }
    if (ele && utils.isParentNode(ele) && utils.isCollapsedNode(ele) && visible === true) {
      partitionHandler.expandCollapsePartition(ele);
    }
  },

  /**
   *
   * @param {*} partitions The partitions that are selected, should not be greyed out.
   * @param {boolean} options.expandPartition boolean (optional)
   *                  options.delay
   */
  greyoutNotSelectedPartitions(partitions, options) {
    const cy = store.getters.getCYInstance();
    const func = function(partitions, options) {
      // cy.startBatch();
      const shownNodes = utils.applyPartitionFilters(/*cy.nodes()*/utils.getAllNodes(cy), partitions);
      // update in-memory filters
      const filterStore = utils.getScratch(cy, constants.m2mScratchFilters);
      filterStore[constants.filterTypePartition] = partitions;
      // handle expandPartition
      if (options && options.expandPartition === true && partitions.length > 0) {
        const partitions = shownNodes.filter("node.cy-expand-collapse-collapsed-node");
        if (partitions.empty() !== true) {
          partitionHandler.expandPartitions(partitions);
        }
      }
      // cy.endBatch();
    }
    const myDelay = (options && options.delay != null) ? options.delay : null;
    // use '!=' for checking 'undefined' and 'null'
    const delay = (myDelay != null) ? myDelay : utils.getFunctionCallDelay(cy);
    return this.callFunctionWithLoadingIcon(func, [partitions, options], delay);
  },

  /**
   * @param {string []} usecases The use cases that are selected, should not be filtered out.
   * @param {boolean} options.expandPartition boolean (optional)
   */
  filterNotSelectedUseCases(usecases, options) {
    const cy = store.getters.getCYInstance();
    const func = function(usecases, options) {
      // cy.startBatch();
      const shownNodes = utils.applyUseCaseFilters(/*cy.nodes()*/utils.getAllNodes(cy), usecases);
      // update in-memory filters
      const filterStore = utils.getScratch(cy, constants.m2mScratchFilters);
      filterStore[constants.filterTypeUseCase] = usecases;
      // handle expandPartition
      if (options && options.expandPartition === true && usecases.length > 0) {
        const partitions = shownNodes.filter("node.cy-expand-collapse-collapsed-node");
        if (partitions.empty() !== true) {
          partitionHandler.expandPartitions(partitions);
        }
      }
      // cy.endBatch();
    }
    const myDelay = (options && options.delay != null) ? options.delay : null;
    // use '!=' for checking 'undefined' and 'null'
    const delay = (myDelay != null) ? myDelay : utils.getFunctionCallDelay(cy);
    return this.callFunctionWithLoadingIcon(func, [usecases, options], delay);
  },

  /**
   * @param {string []} callRange Number of runtime calls range.
   * @param {*} options (optional)
   */
  filterEdgesByRuntimeCalls(callRange, options) {
    const cy = store.getters.getCYInstance();
    const func = function(callRange) {
      // cy.startBatch();
      utils.applyRuntimeCallFilters(utils.getAllEdges(cy), callRange);
      // update in-memory filters
      const scratch = utils.getScratch(cy);
      const defaultRange = scratch[constants.m2mScratchRuntimeCallRange];
      let newCallRange = callRange;
      if (defaultRange && Array.isArray(defaultRange) && utils.arraysEqual(callRange, defaultRange, false)) {
        newCallRange = [];
      }
      const filterStore = scratch[constants.m2mScratchFilters] || utils.getScratch(cy, constants.m2mScratchFilters);
      filterStore[constants.filterTypeRuntimeCall] = newCallRange;
      // cy.endBatch();
    }
    const myDelay = (options && options.delay != null) ? options.delay : null;
    // use '!=' for checking 'undefined' and 'null'
    const delay = (myDelay != null) ? myDelay : utils.getFunctionCallDelay(cy);
    return this.callFunctionWithLoadingIcon(func, [callRange], delay);
  },

  /**
   * @param {string []} labels The labels that are selected, should not be filtered out.
   * @param {boolean} options.expandPartition boolean (optional)
   */
   filterNodesByLabels(labels, options) {
    const cy = store.getters.getCYInstance();
    const func = function(labels, options) {
      // cy.startBatch();
      const shownNodes = utils.applyLabelFilters(/*cy.nodes()*/utils.getAllNodes(cy), labels);
      // update in-memory filters
      const filterStore = utils.getScratch(cy, constants.m2mScratchFilters);
      filterStore[constants.filterTypeLabel] = labels;
      // handle expandPartition
      if (options && options.expandPartition === true && labels.length > 0) {
        const partitions = shownNodes.filter("node.cy-expand-collapse-collapsed-node");
        if (partitions.empty() !== true) {
          partitionHandler.expandPartitions(partitions);
        }
      }
      // cy.endBatch();
    }
    const myDelay = (options && options.delay != null) ? options.delay : null;
    // use '!=' for checking 'undefined' and 'null'
    const delay = (myDelay != null) ? myDelay : utils.getFunctionCallDelay(cy);
    return this.callFunctionWithLoadingIcon(func, [labels, options], delay);
  },

  /**
   * @param {string []} filters filters of showing edges.
   * @param {Object} options (optional)
   */
  filterEdges(filters, options) {
    const cy = store.getters.getCYInstance();
    const func = function(filters) {
      // cy.startBatch();
      const defaultFilters = utils.getDefaultShowEdgeFilter();
      const newFilters = filters || defaultFilters;
      if (newFilters) {
        const filterStore = utils.getScratch(cy, constants.m2mScratchFilters);
        const oldFilters = filterStore[constants.filterTypeShowEdge] || defaultFilters;
        const dependencyFilterChanged = !((oldFilters.includes(constants.showDataDependencies) && newFilters.includes(constants.showDataDependencies)) ||
                                          (!oldFilters.includes(constants.showDataDependencies) && !newFilters.includes(constants.showDataDependencies)));        
        utils.applyShowEdgeFilters(utils.getAllEdges(cy, { includeDataDependencyEdges: dependencyFilterChanged }), newFilters);
        // update in-memory filters
        if (utils.arraysEqual(newFilters, defaultFilters, true) !== true) {
          filterStore[constants.filterTypeShowEdge] = filters;
        } else {
          delete filterStore[constants.filterTypeShowEdge];
        }
      }
      // cy.endBatch();
    }
    const myDelay = (options && options.delay != null) ? options.delay : null;
    // use '!=' for checking 'undefined' and 'null'
    const delay = (myDelay != null) ? myDelay : utils.getFunctionCallDelay(cy);
    return this.callFunctionWithLoadingIcon(func, [filters], delay);
  },

  /**
   *  Apply filters
   * 
   *    options: {
   *      filters: {
   *        'greyout': ['partition-1', 'partition-2', ...]
   *        'filter-usecase': ['usecase-1', 'usecase-2', ...]
   *      },
   *      showBusyIndicator: boolean (optional, default is false)
   *    },
   *  @param {Object} options Filter information object (required)
   * 
   */
  applyFilters(options) {
    const cy = store.getters.getCYInstance();
    const me = this;
    const func = function (options) {
      if (options) {
        if (options.filters && Object.keys(options.filters).length > 0) {
          cy.startBatch();
          const partitions = options.filters[constants.filterTypePartition];
          // filter partitions
          if (partitions && Array.isArray(partitions) && me.isFilterChanged(constants.filterTypePartition, partitions) === true) {
            me.greyoutNotSelectedPartitions(partitions, {delay: 0});  // 'delay:0' for not showing busy icon
          }
          // filter use cases
          const usecases = options.filters[constants.filterTypeUseCase];
          if (usecases && Array.isArray(usecases) && me.isFilterChanged(constants.filterTypeUseCase, usecases) === true) {
            me.filterNotSelectedUseCases(usecases, {delay: 0}); // 'delay:0' for not showing busy icon
          }
          // filter edges by runtime call range
          const callRange = options.filters[constants.filterTypeRuntimeCall];
          if (callRange && Array.isArray(callRange) && me.isFilterChanged(constants.filterTypeRuntimeCall, callRange) === true) {
            me.filterEdgesByRuntimeCalls(callRange, {delay: 0});  // 'delay:0' for not showing busy icon
          }
          // filter nodes by labels
          const labels = options.filters[constants.filterTypeLabel];
          if (labels && Array.isArray(labels) && me.isFilterChanged(constants.filterTypeLabel, labels) === true) {
            me.filterNodesByLabels(labels, {delay: 0}); // 'delay:0' for not showing busy icon
          }
          // filter edges
          const edgeFilters = options.filters[constants.filterTypeShowEdge];
          if (edgeFilters && Array.isArray(edgeFilters) && me.isFilterChanged(constants.filterTypeShowEdge, edgeFilters) === true) {
            me.filterEdges(edgeFilters, {delay: 0});  // 'delay:0' for not showing busy icon
          }          
          //utils.shiftElements(shownNodes.filter(node => !node.removed() && utils.isVisible(node)));
          cy.endBatch();
          utils.shiftPartitions(cy);  // shift partition nodes to show/redraw disappeared edges (work around)
        }
      }
    };
    const delay = (options && options.showBusyIndicator === true) ? utils.getFunctionCallDelay(cy) : 0;
    return this.callFunctionWithLoadingIcon(func, [options], delay);
  },

  /**
   *  Apply default filters.
   * 
   *    options: {
   *      showBusyIndicator: boolean (optional, default is false)
   *    },
   * 
   *  @param {Object} options function options (optional)
   * 
   */
  applyDefaultFilters(options) {
    const cy = store.getters.getCYInstance();
    const me = this;
    const func = function (/*options*/) {
      cy.startBatch();

      // apply default 'show edge' filter 
      me.filterEdges();

      cy.endBatch();
    };
    const delay = (options && options.showBusyIndicator === true) ? utils.getFunctionCallDelay(cy) : 0;
    return this.callFunctionWithLoadingIcon(func, [options], delay);
  },

  /**
   *  Reset filters
   * 
   *    options: {
   *      showBusyIndicator: boolean (optional, default is false)
   *    },
   * 
   *  @param {Object} options function options (optional)
   * 
   */
  resetFilters(options) {
    const cy = store.getters.getCYInstance();
    const me = this;
    const func = function (options) {
      cy.startBatch();
      const filterClasses = utils.getAllFilterClasses(); 
      const allFilteredElements = utils.getAllElements(cy).filter(ele => {
        return ele.classes().some(c => filterClasses.indexOf(c) >= 0);
      });
      if (allFilteredElements.length > 0) {
        allFilteredElements.removeClass(filterClasses);
      }
      // apply default 'show edge' filter 
      me.applyDefaultFilters();
      //utils.shiftElements(shownNodes.filter(node => !node.removed() && utils.isVisible(node)));
      cy.endBatch();
      utils.shiftPartitions(cy);  // shift partition nodes to show/redraw disappeared edges (work around)
      if (options && options.zoomToFit === true) {
        // utils.shiftElements(allFilteredElements.filter("node").filter(ele => !ele.removed() && utils.isVisible(ele)));
        utils.cyFit(cy, true);
      }
      // clean up in-memory filters
      const filterStore = utils.getScratch(cy, constants.m2mScratchFilters);
      Object.keys(filterStore).forEach((filter) => {
        if (filter === constants.filterTypeShowEdge) {  // clear in-memory 'show edge' filters
          delete filterStore[constants.filterTypeShowEdge];
        } else if (filterStore[filter]) {
          if (Array.isArray(filterStore[filter])) {
            filterStore[filter].splice(0, filterStore[filter].length);
          } else {
            filterStore[filter] = undefined;
          }
        }
      });
    };
    const delay = (options && options.showBusyIndicator === true) ? utils.getFunctionCallDelay(cy) : 0;
    return this.callFunctionWithLoadingIcon(func, [options], delay);
  },

  /**
   * Sync filters with all children for given partitions.
   *
   *  @param {[]} partitions given partitions
   *  @param {Object} options function options (optional)
   */
  syncPartitionsFilter(partitions, options) {
    const cy = store.getters.getCYInstance();
    const me = this;
    const func = function (/*options*/) {
      // const partitions = ptns || cy.nodes(":parent, .cy-expand-collapse-collapsed-node");
      if (partitions.empty() !== true && me.anyFiltersApplied() === true) {
          cy.startBatch();
          const filterClasses = utils.getAllFilterClassesForNodes(); 
          partitions.forEach(partition => {
            const filters = me.getPartitionFilter(partition);
            partition.removeClass(filterClasses);
            partition.addClass(filters);
          })
          cy.endBatch();
      }
    };
    const delay = (options && options.showBusyIndicator === true) ? utils.getFunctionCallDelay(cy) : 0;
    return this.callFunctionWithLoadingIcon(func, [options], delay);
  },

  getPartitionFilter(partitionNode) {
    const children = utils.getChildrenNode(partitionNode);
    let commonFilters = [];
    if (children.empty() !== true) {
      commonFilters = children[0].classes();
      const allFilters = utils.getAllFilterClassesForNodes();
      children.every(node => {
        commonFilters = utils.getCommonItems(commonFilters, node.classes(), allFilters);
        return (commonFilters.length > 0);
      });
    }
    return commonFilters;
  },

  /**
   * Get visibility of node
   *
   *  **Please note: This api may take some time to run**
   *
   *  @param {string} nodeName Name of node
   */
  getNodeVisibility(nodeName) {
    const cy = store.getters.getCYInstance();
    const returnObj = {
      classNode: utils.getNodeByName(cy, nodeName),
      visibility: constants.dataNodeIsNotFound,
    };
    if (returnObj.classNode !== null) {
      const parentNode = returnObj.classNode.parent();
      let isCollapsed = !parentNode || parentNode.length < 1;
      const isFilteredOut = utils.isFilteredNode(returnObj.classNode);
      const viewPort = cy.extent();
      const nodeBoundingbox = returnObj.classNode.boundingbox();
      const isInViewPort =
        nodeBoundingbox.x1 > viewPort.x1 &&
        nodeBoundingbox.x2 < viewPort.x2 &&
        nodeBoundingbox.y1 > viewPort.y1 &&
        nodeBoundingbox.y2 < viewPort.y2;
      if (isInViewPort) {
        if (isFilteredOut && isCollapsed) {
          returnObj.visibility = constants.dataNodeIsFilteredOutAndCollapsed;
        } else if (!isFilteredOut && !isCollapsed) {
          returnObj.visibility = constants.dataNodeIsShown;
        } else {
          returnObj.visibility = isFilteredOut
            ? constants.dataNodeIsFilteredOut
            : constants.dataNodeIsCollapsed;
        }
      } else {
        if (isFilteredOut && isCollapsed) {
          returnObj.visibility =
            constants.dataNodeIsOutOfViewPortAndFilteredOutAndCollapsed;
        } else if (!isFilteredOut && !isCollapsed) {
          returnObj.visibility = constants.dataNodeIsOutOfViewPort;
        } else {
          returnObj.visibility = isFilteredOut
            ? constants.dataNodeIsOutOfViewPortAndFilteredOut
            : constants.dataNodeIsOutOfViewPortAndCollapsed;
        }
      }
    }
    return returnObj;
  },

  /*
   * return partitions that are not greyed out in the graph
   */
  getSelectedPartiions() {
    let selectedPartitions = [];
    const cy = store.getters.getCYInstance();
    const partitions = cy.nodes(`[filepath='${constants.clusterIdentifier}']`)
                          .difference(`.${constants.filterTypePartition}, .${constants.m2mFilteredCollapsedNodeClass}`);
    partitions.forEach((partition) => {
      selectedPartitions.push(partition.data('name'));
    });

    return selectedPartitions;
  },

  highlight(element, myDelay) {
    const ele = typeof element === "string"
        ? utils.getNodeByName(store.getters.getCYInstance(), element)
        : element;
    const cy = ele.cy();
    const func = function(ele) {
      if (utils.isVisible(ele) === true && ele.removed() !== true) {
        cy.startBatch();
        highlighter.highlight(ele);
        cy.endBatch();
      }
    };
    // use '!=' for checking 'undefined' and 'null'
    const delay = (myDelay != null) ? myDelay : utils.getFunctionCallDelay(cy);
    return this.callFunctionWithLoadingIcon(func, [ele], delay);
  },

  highlightAll(ele, options) {
    const cy = ele.cy();
    const func = function(ele) {
      cy.startBatch();
      highlighter.highlightAll(ele, options);
      cy.endBatch();
    };
    // const delay = options.myDelay || utils.getFunctionCallDelay(cy);
    // if (delay < constants.minFunctionCallDelay) {
      func(ele);
    // } else {
    //   return this.callFunctionWithLoadingIcon(func, [ele, options], delay);
    // }
  },

  hoverOn(ele) {
    highlighter.hoverOn(ele);
  },

  hoverOff(ele) {
    highlighter.hoverOff(ele);
  },

  /**
   * Clean up (unhighlight) all highlighted elements
   */
  cleanupAllHighlights(options) {
    highlighter.removeHighlights(null, options);
    this.clearCurrentCY();
  },

  // injectDependencyEdges() {
  //   const cy = store.getters.getCYInstance();
  //     const json = store.getters.getJson;
  //       const depData = json[constants.dependendyKey];
  //       var edges = [];
  
  //       depData.links.forEach(element => {
  //       var source = null;
  //       var target = null;
  
  
  //       // var connectToUnobserved = false;
  //       // check if source is in closed/opened view
  //       let sourceNodeElement = utils.getNodeByName(
  //         cy,
  //         element.source
  //       );
  //       if ( sourceNodeElement != null) {
  //         // connectToUnobserved  = utils.isUnobservedCategory(sourceNodeElement.data("category"));
  //         source = sourceNodeElement.data("name");
  //       } 
  
  //       // if ( !connectToUnobserved ) {
  //         // check if source is in closed/opened view
  //         let targetNodeElement = utils.getNodeByName(
  //           cy,
  //           element.target
  //         );
  //         if ( targetNodeElement != null) {
  //           // connectToUnobserved  = utils.isUnobservedCategory(targetNodeElement.data("category"));
  //           target = targetNodeElement.data("name");
  //         } 
  //       // }
  
  
  
  //       if ( /*!connectToUnobserved &&*/ target != null && source != null ) {
  //         var link = {};
  //         link.source = source;
  //         link.target = target;
  //         link.dep = true;
  //         var data = {};
  //         data.data = link;
  //         edges.push(data);
  //       }
  //     });
  
  //     const eles = cy.add({edges: edges});
  
  //     // hide all dep edges by default
  //     eles.forEach((edge) => {
  //       edge.addClass("invisible");
  //     });
  //     // document.querySelector(
  //     //   ".show-dependicies-toggle .bx--toggle-input"
  //     // ).checked = false;
  //   },

  //   showDependencyOverlay(enabled) {
  //     const cy = store.getters.getCYInstance();
  
  //     // click single clicked dependencies
  //     this.clearDependencies();
  //     if (enabled ) {
  //         const existingEdges = utils.getAllEdges(cy, {includePartitionEdges: true, includeDataDependencyEdges: true});
  //         existingEdges.forEach((edge) => {
  //           if ( !edge.data('dep') )
  //           {
  //             // hide existing 
  //             edge.addClass("invisible");
  //           } else {
  //             edge.removeClass("invisible");
  //           }
  //         });
  
  
  //         store.commit("setDynamicDependencyEdgesForEntireCY", true);
  
  //     } else {
  //       store.commit("setDynamicDependencyEdgesForEntireCY", false);
  
  //       const existingEdges = utils.getAllEdges(cy, {includePartitionEdges: true, includeDataDependencyEdges: true});
  
  //       existingEdges.forEach((edge) => {
  //         if ( !edge.data('dep') )
  //         {
  //           edge.removeClass("invisible");
  //         } else {
  //           edge.addClass("invisible");
  //         }
  //       });
  //     }
  //   },
  
  injectDependencyEdges() {
    const cy = store.getters.getCYInstance();
    const json = store.getters.getJson;
    const depData = json[constants.dependendyKey];
    let edges = [];
    let errors = [];
  
    depData.links.forEach(element => {
      let source = cy.getElementById(element.source);
      let target = (source && source.length > 0) ? cy.getElementById(element.target) : undefined;
      if (source && target && source.length === 1 && target.length === 1) {
        const data = JSON.parse(JSON.stringify(element));
        data.dep = true;
        // data.id = `dep_${idx}`;
        edges.push({ data: data });
      } else {
        errors.push(element);
      }    
    });

    cy.startBatch();
    const eles = cy.add({edges: edges});
    // eles.addClass('invisible');
    eles.addClass(constants.filterTypeShowEdge);
    cy.endBatch();

    // document.querySelector(
    //   ".show-dependicies-toggle .bx--toggle-input"
    // ).checked = false;

    return {edges: eles, errors: errors};
  },

  showDependencyOverlay(enabled) {
    const cy = store.getters.getCYInstance();

    // clear single clicked dependencies
    this.clearDependencies();
    const existingEdges = utils.getAllEdges(cy, {includePartitionEdges: true, includeDataDependencyEdges: true});
    const depEdges = existingEdges.filter((edge) => {
      return edge.data('dep') === true;
    });

    cy.startBatch();
    if (enabled) {
      depEdges.removeClass("invisible");
      existingEdges.difference(depEdges).addClass("invisible");
      store.commit("setDynamicDependencyEdgesForEntireCY", true);
    } else {
      store.commit("setDynamicDependencyEdgesForEntireCY", false);
      depEdges.addClass("invisible");
      existingEdges.difference(depEdges).removeClass("invisible");
    }
    cy.endBatch();
  },
  
  // Remove existing dependencies by single click
  clearDependencies() {
    const depEles = store.getters.getDynamicDependencyEdges;
    const eles = depEles[store.getters.getKey];
    if (eles && eles.length > 0) {
      const collection = eles[0].cy().collection().add(eles);
      collection.addClass("invisible");
    }
    store.commit("setDynamicDependencyEdges", []);
  },
  
  clearCurrentCY () {
    //this.clearDependencies();
   // restore cache classes
   const edgeFilterClasses = store.getters.getEdgeFilterClasses;
   const filterClasses = edgeFilterClasses[store.getters.getKey];
   for (const key in filterClasses) {
      filterClasses[key].forEach((edge) => {
       edge.addClass(key);
      });
   }
   
   store.commit("setEdgeFilterClasses", {});

  },

  showNodeDependencies(nodeEle) {
    if (utils.isShowingDataDependencies() === false) {
      const node = typeof nodeEle === "string"
      ? utils.getNodeByName(store.getters.getCYInstance(), nodeEle)
      : nodeEle;
      if (node.isNode() === true && node.isParent() !== true) {
        const cy = node.cy();
        const eles = [];
        cy.startBatch();
        node.connectedEdges().forEach((edge) => {
          if ( true === edge.data('dep') ) {
            edge.removeClass(["invisible", constants.filterTypeShowEdge]);
            eles.push(edge);
          } 
        });
        cy.endBatch();
        store.commit("setDynamicDependencyEdges", eles);
      }
    }
  },  
  enableConnectedEdges(nodeEle) {
    //if (utils.isShowingDataDependencies() === false) {
      const node = typeof nodeEle === "string"
      ? utils.getNodeByName(store.getters.getCYInstance(), nodeEle)
      : nodeEle;
      if (node.isNode() === true && node.isParent() !== true) {
        const cy = node.cy();
        const eles = [];
        const filterClasses = {};
        cy.startBatch();
        node.connectedEdges().forEach((edge) => {
          // if ( true === edge.data('dep') ) {
          //   edge.removeClass("invisible");
          //   eles.push(edge);
          // } 
          // TODO
          // 1. save current filteted edges (runtime call numbers filter and two more from the new api)
          // 
          // 2. remove filter classes
          // 
          const edgeFilterClasses = utils.getAllFilterClassesForEdges();
          edgeFilterClasses.forEach((cls) => {
            if ( edge.hasClass(cls)) {
              if ( ! (cls in filterClasses) ) {
                filterClasses[cls] = [];
              }
              filterClasses[cls].push (edge);
              edge.removeClass(cls);
            }
          });
        });
        cy.endBatch();
        store.commit("setDynamicDependencyEdges", eles);
        store.commit("setEdgeFilterClasses", filterClasses);
      }
    //}
  },  

  getNextPartitionColor() {
    const partitions = this.getPartitionNamesArray();
    const colors = store.getters.getColorInfo.partition_colors;
    var colorLength = colors.length;
    var colorIndex = (partitions.length + 1) % colorLength;
    var bgColor = colors[colorIndex];

    if (partitions.length + 1 <= colorLength) {
      // use next unused color
      bgColor = "";
      var colorMap = this.getCurrentViewsClusterColorMap();

      var colorValues = Object.keys(colorMap).map(function (key) {
        return colorMap[key];
      });

      for (
        var k = 0;
        k < colors.length && bgColor == "";
        k++
      ) {
        var cc = colors[k];
        if (cc && !colorValues.includes(cc)) {
          bgColor = cc;
        }
      }
    }
    return bgColor;
  },

  addDropTargetToNewPartition(event, cy, movingOutPartition) {
    const partitions = this.getPartitionNamesArray();
    const partitionids = this.getPartitionNamesArray("id");
    var partitionNameAndIds = partitions.concat(partitionids);
    var counter = 1;
    var partitionName = "";
    while (!partitionName) {
      // generate new partition name
      var name = constants.newPartitionNamePrefix + counter;
      if (partitionNameAndIds.includes(name) == false) {
        partitionName = name;
      }
      counter++;
    }
    const bgColorMovingOutPartition = movingOutPartition.data(
      "ui_partitionColor"
    );
    this.createNewPartition(cy, { name: partitionName }, bgColorMovingOutPartition);
    // update model
    this.changeClassPartition(event.target, {
      partition: partitionName,
      updateCY: true,
    });
  },

  removeIsolatedUnobservedNodesFromGraph() {
    const cy = store.getters.getCYInstance();
    const isolated= cy.nodes().filter((n) => {
      return !n.data("parent") && true === n.data("unobserved");
    });
    cy.remove(isolated);
  },

  hasIsolatedUnobservedNodes() {
    const cy = store.getters.getCYInstance();
    const isolated= cy.nodes().filter((n) => {
      return !n.data("parent") && true === n.data("unobserved");
    });
    return isolated.length != 0;
  },


  /**
   * Show the unobserved nodes with their dependencies
   * 
   * addUnobservedNodesToGraph (
   * {
   *   nodes: [
   *            { name: "DTBroker3MDB", parent: "New Partition" }
   *            { name: "AnotherUnobservedWithoutParent"}
   *          ],
   *   showBusyIndicator: boolean (optional, default is false)
   * },
   * function() { 
   *  // update UI filter menu
   *  graphvue.updateGraphSummaryPanel(false, true);
   *  // update custom view side panel
   *  graphvue.updateCustomViewSidePanel();
   * }
   * )
   * @param {*} options 
   * @param {*} callback The function to call after the new nodes layout is finished.
   */


  addUnobservedNodesToGraph(args, callback) {

/*    options = {
  nodes: [
    { data: { id: 'DTBroker3MDB', ui_partitionColor: "#dddddd", dep: true}}
  ],
  edges: [
    { data: { source: 'DTBroker3MDB', target: 'MDBStats' , dep: true} },
    { data: { source: 'DTStreamer3MDB', target: 'MDBStats', dep: true } }

  ]
},

*/
  const me = this;
  function run(myCallBack) {
      // check edges, make sure the corresponding partitions are open
    const cy = store.getters.getCYInstance();
    
    cy.startBatch();
    const unobservedDataSource = store.getters.getUnobservedData()[constants.nodeId];
    var unobservedData = {};
    unobservedDataSource.forEach(function(e) {
      unobservedData[e.name]=e;
    });
    var nodes = [];
    var newPartitions = [];
    var nodesToExistingPartitions = [];
    var partitions = me.getPartitionNamesArray();
    var existingPartitions = [];
    existingPartitions.concat(partitions);
      args.nodes.forEach(function(e){
        var ele = utils.clone(unobservedData[e.name]);
        ele.id = ele.name || ele.id;
        ele.name = ele.name  || ele.id;
        ele.unobserved = true;
        // delete ele.category;
        delete ele.cydata;
        delete ele.parent;
        if ( !e.parent ) {
          nodes.push({data:ele});
        } else {
          var parent = e.parent;
          
          if (parent && !partitions.includes(parent)) {
            const colors = store.getters.getColorInfo.partition_colors;
            var colorLength = colors.length;
            var colorIndex = (partitions.length + 1) % colorLength;
            var bgColor = me.getNextPartitionColor();
            if (bgColor == "") {
              bgColor = colors[colorIndex];
            }
            var data = {};
            data.p = parent;
            data.d = {
              updateModel: true,
              partitionColor: bgColor
            };
            partitions.push(parent);
            newPartitions.push(data);
          } else if ( parent ) {
            me.expandPartition(parent, {checkNodeVisibility: false});
          }
          nodesToExistingPartitions.push({name: e.name, parent: parent});
          ele.category = parent;
          ele.parent = parent
          nodes.push({data: ele});
        }
    });
    var options = {nodes: nodes};
    newPartitions.forEach(function(e) {
      var data = e.d;
      data.classNumber = 0;
      me.addPartition(e.p, cy, data);
    });
    cy.endBatch();
      
    /*
    let edgeNodes = new Set();
    options.edges.forEach( element =>{
      edgeNodes.add(element.data.source);
      edgeNodes.add(element.data.target);
    });

    edgeNodes.forEach(element=>{
      const node = utils.getNodeByName(cy, element);
      if ( node ) {
        const parent = utils.getNodeParent(node);
        if ( utils.isCollapsedNode(parent)) {
          partitionHandler.expandCollapsePartition(parent);
        }
      }
    });
  */

    
    const existingData = cy.nodes();
    existingData.lock();
    const eles = cy.add(options);
    eles.layout({name: "cola", stop: ()=>{
      cy.startBatch();
      existingData.unlock();
      // make sure the nodes are visible
      me.unfilterClassNodes(eles);
      if (nodesToExistingPartitions.length > 0) {
        me.changeClassesPartition(eles.filter(node => {
            const name = node.data('name');
            return nodesToExistingPartitions.some(s => s.name === name);
          }), {
          partition: nodesToExistingPartitions[0].parent,
          updateCY: true,
        });
      }
      cy.center(eles);
      cy.endBatch();
      if ( myCallBack && myCallBack instanceof Function ) {
        myCallBack();
      }
    }}).run();  
  }

  if (args && args.showBusyIndicator === true) {
    (async () => {
      await me.showLoadingIcon(true);
      try {
        run(() => {
          if ( callback && callback instanceof Function ) {
            callback();
          }
          me.showLoadingIcon(false);
        });
      } catch(err) {
        me.showLoadingIcon(false);
      }
    })();
  } else {
    run(() => {
      if ( callback && callback instanceof Function ) {
        callback();
      }
    });
  }

  },

  /**
   * Pan the node to center of view port
   *
   *  @param {string|Object} ele Node element or name of node
   */
  panNodeToCenter(ele) {
    const node = typeof ele === "string"
        ? utils.getNodeByName(store.getters.getCYInstance(), ele)
        : ele;
    if (node && utils.isVisible(node) === true) {
      node.cy().center(node);
    }
  },

  /**
   * Unfilter partition
   *
   *  @param {string|Object} partition Partition name or partition node
   */
  // unfilterPartition(partition) {
  //   const partitionNode =
  //     typeof partition === "string"
  //       ? utils.getNodeByName(store.getters.getCYInstance(), partition)
  //       : partition;
  //   if (partitionNode && partitionNode.length > 0) {
  //     partitionNode.removeClass(`${constants.m2mFilteredClass} ${constants.m2mFilteredCollapsedNodeClass}`);
  //     let nodes;
  //     if (utils.isCollapsedNode(partitionNode)) {
  //       nodes = utils.getCollapsedPartitionChildren(partitionNode).filter("node");
  //     } else {
  //       nodes = partitionNode.children();
  //     }
  //     if (nodes && nodes.length > 0) {
  //       nodes.filter(`.${constants.m2mFilteredClass}, .${constants.m2mFilteredCollapsedNodeClass}`)
  //         .removeClass(`${constants.m2mFilteredClass} ${constants.m2mFilteredCollapsedNodeClass}`);
  //     }
  //   }
  // },

  /**
   * Unfilter class node
   *
   *  @param {string|Object} clazz Class name or class node
   */
  // unfilterClassNode(clazz) {
  //   const classNode = typeof partition === "string"
  //           ? utils.getNodeByName(store.getters.getCYInstance(), clazz)
  //           : clazz;
  //   if (classNode && classNode.length > 0) {
  //     const nodeClasses = classNode.classes();
  //     const filterStore = utils.getScratch(classNode.cy(), constants.m2mScratchFilters);
  //     // partition filtered
  //     if (nodeClasses.indexOf(constants.m2mFilteredClass) !== -1 || 
  //         nodeClasses.indexOf(constants.m2mFilteredCollapsedNodeClass) !== -1) {
  //       const notFilteredPartitions = filterStore[constants.m2mFilteredClass];
  //       notFilteredPartitions.push(classNode.data("category"));
  //       this.greyoutNotSelectedPartitions(notFilteredPartitions, 1);
  //     }
  //     // use case filtered 
  //     if (nodeClasses.indexOf(constants.m2mFilterClassUseCase) !== -1) {
  //       const semantics = classNode.data('semantics');
  //       let notFilteredUseCases = filterStore[constants.m2mFilterClassUseCase];
  //       if (!semantics || semantics.length < 1) {
  //         notFilteredUseCases.splice(0, notFilteredUseCases.length)
  //       } else {
  //         notFilteredUseCases.push(semantics[0]);
  //       }
  //       this.filterNotSelectedUseCases(notFilteredUseCases, 1);
  //     }
  //   }
  // },

  /**
   * Unfilter class nodes
   *
   *  @param {string|Object} nodes Collection of class nodes
   */
  unfilterClassNodes(nodes) {
    if (nodes && nodes.length > 0) {
      const cy = nodes[0].cy();
      const filterStore = utils.getScratch(cy, constants.m2mScratchFilters);
      const partitionFilters = filterStore[constants.filterTypePartition] || [];
      const usecaseFilters = filterStore[constants.filterTypeUseCase] || [];
      const labelFilters = filterStore[constants.filterTypeLabel] || [];
      const visiblePartitions = [];
      const visibleUsecases = [];
      const visibleLabels = [];
      let clearUseCaseFilters = false;
      let clearLabelFilters = false;

      nodes.forEach(node => {
        const data = node.data();
        // check node's partition 
        if (partitionFilters.length > 0 && data.category && 
            partitionFilters.indexOf(data.category) === -1 && 
            visiblePartitions.indexOf(data.category) === -1) {
          visiblePartitions.push(data.category);
        }
        // check node's use cases
        if (usecaseFilters.length > 0 && clearUseCaseFilters === false) {
          const semantics = data.semantics;
          if (semantics && Array.isArray(semantics) && semantics.length > 0) {
            if (semantics.some(s => usecaseFilters.indexOf(s) >= 0) !== true &&
                semantics.some(s => visibleUsecases.indexOf(s) >= 0) !== true) {
              visibleUsecases.push(semantics[0]);
            }
          } else {
            clearUseCaseFilters = true;
          }
        }
        // check node's labels
        if (labelFilters.length > 0 && clearLabelFilters === false) {
          const labels = utils.getLabelsByClassName(data.name);
          if (labels.length > 0) {
            if (labels.some(s => labelFilters.indexOf(s) >= 0) !== true &&
                labels.some(s => visibleLabels.indexOf(s) >= 0) !== true) {
              visibleLabels.push(labels[0]);
            }
          }
          if (visibleLabels.length === 0) {
            if (data[constants.unobservedId] === true) {
              visibleLabels.push(constants.unobservedLabelFilterValue); // add "Unobserved" label for unobserved class
            } else {
              clearLabelFilters = true;
            }
          }
        }
      });

      // update partition filter
      const allNodes = /*cy.nodes()*/utils.getAllNodes(cy);
      if (visiblePartitions.length > 0) {
        _unfilterPartitions(allNodes, visiblePartitions);
      }
      // update use case filter
      if (clearUseCaseFilters === true) {
        usecaseFilters.splice(0, usecaseFilters.length);
        this.filterNotSelectedUseCases([], {delay: 0});
      } else if (visibleUsecases.length > 0) {
        _unfilterUseCases(allNodes, visibleUsecases);
      }
      // update label filter
      if (clearLabelFilters === true) {
        labelFilters.splice(0, labelFilters.length);
        this.filterNodesByLabels([], {delay: 0});
      } else if (visibleLabels.length > 0) {
        _unfilterLabels(allNodes, visibleLabels);
      }
    }
  },

  /**
   * Search class
   *
   *  @param {string|Object} clazz Name of class (string) or class node (node object)
   *  @param {boolean} options.panToCenter boolean (optional)
   *  @param {boolean} options.expandPartition boolean (optional)
   *  @param {boolean} options.unfilterPartition boolean (optional)
   */
  searchClass(clazz, options) {
    const cy = store.getters.getCYInstance();
    const me = this;
    const func = function(clazz, options) {
      const node = typeof clazz === "string"
              ? utils.getNodeByName(cy, clazz)
              : clazz;
      if (node && node.isNode() === true) {
        cy.startBatch();
        if (options) {
          if (options.unfilterClass !== false && utils.isFilteredNode(node) === true) {
            me.unfilterClassNodes(cy.collection().add(node));
          }
          const partition = utils.getNodeParent(node) || node.data("category");
          if (options.expandPartition === true) {
            me.expandPartition(partition);
          }
          if (options.panToCenter) {
            me.panNodeToCenter(node);
          }
        }
        cy.endBatch();
        cy.startBatch();
        if (utils.isVisible(node) === true && node.removed() !== true) {
          highlighter.searchNode(node);
        }
        cy.endBatch();
        // show data dependency edges for searched class
        // me.showNodeDependencies(node);
      }
    }
    const myDelay = (options && options.delay != null) ? options.delay : null;
    // use '!=' for checking 'undefined' and 'null'
    const delay = (myDelay != null) ? myDelay : utils.getFunctionCallDelay(cy);    
    return this.callFunctionWithLoadingIcon(func, [clazz, options], delay);
  },

  getClassBySearchFilter(classFilter) {
    let nodes = [];
    if (!utils.isEmpty(classFilter)) {
      const name = classFilter.trim().toLowerCase();
      const allNodes = store.getters.getNodes;
      nodes = allNodes.filter((node) => {
        const nodeName = node.name.toLowerCase();
        if (node.filepath !== constants.clusterIdentifier) {
          if (nodeName === name) {
            return true;
          } else if (nodeName.indexOf(name) !== -1) {
            return true;
          }
        }
      });
    }
    return nodes;
  },

  /**
   * Move class to a different partition
   *
   *  This will do:
   *  1. Update clazz.data.category
   *  2. Update both partitions "semantics"
   *  3. Update partitions links "value"
   *
   *    ...
   *
   *  @param {string|Object} clazz Name of class (string) or class node (node object)
   *  @param {string} options.partition target partition name
   *  @param {boolean} options.updateCY whether to update CY
   *  @param {boolean} options.removeEmptyPartition whether to remove empty partition
   */
  changeClassPartition(clazz, options) {
    const nodeEle = typeof clazz === "string" ? utils.getNodeByName(store.getters.getCYInstance(), clazz) : clazz;
    if (nodeEle && nodeEle.isNode() === true && options) {
      this.changeClassesPartition(nodeEle.cy().collection().add(nodeEle), options);
    }
  },

  /**
   * Move classes (must NOT be collapsed) to multiple partitions
   *
   *  This will do:
   *  1. Update clazz.data.category
   *  2. Update both partitions "semantics"
   *  3. Update partitions links "value"
   *
   *    ...
   *
   *  @param {object} changeInfo moving information
   *    {
   *      targetPartition: {
   *                          classes: [],
   *                          isUtility: boolean
   *                       }
   *    }
   *  @param {object} options moving options
   *    {
   *      updateCY: boolean
   *      moveNodes: boolean
   *      closePartitions: boolean
   *    }
   */
  moveClassesToMultiplePartitions(changeInfo, options) {
    const cy = store.getters.getCYInstance();
    const me = this;
    const func = function(changeInfo, options) {
      // check any "closed" partitions
      let partitions = cy.nodes(utils.getSelectorForNodeNames(Object.keys(changeInfo)));
      Object.keys(changeInfo).forEach((partition) => {
        if (changeInfo[partition].classes.length > 0 && typeof changeInfo[partition].classes[0] === "string") {
          changeInfo[partition].classes = utils.getNodesByNames(cy, changeInfo[partition].classes); // resolve names to class nodes
        }
        partitions = partitions.add(utils.getParentNodes(changeInfo[partition].classes)); // save all parents
      });
      let closedPartitions = partitions.filter('node.cy-expand-collapse-collapsed-node');
      if (closedPartitions.empty() !== true) {
        partitionHandler.expandPartitions(closedPartitions);
      }
      // move classes to new partitions
      const opts = options || {
        removeEmptyPartition: true,
        updateCY: true,
        moveNodes: true,
      };
      Object.keys(changeInfo).forEach(target => {
        opts.partition = target;
        if (changeInfo[target].isUtility === true) {
          opts.isUtilityPartition = true;
        }
        me.changeClassesPartition(changeInfo[target].classes, opts);
      });
      // close partition if necessary
      // when any "to"/"from" partitions are closed, the API will "open" them before moving classes
      // the 'closePartitions' is to control whether to "close" those partitions after moving
      if (opts.closePartitions !== false && closedPartitions.empty() !== true) {
        partitionHandler.collapsePartitions(closedPartitions);
      }
      // after moving classes, visibility of partitions could be changed
      // me.syncPartitionsFilter(partitions);
    }
    const myDelay = (options && options.delay != null) ? options.delay : null;
    // use '!=' for checking 'undefined' and 'null'
    const delay = (myDelay != null) ? myDelay : utils.getFunctionCallDelay(cy);
    return this.callFunctionWithLoadingIcon(func, [changeInfo, options], delay);
  },

  /**
   * Move classes (must NOT be collapsed) to a partition
   *
   *  This will do:
   *  1. Update clazz.data.category
   *  2. Update both partitions "semantics"
   *  3. Update partitions links "value"
   *
   *    ...
   *
   *  @param {[]} classArray Array of classes (string) or class nodes (node object)
   *  @param {string} options.partition target partition name
   *  @param {boolean} options.updateCY whether to update CY
   *  @param {boolean} options.removeEmptyPartition whether to remove empty partition
   */
  changeClassesPartition(classArray, options) {
    if (!classArray || classArray.length <= 0 || !options) {
      return;
    }
    let unobservedPartitionId = 'Unobserved';
    const storeOverview = store.getters.getOverview;
    if (storeOverview[constants.nodeId] && storeOverview[constants.nodeId].length > 0) {
      storeOverview[constants.nodeId].find(node => {
        if (utils.isUnobservedCategory(node.name) === true) {
          unobservedPartitionId = node.name;
          return true;
        }
      });
    }

    const targetPartition = (options.partition || unobservedPartitionId);
    const cy = store.getters.getCYInstance();
    const targetPartitionNodes = utils.getNodeByName(cy, targetPartition);
    let newPartitionNode = null, targetPartitionEle = null;

    // check existence of the partition node
    if ((!targetPartitionNodes || targetPartitionNodes.length < 1) && 
        (targetPartition !== unobservedPartitionId || constants.keepUnobserved === true)) {
      newPartitionNode = this.createNewPartition(cy, { name: targetPartition, isUtility: options.isUtilityPartition });
      targetPartitionEle = newPartitionNode;
    } else {
      targetPartitionEle = targetPartitionNodes[0];
    }

    if (!targetPartitionEle && constants.keepUnobserved) {  // target partition node doesn't exist
      return;
    }

    let boundary;
    const corners = {};
    if (options.moveNodes === true && targetPartitionEle !== null) {
      const displayStyle = targetPartitionEle.style('display');
      if (displayStyle === 'none') {  // the partition is invisible
        const pos = targetPartitionEle.position();  // use its position to cal. the boundary
        boundary = {
          x1: pos.x - constants.partitionCornerOffset,
          y1: pos.y - constants.partitionCornerOffset,
          x2: pos.x + constants.partitionCornerOffset,
          y2: pos.y + constants.partitionCornerOffset
        };
      } else {
        boundary = targetPartitionEle.boundingBox();
      }
      corners.x1y1 = {
        position: [boundary.x1, boundary.y1],
        offset: [constants.partitionCornerOffset, constants.partitionCornerOffset],
      };
      corners.x1y2 = {
        position: [boundary.x1, boundary.y2], 
        offset: [constants.partitionCornerOffset, -constants.partitionCornerOffset],
      };
      corners.x2y1 = {
        position: [boundary.x2, boundary.y1], 
        offset: [-constants.partitionCornerOffset, constants.partitionCornerOffset],
      };
      corners.x2y2 = {
        position: [boundary.x2, boundary.y2], 
        offset: [-constants.partitionCornerOffset, -constants.partitionCornerOffset],
      };
    }

    // build partition info
    let classes = cy.collection();
    const partitionsInfo = {};
    const classInfo = [];
    let newSemantics = [];
    classArray.forEach(clazz => {
      const node = typeof clazz === "string" ? utils.getNodeByName(cy, clazz) : clazz;
      if (node) {
        const data = node.data();
        let category = data.category || unobservedPartitionId;
        if (category === targetPartition && data[constants.unobservedId] === true) {
          category = unobservedPartitionId;
        }
        partitionsInfo[category] = partitionsInfo[category] || {};
        partitionsInfo[category]['classNumber'] = (partitionsInfo[category]['classNumber'] || 0) + 1;
        if (data.semantics && Array.isArray(data.semantics) && data.semantics.length > 0) {
          newSemantics = utils.mergeArrays(newSemantics, data.semantics);
        }
        classInfo.push(`${data.name}${_name_delimiter_}${category}`);
        classes = classes.add(node);
        if (options.moveNodes === true && category !== targetPartition) {
          const corner = _findNearestCorner(node, boundary);
          node.position({
            x: corners[corner].position[0] + corners[corner].offset[0],
            y: corners[corner].position[1] + corners[corner].offset[1],
          });
          corners[corner].offset[0] += corners[corner].offset[0] > 0 ? constants.partitionCornerOffset : -constants.partitionCornerOffset;
          corners[corner].offset[1] += corners[corner].offset[1] > 0 ? constants.partitionCornerOffset : -constants.partitionCornerOffset;
        }
      }
    });
  
    // update class node
    classes.filter(node => node.data('category') !== targetPartition).data("category", targetPartition); 
    if (options.updateCY === true) {
      if (targetPartition === unobservedPartitionId && constants.keepUnobserved === false) {
        classes.move({ parent: null });
      } else if (targetPartitionEle) {
        const targetParentId = targetPartitionEle.id();
        const movingClasses = classes.filter(node => node.data('parent') !== targetParentId);
        movingClasses.move({ parent: targetParentId });
        if (newPartitionNode !== null) {  // do a "layout" for all nodes within newly created partition
          movingClasses.layout(utils.getDefaultFcoseLayout()).run();
        }
      } 
    }

    Object.keys(partitionsInfo).forEach((partition) => {
      // update number of classes for partitions
      const partitionEle = cy.nodes().filter(`node[name="${partition}"]`);
      if (partitionEle && partitionEle.length > 0) {
        const newClassNumber = partitionEle.data("ui_classNumber") - partitionsInfo[partition]['classNumber'];
        partitionEle.data("ui_classNumber", newClassNumber);
        partitionEle.data("ui_symbolSize", store.getters.getPartitionSymbolSize(newClassNumber));
        // update partitions' semantics
        const children = utils.getChildrenNode(partitionEle).filter(`node[category="${partition}"]`); // for collapsed partition, this may still contain 'moved' nodes
        let oldSemantics = [];
        children.forEach(node => {
          oldSemantics = utils.mergeArrays(oldSemantics, node.data('semantics'));
        });
        partitionEle.data("semantics", oldSemantics);
        partitionsInfo[partition]['ui_classNumber'] = newClassNumber;
        partitionsInfo[partition]['semantics'] = oldSemantics;
        partitionsInfo[partition]['ui_symbolSize'] = partitionEle.data('ui_symbolSize');
      } else {  // the partition doesn't exist (removed)
        partitionsInfo[partition]['ui_classNumber'] = 0;
        partitionsInfo[partition]['semantics'] = [];
        partitionsInfo[partition]['ui_symbolSize'] = constants.defaultPartitionSize;
      }
    });

    // update new partition
    if (targetPartitionEle) {
      // update new partition class number and symbol size
      const newClassNumber = targetPartitionEle.data("ui_classNumber") + classes.length;
      targetPartitionEle.data("ui_classNumber", newClassNumber);
      targetPartitionEle.data("ui_symbolSize", store.getters.getPartitionSymbolSize(newClassNumber));
      // update new partitions' semantics
      if (newSemantics.length > 0) {
        const semantics = targetPartitionEle.data('semantics');
        if (semantics && Array.isArray(semantics)) {
          targetPartitionEle.data('semantics', utils.mergeArrays(semantics, newSemantics));
        } else {
          targetPartitionEle.data('semantics', newSemantics);
        }
      }
    }

    /**
     * Update store models
     */
    // Update nodes in sotre
    const storeNodes = store.getters.getNodes.filter(node => {
      return (classInfo.indexOf(`${node.name}${_name_delimiter_}${node.category}`) !== -1);
    });
    storeNodes.forEach((node) => {
      node.category = targetPartition;
      if (node.parent !== undefined) {
        if (targetPartition !== unobservedPartitionId || constants.keepUnobserved === true) {
          node.parent = targetPartition;
        } else {
          delete node.parent;
        }
      }
    });
    // update partitions' semantics - store
    storeOverview[constants.nodeId].forEach((partition) => {
      if (partition.name === targetPartition) {  // match with target partition
        if (targetPartitionEle) {
          partition.semantics = targetPartitionEle.data('semantics');
          partition.ui_classNumber = targetPartitionEle.data("ui_classNumber");
          partition.ui_symbolSize = targetPartitionEle.data("ui_symbolSize");
        } else {  // should never be here
          if (newSemantics.length > 0) {
            if (partition.semantics && Array.isArray(partition.semantics)) {
              partition.semantics = utils.mergeArrays(partition.semantics, newSemantics);
            } else {
              partition.semantics = newSemantics;
            }
          }
          if (partition.ui_classNumber !== undefined) {
            partition.ui_classNumber = partition.ui_classNumber + classes.length;
            if (partition.ui_symbolSize !== undefined) {
              partition.ui_symbolSize = store.getters.getPartitionSymbolSize(partition.ui_classNumber);
            }
          }
        }
      } else if (partitionsInfo[partition.name]) { // match with one of source partition
        if (partition.name !== unobservedPartitionId || constants.keepUnobserved === true) {
          partition.semantics = partitionsInfo[partition.name].semantics;
        } else {  // moving out from "Unobserved" partition
          if (partition.semantics && Array.isArray(partition.semantics) && partition.semantics.length > 0) {
            const uoNodes = store.getters.getNodes.filter(node => {
              return node.category === partition.name && node.semantics && Array.isArray(node.semantics) && node.semantics.length > 0;
            });
            let semantics = [];
            uoNodes.forEach(node => {
              semantics = utils.mergeArrays(semantics, node.semantics);
            });
            partition.semantics = semantics;
          }
        }
        if (partition.ui_classNumber !== undefined) {
          partition.ui_classNumber = partition.ui_classNumber - partitionsInfo[partition.name].classNumber;
          if (partition.ui_symbolSize !== undefined) {
            partition.ui_symbolSize = store.getters.getPartitionSymbolSize(partition.ui_classNumber);
          }
          if (partitionsInfo[partition.name].ui_classNumber !== partition.ui_classNumber) {
            partitionsInfo[partition.name].ui_classNumber = partition.ui_classNumber
          }
        }
      }
    });
    // update partition links' value - store
    const edges = utils.getAllCrossPartitionEdges(cy);
    const crossConnectionInfo = {};
    edges.each((edge) => {
      const sourceCategory = edge.source().data("category");
      const targetCategory = edge.target().data("category");
      if (partitionsInfo[sourceCategory] || sourceCategory === targetPartition ||
          partitionsInfo[targetCategory] || targetCategory === targetPartition) {
        const name = `${sourceCategory}${_name_delimiter_}${targetCategory}`;
        if (crossConnectionInfo[name]) {
          crossConnectionInfo[name] += edge.data("value");
        } else {
          crossConnectionInfo[name] = edge.data("value");
        }
      }
    });
    storeOverview[constants.linkId].forEach((link) => {
      const name = link.source + _name_delimiter_ + link.target;
      if (crossConnectionInfo[name]) {
        link.value = Math.sqrt(Math.sqrt(crossConnectionInfo[name]));
        crossConnectionInfo[name] = -1;
      } else if ((partitionsInfo[link.source] && partitionsInfo[link.source].ui_classNumber < 1) ||
                 (partitionsInfo[link.target] && partitionsInfo[link.target].ui_classNumber < 1) ) {
        link.value = 0; // empty partition
      }
    });
    Object.keys(crossConnectionInfo).forEach((key) => {
      if (crossConnectionInfo[key] > -1) {  // new entry
        const partitions = key.split(_name_delimiter_);
        storeOverview[constants.linkId].push({
          source: partitions[0],
          target: partitions[1],
          value: Math.sqrt(Math.sqrt(crossConnectionInfo[key])),
        });
      }
    });

    /**
     * Remove empty partition
     */
    if (options.removeEmptyPartition === true) {
      const names = [];
      Object.keys(partitionsInfo).forEach((partition) => {
        if (partitionsInfo[partition].ui_classNumber < 1 && partition !== targetPartition) {
          names.push(partition);
        }
      });
      if (names.length > 0) {
        this.removePartitions(cy, names);
      }
    }

    // reapply filters to moving classes and connected edges
    const elements = classes.add(classes.connectedEdges());
    utils.applyAllFilters(elements);
  },

  createNewPartition(cy, partitionInfo, currentColor) {
    const partitions = this.getPartitionNamesArray();
    // get new partition color
    let bgColor = (utils.isUnobservedCategory(partitionInfo.name) === true) ? store.getters.getUOColor : this.getNextPartitionColor();
    if (bgColor == "" || (currentColor && bgColor === currentColor)) {
      const colors = store.getters.getColorInfo.partition_colors;
      var colorIndex = (partitions.length + 1) % colors.length;
      bgColor = (colors[colorIndex] === currentColor) ? colors[(partitions.length + 2) % colors.length] : colors[colorIndex];
    }
    // get center of current viewport
    const extent = cy.extent();
    const pos = {
      x: (extent.x2 - extent.x1) / 2.0,
      y: (extent.y2 - extent.y1) / 2.0
    }
    // add new partition node
    return this.addPartition(partitionInfo.name, cy, {
      updateModel: true,
      partitionColor: bgColor,
      position: pos,
      isUtility: partitionInfo.isUtility,
    });
  },  

  /**
   * Adds the specified partition to the current view
   *
   * @param {String} partitionName Name of partition
   * @param {Object} cyObj cy Object (could be null)
   * @param {Object} options
   * @param {boolean} options.updateModel whether to update in-memory model
   * @param {string}  options.partitionColor partition color string
   */
  addPartition(partitionName, cyObj, options) {
    const cy = cyObj || store.getters.getCYInstance();
    const partitionData = {
      group: "nodes",
      data: {
        semantics: [],
        name: partitionName,
        group: 0,
        category: partitionName,
        id: partitionName,
        filepath: constants.clusterIdentifier,
        ui_classNumber: 0,
        ui_symbolSize: constants.defaultPartitionSize,
      },
    };

    if (options) {
      if (options.partitionColor) {
        partitionData.data.ui_partitionColor = options.partitionColor;
      }
      if (options.classNumber) {
        partitionData.data.ui_classNumber = options.classNumber;
        partitionData.data.ui_symbolSize = utils.getPartitionSymbolSize(options.classNumber);
      }
      if (options.data) {
        partitionData.data = options.data;
      }
      if (options.updateModel === true) {
        // update store.source (overview)
        const storeOverview = store.getters.getOverview;
        storeOverview[constants.nodeId].push(
          JSON.parse(JSON.stringify(partitionData.data))
        );
      }
      if (options.position) {
        partitionData.position = options.position;
      }
      if (options.isUtility === true) {
        partitionData.data.tag = [`${constants.utilityLabelFilterValue}`];
      }
    }

    // add partition node to cy
    try {
      const eles = cy.add(partitionData);
      // add new partition to filter list
      const filterStore = utils.getScratch(cy, constants.m2mScratchFilters);
      const partitionFilter = filterStore[constants.filterTypePartition];
      if (partitionFilter && partitionFilter.length > 0 && partitionFilter.indexOf(partitionName) < 0) {
        partitionFilter.push(partitionName);
      }
      return eles[0];
    } catch (e) {
      console.log(`Failed to add partition [${partitionName}]:  `, e.message);
    }

    return null;
  },

  /**
   * Remove partitions
   *
   *  @param {*} partitionNames Array of partition name
   */
   removePartitions(cy, partitionNames) {
    if (partitionNames && partitionNames.length > 0) {
      let selector = '';
      const storeOverview = store.getters.getOverview;
      const partitionNodes = storeOverview[constants.nodeId];
      const partitionLinks = storeOverview[constants.linkId];
      partitionNames.forEach((name, i) => {
        // remove it from store.source.overview.nodes
        const idx = partitionNodes.findIndex((node) => (node.name === name && node.category === name));
        if (idx >= 0) {
          partitionNodes.splice(idx, 1);
        }
        // remove it from store.source.overview.links
        for (let k = partitionLinks.length; k --;) {
          if (partitionLinks[k].source === name || partitionLinks[k].target === name) {
            partitionLinks.splice(k, 1);
          }
        }
        if (i !== 0) {
          selector += ', ';
        }
        selector += `node[name="${name}"]`;
      });
      // remove the cy elements if they exist
      cy.nodes().filter(selector).remove();

      // remove parttions from filter list
      const filterStore = utils.getScratch(cy, constants.m2mScratchFilters);
      const partitionFilter = filterStore[constants.filterTypePartition];
      if (partitionFilter && partitionFilter.length > 0) {
        filterStore[constants.filterTypePartition] = partitionFilter.filter((filter) => partitionNames.indexOf(filter) < 0);
      }
    }
  },

  /**
   * Rename partition
   *
   *  @param {string} partitionName Name of partition (string)
   */
  validatePartitionName(partitionName) {
    // Set up validation array.  True will indicate that the specific error occurred.
    // Validation[0] = true indicates that the partitionName is good.
    let validation = [false, false, false, false, false];
    let validationError = false;

    if (/^[A-Za-z0-9_.-]+$/.test(partitionName) !== true) {
      // Must use only allowable characters. (2)
      validation[constants.partitionNameIsInvalid] = true;
      validationError = true;
    }

    if (/^[A-Za-z](.*[A-Za-z0-9])?$/.test(partitionName) !== true) {
      // Must start with a letter; end with a letter or number (3)
      validation[constants.partitionNameBadFormat] = true;
      validationError = true;
    }

    if (!validationError) {
      // Name is valid..check for duplicate
      const partitions = this.getPartitionNamesArray();
      const lowerCaseName = partitionName.toLowerCase();
      if (partitions.find((name) => name.toLowerCase() === lowerCaseName)) {
        // Name must be unique (1)
        validation[constants.partitionNameIsDuplicated] = true;
      } else if (utils.isUnobservedCategory(partitionName) === true) {
        validation[constants.partitionNameUnobserved] = true;
      }
      else {
        validation[constants.partitionNameIsOk] = true;
      }
    }

    return validation;
  },

  /**
   * Rename partition
   *
   *  @param {string|Object} partition Name of partition (string) or partition node (node object)
   *  @param {string} options.newName new partition name
   */
  renamePartition(partition, options) {
    if (options && options.newName && options.newName.length > 0) {
      const cy = store.getters.getCYInstance();
      const nodeEle =
        typeof partition === "string"
          ? utils.getNodeByName(cy, partition)
          : partition;
      if (nodeEle && utils.isParentNode(nodeEle) === true) {
        const data = nodeEle.data();
        const oldName = data.name;

        // update node
        // data.id = options.newName; // id is immutable
        data.category = options.newName;
        data.name = options.newName;
        nodeEle.style("content", options.newName); // triger to refresh label on screen
        // update children node
        const children = utils.getChildrenNode(nodeEle);
        if (children) {
          children.forEach((node) => {
            const nodeData = node.data();
            // nodeData.parent = options.newName; // parent id is not changed
            nodeData.category = options.newName;
          });
        }
        // update connected edges, only for collapsed partition node
        // if (utils.isCollapsedNode(nodeEle) === true) {
        //   const connections = nodeEle.openNeighborhood().filter("edge");
        //   connections.forEach(edge => {
        //     if (edge.data("source") === oldName) {
        //       edge.data("source", options.newName);
        //     } else if (edge.data("target") === oldName) {
        //       edge.data("target", options.newName);
        //     }
        //   });
        // }

        /**
         * Update sotre models
         */
        const storeNodes = store.getters.getNodes;
        storeNodes.forEach((node) => {
          if (node.category === oldName) {
            node.category = options.newName;
            if (node.name === oldName && utils.isClusterNode(node) === true) {
              node.name = options.newName;
            }
          }
        });
        // update 'overview' (nodes and links)
        const storeOverview = store.getters.getOverview;
        storeOverview[constants.nodeId].forEach((node) => {
          if (node.category === oldName) {
            node.category = options.newName;
            if (node.name === oldName && utils.isClusterNode(node) === true) {
              node.name = options.newName;
            }
          }
        });
        storeOverview[constants.linkId].forEach((edge) => {
          if (edge.source === oldName) {
            edge.source = options.newName;
          } else if (edge.target === oldName) {
            edge.target = options.newName;
          }
        });
        // update in-memory partition filter
        const filterStore = utils.getScratch(cy, constants.m2mScratchFilters);
        const partitionFilter = filterStore[constants.filterTypePartition] || []
        const idx = partitionFilter.indexOf(oldName);
        if (idx !== -1) {
          partitionFilter[idx] = options.newName
        }
      }
    }
  },

  getCurrentViewAllUseCases() {
    const nodes = store.getters.getSource[constants.overviewId][constants.nodeId];
    const cases = new Set();
    nodes.forEach((node) => {
      var semantics = node.semantics;
      if (semantics) {
        semantics.forEach(item => cases.add(item))
      }
    });
    return Array.from(cases);
  },

  getAllClassLabels() {
    const labels = new Set();
    store.getters.getLabels.forEach(obj => {
      labels.add(obj.name);
    })
    return Array.from(labels);
  },  

  /**
   * Get filter information
   *
   *  @param {string} key one of the filter types defined in constants or null
   * 
   */
  getFilterInfo(filterType) {
    const filterStore = utils.getScratch(store.getters.getCYInstance(), constants.m2mScratchFilters);
    if (filterType) {
      return filterStore[filterType];
    } else {
      return filterStore;
    }
  },

  callFunctionWithLoadingIcon(func, parameters, runDelay) {
    if (runDelay <= 0) {
      return func.apply(null, parameters);
    } else {
      return new Promise((resolve, reject) => {
        _showLoadingIcon(true);
        setTimeout(function() {
          try {
            resolve(func.apply(null, parameters));
          } catch (err) {
            reject(err);
          } 
          finally {
            _showLoadingIcon(false);
          }
        }, Math.max(constants.minFunctionCallTimeout, runDelay));
      });
    }
  },

  async showLoadingIcon(show, myDelay) {
    const delay = (myDelay != null) ? myDelay : utils.getFunctionCallDelay(store.getters.getCYInstance());
    if (delay <= 0 && show === true) {
      return;
    }
    _showLoadingIcon(show);
    if (show === true) {
      await this.sleep(delay);
    }
  },

  sleep(milliseconds) {
    return new Promise(resolve => {
      setTimeout(function() {
        resolve();
      }, milliseconds);
    });
  },

  isFilterChanged(filterType, filter) {
    const cy = store.getters.getCYInstance();
    const filterStore = utils.getScratch(cy, constants.m2mScratchFilters);
    let currentFilter;
    let sort = true;
    if (filterType === constants.filterTypeRuntimeCall) {
      if (filterStore[filterType] && Array.isArray(filterStore[filterType]) && filterStore[filterType].length > 0) {
        currentFilter = filterStore[filterType];
      } else {
        const scratch = utils.getScratch(cy);
        currentFilter = scratch[constants.m2mScratchRuntimeCallRange] || [];
      }
      sort = false;
    } else {
      currentFilter = filterStore[filterType] || ((filterType === constants.filterTypeShowEdge) ? utils.getDefaultShowEdgeFilter() : []);
    }
    return !utils.arraysEqual(filter, currentFilter, sort);
  },

  anyFiltersApplied() {
    const filterStore = utils.getScratch(store.getters.getCYInstance(), constants.m2mScratchFilters);
    return /*utils.isShowingDataDependencies() === true || */Object.keys(filterStore).some((filter) => {
      if (filterStore[filter]) {
        if (Array.isArray(filterStore[filter])) {
          return filterStore[filter].length > 0 || filter === constants.filterTypeShowEdge;
        }
        return true;
      }
      return false;
    });
  },

  showTooltip(evt) {
    tooltip.show(evt);
  },

  hideTooltip() {
    tooltip.hide();
  },

  getRuntimeCallRange() {
    const cy = store.getters.getCYInstance();
    const scratch = utils.getScratch(cy);
    if (!scratch[constants.m2mScratchRuntimeCallRange]) {
      let minCalls = 0, maxCalls = 0;
      const edges = utils.getAllEdges(cy, {includePartitionEdges: false});
      const partitionEdges = {};
      edges.forEach((edge, idx) => {
        let calls = utils.getEdgeCallNumber(edge);
        // partition edges
        const sCategory = edge.source().data('category');
        const tCategory = edge.target().data('category');
        if (sCategory !== tCategory) {
          const key = (sCategory < tCategory) ? `${sCategory}->${tCategory}` : `${tCategory}->${sCategory}`;
          partitionEdges[key] = (partitionEdges[key] || 0) + calls;
          calls = partitionEdges[key];
        }
        if (idx === 0) {
          minCalls = maxCalls = calls;
        } else {
          if (calls < minCalls) {
            minCalls = calls;
          } else if (calls > maxCalls) {
            maxCalls = calls;
          }
        }
      });
      // update cy in-memory store
      scratch[constants.m2mScratchRuntimeCallRange] = [minCalls, maxCalls];
    }
    return scratch[constants.m2mScratchRuntimeCallRange];
  },

  /**
   * Return JSON data of all visible class nodes in cytoscape
   *  Note: this API could be expensive
   * 
   */
  getCYClassNodesInJson() {
    const cy = store.getters.getCYInstance();
    let classNodes = utils.getAllNodes(cy).filter(node => utils.isVisible(node) && !utils.isParentNode(node));
    const result = {nodes: []};
    classNodes.forEach((node) => {
      if (utils.isHighlightedFocusedElement(node) === true) {
        Object.assign(result, {selected: node.data()});
      }      
      result.nodes.push(node.data());
    });
    return result;
  },

  getNodesByNames(cyObj, names) {
    const cy = cyObj || store.getters.getCYInstance();
    if (names.length === 1) {
      return utils.getNodeByName(cy, names[0]);
    } else {
      return utils.getNodesByNames(cy, names);
    }
  },

  /**
   * Get node by id
   *
   *  @param {object} cyObj cy object or null
   *  @param {string} nodeId node/class id (should be == name)
   * 
   */
  getNodeById(cyObj, nodeId) {
    const cy = cyObj || store.getters.getCYInstance();
    return cy.getElementById(nodeId);
  },

  /**
   * Get node by its name and parent id
   *
   *  @param {object} cyObj cy object or null
   *  @param {string} name node/class name (should be == id)
   *  @param {string} parent parent id or null
   * 
   */
  getNodeByParent(cyObj, name, parent) {
    const cy = cyObj || store.getters.getCYInstance();
    if (!parent) {
      return utils.getNodeByName(cy, name);
    }
    let ele = cy.getElementById(name);  // assume name === id for now
    if (!ele || ele.length == 0) {
      const partition = cy.getElementById(parent);  // assume name === id for now
      if (partition && partition.length === 1 && utils.isCollapsedNode(partition)) {
        ele = partition.data('collapsedChildren').filter(`[name="${name}"]`);
      }
    }  
    return ele;
  },

  updateAppliedLabelFilter(oldLabel, newLabel) {
    const cy = store.getters.getCYInstance();
    const filterStore = utils.getScratch(cy, constants.m2mScratchFilters);
    const labelFilters = filterStore[constants.filterTypeLabel] || [];
    const idx = labelFilters.indexOf(oldLabel);
    if (idx >= 0) { // found old label
      if (newLabel && newLabel.length > 0) {  // rename
        labelFilters[idx] = newLabel;
      } else {  // delete
        labelFilters.splice(idx, 1);
        // since there is a filter removed, have to re-apply label filters
        this.cleanupAllHighlights();
        this.filterNodesByLabels(labelFilters);
      }
    }
  },

  /**
   * Apply existing label filter to the given nodes
   * 
   *  @param {*} nodeNames Array of node name in string
   */
  applyLabelFilterToNodesByNames(nodeNames) {
    if (nodeNames && nodeNames.length > 0) {
      const cy = store.getters.getCYInstance();
      const nodes = this.getNodesByNames(cy, nodeNames);
      if (nodes && nodes.length > 0) {
        const filterStore = utils.getScratch(cy, constants.m2mScratchFilters);
        const labelFilters = filterStore[constants.filterTypeLabel] || [];    
        if (labelFilters.length > 0) {
          utils.applyLabelFilters(nodes, labelFilters);
        }
      }
    }
  },

  /**
   * Get number of filters which applied to the view
   */
  getNumberOfAppliedFilters() {
    const cy = store.getters.getCYInstance();
    const filterStore = utils.getScratch(cy, constants.m2mScratchFilters);
    let count = 0;
    Object.keys(filterStore).forEach(key => {
      if (filterStore[key] && Array.isArray(filterStore[key]) && 
          // "show edge filter" could be empty array to hide all edges
          (filterStore[key].length > 0 || key === constants.filterTypeShowEdge)) {
        count ++;
      }
    });
    return count;
  },

  /**
   * Get all utility class nodes from given partition.
   * 
   *  @param {string/object} partition partition name or node.
   *  @returns Utility class nodes from the partition or view (partition is null)
   */
  getUtilityClassNodes(partition) {
    const cy = store.getters.getCYInstance();
    if (!partition) {
      return utils.getAllNodes(cy).filter(node => node.data('isUtilityClass') === true);
    } else {
      const partitionEle = typeof partition === "string" ? cy.getElementById(partition) : partition;
      return utils.getChildrenNode(partitionEle).filter(node => node.data('isUtilityClass') === true);
    }
  },

};

function _showLoadingIcon(show) {
  const loadingEle = document.getElementById("app-busy-icon");
  if (loadingEle) {
    if (show === true) {
      loadingEle.classList.remove("bx--loading-overlay--stop");
      loadingEle.classList.add("bx--loading-overlay");
      loadingEle.firstChild.classList.add("bx--loading");
    } else {
      loadingEle.classList.remove("bx--loading-overlay");
      loadingEle.classList.add("bx--loading-overlay--stop");
      loadingEle.firstChild.classList.remove("bx--loading");
    }
  }
}

/**
 * Unfilter partitions. (add given partitions to partition filter)
 * 
 *  @param {*} partitions Partition array which is going to unfiltered.
 */
function _unfilterPartitions(allNodes, partitions) {
  if (partitions.length > 0 && allNodes.length > 0) {
    const allFilteredNodes = allNodes.filter(`.${constants.filterTypePartition}, .${constants.m2mFilteredCollapsedNodeClass}`);
    var selector = '';
    partitions.forEach((partition, idx) => {
      if (idx !== 0) {
        selector += ', ';
      }
      selector += `node[category="${partition}"], node[name="${partition}"]`;
    });
    allFilteredNodes.filter(selector).removeClass(`${constants.filterTypePartition} ${constants.m2mFilteredCollapsedNodeClass}`);
    // update in-memory partition filter
    const filterStore = utils.getScratch(allNodes[0].cy(), constants.m2mScratchFilters);
    if (filterStore[constants.filterTypePartition]) {  // partition filter exists  
      filterStore[constants.filterTypePartition].push(...partitions);  // assume there is partition filter
    } else {
      filterStore[constants.filterTypePartition] = partitions;
    }
  }
}

/**
 * @param {*} usecases Use case array which is going to unfiltered.
 */
function _unfilterUseCases(allNodes, usecases) {
  if (usecases.length > 0 && allNodes.length > 0) {
    const allFilteredNodes = allNodes.filter(`.${constants.filterTypeUseCase}`);
    allFilteredNodes.filter(node => {
      const semantics = node.data('semantics');
      if (semantics && Array.isArray(semantics) && semantics.length > 0) {
        return semantics.some(s=> usecases.indexOf(s) >= 0)
      }
      return false;
    }).removeClass(`${constants.filterTypeUseCase}`);
    // update in-memory partition filter      
    const filterStore = utils.getScratch(allNodes[0].cy(), constants.m2mScratchFilters);
    if (filterStore[constants.filterTypeUseCase]) { // usecase filter exists  
      filterStore[constants.filterTypeUseCase].push(...usecases); // assume there is usecase filter
    } else {
      filterStore[constants.filterTypeUseCase] = usecases;
    }
  }
}

/**
 * @param {*} labels Label array which is going to unfiltered.
 */
function _unfilterLabels(allNodes, labels) {
  if (labels.length > 0 && allNodes.length > 0) {
    const allFilteredNodes = allNodes.filter(`.${constants.filterTypeLabel}`);
    const unfilterUOLabel = (labels.indexOf(constants.unobservedLabelFilterValue) >= 0);
    let classNodes = allFilteredNodes.filter(node => {
      const data = node.data();
      if (data.filepath !== constants.clusterIdentifier) {  // class node
        const nodeLabels = utils.getLabelsByClassName(data.name);
        if (nodeLabels.length > 0) {
          return nodeLabels.some(s=> labels.indexOf(s) >= 0);
        } else if (unfilterUOLabel) {
          return data[constants.unobservedId];
        }
      }
      return false;
    });
    classNodes = classNodes.add(classNodes.parent()); // make sure to show their parent partition
    classNodes.removeClass(`${constants.filterTypeLabel}`);
    // update in-memory partition filter      
    const filterStore = utils.getScratch(allNodes[0].cy(), constants.m2mScratchFilters);
    if (filterStore[constants.filterTypeLabel]) { // label filter exists  
      filterStore[constants.filterTypeLabel].push(...labels); // assume there is usecase filter
    } else {
      filterStore[constants.filterTypeLabel] = labels;
    }
  }
}

function _findNearestCorner(node, boundary) {
  const pos = node.position();
  const info = [];
  info.push({name: 'x1y1', dist: Math.sqrt( Math.pow((pos.x - boundary.x1), 2) + Math.pow((pos.y - boundary.y1), 2) )});
  info.push({name: 'x1y2', dist: Math.sqrt( Math.pow((pos.x - boundary.x1), 2) + Math.pow((pos.y - boundary.y2), 2) )});
  info.push({name: 'x2y1', dist: Math.sqrt( Math.pow((pos.x - boundary.x2), 2) + Math.pow((pos.y - boundary.y1), 2) )});
  info.push({name: 'x2y2', dist: Math.sqrt( Math.pow((pos.x - boundary.x2), 2) + Math.pow((pos.y - boundary.y2), 2) )});
  info.sort((a, b) => {
    return a.dist - b.dist;
  });
  return info[0].name;
}


export default graphAPIHandler;
