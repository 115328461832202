<!--
# *****************************************************************
#
# Licensed Materials - Property of IBM
#
# (C) Copyright IBM Corp. 2021, 2022. All Rights Reserved.
#
# US Government Users Restricted Rights - Use, duplication or
# disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
#
# *****************************************************************
-->
<template>
<!-- @modal-shown="showTableCustomizationModal(true)" -->
<!--     @secondary-click="showTableCustomizationModal(false)"-->
  <cv-modal
    :visible="showModal"
    size="small"
    class="table-customization-modal"
    :close-aria-label="$t('close-button-aria')"
    :primary-button-disabled="primaryButtonDisabled"
    @modal-hidden="showTableCustomizationModal(false)"
    @primary-click="onSave()"
    @secondary-click="showTableCustomizationModal(false)"
    :auto-hide-off="false"
    ref="tableCustomizationDialog"
  >
    <template slot="title">{{ $t("table-customization-modal-title") }}</template>
    <template slot="content">
      <p>{{ $t("table-customization-modal-desc") }}</p>
      <ol role="listbox"
        aria-label="Customize columns"
        @dragover.prevent
        @dragenter.prevent
        @drop="onDrop($event)"
      >
        <li
          v-for="columnId in tableCustomization.columnOrder"
          :key="columnId"
          class="column-row"
          :data-id="columnId"
          draggable
          @dragstart="startDrag($event, columnId)"
          tabindex="0"
        >
          <Draggable20/>
          <cv-checkbox
            :label="$t(`table-${columnId}-column`)"
            :value="`checkbox-${columnId}`"
            v-model="tableCustomization.showColumn[columnId]"
            @change="onCheckboxChange()"
            :disabled="columnId === 'classname'"
          >
          </cv-checkbox>
        </li>
      </ol>
    </template>
    <template slot="secondary-button">{{ $t("cancel-button-label") }}</template>
    <template slot="primary-button">{{ $t("save-button-label") }}</template>
  </cv-modal>
</template>

<script>
import { eventBus } from "../../main.js";
import Draggable20 from "@carbon/icons-vue/es/draggable/20";

export default {
  name: "TableCustomization",
  components: {
    Draggable20,
  },
  props: ["columnOrder", "columnShow"], // [columnData],
  data() {
    return {
      showModal: false,
      primaryButtonDisabled: true,
      initialColumnOrder: [],
      tableCustomization: {
        // set the default ordering and display
        columnOrder: [
          "classname",
          "partition",
          "usecases",
          "path",
          "labels"
        ],
        showColumn: {
          "classname": true,
          "partition": true,
          "usecases": true,
          "path": false,
          "labels": true
        }
      },
    }
  },
  methods: {
    showTableCustomizationModal(showModal) {
      if (this.showModal !== showModal) {
        this.showModal = showModal;

        if (showModal) {
          this.initialColumnOrder = []; // important: has to clear it out here, not when modal is hidden
          this.tableCustomization.columnOrder = [ ...this.columnOrder];
          this.tableCustomization.showColumn = { ...this.columnShow};
          // console.log("---- this.tableCustomization", this.tableCustomization);
          // if (this.tableCustomization.showColumn.classname === undefined) {
          //   this.tableCustomization.showColumn.classname = true;
          // }

          // set focus
          this.$nextTick(function() {
            setTimeout(function() {
              // put focus to first editable checkbox
              document.querySelector(".table-customization-modal .bx--modal-content .column-row .cv-checkbox input:not([disabled='disabled'])").focus();
            }, 200);
          })
        }
        setTimeout(function() {
          if (!showModal ) {
            // restore the relative position for page header content
            // Note: the page header relative position is unset in Toolbar.onTableCustomization
            const pageHeaderContentElement = document.querySelector(".main-graph .page-header-content");
            if (pageHeaderContentElement) {
              pageHeaderContentElement.style.position = "relative";
            }
            // set focus back to table customization toolbar button
            eventBus.$emit("Toolbar.setFocus", "customizeColumn");
          }
        }, 200);
      }
      if (!this.showModal) {
        this.primaryButtonDisabled = true;
      }
    },
    onCheckboxChange() {
      // console.log("---- table customization for " + columnId + " isChecked: " + isChecked);
      // console.log("this.tableCustomization.showColumn", this.tableCustomization.showColumn);
      // this.primaryButtonDisabled = false;
      this.primaryButtonDisabled = !this.setPrimaryButtonEnablement();
    },
    onSave() {
      // update the column order
      // console.log("-------- onSave tableCustomerization is set to ", this.tableCustomization);
      eventBus.$emit("TableView.setTableCustomization", this.tableCustomization);
      this.showTableCustomizationModal(false);
    },
    startDrag(evt, columnId) {
      // console.log("----- dragging " + columnId + " with drag event", evt);
      evt.dataTransfer.dropEffect = 'move';
      evt.dataTransfer.effectAllowed = 'move';
      evt.dataTransfer.setData('columnId', columnId);
    },
    onDrop(evt) {
      evt.stopPropagation();
      const allColumnRows = document.querySelectorAll(".column-row");
      const dragColumnId = evt.dataTransfer.getData('columnId');
      const dragColumnIndex = this.tableCustomization.columnOrder.findIndex(columnId => columnId === dragColumnId);
      const dragColumnInitialYPosition = allColumnRows[dragColumnIndex].getBoundingClientRect().y;
      // determine the direction of the drop relative to the original position
      let dragDirectionDown = true;
      if (evt.y < dragColumnInitialYPosition) {
        dragDirectionDown = false;
      }
      // console.log('---- direction is down: ' + dragDirectionDown);
      // console.log("---- dropping " + dragColumnId + " to " + evt.y +" with drop event", evt);
      let moveBeforeColumnId;
      // Use the y position to determine where the drag is dropped
      if (dragDirectionDown) {
        // if dragging down, position the drop before the elemnt that has a y position > the drop y position
        for (let rowIndex = 0; rowIndex < allColumnRows.length && moveBeforeColumnId === undefined; rowIndex++) {
          const columnRow = allColumnRows[rowIndex];
          // console.log("column id", columnRow.getBoundingClientRect().y);
          if (columnRow.getBoundingClientRect().y >= evt.y) {
            moveBeforeColumnId = columnRow.getAttribute("data-id");
          } else if (rowIndex === allColumnRows.length - 1) {
            moveBeforeColumnId = "AfterLast";
          }
        }
      } else {
        // if dragging up, position the drop after the element that has a y position < the drop y position
        for (let rowIndex = allColumnRows.length - 1; rowIndex >= 0 && moveBeforeColumnId === undefined; rowIndex--) {
          const columnRow = allColumnRows[rowIndex];
          // console.log("column id", columnRow.getBoundingClientRect().y);
          if (evt.y >= columnRow.getBoundingClientRect().y) {
            moveBeforeColumnId = columnRow.getAttribute("data-id");
          } else if (rowIndex === 0) {
            moveBeforeColumnId = "BeforeFirst";
          }
        }
      }
      // console.log("moveBeforeColumnId is " + moveBeforeColumnId);
      if (moveBeforeColumnId && (moveBeforeColumnId !== dragColumnId)) {
        // first, remove the drag element from the columnOrder array
        // console.log("dragColumnIndex to be removed is " + dragColumnIndex);
        if (dragColumnIndex !== -1) {
          this.tableCustomization.columnOrder.splice(dragColumnIndex, 1);
        }
        // now add the drag element to the correct position in the columnOrder array
        let dropColumnIndex = this.tableCustomization.columnOrder.findIndex(columnId => columnId === moveBeforeColumnId);
        if (dropColumnIndex !== -1 && !dragDirectionDown) {
          // for dragging up, it is dropping after not before
          dropColumnIndex++;
        }
        if (dropColumnIndex !== -1) {
          this.tableCustomization.columnOrder.splice(dropColumnIndex, 0, dragColumnId);
        } else if (moveBeforeColumnId === "AfterLast") {
          this.tableCustomization.columnOrder.push(dragColumnId);
        } else if (moveBeforeColumnId === "BeforeFirst") {
          this.tableCustomization.columnOrder.unshift(dragColumnId);
        }
        // console.log("----- columnOrders", this.tableCustomization.columnOrder);
        // this.primaryButtonDisabled = false;
        this.primaryButtonDisabled = !this.setPrimaryButtonEnablement();
      }
    },
    setPrimaryButtonEnablement() {
      let enableSave = this.tableCustomization.columnOrder.toString() !== this.columnOrder.toString();
      if (!enableSave) {
        this.tableCustomization.columnOrder.forEach(columnId => {
          if (this.tableCustomization.showColumn[columnId] !== this.columnShow[columnId]) {
            enableSave = true;
          }
        });
      }
      return enableSave;
    },
  },
  mounted() {
    eventBus.$on("TableCustomization.showModal", () => {
      this.showTableCustomizationModal(true);
    });
  },
  beforeDestroy() {
    eventBus.$off("TableCustomization.showModal");
  },
}
</script>

<style lang="scss" scoped>
.table-customization-modal {
  .bx--modal-content {
    p {
      padding-right: 20%;
      margin-bottom: 0; // override the carbon default margin-bottom 1.25rem
    }
    ol {
      padding-top: 1.25rem; // putting the margin here so as to give drag and drop more room to drag up past the first element
    }
  }

  .column-row {
    display: flex;
    align-items: center;
    height: 2.125rem;
    background-color: var(--app-table-customization-column-background-color);
    margin-bottom: 0.5rem;
    cursor: pointer;

    svg {
      margin-left: 0.5rem;
    }

    .cv-checkbox {
      margin-left: 0.5rem;
    }
  }
}
</style>

<style lang="scss">
.table-customization-modal {
  .bx--modal-container {
    background-color: var(--app-table-customization-background-color);

    .bx--checkbox-label {
      padding-top: 0;
      min-height: 1.375rem;
  
      &[data-contained-checkbox-disabled='true'] {
        cursor: pointer;
      }

      .bx--checkbox-label-text {
        padding-left: 0.5rem;
        color: var(--app-table-customization-checkbox-label-color);
        font-size: 1rem;
        letter-spacing: 0;
        line-height: 22px;
        font-weight: 400;
      }
    }
  }
}
</style>