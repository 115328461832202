<!--
# *****************************************************************
#
# Licensed Materials - Property of IBM
#
# (C) Copyright IBM Corp. 2019, 2020, 2021, 2022. All Rights Reserved.
#
# US Government Users Restricted Rights - Use, duplication or
# disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
#
# *****************************************************************
-->
<template>
  <div>
    <top-nav></top-nav>
    <side-nav></side-nav>
    <div class="about-container">
      <h3>Mono2Micro</h3>
      <p>Content coming soon ... </p>
      <div class="workflow"></div>
    </div>
  </div>
</template>

<script>
import TopNav from "../../components/TopNav";
import SideNav from "../../components/SideNav";

export default {
  name: "About",
  components: { TopNav, SideNav }
};
</script>

<style scoped>
.about-container {
  margin-top: 130px;
  margin-left: 100px;
  width: 30%;
  height: 500px;
}

.about-container h3 {
  width: 101%;
}
</style>