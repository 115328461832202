/*
# *****************************************************************
#
# Licensed Materials - Property of IBM
#
# (C) Copyright IBM Corp. 2020, 2021, 2022. All Rights Reserved.
#
# US Government Users Restricted Rights - Use, duplication or
# disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
#
# *****************************************************************
*/

import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

function loadLocaleMessages() {
    // read locales directory to load language json files
    const locales = require.context(
      "../locales",
      true,
      /[A-Za-z0-9-_,\s]+\.json$/i
    );
    const messages = {};
    locales.keys().forEach(key => {
      // use the first two character of the language as the key
      const matched = key.match(/([A-Za-z0-9-_]+)\./i);
      if (matched && matched.length > 1) {
        const locale = matched[1];
        messages[locale] = locales(key);
      }
    });
    return messages;
  }

console.log('locale is ', navigator.language || navigator.userLanguage);
const i18n = new VueI18n({
    locale: navigator.language || navigator.userLanguage, // set to browser locale
    fallbackLocale: 'en', // set fallback locale
    silentFallbackWarn: true,
    messages: loadLocaleMessages()// set locale messages
});

export default i18n;