<!--
# *****************************************************************
#
# Licensed Materials - Property of IBM
#
# (C) Copyright IBM Corp. 2021, 2022. All Rights Reserved.
#
# US Government Users Restricted Rights - Use, duplication or
# disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
#
# *****************************************************************
-->
<template>
  <div class="page-header-content">
    <section
      class="
        page-header page-header--show-background page-header--has-navigation
      "
    >
      <div class="bx--grid">
        <div class="page-header__non-navigation-row-content" v-show="showPageHeader">
          <div class="page-header__breadcrumb-row bx--row" />
          <div
            class="
              page-title
              page-header__title-row page-header__title-row--has-page-actions
              bx--row
            "
          >
            <div class="page-header__title-column bx--col">
              <div class="page-header__title page-header__title--fades">
                <span :title="fileName">
                  {{ fileName }}
                </span>
              </div>
            </div>

            <div class="page-header__page-actions bx--col">
              <div
                class="
                  page-header__page-actions-container
                  page-header--button-set-with-overflow
                "
              >
                <div
                  class="
                    page-header--button-set-with-overflow__button-container
                    bx--btn-set
                  "
                >
                  <cv-button class="unset-max-width" id='upload-json-button' kind="tertiary" @click="uploadJson">
                    {{ $t("upload-icon-tooltip") }}
                  </cv-button>
                  <cv-button class="unset-max-width" kind="primary" @click="showGenerateJsonDialog">
                    {{ $t("generate-json-label") }}
                  </cv-button>
                </div>
              </div>
            </div>
          </div>

          <div class="page-header__subtitle-row bx--row" />
          <div
            class="
              page-header__last-row-buffer page-header__last-row-buffer--active
            "
          />
        </div>

        <div
          class="
            page-header__navigation-row
            page-header__navigation-row--spacing-above-06
            bx--row
          "
        >
          <cv-tabs
            @tab-selected="onTabSelected"
          >
            <cv-tab
              :key="(option === 'custom_create' || option === 'custom_view')? 'custom' : option"
              :label="$t(option)"
              v-for="(option, index) in graphView.options"
              :data-selected="index === selectedIndex"
              :selected="index === selectedIndex"
            >
              <toolbar
                :readonly="isReadonlyView(option)"
                :graphViewKey="option"
                :class="option"
              >
              </toolbar>
            </cv-tab>
          </cv-tabs>
        </div>
      </div>
    </section>

    <cv-modal
      :visible="showJsonDialog"
      id="confirm-generate-json-dialog"
      :close-aria-label="$t('close-button-aria')"
      @modal-hidden="hideGenerateJsonDialog"
      @primary-click="onGenerateJson"
      @secondary-click="hideGenerateJsonDialog"
      :auto-hide-off="false"
    >
      <template slot="title">{{ $t("generate-json-dialog-title") }}</template>
      <template slot="content">
        <span
          v-html="$t('generate-json-dialog-desc')">
        </span>
        <cv-inline-loading
          v-show="showGenerateJsonInitiated"
          :loaded-text="$t('generate-json-initiated')"
          state="loaded">
        </cv-inline-loading>
      </template>
      <template slot="secondary-button">
        {{ generateJsonDialogSecondaryButtonLabel }}
      </template>
      <template slot="primary-button">
        {{ $t("generate-json-label") }}
      </template>
    </cv-modal>
  </div>
</template>

<script>
import { eventBus } from "../../main.js";
import constants from "@/lib/constants";
import utils from "@/lib/graph/graphUtil";
import Toolbar from "../Toolbar"

export default {
  name: "PageHeader",
  components: {
    Toolbar
  },
  props: ["fileName", "graphView"],
  data: () => ({
    selectedIndex: 0,
    showPageHeader: true,
    showJsonDialog: false,
    showGenerateJsonInitiated: false,
    generateJsonDialogSecondaryButtonLabel: '',
    demoMode: false,
  }),
  created() {
    eventBus.$on("PageHeader.showPageHeader", (options) => {
      this.showPageHeader = options?.showHeader;

      if (this.showPageHeader) {
        document.querySelector('.page-header__navigation-row').querySelector('.bx--tabs--scrollable').style.display = "block";
      } else {
        document.querySelector('.page-header__navigation-row').querySelector('.bx--tabs--scrollable').style.display = "none";
      }

      if (options?.events) {
        const events = options.events;
        events.forEach((ele) => {
          eventBus.$emit(ele.name, ele.params);
        });
      }
    });
    eventBus.$on("PageHeader.gotoView", (viewOptionName) => {
      // This event is called after loading a new json to reset the tab to the first view or
      // when creating a custom view is cancelled.
      const viewIndex = this.graphView?.options.findIndex(optionName => optionName === viewOptionName);
      // console.log("view index for " + viewOptionName + " is " + viewIndex + " and selected index is " + this.selectedIndex);
      if (viewIndex !== -1) {
        if (this.selectedIndex === viewIndex) {
          // force to reload the view and go back to graph tab
          eventBus.$emit('Toolbar.gotoGraph', {
            event: { name: "GraphSummaryTopPanel.viewOnChange", params: viewOptionName }
          })
          // eventBus.$emit("GraphSummaryTopPanel.viewOnChange", viewOptionName);
        } else {
          // click on the tab to load the view
          const tabButtonElements = document.querySelectorAll(`.page-header .page-header__navigation-row .cv-tabs .cv-tab .cv-tabs-button button`);
          if (viewIndex < tabButtonElements.length) {
            tabButtonElements[viewIndex].click();
            tabButtonElements[viewIndex].focus(); // accessibility to put focus there to continue navigation
          }
        }
      }
    });
    eventBus.$on("PageHeader.setTabFocus", (viewOptionName) => {
      const viewIndex = this.graphView?.options.findIndex(optionName => optionName === viewOptionName);
      if (viewIndex !== -1) {
          const tabButtonElements = document.querySelectorAll(`.page-header .page-header__navigation-row .cv-tabs .cv-tab .cv-tabs-button button`);
          if (viewIndex < tabButtonElements.length) {
            tabButtonElements[viewIndex].focus();
          }
      }
    });
    eventBus.$on("PageHeader.setDemoMode", (demoMode) => {
      this.demoMode = demoMode;
    })
  },
  beforeDestroy() {
    eventBus.$off("PageHeader.showPageHeader");
    eventBus.$off("PageHeader.gotoView");
    eventBus.$off("PageHeader.setTabFocus");
    eventBus.$off("PageHeader.setDemoMode");
  },
  methods: {
    isReadonlyView(viewName) {
      return (viewName !== "custom_view" && viewName !== "custom_create");
    },
    onTabSelected(index) {
      this.selectedIndex = index;
      const optionName =  this.graphView?.options[index];
      // The hideLabelsPanel call is necessary when switching BL/NS/CV views while in graph tab
      eventBus.$emit("TableView.hideLabelsPanel");
      //this.$nextTick(() => {
        if (optionName) {
          eventBus.$emit("Toolbar.onGraphViewChange", optionName);
        }
      //});
    },
    showGenerateJsonDialog() {
      if (!this.demoMode) {
        this.showJsonDialog = true;
        this.showGenerateJsonInitiated = false;
        this.generateJsonDialogSecondaryButtonLabel = this.$t("cancel-button-label");
        // enable the generate json button
        this.disableGenerateJsonDialogPrimaryButton(false);
      }
    },
    hideGenerateJsonDialog() {
      this.showJsonDialog = false;
    },
    onGenerateJson() {
      if (!this.demoMode) {
        let a = document.createElement("a");
        let jsonData = this.unconvertGraphViewData();
        // add labels to json 
        jsonData.labels = this.$store.getters.getLabels;
        let file = new Blob([JSON.stringify(jsonData)], {
          type: "application/json",
        });
        a.href = URL.createObjectURL(file);
        a.download = this.$store.getters.getFilename;
        a.click();
      }
      this.showGenerateJsonInitiated = true;
      this.generateJsonDialogSecondaryButtonLabel = this.$t("dismiss-button-label");
      // disable the generate json button
      this.disableGenerateJsonDialogPrimaryButton(true);
    },
    uploadJson() {
      if (!this.demoMode) {
        eventBus.$emit("GraphSummaryTopPanel.showUploadDialog");
      }
    },
    unconvertGraphViewData() {
      let apps = this.$store.getters.getApps;
      let storeJson = this.$store.getters.getJson;
      let cyInstanceMap = this.$store.getters.getCYInstanceMap;
      let unconvertedJson = {};

      // if custom view is newly created, add the view to be saved in apps
      if (cyInstanceMap["custom_view"] && !apps.includes("custom_view")) {
        apps.push("custom_view");
      }
      apps.forEach(function (viewKey) {
        if (Object.prototype.hasOwnProperty.call(cyInstanceMap, viewKey)) {
          const cy = cyInstanceMap[viewKey];
          let data = utils.unconvertData(
            viewKey,
            cy,
            storeJson[viewKey][constants.overviewId][constants.linkId],
            false
          );
          unconvertedJson[viewKey] = data;
        } else {
          unconvertedJson[viewKey] = storeJson[viewKey];
        }
      });

      return unconvertedJson;
    },
    disableGenerateJsonDialogPrimaryButton(setDisable) {
      const generateJsonButtonElement = document.querySelector("#confirm-generate-json-dialog .bx--btn--primary");
      if (generateJsonButtonElement) {
        generateJsonButtonElement.disabled = setDisable;
      }
    }
  },
};
</script>

<style lang="scss">
@import "@/styles/page-header.scss";

#confirm-generate-json-dialog {
  .bx--modal-content {
    margin-bottom: 3rem;

    span a {
      text-decoration: none;
    }

    .bx--inline-loading {
      padding-top: 1.5rem;
      padding-left: 0.5rem;
    }
  }
}

#upload-json-button {
  margin-right: 1rem;
}
.unset-max-width {
  max-width: unset !important;
}
</style>
