<!--
# *****************************************************************
#
# Licensed Materials - Property of IBM
#
# (C) Copyright IBM Corp. 2019, 2020, 2021, 2022, 2023. All Rights Reserved.
#
# US Government Users Restricted Rights - Use, duplication or
# disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
#
# *****************************************************************
-->
<template>
  <div>
  <page-header style="position: relative; z-index: 16;"
    :fileName="panelSummary.fileName"
    :graphView="panelSummary.graphViewDropdown"
  >
  </page-header>
  <filter-panel/>
  <div class="tab-contents">
    <table-view></table-view>
  </div>
  <div>
    <edit-labels-panel
      onEditLabelsEvent="TableView.onEditLabels"
      ref="editLabelsPanel"
    />

    <manage-labels-panel
    />
  </div>
  <cv-modal
    :visible="showUploadAnalysisDialog"
    id="upload-dialog"
    kind="danger"
    :close-aria-label="$t('close-button-aria')"
    :primary-button-disabled="disablePrimaryButton"
    @modal-hidden="hideUploadDialog"
    @primary-click="onUpload"
    ref="uploadDialogRef"
    :auto-hide-off="false"
  >
    <template slot="title">{{ $t("upload-dialog-title") }}</template>
    <template slot="content">
      <i18n path="upload-desc-dialog" tag="span">
        <a :href="fileUploaderDialog.url" target="_blank" rel="noopener noreferrer">{{ $t('upload-desc-link-text') }}</a>
      </i18n>
      <file-uploader :fileUploader="fileUploaderDialog"></file-uploader>
    </template>
    <template slot="secondary-button">{{
      $t("cancel-button-label")
    }}</template>
    <template slot="primary-button">{{ $t("upload-button-label") }}</template>
  </cv-modal>

  <cv-modal
    :visible="showCreateCustomDialog"
    id="custom-dialog"
    :close-aria-label="$t('close-button-aria')"
    :primary-button-disabled="customViewPrimaryButtonDisabled"
    @modal-hidden="restoreView"
    @primary-click="onCreateCustomView"
    @secondary-click="restoreView"
    :auto-hide-off="false"
  >
    <template slot="title">{{ $t("create-custom-dialog-title") }}</template>
    <template slot="content">
      <p>{{ $t("create-custom-dialog-desc") }}</p>
      <div class="custom-view-selection">
        <cv-tile
          kind="selectable"
          value="createCustomViewBusinessLogic"
          :light="true"
          :selected="createStartViewSelection === 'micro_detail_partition_by_business_logic'"
          @change="onCustomViewBusinessLogicViewSelect(false)"
          @keydown.enter.prevent="onCustomViewBusinessLogicViewSelect(false)"
          @keydown.space.prevent="onCustomViewBusinessLogicViewSelect(false)"
        >
          <div class="custom-view-selection-title">
            {{ $t("custom-dialog-option-business")}}
          </div>
          <p>
            {{ $t("custom-dialog-business-logic-desc")}}
          </p>
        </cv-tile>
        <cv-tile
          kind="selectable"
          value="createCustomViewNaturalSeam"
          :light="true"
          :selected="createStartViewSelection === 'micro_detail_partition_by_natural_seam'"
          @change="onCustomViewNaturalSeamViewSelect(false)"
          @keydown.enter.prevent="onCustomViewNaturalSeamViewSelect(false)"
          @keydown.space.prevent="onCustomViewNaturalSeamViewSelect(false)"
        >
          <div class="custom-view-selection-title">
            {{ $t("custom-dialog-option-natural")}}
          </div>
          <p>
            {{ $t("custom-dialog-natural-seam-desc")}}
          </p>
        </cv-tile>
      </div>
    </template>
    <template slot="secondary-button">{{
      $t("cancel-button-label")
    }}</template>
    <template slot="primary-button">{{ $t("create-view-button-label") }}</template>
  </cv-modal>
  <cv-modal
    :visible="showResetCustomDialog"
    id="reset-dialog"
    kind="danger"
    :primary-button-disabled="customViewPrimaryButtonDisabled"
    @modal-hidden="hideResetCustomDialog(true)"
    @primary-click="onResetCustomView"
    :close-aria-label="$t('close-button-aria')"
    :auto-hide-off="false"
  >
    <template slot="title">{{ $t("reset-custom-dialog-title")}}</template>
    <template slot="content">
      <p>{{ $t("reset-custom-dialog-body")}}</p>
      <div class="custom-view-selection">
        <cv-tile
          kind="selectable"
          value="resetCustomViewBusinessLogic"
          :light="true"
          :selected="resetStartViewSelection === 'micro_detail_partition_by_business_logic'"
          @change="onCustomViewBusinessLogicViewSelect(true)"
          @keydown.enter.prevent="onCustomViewBusinessLogicViewSelect(true)"
          @keydown.space.prevent="onCustomViewBusinessLogicViewSelect(true)"
        >
          <div class="custom-view-selection-title">
            {{ $t("custom-dialog-option-business")}}
          </div>
          <p>
            {{ $t("custom-dialog-business-logic-desc")}}
          </p>
        </cv-tile>
        <cv-tile
          kind="selectable"
          value="resetCustomViewNaturalSeam"
          :light="true"
          :selected="resetStartViewSelection === 'micro_detail_partition_by_natural_seam'"
          @change="onCustomViewNaturalSeamViewSelect(true)"
          @keydown.enter.prevent="onCustomViewNaturalSeamViewSelect(true)"
          @keydown.space.prevent="onCustomViewNaturalSeamViewSelect(true)"
        >
          <div class="custom-view-selection-title">
            {{ $t("custom-dialog-option-natural")}}
          </div>
          <p>
            {{ $t("custom-dialog-natural-seam-desc")}}
          </p>
        </cv-tile>
      </div>
    </template>
    <template slot="secondary-button">{{ $t("cancel-button-label") }}</template>
    <template slot="primary-button">{{ $t("reset-button-label") }}</template>
  </cv-modal>
  <cv-modal
    :visible="showInformationDialog"
    id="info-dialog"
    :close-aria-label="$t('close-button-aria')"
    @modal-shown="showInfoDialog"
    @modal-hidden="hideInfoDialog"
    :auto-hide-off="false"
    @primary-click="redirectToDocs"
    size="small"
  >
    <template slot="title">
      <div class="info-title">{{ $t("landing-welcome-title") }}</div>
    </template>
    <template slot="content">
      <div class="info-version">{{ $t("info-version", {m2mVersion: this.m2mVersion}) }}</div>
      <i18n path="info-link" tag="span">
        <a class="info-link info-link-learn-more" href="https://www.ibm.com/cloud/mono2micro" target="_blank" rel="noopener noreferrer">{{ $t('info-help-learn-more') }}</a>
        <a class="info-link info-link-doc"  href="https://www.ibm.com/docs/en/mono2micro" target="_blank" rel="noopener noreferrer">{{ $t('info-help-doc') }}</a>
      </i18n>
      <div class="info-text">{{ $t("info-help-content") }}</div>
    </template>
  </cv-modal>
  <cv-modal
    :visible="showGraphViewInfoDialog"
    id="gvinfo-dialog"
    :close-aria-label="$t('close-button-aria')"
    @modal-shown="showGVInfoDialog"
    @modal-hidden="hideGVInfoDialog"
    :auto-hide-off="false"
  >
    <template slot="title">{{ $t("graph-controls") }}</template>
    <template slot="content">
      <div class="gv-info-section-header">
        {{ $t("graph-controls-info") }}
      </div>
      <cv-structured-list class="gv-info-section">
        <template slot="items">
          <cv-structured-list-item class="gv-info-entry">
            <cv-structured-list-data class="gv-info-term">{{
              $t("graph-view-info-bus-log-term")
            }}</cv-structured-list-data>
            <cv-structured-list-data class="gv-info-def">{{
              $t("graph-view-info-bus-log-def")
            }}</cv-structured-list-data>
          </cv-structured-list-item>
          <cv-structured-list-item class="gv-info-entry">
            <cv-structured-list-data class="gv-info-term">{{
              $t("graph-view-info-nat-seam-term")
            }}</cv-structured-list-data>
            <cv-structured-list-data class="gv-info-def">{{
              $t("graph-view-info-nat-seam-def")
            }}</cv-structured-list-data>
          </cv-structured-list-item>
          <cv-structured-list-item class="gv-info-entry">
            <cv-structured-list-data class="gv-info-term">{{
              $t("graph-view-info-custom-term")
            }}</cv-structured-list-data>
            <cv-structured-list-data class="gv-info-def">{{
              $t("graph-view-info-custom-def")
            }}</cv-structured-list-data>
          </cv-structured-list-item>
        </template>
      </cv-structured-list>
    </template>
  </cv-modal>
  <cv-modal
    :visible="showRemoveUnobservedDialog"
    id="remove-unobserved-dialog"
    kind="danger"
    :close-aria-label="$t('close-button-aria')"
    @modal-shown="showRemoveUnobservedDialog"
    @modal-hidden="()=>this.showRemoveUnobservedDialog = false"
    @primary-click="onRemoveUnobservedDialog"
    @secondary-click="()=>this.showRemoveUnobservedDialog = false"
    :auto-hide-off="false"
  >
    <template slot="title">{{ $t("remove-unobserved-dialog-title") }}</template>
    <template slot="content">
      <p>{{ $t("remove-unobserved-dialog-body") }}</p>
      <p>{{ $t("remove-unobserved-dialog-note") }}</p>
    </template>
    <template slot="secondary-button">{{
      $t("cancel-button-label")
    }}</template>
    <template slot="primary-button">{{ $t("remove-button-label") }}</template>
  </cv-modal>
  </div>
</template>

<script>
import apis from "../../lib/graph/graphAPIHandler";
import FileUploader from "../FileUploader";
import PageHeader from "../PageHeader";
import TableView from "../TableView";
import FilterPanel from "../FilterPanel";
import EditLabelsPanel from "../EditLabelsPanel";
import ManageLabelsPanel from "../ManageLabelsPanel";
import messageUtils from "@/js/messageUtils";
import { eventBus } from "../../main.js";
import $ from "jquery";

export default {
  name: "GraphSummaryTopPanel",
  components: {
    // DropDownField,
    FileUploader,
    PageHeader,
    TableView,
    FilterPanel,
    EditLabelsPanel,
    ManageLabelsPanel
  },
  // watch: {
  //   "panelSummary.microservicesDropdown.selectedOption": {
  //     handler: function(val) {
  //       const microservicesInput = document.querySelector("#microservicesDropdown input")
  //       if (!microservicesInput) return;
  //       if (val.length > 0) {
  //         microservicesInput.classList.add("white-placeholder");
  //       } else {
  //         microservicesInput.classList.remove("white-placeholder");
  //       }
  //     },
  //   },
  //   "panelSummary.usecasesDropdown.selectedOption": {
  //     handler: function(val) {
  //       const usecasesInput = document.querySelector("#usecasesDropdown input")
  //       if (!usecasesInput) return;
  //       if (val.length > 0) {
  //         usecasesInput.classList.add("white-placeholder");
  //       } else {
  //         usecasesInput.classList.remove("white-placeholder");
  //       }
  //     }
  //   }
  // },
  computed: {
  },
  methods: {
    isReadonlyView(viewName) {
      return (viewName !== "custom_view" && viewName !== "custom_create");
    },
    resetCustomView: function(e) {
      e.preventDefault();
      e.stopPropagation();
      this.showResetCustomDialog = true;
      this.customViewPrimaryButtonDisabled = true;
      this.resetStartViewSelection = "";

      this.$nextTick(() => {
        // has to wait to set focus on the reset dialog even with $nextTick
        setTimeout(() => {
          // set focus on first tile
          document
            .querySelector("#reset-dialog .bx--modal-content .bx--tile--selectable")
            .focus();
        }, 100);
      });
    },
    hideUploadDialog() {
      this.showUploadAnalysisDialog = false;
    },
    showCustomDialog() {
      // Show the create custom view dialog
      this.showCreateCustomDialog = true;
      this.createStartViewSelection = "";
      this.customViewPrimaryButtonDisabled = true;

      this.$nextTick(() => {
        // has to wait to set focus on the custom dialog even with $nextTick
        setTimeout(() => {
          // set focus on first tile
          document
            .querySelector("#custom-dialog .bx--modal-content .bx--tile--selectable")
            .focus();
        }, 100);
      });
    },
    hideCustomDialog() {
      // Hide the create custom view dialog
      this.showCreateCustomDialog = false;
    },
    hideResetCustomDialog(setFocus) {
      this.showResetCustomDialog = false;

      if (setFocus) {
        // accessibility: set focus back to the reset custom view button
        eventBus.$emit("Toolbar.setFocus", "resetCustomView");
      }
    },
    onResetCustomView() {
      let selectedStartView = this.resetStartViewSelection;
      this.$store.commit("setKey", {
        key: "custom_view",
        seedingView: selectedStartView,
        resetSelectedView: true,
      });
      this.hideResetCustomDialog(false);

      // emit is async call, anything requires drawGraph to be finished must be wrapped in another event.
      eventBus.$emit("Graph.drawGraph", {
        resetSelectedView: true,
        event: { name: "GraphSummaryTopPanel.onResetCustomView", params: {} },
      });
      // clear dependencies
      this.$store.commit("setDynamicDependencyEdgesForEntireCY", false);
      this.$store.commit("setEdgeFilterClasses", {});
      // eventBus.$emit("FilterPanel.resetPanel", true);
    },
    onRemoveUnobservedDialog() {
      apis.removeIsolatedUnobservedNodesFromGraph();
      this.showRemoveUnobservedDialog = false;
    },
    showGVInfoDialog() {
      // Show the Graph view information dialog
      this.showGraphViewInfoDialog = true;
    },
    hideGVInfoDialog() {
      // Hide the Graph view information dialog
      this.showGraphViewInfoDialog = false;
    },
    showInfoDialog() {
      this.showInformationDialog = true;
      const ele = $("div#info-dialog div.bx--modal-container");//("div.bx--modal-container");
      ele.attr("aria-label","About IBM Mono2Micro");
    },
    hideInfoDialog() {
      this.showInformationDialog = false;
    },
    onShowInformationDialog() {
      this.showInfoDialog()
    },
    onUpload() {
      document.querySelector(
        "#upload-dialog .bx--btn--danger"
      ).disabled = true;
      // document.querySelector(
      //   ".edit-class-toggle .bx--toggle-input"
      // ).checked = false;

      eventBus.$emit(this.fileUploaderDialog.ref + ".processFileUpload", this.uploadSelectedFile);
    },
    onUploadIconClick() {
      this.showUploadAnalysisDialog = true;

      // Reset the dialog to its original state. This event is moved here so as not to see the reset
      // happening when the dialog is closed.
      eventBus.$emit(this.fileUploaderDialog.ref + ".fileUploaderClosing");
      // set focus after waiting for 200 milliseconds
      setTimeout(this.setUploadDialogFocus, 200);
    },
    setUploadDialogFocus() {
      //document.getElementById("upload-dialog").getElementsByClassName("bx--file-browse-btn")[0].focus();
      // focus on the dialog instead of the drag and drop area so that JAWS could read the dialog
      document
        .getElementById("upload-dialog")
        .getElementsByClassName("cv-modal__before-content")[0]
        .focus();
    },
    redirectToDocs() {
      window.open(
        'https://www.ibm.com/docs/en/mono2micro',
        '_blank'
      );
      this.showInformationDialog = false;
    },
    // onFileChange(file) {
    //   // enable/disable upload dialog + enable/disable add/drag file area
    //   let fileBrowseElement = document.querySelector(
    //     "#upload-dialog .bx--file-browse-btn"
    //   );
    //   if (file.length === 0 && fileBrowseElement.classList.contains("hidden")) {
    //     fileBrowseElement.classList.remove("hidden");
    //     document.querySelector(
    //       "#upload-dialog .bx--btn--primary"
    //     ).disabled = true;
    //   } else if (
    //     file.length === 1 &&
    //     !fileBrowseElement.classList.contains("hidden")
    //   ) {
    //     this.uploadSelectedFile = file;
    //     fileBrowseElement.classList.add("hidden");
    //     document.querySelector(
    //       "#upload-dialog .bx--btn--primary"
    //     ).disabled = false;
    //   }
    // },
    setFileName(fileName) {
      if (fileName) {
        this.panelSummary.fileName = fileName;
      }
    },
    setGraphViewDropdown(dropdownList) {
      if (dropdownList) {
        this.panelSummary.graphViewDropdown.options = dropdownList;
      }
    },
    setGraphViewSelected(selectedOption) {
      if (selectedOption !== undefined) {
        this.panelSummary.graphViewDropdown.selectedOption = selectedOption;
      }
    },
    restoreView() {
      // User canceled out of the Create custom view dialog.  Restore the view to
      // the previously selected option.
      let previousView = this.$store.getters.getKey;
      this.panelSummary.graphViewDropdown.selectedOption = previousView;
      this.hideCustomDialog();
      eventBus.$emit("PageHeader.gotoView", previousView);
    },
    onCreateCustomView() {
      // Change the view to match the natural seams/business view starting point
      let selectedStartView = this.createStartViewSelection;
      this.$store.commit("setKey", {
        key: "custom_view",
        seedingView: selectedStartView,
      });
      // Hide the Create custom view dialog
      this.hideCustomDialog();

      // emit is async call, anything requires drawGraph to be finished must be wrapped in another event.
      eventBus.$emit("Graph.drawGraph", {
        event: { name: "GraphSummaryTopPanel.onCreateCustomView", params: {} },
      });
    },
    onCustomViewBusinessLogicViewSelect(isReset) {
      this.customViewPrimaryButtonDisabled = false;
      if (isReset) {
        this.resetStartViewSelection = "micro_detail_partition_by_business_logic";
      } else {
        this.createStartViewSelection = "micro_detail_partition_by_business_logic";
      }
    },
    onCustomViewNaturalSeamViewSelect(isReset) {

      this.customViewPrimaryButtonDisabled = false;
      if (isReset) {
        this.resetStartViewSelection = "micro_detail_partition_by_natural_seam";
      } else {
        this.createStartViewSelection = "micro_detail_partition_by_natural_seam";
      }
    },
    // use passed in argument to determine enablement of partition edit
    _enableEditMode(enabled) {
      if (!this.showEditClass) return;
      apis.activateDragAndDrop(enabled);
      // document.querySelector(
      //   ".edit-class-toggle .bx--toggle-input"
      // ).checked = enabled;
      eventBus.$emit("Toolbar.updateLockPartition", !enabled);
    },
    showBusyIcon(show) {
      this.$parent.isLoading = show;
    },
  },

  mounted() {
    $('.custom-view-selection label').on('keydown', function(event) {
      //console.log(event.keyCode);
      if (event.keyCode==13 || event.keyCode==32) {
        $(this).trigger("click");
      }
    });
    // var microservicesDropdownDiv = $("#main-content")[0];
    // this.microservicesDropdownObserver = new MutationObserver(function (mutations) {
    //   mutations.forEach(function(mutation) {
    //     if (mutation.attributeName === "class") {
    //       var grandParent = $(mutation.target).parent().parent();
    //       const gpId = grandParent.prop("id");
    //       const heightMap = {"microservicesDropdown": "200px", "usecasesDropdown": "250px"}
    //       if ( gpId in heightMap ) {
    //         if ($(mutation.target).hasClass("bx--list-box--expanded")) {
    //           grandParent.parent().css("min-height", heightMap[gpId]);
    //         } else {
    //           grandParent.parent().css("min-height", "");
    //         }
    //       }
    //     }
    //   });
    // });
    // this.microservicesDropdownObserver.observe(microservicesDropdownDiv, {
    //   attributes: true,
    //   childList: true,
    //   subtree: true,
    //   characterData: true
    // });
    eventBus.$on("GraphSummaryTopPanel.onResetCustomView", () => {
      // we have to call resetFilters here to work around the issue
      // Due to current implementation, after 'reset custom view', the graph elements contain cy data (preset layout),
      // elements contain filter class(es), the API cleans up all filters applied to graph view
      eventBus.$emit("FilterPanel.resetFilters", true);
      // Hide the Reset custom view dialog and reset the radio button selection for
      // the next time it opens.
      this.resetStartViewSelection = ""; // "micro_detail_partition_by_business_logic";

      // Reset edit mode
      this._enableEditMode(true);
      eventBus.$emit("Toolbar.showGraphIfHidden");
      eventBus.$emit("GraphSummaryTopPanel.viewOnChangeCompleted", "custom_view");
      eventBus.$emit("SidePanel.updateCustomView");
      eventBus.$emit("Toolbar.setFocus", "resetCustomView");
    });
  },
  created() {
    eventBus.$on("GraphSummaryTopPanel.shouldReset", () => {
      this.shouldReset = true;
    });
    eventBus.$on("GraphSummaryTopPanel.showPanel", (summaryData) => {
      if (summaryData.fileName) {
        this.setFileName(summaryData.fileName);
      }
      if (summaryData.graphViewDropdown && summaryData.graphViewDropdown.options) {
        // don't show the class dependencies view
        const noDependenyOptions = summaryData.graphViewDropdown.options.filter(view => view != 'mono_data_dependency_graph');
        this.setGraphViewDropdown(noDependenyOptions);
      }
      // if (summaryData.graphViewDropdown && summaryData.graphViewDropdown.selectedOption) {
      //   const oldSelectedView = this.panelSummary.graphViewDropdown.selectedOption;
      //   this.setGraphViewSelected(summaryData.graphViewDropdown.selectedOption);
      //   if (oldSelectedView === summaryData.graphViewDropdown.selectedOption) {
      //     // old value equals to the new one, force to trigger viewOnChange as graph file has been changed
      //     eventBus.$emit("GraphSummaryTopPanel.viewOnChange", summaryData.graphViewDropdown.selectedOption);
      //   }
      // }

      // go to the default selected tab (i.e BL view) to reload the view if loading a new json file
      if (this.showPanel) {
        eventBus.$emit("PageHeader.gotoView", summaryData.graphViewDropdown.selectedOption);
      }

      // Set placeholder text of Search to include number of classes when available.
      // if (summaryData.numClasses != "") {
      //   //eventBus.$emit("TopNav.getNumberOfClasses", summaryData.numClasses);
      //   eventBus.$emit("Toolbar.getNumberOfClasses", summaryData.numClasses);
      // } else {
        this.panelSummary.search.placeholder = this.$t("search-classes");
      // }
      this.showPanel = true;
      eventBus.$emit("TopNav.showHeaderAction", true);
      eventBus.$emit("Toolbar.showToolbarAction", true);
    });
    eventBus.$on("GraphSummaryTopPanel.viewOnChange", (viewKey) => {
      if (viewKey !== "custom_create") {
        // ToDo: once drop down in control panel is gone, remove the last call
        //eventBus.$emit("DropDownField.setSelectedOption", viewKey);

        this.$store.commit("setKey", { key: viewKey });
        // emit is async call. Graph.drawGraph must be finished drawing the graph before
        // GraphSummaryTopPanel.viewOnChangeCompleted event is processed so that the
        // current graph highlighting can be reset.  Therefore, pass event to drawGraph
        // so the event can be emitted from there when it is done.
        eventBus.$emit("Graph.drawGraph", {
          resetSelectedView: this.shouldReset,
          event: {
            name: "GraphSummaryTopPanel.viewOnChangeCompleted",
            params: viewKey,
          },
        });
        if (this.shouldReset) {
          this.shouldReset = false;
        }
        this.setGraphViewSelected(viewKey);
      } else {
        // Custom view has not yet been created so show Create custom view dialog.
        this.showCustomDialog();
      }
    });
    eventBus.$on("GraphSummaryTopPanel.viewOnChangeCompleted", (viewKey) => {
      apis.cleanupAllHighlights();
      // apply default 'show edge' filters after CY layout
      apis.applyDefaultFilters();
      // mark table to be reseted but not actually loading and showing it
      eventBus.$emit("TableView.resetTable", false);
      // reset search
      eventBus.$emit("TableView.search", "");
      eventBus.$emit("SearchWithFilter.resetSearch");
      // appply filter indicator
      eventBus.$emit("Toolbar.updateFilterIndicator");
      
      // Only when in custom view...
      //    - edit toggle is showing
      //    - the Reset custom button in the Custom View section of the side panel is enabled.
      // So, notify components.
      viewKey !== "custom_view"
        ? eventBus.$emit("inCustomView", false)
        : eventBus.$emit("inCustomView", true);
    });
    eventBus.$on("GraphSummaryTopPanel.viewGraphViewInfo", () => {
      // Show the Graph view information dialog
      this.showGVInfoDialog();
    });
    eventBus.$on("GraphSummaryTopPanel.viewInformation", (version) => {
      this.m2mVersion = version;
      this.onShowInformationDialog();
    });
    eventBus.$on("GraphSummaryTopPanel.expandGraphTopPanel", (expand) => {
      // Let width be 100% - sidepanel width - SpacingWanted(16px) - margin left
      if (expand) {
        if (this.$refs.graphTopPanel) {
          this.$refs.graphTopPanel.style.width =
            "calc(100% - 50px - 16px - 20px)";
        }
      } else {
        if (this.$refs.graphTopPanel) {
          this.$refs.graphTopPanel.style.width =
            "calc(100% - 256px - 16px - 20px)";
        }
      }
    });
    eventBus.$on("GraphSummaryTopPanel.showUploadDialog", () => {
      this.onUploadIconClick();
    });
    eventBus.$on("GraphSummaryTopPanel.onFileSelected", (file) => {
      // enable/disable upload dialog + enable/disable add/drag file area
      let fileBrowseElement = document.querySelector(
        "#upload-dialog .bx--file-browse-btn"
      );
      if (file.length === 0 && fileBrowseElement.classList.contains("hidden")) {
        fileBrowseElement.classList.remove("hidden");
        document.querySelector(
          "#upload-dialog .bx--btn--danger"
        ).disabled = true;
      } else if (
        file.length === 1 &&
        !fileBrowseElement.classList.contains("hidden")
      ) {
        this.uploadSelectedFile = file;
        fileBrowseElement.classList.add("hidden");
        document.querySelector(
          "#upload-dialog .bx--btn--danger"
        ).disabled = false;
      }
    });
    eventBus.$on("GraphSummaryTopPanel.closeFileUploadDialog", () => {
      this.hideUploadDialog();
    });
    eventBus.$on("inCustomView", (active) => {
      // The edit class toggle button is available for Custom view only
      this.showEditClass = active;
      this._enableEditMode(active);
    });
    eventBus.$on("GraphSummaryTopPanel.onCreateCustomView", () => {
      apis.cleanupAllHighlights();
      this._enableEditMode(true);
      eventBus.$emit("FilterPanel.resetFilters", true);
      // On the Side panel, enable the Custom view section.
      eventBus.$emit("SidePanel.enableCustomView", true);
      // There can only ever be one custom view.  So the 'Create custom' menu
      // item should be discarded and in its place put 'Custom view' and it
      // should be selected.
      let createCustomIndex = this.panelSummary.graphViewDropdown.options.indexOf(
        "custom_create"
      );
      if (createCustomIndex !== -1) {
        this.panelSummary.graphViewDropdown.options[createCustomIndex] =
          "custom_view";
        this.panelSummary.graphViewDropdown.selectedOption = "custom_view";
      }
      // On the Side panel, indicate that we are now in custom view
      // eventBus.$emit("inCustomView", true);
      // need to go thru the same path as changing a view to reset various things when creating a custom view
      eventBus.$emit("GraphSummaryTopPanel.viewOnChangeCompleted", "custom_view");
      eventBus.$emit("PageHeader.setTabFocus", "custom_view");
    });
    eventBus.$on("GraphSummaryTopPanel.resetCustomView", (e) => {
      this.resetCustomView(e);
    });
  },
  beforeDestroy() {
    if (this.microservicesDropdownObserver) {
      this.microservicesDropdownObserver.disconnect();
    }
    eventBus.$off("GraphSummaryTopPanel.shouldReset");
    eventBus.$off("GraphSummaryTopPanel.showPanel");
    eventBus.$off("GraphSummaryTopPanel.viewOnChange");
    eventBus.$off("GraphSummaryTopPanel.onFileSelected");
    eventBus.$off("GraphSummaryTopPanel.closeFileUploadDialog");
    eventBus.$off("GraphSummaryTopPanel.viewOnChangeCompleted");
    eventBus.$off("GraphSummaryTopPanel.viewGraphViewInfo");
    eventBus.$off("GraphSummaryTopPanel.expandGraphTopPanel");
    eventBus.$off("GraphSummaryTopPanel.showUploadDialog");
    eventBus.$off("inCustomView");
    eventBus.$off("GraphSummaryTopPanel.onCreateCustomView");
    eventBus.$off("GraphSummaryTopPanel.onResetCustomView");
    eventBus.$off("GraphSummaryTopPanel.resetCustomView");
  },
  data: () => ({
    // one of the three: 'disabled', 'ready', 'applied'
    microservicesDropdownObserver: undefined,
    shouldReset: false,
    showPanel: false,
    showUploadAnalysisDialog: false,
    showCreateCustomDialog: false,
    showResetCustomDialog: false,
    showGraphViewInfoDialog: false,
    showRemoveUnobservedDialog: false,
    showInformationDialog: false,
    showMultiSelectComponent: true,
    showEditClass: false, // class editing should be enabled for Custom view only
    disablePrimaryButton: true,
    show_control: false,
    uploadSelectedFile: "",
    open: [false], // this variable maybe not taking any effects, needs further investigation
    fileUploaderDialog: {
      title: "",
      helperText: "",
      uploadText: "upload-text",
      onChangeEvent: "GraphSummaryTopPanel.onFileSelected",
      ref: "uploadDialogFileUploader",
      url: "https://www.ibm.com/docs/mono2micro",
    },
    align: "start",
    panelSummary: {
      fileName: "",
      search: {
        id: "classSearchFilter",
        label: messageUtils.getMessage("search-classes"),
        placeholder: messageUtils.getMessage("search-classes"),
        clearAriaLabel: messageUtils.getMessage("clear-search"),
        clusterColorMap: [],
      },
      graphViewDropdown: {
        id: "graphViewDropdown",
        label: "graph-view", // use message key in i18n.js
        placeholder: "viewSelectPlaceholder", // use message key in i18n.js
        onChangeEvent: "GraphSummaryTopPanel.viewOnChange",
        value: "",
        selectedOption: "",
        options: [],
        onShowInfo: "GraphSummaryTopPanel.viewGraphViewInfo",
      },
    },
    createStartViewSelection: "", // Create graph view starting point selection - Business logic or Natural seams
    resetStartViewSelection: "", // "micro_detail_partition_by_business_logic",
    m2mVersion: 0,
    customViewPrimaryButtonDisabled: true,
  }),
};
</script>

<style lang="scss" scoped>
#custom-dialog, #reset-dialog {
  .bx--modal-container {
    .bx--modal-content {
      p {
        margin-bottom: 1.5rem;
        padding-right: 20%;
      }

      .custom-view-selection {
        display: flex;
        margin-left: 0.5rem;
        margin-right: 0.5rem;

        .bx--tile--selectable {
          .custom-view-selection-title {
            @include carbon--type-style('productive-heading-02');
            padding-bottom: 0.5rem;
          }

          p {
            @include carbon--type-style('body-short-01');
            // padding-right: 0;
            margin-bottom: 1rem;
          }

          &:first-of-type {
            margin-right: 1.5rem;
          }
        }
      }
    }
  }
}

#info-dialog {
  .bx--modal-container {
    .info-title {
      @include carbon--type-style('productive-heading-04');
      color: var(--app-info-dialog-title-color);
      margin-top: 2.5rem;
    }

    .info-version {
      @include carbon--type-style('body-short-02');
      color: var(--app-info-dialog-body-color);
      margin-bottom: 1.5rem;
    }

    .info-link {
      @include carbon--type-style('body-short-01');
      color: var(--app-info-dialog-link-color);
      text-decoration: none;
    }

    .info-link-learn-more {
      border-right: solid 1px var(--app-info-dialog-link-border-color);
      padding-right: 0.5rem;
    }

    .info-link-doc {
      padding-left: 0.5rem;
    }

    .info-text {
      @include carbon--type-style('caption-01');
      color: var(--app-info-dialog-body-color);
      margin-top: 1.5rem;
    }
  }
}
</style>

<style lang="scss">
#custom-dialog, #reset-dialog {
  .bx--modal-container {
    .bx--modal-content {
      margin-bottom: 3rem;
      padding-right: 1rem;

      .bx--tile--selectable {
        min-height: 11.25rem;
      }
    }
  }
}

#upload-dialog {
  .bx--modal-content {
    margin-bottom: 3rem;

    span {
      a {
        text-decoration: none;
      }
    }

    .cv-file-uploader {
      .bx--file--label, .bx--label-description {
        display: none
      }

      .bx--file {
        margin-top: 1.5rem;

        .bx--file-browse-btn {
          max-width: 100%;

          &.hidden {
            display: none;

            & + .bx--file-container {
              margin-top: 0;
            }
          }

          .bx--file__drop-container {
            justify-content: flex-start;
            align-items: baseline;
          }
        }
      }

      .bx--file-container {

        .bx--file__selected-file {
          background-color: var(--app-upload-dialog-file-selected-background-color);
        }
      }
    }
  }
}

#info-dialog {
  .bx--modal-container {
    background-color: var(--app-info-dialog-background-color);

    .bx--modal-close {
      &:hover {
        background-color: var(--app-info-dialog-close-button-hover-color);
      }

      svg {
        fill: var(--app-info-dialog-title-color);
      }
    }

    .bx--modal-content {
      padding-top: 0rem;
      margin-bottom: 2.5rem;
    }
  }
}
</style>

<style>
.dropdown-field .cv-multi-select,
.dropdown-field .bx--form-item,
.class-search .bx--search {
  width: 100%;
  max-width: 100%;
}

/* Graph controls information dialog */
.gv-info-section-header {
  /* font-size: 12px;
  font-style: italic;
  color: var(--app-panel-label-02-color); */
  letter-spacing: 0.32px;
  line-height: 16px;
  margin: 0 0 4px 0;
}
.gv-info-section {
  margin-bottom: 9px !important;
  margin-top: 48px;
}
.gv-info-entry {
  border: 0 !important;
}
.gv-info-term {
  font-size: 14px !important;
  font-weight: 600 !important;
  /* color: #f5f5f5; */
  letter-spacing: 0.16px !important;
  line-height: 18px !important;
  padding: 0 0 9px 0 !important;
  width: 30% !important;
}
.gv-info-def {
  font-size: 12px !important;
  /* color: #f5f5f5; */
  letter-spacing: 0.32px !important;
  line-height: 16px !important;
  padding: 0 0 9px 0 !important;
}
#gvinfo-dialog .bx--structured-list-row--header-row {
  border: 0;
}

.white-placeholder::placeholder {
  color: var(--app-filter-panel-white-placeholder-color) !important;
  opacity: 1;
}
</style>