<!--
# *****************************************************************
#
# Licensed Materials - Property of IBM
#
# (C) Copyright IBM Corp. 2021, 2022, 2023. All Rights Reserved.
#
# US Government Users Restricted Rights - Use, duplication or
# disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
#
# *****************************************************************
-->
<template>
  <div>
  <div class="manage-labels-container" v-show="showManageLabelsPanel" ref="manageLabelsContainer">
    <div class="manage-labels-heading">
      <h4>{{ $t("manage-labels-title") }}</h4>
      <cv-button aria-label="Close" class="manage-labels-close" kind="ghost" :icon="Close16" @click="onClosePanel" />
    </div>
    <div class="manage-labels-content-top">
      <div class="manage-labels-description">
        {{ $t("manage-labels-description") }}
      </div>
      <div class="manage-labels-label" ref="createLabelContainer">
        <cv-text-input
          :label="$t('manage-labels-input-title')"
          :placeholder="$t('manage-labels-input-placeholder')"
          v-model="labelValue"
          @input="onLabelInput"
          ref="manageLabelsInput">
          <template v-if="createInvalidLabelMessageSlot" slot="invalid-message">{{ invalidLabelMessage }}</template>
        </cv-text-input>
        <cv-button
          kind="tertiary"
          :disabled="disabledCreate"
          class="manage-labels-create-button"
          @click="onCreateLabel"
        >
          {{ $t("manage-labels-create-button-label") }}
        </cv-button>
      </div>
    </div>
    <cv-data-table
      class="manage-labels-table-data"
      :pagination="tablePagination"
      @pagination="onPagination"
      :borderless=true
      :staticWidth=true
      ref="table"
    >
      <template slot="headings">
        <cv-data-table-heading class="manage-labels-table-name-column">{{ $t("manage-labels-table-label-column", {numOfLabels: labelsInTable.length}) }}</cv-data-table-heading>
        <cv-data-table-heading class="manage-labels-table-time-used-column manage-labels-right-align-column">{{ $t("manage-labels-table-times-used-column") }}</cv-data-table-heading>
        <cv-data-table-heading class="manage-labels-table-icon-column"/>
        <cv-data-table-heading class="manage-labels-table-icon-column manage-labels-table-delete-icon-column"/>
      </template>
      <template slot="data">
        <cv-data-table-row
          tabindex="0"
          v-for="(row, rowIndex) in labelData"
          :key="`${rowIndex}`"
          :value="`${row.name}`"
          @mouseover.native="onTableRowHover(rowIndex)"
          @mouseleave.native="onTableRowBlur(rowIndex)"
          @focus.native="onTableRowHover(rowIndex)"
        >
          <cv-data-table-cell
            v-bind:class="{'manage-lables-edit-label-text-input': editLabelRowIndex === rowIndex}"
          >
            <span 
              v-show="editLabelRowIndex !== rowIndex"
              ref="nonEditableLabelValue"
            >
              {{ row.name }}
            </span>
            <cv-text-input
              v-show="editLabelRowIndex === rowIndex"
              v-bind:class="{'edit-label-input--invalid': editLabelRowIndex === rowIndex && editLabelError}"
              v-model="editLabelValue"
              @input="onEditLabelInput"
              @keyup.enter="onEditLabelInputCompleted"
              @blur="onEditLabelInputBlur"
              ref="editLabelInput"
            >
            </cv-text-input>
            <!--
              <template v-if="editInvalidLabelMessageSlot" slot="invalid-message">{{ invalidLabelMessage }}</template>
            -->
              <cv-tooltip
              v-show="editLabelError && editLabelRowIndex === rowIndex"
              class="edit-label-input--invalid-tooltip-icon"
              ref="invalidEditLabelIcon"
              alignment="center" direction="top" :tip="invalidLabelMessage"
              >
                <Warning16/>
              </cv-tooltip>
          </cv-data-table-cell>
          <cv-data-table-cell class="manage-labels-right-align-column">
            {{ row.assignedClasses.length }}
          </cv-data-table-cell>
          <cv-data-table-cell class="">
            <cv-tooltip
              tabindex="0"
              class="manage-labels-table-icon-data-column"
              ref="editIcon"
              alignment="center" direction="top" :tip="$t('manage-labels-table-edit-label-tooltip')"
              @click.native="onEditLabel(row, rowIndex)"
            >
              <Edit16 />
            </cv-tooltip>
          </cv-data-table-cell>
          <cv-data-table-cell class="">
            <cv-tooltip
              tabindex="0"
              class="manage-labels-table-icon-data-column manage-labels-table-delete-icon"
              ref="deleteIcon"
              alignment="end" direction="top" :tip="$t('manage-labels-table-delete-label-tooltip')"
              @click.native="onDeleteLabel($event, row, rowIndex)"
              @blur.native="onTableCellBlur(rowIndex)"
            >
              <Delete16 />
            </cv-tooltip>
          </cv-data-table-cell>
        </cv-data-table-row>
      </template>
      <template slot="of-n-pages">
        {{ $t("table-pagination-of-pages",
          {total: Math.max(1, Math.ceil(labelsInTable.length / pagination.length)) }) }}
      </template>
    </cv-data-table>
    </div>

    <cv-modal
      :visible="showDeleteDialog"
      kind="danger"
      size="xs"
      class="delete-label-dialog"
      :close-aria-label="$t('close-button-aria')"
      @modal-shown="displayDeleteDialog(true)"
      @modal-hidden="displayDeleteDialog(false)"
      @primary-click="onModalDeleteLabel"
      @secondary-click="displayDeleteDialog(false)"
      :auto-hide-off="false"
      ref="deleteLabelDialog"
    >
      <template slot="title">{{ $t("manage-labels-delete-label-modal-title") }}</template>
      <template slot="content">
        <p>{{ $t("manage-labels-delete-label-modal-body") }}</p>
      </template>
      <template slot="secondary-button">{{
        $t("cancel-button-label")
      }}</template>
      <template slot="primary-button">{{ $t("delete-button-label") }}</template>
    </cv-modal>
  </div>
</template>

<script>
import { eventBus } from "../../main.js";
import Close16 from "@carbon/icons-vue/es/close/20";
import Edit16 from "@carbon/icons-vue/es/edit/16";
import Delete16 from "@carbon/icons-vue/es/trash-can/16";
import Warning16 from "@carbon/icons-vue/es/warning--filled/16";
import constants from "../../lib/constants";
import apis from "../../lib/graph/graphAPIHandler";
//import apis from "../../lib/graph/graphAPIHandler";

export default {
  name: "ManageLabelsPanel",
  components: {
    Edit16,
    Delete16,
    Warning16
  },
  props: [
  ],
  data() {
    return {
      Close16,
      showManageLabelsPanel: false,
      disabledCreate: true,
      createInvalidLabelMessageSlot: false,
      editLabelRowIndex: -1,
      editLabelRowData: {},
      editLabelError: false,
      labelValue: '',
      editLabelValue: '',
      showDeleteDialog: false,
      deleteRowIndex: -1,
      deleteRowData: {},
      hasChanges: false,
      initialRender: true,
      invalidLabelMessage: "",
      pagination: {
        start: 0,
        page: 0,
        length: 8
      },
      tablePagination: {
        page: 1,
        numberOfItems: 0,
        pageSizes: [8]
      },
      labelsInTable: [],
      columns: [
        {
          label: "3 Labels", //this.$t("manage-labels-table-label-column", {numOfLabels: this.labelsInTable.length}),
          headingStyle: {
            width: "13rem", //"8rem",
            display: "block",
          }
        },
        {
          label: "Times used", //this.$t("manage-labels-table-times-used-column"),
          headingStyle: {
            width: "5.75rem", //6rem",
            display: "block",
            textAlign: "right",
          }
        },
        {
          headingStyle: {
            width: "1rem",
            display: "block",
          }
        },
        {
          headingStyle: {
            width: "1rem" ,
            display: "block",
            paddingLeft: "0",
          }
        }
      ]
    }
  },
  computed: {
    labelData: function() {
      if (this.pagination) {
        const startIndex = this.pagination.start - 1;
        let nodes = this.labelsInTable.slice(startIndex, startIndex + this.pagination.length);
        return nodes;
      } else {
        return this.labelsInTable;
      }
    }
  },
  methods: {
    onClosePanel() {
      this.showManageLabelsPanel = false;
      eventBus.$emit("TableView.deselectManageLabels", this.hasChanges)
      // if (this.hasChanges) {
      //   eventBus.$emit("TableView.resetTable");
      // }
    },
    onLabelInput(val) {
      //console.log("val is " + val);
      if (val === "") {
        this.disabledCreate = true;
      } else {
        this.disabledCreate = false;
      }

      // clear out invalid message if existed
      if (this.createInvalidLabelMessageSlot) {
        this.createInvalidLabelMessageSlot = false;
        this.$refs.createLabelContainer.style.alignItems = "flex-end";
      }
    },
    onCreateLabel() {
      // check for duplicate label
      if (this.validateLabel(this.labelValue)) {
        // no op for empty label
        if (this.labelValue) {
          // this will refresh the table with the newly created label
          this.$store.commit("addLabel", this.labelValue);

          // testing
          //this.$store.commit("addClassesToLabel", {labelName: this.labelValue, classNames:['class1', 'class2']});
          //this.$store.commit("addClassesToLabel", {labelName: this.labelValue, classNames:['class2', 'class3']});

          // reset label input and create button
          this.labelValue = "";
          this.disabledCreate = true;

          // go to page 1
          this.resetTableToFirstPage();

          // notify edit label panel to reset labels
          eventBus.$emit("EditLabelsPanel.resetLabels");

          // notify filter panel
          eventBus.$emit("FilterPanel.updatePanel", {filtersToBeUpdated: [constants.filterTypeLabel]});
        }
      } else {
        // show error message
        // this.invalidLabelMessage = this.$t("manage-labels-duplicate-label-error");
        this.createInvalidLabelMessageSlot = true;
        this.$refs.createLabelContainer.style.alignItems = "center";
      }
    },
    onDeleteLabel(e, row, rowIndex) {
      //console.log("------- on delete label " + rowIndex + ": ", row);
      this.showDeleteDialog = true;
      this.deleteRowIndex = rowIndex;
      this.deleteRowData = row;
    },
    onEditLabel(row, rowIndex) {
      //console.log("------- on edit label", row);
      this.editLabelRowIndex = rowIndex;
      this.editLabelRowData = row;
      // initialize the text input value
      this.editLabelValue = row.name;
      const me = this;
      this.$nextTick(function () {
        setTimeout(function() {
          me.$refs.editLabelInput[rowIndex].focus();
        }, 100);
      });
    },
    onEditLabelInput() {
      // clear out red border if existed
      if (this.editLabelError) { // || this.editInvalidLabelMessageSlot) {
        // console.log("clear out edit label red border");
        this.editLabelError = false;
        this.editInvalidLabelMessageSlot = false;
      }
    },
    onEditLabelInputCompleted() {
      // if already has error, just bail out and no change
      if (this.editLabelError) {
        this.editLabelError = false;
        this.showEditInvalidLabelIcon = false;
        this.invalidLabelMessage = "";
        this.editLabelRowIndex = -1;
        this.editLabelRowData = {};
        this.editLabelValue = "";
      } else if (this.editLabelValue) {
        // validate the label
        if (this.validateLabel(this.editLabelValue, this.editLabelRowData.name)) {
          const oldLabelData = {...this.editLabelRowData};
          // change label in the store
          this.$store.commit("changeLabel", {oldLabelName: this.editLabelRowData.name, newLabelName: this.editLabelValue});
          // notify table view to update label name
          eventBus.$emit("TableView.editLabelName", {oldLabelData: oldLabelData, newLabelName: this.editLabelValue});
          // notify edit label panel to reset labels
          eventBus.$emit("EditLabelsPanel.resetLabels", {oldLabelName: oldLabelData.name, newLabelName: this.editLabelValue});
          // update applied label filters
          apis.updateAppliedLabelFilter(oldLabelData.name, this.editLabelValue);
          // notify filter panel
          eventBus.$emit("FilterPanel.updatePanel", {filtersToBeUpdated: [constants.filterTypeLabel]});

          // mark changes
          this.hasChanges = true;

          // clear up edit label related fields
          this.editLabelRowIndex = -1;
          this.editLabelRowData = {};
          this.editLabelValue = "";
        } else {
          // duplicate edited label
          // this.invalidLabelMessage = this.$t("manage-labels-duplicate-label-error");
          this.editLabelError = true;
        }
      } else {
        // empty edit label, restoring to the original label
        this.editLabelRowIndex = -1;
        this.editLabelRowData = {};
        this.editLabelValue = "";
      }
    },
    onEditLabelInputBlur() {
      this.onEditLabelInputCompleted();
    },
    onTableRowHover(rowIndex) {
      if (this.$refs.editIcon && this.$refs.deleteIcon) {
        //this.$refs.editIcon[rowIndex].$children[0].$el.style.visibility = "visible";
        //this.$refs.deleteIcon[rowIndex].$children[0].$el.style.visibility = "visible";
        this.$refs.editIcon[rowIndex].$el.style.visibility = "visible";
        this.$refs.deleteIcon[rowIndex].$el.style.visibility = "visible";
      }
    },
    onTableRowBlur(rowIndex) {
      if (this.$refs.editIcon && this.$refs.deleteIcon) {
        //this.$refs.editIcon[rowIndex].$children[0].$el.style.visibility = "hidden";
        //this.$refs.deleteIcon[rowIndex].$children[0].$el.style.visibility = "hidden";
        this.$refs.editIcon[rowIndex].$el.style.visibility = "hidden";
        this.$refs.deleteIcon[rowIndex].$el.style.visibility = "hidden";
      }
    },
    onPagination(paginationInfo) {
      this.pagination = paginationInfo;
      this.tablePagination.page = paginationInfo.page;

      this.$nextTick(function() {
        this.setTableBottomBorder();
      });
    },
    onModalDeleteLabel() {
      // delete label in the store
      this.$store.commit("deleteLabel", this.deleteRowData.name);
      // notify edit label panel to reset labels
      eventBus.$emit("EditLabelsPanel.resetLabels");
      // notify table view to update the labels in the row
      if (this.deleteRowData.assignedClasses.length > 0) {
        eventBus.$emit("TableView.deleteLabel", this.deleteRowData);
        this.hasChanges = true;
      }
      // update applied label filters
      apis.updateAppliedLabelFilter(this.deleteRowData.name, null);
      // show filter indicator
      eventBus.$emit("Toolbar.updateFilterIndicator");
      // notify filter panel
      eventBus.$emit("FilterPanel.updatePanel", {filtersToBeUpdated: [constants.filterTypeLabel]});

      // hide the dialog
      this.showDeleteDialog = false;
    },
    setTableBottomBorder() {
      // determine whether to have border-bottom on the last row of the table to avoid double border with pagination top border
      const theadElementHeight = document.querySelector('.manage-labels-table-data .bx--data-table thead')?.getBoundingClientRect().height;
      const tbodyElement = document.querySelector('.manage-labels-table-data .bx--data-table tbody');
      const tbodyHeight = tbodyElement?.getBoundingClientRect().height;
      const tableHeight = document.querySelector('.manage-labels-table-data .bx--data-table-container')?.getBoundingClientRect().height;
      // console.log("table height is " + tableHeight + "; tbody height is " + tbodyHeight);
      // add a class to set border-bottom of last row of the table to 0 if table total height (head + body) needs scrolling
      if (tbodyHeight + theadElementHeight >= tableHeight) {
        tbodyElement.className = "overflow-table-body";
      } else {
        tbodyElement.className = "";
      }
    },
    displayDeleteDialog(showDialog) {
      this.showDeleteDialog = showDialog;

      if (!showDialog) {
        // clear up delete related fields
        this.deleteRowIndex = -1;
        this.deleteRowData = {};
      }
    },
    setPanelFocus() {
      this.$refs.manageLabelsInput?.focus();
    },
    validateLabel(inputedLabel, originalLabel) {
      // If the label is not valid, this method will set the error message in invalidLabelMessage which
      // is used to display the error
      let validLabel = true;
      if (inputedLabel !== originalLabel) {
        // treat empty value as valid and to reset it back to the original label value
        if (inputedLabel) {
          let matchedLabel = this.labelsInTable.filter(label => {
            return label.name === inputedLabel;
          });
          if (matchedLabel.length > 0) {
            validLabel = false;
            // duplicate edited label
            this.invalidLabelMessage = this.$t("manage-labels-duplicate-label-error");
          }
        }

        if (validLabel) {
          // check for Unobserved label
          // Note: may need to do localeCompare here
          if (inputedLabel.trim().toLowerCase() === this.$t("table-unobserved-label-tag").toLowerCase()) {
            validLabel = false;
            this.invalidLabelMessage = this.$t("manage-labels-unobserved-not-allowed");
          }
        }

        if (validLabel && inputedLabel.trim().toLowerCase() === this.$t("table-utility-label-tag").toLowerCase()) {
            validLabel = false;
            this.invalidLabelMessage = this.$t("manage-labels-utility-not-allowed");
        }
      }

      return validLabel;
    },
    resetTableToFirstPage() {
      if (this.pagination.page != 1) {
        this.tablePagination.page = 1;
        this.tablePagination.numberOfItems = this.labelsInTable.length;
        this.pagination.page = 1;
        this.pagination.start = 1;
      }
    },
    // adjustTableHeight() {
    //   // adjust the table height
    //   const containerElement = document.getElementsByClassName("manage-labels-container");
    //   const headingElement = document.getElementsByClassName("manage-labels-heading");
    //   const contentTopElement = document.getElementsByClassName("manage-labels-content-top");
    //   const containerElementHeight = `calc(${Math.ceil(
    //     containerElement[0].getBoundingClientRect().height
    //   )}px)`;
    //   const headingElementHeight = `calc(${Math.ceil(
    //     headingElement[0].getBoundingClientRect().height
    //   )}px)`;
    //   const contentTopElementHeight = `calc(${Math.ceil(
    //     contentTopElement[0].getBoundingClientRect().height
    //   )}px)`;
    //   const labelsTableElement = document.querySelector(".manage-labels-table-data .bx--data-table-container");
    //   if (labelsTableElement) {
    //     labelsTableElement.style.height = `calc(${containerElementHeight} - ${headingElementHeight} - ${contentTopElementHeight} - 3.75rem)`; // account for border width
    //     labelsTableElement.style.maxHeight = "27rem";
    //   }
    // }
  },
  mounted() {
    this.tablePagination.numberOfItems = this.labelsInTable.length;

    eventBus.$on("ManageLabelsPanel.showPanel", (showPanel) => {
      this.showManageLabelsPanel = showPanel;
      // reset fields
      this.showDeleteDialog = false;
      this.labelValue = "";
      this.disabledCreate = true;
      this.createInvalidLabelMessageSlot = false;
      this.editInvalidLabelMessageSlot = false;
      this.invalidLabelMessage = "";
      this.editLabelRowIndex = -1;
      this.editLabelRowData = {};
      this.editLabelError = false;
      this.editLabelValue = "";
      this.deleteRowIndex = -1;
      this.deleteRowData= {};
      this.hasChanges = false;
      this.initialRender = true;
      if (showPanel) {
        this.$refs.createLabelContainer.style.alignItems = "flex-end";
        this.resetTableToFirstPage();
        if (this.labelsInTable.length === 0) {
          this.labelsInTable = this.$store.getters.getLabels;
        }

        this.$nextTick(function () {
          // use one scroll bar for the entire panel, no need to adjust table height anymore.
          // this.adjustTableHeight();

          setTimeout(this.setPanelFocus, 100);
        });
      }
    }),
    eventBus.$on("ManageLabelsPanel.setPanelHeight", (heights) => {
      // This event is called
      // - during normal path to setup the various panel heights
      // - when table batch toolbar appears/disappears
      // - when page header is collapsed/expanded thru the toolbar
      // - when browser height is changed

      let toolbarHeight = "0.0625rem";
      // Since manage labels panel could be laumched thru the edit labels panel in graph view, has to
      // check whether table is hidden or not to calculate the correct height and top anchor position.
      // Note: the batch action toolbar may still be there even when table view is not displayed.
      const isTableHidden = document.querySelector(".table-view-content")?.getAttribute("aria-hidden");
      const toolbarElement = document.querySelector('.table-view-content .table-graph-data .bx--table-toolbar');
      if ((isTableHidden !== "true") && toolbarElement && (toolbarElement.style.display === "block")) {
        toolbarHeight = "3rem + 0.0625rem"
      }
      if (this.$refs.manageLabelsContainer) {
        if (heights.topHeight) {
          //this.$refs.manageLabelsContainer.style.top = `calc(${heights.topHeight} + 0.0625rem)`;
          this.$refs.manageLabelsContainer.style.top = `calc(${heights.topHeight} + ${toolbarHeight})`;
        }
        if (heights.panelHeight) {
          //this.$refs.manageLabelsContainer.style.height = `calc(${heights.panelHeight} - 0.0625rem)`;
          this.$refs.manageLabelsContainer.style.height = `calc(${heights.panelHeight} - ${toolbarHeight})`;
          this.$refs.manageLabelsContainer.style.maxHeight = `calc(${heights.panelHeight} - ${toolbarHeight})`;
        }
      }
      if (this.$refs.deleteLabelDialog) {
        if (heights.topHeight) {
          //this.$refs.deleteLabelDialog.$el.style.top = `calc(${heights.topHeight} + 0.0625rem)`;
          this.$refs.deleteLabelDialog.$el.style.top = `calc(${heights.topHeight} + ${toolbarHeight})`;
        }
        if (heights.panelHeight) {
          //this.$refs.deleteLabelDialog.$el.style.height = `calc(${heights.panelHeight} - 0.0625rem)`;
          this.$refs.deleteLabelDialog.$el.style.height = `calc(${heights.panelHeight} - ${toolbarHeight})`;
        }
      }

      // use one scroll bar for the entire panel, no need to adjust table height anymore.
      // this.adjustTableHeight();
    })
  },
  updated() {
    // console.log("in updated", this.labelsInTable);
    // update field only when necessary
    if (this.tablePagination.numberOfItems !== this.labelsInTable.length) {
      this.tablePagination.numberOfItems = this.labelsInTable.length;
    }
    // if there is a delete and the delete label is the only row on the last page, 
    // has to adjust the pagination object to show the previous page
    if (this.pagination.start > this.tablePagination.numberOfItems) {
      this.pagination.page--;
      this.pagination.start = (this.pagination.page - 1) * this.pagination.length + 1;
      // console.log("in update with pagination start is " + this.pagination.start + " with page number " + this.pagination.page);
    }
    // has to make the call here instead of in mounted lifecycle which still has 0 height for the table
    this.$nextTick(function() {
      // do this in the initial render of the table only, otherwise pagination will take care of it
      if (this.initialRender) {
        this.initialRender = false;
        this.setTableBottomBorder();
      }
    })
  },
  beforeDestroy() {
    eventBus.$off("ManageLabelsPanel.showPanel");
    eventBus.$off("ManageLabelsPanel.setPanelHeight");
  }
}
</script>

<style lang="scss" scoped>
.manage-labels-container {
  position: fixed;
  right: 0;
  width: 30rem;
  z-index: 2;
  background: var(--app-managed-labels-background-color);
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.30);
  overflow-y: auto;

  .manage-labels-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 0.5rem 1rem 1rem;

    h4 {
      padding-top: 0.5rem;
      align-self: flex-start;
    }

    .manage-labels-close {
      &.bx--btn--ghost {
        color: var(--app-managed-labels-close-button-color);
        min-height: 2rem;;
        min-width: 2rem;
        padding: 0;
        margin-top: -0.125rem; // to achieve 0.5rem top padding per design
      }
    }
  }

  .manage-labels-content-top {
    padding: 0 1rem;

    .manage-labels-description {
      padding-bottom: 1.5rem;
      padding-right: 4rem;
    }

    .manage-labels-label {
      display: flex;
      align-items: flex-end;
      justify-content: space-around;
      padding-bottom: 1rem;
    }
  }

  .manage-labels-table-data {
    padding: 0 1rem 1rem 1rem;

    .manage-labels-right-align-column {
      text-align: right;
    }

    tr {
      outline: none;
    }

    // when placing the class in button
    .manage-labels-table-icon-data-column {
      visibility: hidden;
      // width: 1rem;
      padding-right: 0;
      padding-left: 0;
      padding-top: 0.25rem;

      padding: 0.5rem;
    }

    .bx--data-table-container {
      td {
        border-top: 0;
      }

      td:nth-child(3) {
        padding-left: 0.5rem;
        padding-right: 0rem;
      }

      td:last-of-type {
        // when class is put in the button
        //padding-left: 0.25rem;
        // when class is put in td
        padding-left: 0;
        padding-right: 0.5rem;
      }
    }
  }
}

.delete-label-dialog {
  width: 30rem;
  right: 0;
  left: unset;
}
</style>

<style lang="scss">
.manage-labels-container {
  .manage-labels-close {
    &.bx--btn--ghost .bx--btn__icon {
      width: 1.25rem;
      height: 1.25rem;
      margin-left: 0.25rem;
    }
  }

  .manage-labels-label {
    .bx--text-input__field-wrapper {
      padding-right: 1rem;

      .bx--text-input {
        height: 3rem;
      }

      svg {
        right: 2rem; 
      }

      .bx--text-input--invalid:focus {
        outline-color: var(--app-managed-labels-table-label-invalid);
      }
    }
  }

  .manage-labels-table-data .bx--data-table {
    th:nth-child(3) {
      padding-right: 0.5rem;
    }

    th:last-of-type {
      padding-left: 0.25rem;
    }

    tr:focus td button {
      visibility: visible !important;
    }
  }

  .bx--data-table-container {
    height: 27rem;
    background-color: var(--app-managed-labels-table-background-color);
    padding-top: 0;

    .bx--data-table-content {
      height: 100%;

      .bx--data-table {
        thead {
          position: sticky;
          z-index: 1;
          top: 0;
          width: 100%;
          will-change: transform;

          .manage-labels-table-name-column {
            padding-right: 0;

            span {
            display: block;
            width: 14.25rem;
            }
          }

          .manage-labels-table-time-used-column span {
            display: block;
            width: 5.75rem;
          }

          .manage-labels-table-icon-column span {
            display: block;
            width: 1rem;
          }

          .manage-labels-table-delete-icon-column span {
            padding-left: 0;
          }
        }

        tbody {
          // tr:last-of-type td {
          //   border-bottom: 0;
          // }

          td:first-child {
            // give edit label more space per design
            padding-right: 0;
          }

          &.overflow-table-body tr:last-of-type {
            td {
              border-bottom: 0;
            }
          }

          .manage-lables-edit-label-text-input {
            padding-left: 0;

            label {
              margin-bottom: 0;
            }

            .bx--text-input {
              height: 3rem;
              //width: 13.5rem;
              padding-right: 0;
              background-color: var(--app-managed-labels-text-input-background-color);
              border: 1px solid var(--app-managed-labels-text-input-border);
            }
          }

          .manage-labels-table-icon-data-column {
            &:hover, &:focus {
              background-color: var(--app-managed-labels-table-icon-hover);
            }
          }

          td:nth-child(3) {
            padding-left: 0.5rem;
            padding-right: 0rem;
          }

          .edit-label-input--invalid {
            .bx--text-input {
              outline-color: var(--app-managed-labels-table-label-invalid);
            }

            .bx--form-requirement {
              display: none;
            }
          }

          .edit-label-input--invalid-tooltip-icon {
            // repositioning without the space left by relative positioning
            float: left;
            position: relative;
            margin-top: -2rem;
            margin-left: 13.5rem;
            margin-bottom: -2rem;

            svg {
              fill: var(--app-managed-labels-table-label-invalid);
            }
          }
        }
      }
    }
  }

  .manage-labels-table-data .bx--pagination {
    height: 3rem;
    justify-content: flex-end;
    // position: sticky;
    // bottom: 0;
    border-top: 1px solid var(--app-table-pagination-top-border-color);

    .bx--pagination__left {
      display: none;
    }
  }
}

.delete-label-dialog {
  .bx--modal-container--xs {
    width: 93%;
    height: 38%;
  }
}
</style>