/*
# *****************************************************************
#
# Licensed Materials - Property of IBM
#
# (C) Copyright IBM Corp. 2019, 2020, 2021, 2022, 2023. All Rights Reserved.
#
# US Government Users Restricted Rights - Use, duplication or
# disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
#
# *****************************************************************
*/
import Vue from "vue";
import Vuex from "vuex";
import utils from "./lib/graph/graphUtil";
import constants from "./lib/constants";

Vue.use(Vuex);

const _colorInfo = require("./assets/color/cy_color.json");
const _groupId = "group_label";

const store = new Vuex.Store({
  state: {
    //unobservedData: {},// Store unobserved nodes/links.  Key is the view name.
    json: {}, // JSON object loaded from the selected file
    source: {}, // JSON object for selcted "view"
    key: "", // key of the JSON object for selected "view"
    elements: {}, // elements for Cytoscape
    // {
    //    partition_name: {
    //      classNumber: # of classes within this partition
    //      symbolSize: size of partition symbol
    //    }
    // }
    apps: {},
    filename: "",
    demoMode: false,
    cyInstanceMap: {},
    cyExtensionMap: {},

    // nodes: null,
    groups: null,
    options: {},
    clusters: [],
    // the dependency edges added by single click.  
    // Should be removed from graph when the view is saved, or before single click
    dynamicDependencyEdges: {},
    edgeFilterClasses: {},


    // the dependency edges added by cickking the show-dependencies toggle button.  
    // Should be removed from graph when the view is saved
    dynamicDependencyEdgesForEntireCY: {},
    /*
     * See getPartitionStats from stats.js for data scructure
     */
    partitionDataNaturalSeam: {},
    partitionDataBusinessLogic: {},
    partitionDataCustomView: {},
    /*
     * We will many use cases stats.  For each view, we have the overview one (all partitions), and individual
     * partitions.  The useCase object will be created as following:
     * {
     *  viewName: {
     *    overview: {},
     *    partition_name_1: {},
     *    partition_name_2: {}
     *  }
     * }
     */
    useCases: {},
    theme: 'dark',
    searchItems: [],
    labels: [],
    numberOfUtilityClasses: 0,
    numberOfUtilityPartitions: 0,
  },
  mutations: {
    setSeries(state, json) {
      if (state.cyInstanceMap) {
        Object.keys(state.cyInstanceMap).forEach((k) => {
          state.cyInstanceMap[k].unmount();
        });
      }
      state.json = json;
      state.cyInstanceMap = {};
      state.cyExtensionMap = {};
      state.dynamicDependencyEdges = {};  // reset dependency info after loading a new JSON file
      state.dynamicDependencyEdgesForEntireCY = {}; // reset dependency info after loading a new JSON file
      state.labels = [];
      state.numberOfUtilityClasses = 0;
      state.numberOfUtilityPartitions = 0;
      state.edgeFilterClasses = {};
//      console.log(state.json);
    },
    setOptions(state, options) {
      state.options = options;
      console.log(state.options);
    },
    setKey(state, params) {
      state.key = params.key;
      if (
        !Object.prototype.hasOwnProperty.call(state.json, state.key) ||
        params.resetSelectedView
      ) {
        // clone view and set the data back to JSON
        // the seeding view may not be loaded yet.  If it is loaded, get the cy instance, unconvert the data and feed to the new view.
        // If the seeding view is not loaded, then just copy the json over.
        var data = {};
        var seedingView = params.seedingView;
        if (
          Object.prototype.hasOwnProperty.call(state.cyInstanceMap, seedingView)
        ) {
          const cy = state.cyInstanceMap[seedingView];
          data = utils.unconvertData(
            seedingView,
            cy,
            state.json[seedingView][constants.overviewId][constants.linkId],
            false
          );
          data = JSON.parse(JSON.stringify(data));

          // data.nodes.forEach(function(item, index, object) {
          //   if (item.unobserved === true) {
          //     object.splice(index, 1);
          //   }
          // });

          // if ( constants.keepUnobserved === false ) {
          //   // copy the unobserved data over
          //   const originalData = state.json[seedingView];
          //   const splitedData = utils.clone( utils.splitUnobservedSource(originalData) );
          //   var unobserved = splitedData.unobserved;
          //   data[constants.nodeId] = data[constants.nodeId].concat(unobserved[constants.nodeId]);
          //   data[constants.linkId] = data[constants.linkId].concat(unobserved[constants.linkId]);
          //   data[constants.overviewId][constants.nodeId] = data[constants.overviewId][constants.nodeId].concat(unobserved[constants.overviewId][constants.nodeId]);
          //   data[constants.overviewId][constants.linkId] = data[constants.overviewId][constants.linkId].concat(unobserved[constants.overviewId][constants.linkId]);
          // }
          /*if ( constants.keepUnobserved )
          {
            var unobserved = JSON.parse(JSON.stringify(state.unobservedData[seedingView]));
            data[constants.nodeId] = data[constants.nodeId].concat(unobserved[constants.nodeId]);
            data[constants.linkId] = data[constants.linkId].concat(unobserved[constants.linkId]);
            data[constants.overviewId][constants.nodeId] = data[constants.overviewId][constants.nodeId].concat(unobserved[constants.overviewId][constants.nodeId]);
            data[constants.overviewId][constants.linkId] = data[constants.overviewId][constants.linkId].concat(unobserved[constants.overviewId][constants.linkId]);
          }
          */
        } else {
          data = JSON.parse(JSON.stringify(state.json[seedingView]));
        }
        state.json[state.key] = data;
      }
      initialize(state.key);
    },
    setClusters(state, clusters) {
      state.clusters = clusters;
    },
    setCluster(state, cluster) {
      var index = cluster.position;
      state.clusters[index] = cluster;

      if (index == 0) {
        var i;
        if (state.clusters[index].isHidden) {
          for (i = 1; i < state.clusters.length; i++) {
            state.clusters[i].isHidden = true;
          }
        } else {
          for (i = 1; i < state.clusters.length; i++) {
            state.clusters[i].isHidden = false;
          }
        }

        if (state.clusters[index].isExpanded) {
          for (i = 1; i < state.clusters.length; i++) {
            state.clusters[i].isExpanded = true;
          }
        } else {
          for (i = 1; i < state.clusters.length; i++) {
            state.clusters[i].isExpanded = false;
          }
        }
      }
    },
    addSearchItem(state, key) {
      const index = state.searchItems.findIndex(item => key.toLowerCase() === item.toLowerCase());
      if (index >= 0) {
        state.searchItems.splice(index, 1);
      }
      state.searchItems.unshift(key);
    },
    clearSearchItem(state) {
      state.searchItems = [];
    },
    addLabel(state, labelName) {
      const newLabel = {
        name: labelName,
        assignedClasses: []
      }
      state.labels.unshift(newLabel);
    },
    changeLabel(state, changeLabels) {
      const index = state.labels.findIndex(item => changeLabels.oldLabelName.toLowerCase() === item.name.toLowerCase());
      if (index >= 0) {
        state.labels[index].name = changeLabels.newLabelName;
      }
    },
    addRemoveClassesInLabel(state, params) {
      if (params.labels && params.classNames?.length > 0) {
        Object.keys(params.labels).forEach((label) => {
          const index = state.labels.findIndex(item => label.toLowerCase() === item.name.toLowerCase());
          if (index >= 0) {
            if (params.labels[label]) {
              // add classes to label
              const combinedClasses = state.labels[index].assignedClasses.concat(params.classNames);
              // remove duplicate classes in the combined classes array
              state.labels[index].assignedClasses = combinedClasses.filter((className, index) => {
                return combinedClasses.indexOf(className) === index;
              });
            } else {
              // remove classes from label
              params.classNames.forEach((className) => {
                const classIndex = state.labels[index].assignedClasses.findIndex(item => className === item);
                if (classIndex > -1) {
                  state.labels[index].assignedClasses.splice(classIndex, 1);
                }
              });
            }
          }
        })
      }
    },
    deleteLabel(state, labelName) {
      const index = state.labels.findIndex(item => labelName.toLowerCase() === item.name.toLowerCase());
      if (index >= 0) {
        state.labels.splice(index, 1);
      }
    },
    setApps(state, apps) {
      state.apps = apps;
    },
    setFilename(state, filename) {
      state.filename = filename;
    },
    setDemoMode(state) {
      state.demoMode = true;
    },
    setPartitionDataNaturalSeam(state, data) {
      state.partitionDataNaturalSeam.data = data.data;
    },
    setPartitionDataBusinessLogic(state, data) {
      state.partitionDataBusinessLogic.data = data.data;
    },
    setPartitionDataCustomView(state, data) {
      state.partitionDataCustomView.data = data.data;
    },
    setUseCases(state, data) {
      if (
        false ==
        Object.prototype.hasOwnProperty.call(state.useCases, data.viewName)
      ) {
        state.useCases[data.viewName] = {};
      }
      if (
        false ==
        Object.prototype.hasOwnProperty.call(
          state.useCases[data.viewName],
          data.partition
        )
      ) {
        state.useCases[data.viewName][data.partition] = {};
      }
      state.useCases[data.viewName][data.partition] = data.data;
    },
    /*setUnobservedData(state, data) {
      state.unobservedData[state.key] = data;
    },*/
    // This is for singl click show dependency
    setDynamicDependencyEdges(state, data) {
      state.dynamicDependencyEdges[state.key] = data;
    },
    // This is for the show dependencies button
    setDynamicDependencyEdgesForEntireCY(state, data) {
      state.dynamicDependencyEdgesForEntireCY[state.key] = data;
    },
    setEdgeFilterClasses(state, data) {
      state.edgeFilterClasses[state.key] = data;
    },

    /**
     * Sets the labels for the unobserved nodes.
     * {
     *    nodeId1: [ label1, label2 ],
     *    nodeId2: [ label3, lanel2 ]
     * }
     * @param {} obj 
     */
    setUnobservedNodesLabels(state, obj) {
      const keys = Object.keys(obj);
      const source = state.json[state.key];
      source.nodes.forEach((node) => {
        if (keys.includes(node["name"])) {
          node.label = obj[node["name"]];
        }
      });
    },
    setThemeInfo(state, isDark) {
      if (isDark === true) {
        state.theme = 'dark';
      } else {
        state.theme = 'light';
      }
    }
  },
  getters: {
    getLabels: (state) => {
      return state.labels;
    },
    getSearchItems: (state) => {
      return state.searchItems;
    },
    getUnobservedData: (state) => () => {
      const source = utils.splitUnobservedSource(state.json[state.key]).unobserved;
      // source is already a cloned copy, but we need to remove the ones already included in graph
      const unobservedNodes = utils.getUnobservedNodes(state.cyInstanceMap[state.key]);

      var inGraphNodes = [];
      unobservedNodes.forEach(function (element) {
        inGraphNodes.push(element.name);
      });

      var unobserved = {};
      unobserved[constants.nodeId] = [];
      unobserved[constants.linkId] = [];
      unobserved[constants.overviewId] = source[constants.overviewId];

      source[constants.nodeId].forEach(function (element) {
        if (inGraphNodes.includes(element.name) === false) {
          unobserved[constants.nodeId].push(element);
        }
      });

      source[constants.linkId].forEach(function (element) {
        if (inGraphNodes.includes(element.source) === false && inGraphNodes.includes(element.target) === false) {
          unobserved[constants.linkId].push(element);
        }
      });

      // sort unobserved nodes
      unobserved[constants.nodeId].sort(function (a, b) {
        return ("" + a.name).localeCompare("" + b.name);
      });


      return unobserved;
    },
    getDynamicDependencyEdges: (state) => {
      return state.dynamicDependencyEdges;
    },
    getEdgeFilterClasses: (state) => {
      return state.edgeFilterClasses;
    },

    getDynamicDependencyEdgesForEntireCY: (state) => {
      return state.dynamicDependencyEdgesForEntireCY;
    },
    getOptions: (state) => {
      return state.options;
    },
    getJson: (state) => {
      return state.json;
    },
    getDemoMode: (state) => {
      return state.demoMode;
    },
    getSource: (state) => {
      return state.source;
    },
    getGroups: (state) => {
      return state.groups;
    },
    getKey: (state) => {
      return state.key;
    },
    getClusters: (state) => {
      return state.clusters;
    },
    getApps: (state) => {
      return state.apps;
    },
    getFilename: (state) => {
      return state.filename;
    },

    // cytoscape data
    getElements: (state) => {
      return state.elements;
    },
    getLinks: (state) => {
      return state.source[constants.linkId];
    },
    getNodes: (state) => {
      return state.source[constants.nodeId];
    },
    getOverview: (state) => {
      return state.source[constants.overviewId];
    },
    // getIsolatedClasses: (state) => {
    //   return state.isolatedClasses;
    // },
    getCYInstanceMap: (state) => {
      return state.cyInstanceMap;
    },
    getCYExtensionMap: (state) => {
      return state.cyExtensionMap;
    },
    getCYInstance: (state) => {
      return function () {
        return state.cyInstanceMap[state.key];
      };
    },
    getPartitionDataNaturalSeam: (state) => {
      return state.partitionDataNaturalSeam;
    },
    getPartitionDataBusinessLogic: (state) => {
      return state.partitionDataBusinessLogic;
    },
    getPartitionDataCustomView: (state) => {
      return state.partitionDataCustomView;
    },
    getUseCases: (state) => {
      return state.useCases;
    },
    getPartitionSymbolSize: () => (classNumber) => {
      return getPartitionSymbolSize(classNumber);
    },
    getColorInfo: (state) => {
      return _colorInfo[state.theme];
    },
    getUOColor: (state) => {
      return _colorInfo[state.theme].unobserved;
    },
    getNumberOfUtilityClasses: (state) => {
      return state.numberOfUtilityClasses;
    },
    getNumberOfUtilityPartitions: (state) => {
      return state.numberOfUtilityPartitions;
    },   
  },
});


function initialize(key) {
  // has the key, need to process data
  store.state.source = store.state.json[key];
  store.state.numberOfUtilityClasses = 0;
  store.state.numberOfUtilityPartitions = 0;
  /*
  var splitedSource = utils.splitUnobservedSource(store.state.json[key]);
  store.state.source = {};
  store.state.source[constants.nodeId] = splitedSource.nodes;
  store.state.source[constants.linkId] = splitedSource.links;
  store.state.source[constants.overviewId] = splitedSource.overview;
  store.state.unobservedData[key] = splitedSource.unobserved;
  
  */
  // store.state.nodes = store.state.source[_nodeId];
  store.state.groups = new Set();
  var partitions = {};

  var nodes = store.state.source[constants.nodeId].concat();  // make a clone
  nodes.forEach(function (node) {
    node.category = node.category || node[_groupId];

    if (utils.isUnobservedCategory(node.category) === true || 
        (node[constants.originalPartitionId] && utils.isUnobservedCategory(node[constants.originalPartitionId]) === true)) {
      node.unobserved = true;
    } else {
      node.unobserved = false;
    }

    const info = partitions[node.category] || {};
    partitions[node.category] = info;
    info.classNumber = (info.classNumber || 0) + 1;
    info.symbolSize = 0;
    store.state.groups.add(node[_groupId]);

    if ((node.tags && utils.hasUtilityTag(node.tags) === true) || node[constants.originalPartitionId]) {
      node.isUtilityClass = true;
      store.state.numberOfUtilityClasses ++;
      // init. 'original' partition property
      node[constants.originalPartitionId] = node[constants.originalPartitionId] || node.category;
    } else {
      delete node.isUtilityClass;
    }
  });

  // calculate symbol size for partitions
  const pKeys = Object.keys(partitions);
  pKeys.forEach(function (item) {
    partitions[item].symbolSize = getPartitionSymbolSize(
      partitions[item].classNumber
    );
  });
  // convert to Cytoscape elements (partition nodes)
  if (store.state.source[constants.overviewId]) {
    let colorIndex = 0;
    let isUtilityPartition = false;
    store.state.source[constants.overviewId][constants.nodeId].forEach(
      (node) => {
        // fix the old data
        if (!node.filepath || node.filepath !== constants.clusterIdentifier) {
          node.filepath = constants.clusterIdentifier;
        }
        node.category = node.category || node[_groupId];
        node.name = node.name || node.category;
        if (node.id && node.id !== node.name) {
          node.id = node.name;
        }
        isUtilityPartition = (node.tags && utils.hasUtilityTag(node.tags) === true);
        // assign node color - it is the partition color.
        if (!node.ui_partitionColor) {
          if (utils.isUnobservedCategory(node.category) !== true && isUtilityPartition !== true) {
            if (colorIndex >= _colorInfo[store.state.theme].partition_colors.length) {
              colorIndex = 0;
            }
            node.ui_partitionColor = _colorInfo[store.state.theme].partition_colors[colorIndex++];
          } else {  // unobserved partition has its own color
            node.ui_partitionColor = _colorInfo[store.state.theme].unobserved;
          }
        }
        // case that the partition only defined in "overview", it has no class
        if (!partitions[node.category]) {
          partitions[node.category] = {
            classNumber: 0,
            symbolSize: constants.defaultPartitionSize,
          };
        }
        node.ui_classNumber = partitions[node.category].classNumber;
        node.ui_symbolSize = partitions[node.category].symbolSize;
        if (node.ui_classNumber > 0) {
          if (isUtilityPartition === true) {
            store.state.numberOfUtilityPartitions ++;
          }
          nodes.push(node);
        }
      }
    );
    // nodes = nodes.concat(
    //   store.state.source[constants.overviewId][constants.nodeId]
    // );
  }
  if (constants.keepUnobserved) {
    store.state.elements = utils.convertData(
      nodes,
      store.state.source[constants.linkId]
    );
  } else {
    var nodesWithoutUnobservedNamesOnly = [];
    var nodesWithoutUnobserved = nodes.filter((node) => {
      if (utils.isUnobservedCategory(node.category) !== true) {
        nodesWithoutUnobservedNamesOnly.push(node.name);
        return true;
      }
    });

    var linksWithoutUnobserved = store.state.source[constants.linkId].filter((edge) => {
      if (nodesWithoutUnobservedNamesOnly.indexOf(edge.source) > -1
        && nodesWithoutUnobservedNamesOnly.indexOf(edge.target) > -1) {
        return true;
      }
    });
    store.state.elements = utils.convertData(
      nodesWithoutUnobserved,
      linksWithoutUnobserved
    );
  }

  // initialize labels if existed in json
  if (store.state.labels.length === 0) {
    if (store.state.json["labels"]) {
      store.state.labels = store.state.json["labels"];
    } else {
      // migration path to scan labels in all views
    }
  }

  // store.state.isolatedClasses = utils.getIsolatedClasses(
  //   nodes,
  //   store.state.source[constants.linkId]
  // );
}

function getPartitionSymbolSize(partitionClassNumber) {
  const totalClassNumber = store.state.source[constants.nodeId].length;
  const ratio = partitionClassNumber / totalClassNumber;
  // range 1: classNumber / totalClassNumber < 10%
  if (ratio < 0.1) {
    return constants.defaultPartitionSize;
  } else if (ratio < 0.33) {
    // 11% - 33%
    return constants.defaultPartitionSize * 1.38;
  } else if (ratio < 0.5) {
    // 34% - 50%
    return constants.defaultPartitionSize * 1.88;
  } else {
    // > 50%
    return constants.defaultPartitionSize * 2.5;
  }
}

export default store;