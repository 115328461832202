/********************************************************************
# Licensed Materials - Property of IBM
#
# (C) Copyright IBM Corp. 2020, 2021, 2022. All Rights Reserved.
#
# US Government Users Restricted Rights - Use, duplication or
# disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
**********************************************************************/

import utils from './graphUtil';
import ecUtils from './expandCollapseUtil';
import constants from '../constants';

const partitionHandler = {
  expandCollapsePartition(ele) {
    if (ele) {
      const expandCollapseExtension = ele.cy().expandCollapse('get');
      if (expandCollapseExtension.isCollapsible(ele) === true) {
        // expandCollapseExtension.collapse(ele);
        ecUtils.collapse(ele);
        this.collapseEdgesBetweenNodes(this.getConnectedCollapsedNodes(ele));
      } else {
        expandCollapseExtension.expandEdges(ele.connectedEdges());
        expandCollapseExtension.expand(ele);
      }
    }
  },

  collapsePartitions(partitions) {
    if (partitions.empty() !== true) {
      ecUtils.collapse(partitions);
      let nodes = partitions[0].cy().collection();
      partitions.forEach(partition => {
        nodes = nodes.add(this.getConnectedCollapsedNodes(partition));
      });
      this.collapseEdgesBetweenNodes(nodes);
      // this.collapseEdgesBetweenNodes(partitions);
//      ecUtils.collapse(partitions);
    }
  },

  collapseEdgesBetweenNodes(connectedNodes) {
    if (connectedNodes.length > 0) {
      const cy = connectedNodes[0].cy();
      const opts = {
        groupEdgesOfSameTypeOnCollapse: true,
        edgeTypeInfo: function()  {
          return (this && this.data("dep") === true) ? constants.edgeGroupDependency : constants.edgeGroupUnknown;
        },
      };      
      // const result = cy.expandCollapse('get').collapseEdgesBetweenNodes(connectedNodes);
      const result = ecUtils.collapseEdgesBetweenNodes(connectedNodes, opts);
      if (result && result.edges && result.edges.length > 0) {
        const filterStore = utils.getScratch(cy, constants.m2mScratchFilters);
        if (filterStore[constants.filterTypeRuntimeCall] && filterStore[constants.filterTypeRuntimeCall].length > 0) {
          utils.applyRuntimeCallFilters(result.edges, filterStore[constants.filterTypeRuntimeCall]);
        }
        result.edges.forEach(edge => {
          if (edge.data('edgeType') === constants.edgeGroupDependency && edge.data('dep') !== true) {
            edge.data('dep', true);
          }
        });
        utils.applyShowEdgeFilters(result.edges, filterStore[constants.filterTypeShowEdge] || utils.getDefaultShowEdgeFilter());
        // utils.applyShowDependencyFilter(result.edges);
      }
    }
  },

  expandAllPartitions(cy) {
    const expandCollapseExtension = cy.expandCollapse("get");
    expandCollapseExtension.expandEdgesBetweenNodes(cy.nodes("node.cy-expand-collapse-collapsed-node"));
    expandCollapseExtension.expandAll();
  },

  expandPartitions(partitions) {
    if (partitions && partitions.length > 0) {
      const expandCollapseExtension = partitions[0].cy().expandCollapse('get');
      expandCollapseExtension.expandEdges(partitions.connectedEdges());
      expandCollapseExtension.expand(partitions);
    }
  },

  getConnectedCollapsedNodes(node) {
    let neighbors = node.cy().collection();
    const connectedEdges = node.connectedEdges().filter(edge => {
      return (
        utils.isCollapsedNode(edge.source()) === true && 
        utils.isCollapsedNode(edge.target()) === true && 
        edge.hasClass('cy-expand-collapse-collapsed-edge') !== true
      );
    });
    neighbors = neighbors.add(connectedEdges.sources());
    neighbors = neighbors.add(connectedEdges.targets());
    return neighbors;
  },
}

export default partitionHandler;
