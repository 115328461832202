<!--
# *****************************************************************
#
# Licensed Materials - Property of IBM
#
# (C) Copyright IBM Corp. 2020, 2021, 2022. All Rights Reserved.
#
# US Government Users Restricted Rights - Use, duplication or
# disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
#
# *****************************************************************
-->
<template>
  <div class="dropdown-field">
    <cv-dropdown
      :placeholder="$t(dropdown.placeholder)"
      :value="dropdown.value"
      :label="$t(dropdown.label)"
      v-model="selectedOption"
      @change="onDropDownChange"
      ref="dropdownRef"
    >
      <cv-dropdown-item v-for="option in dropdown.options" :key="option" :value="option">
        <span class="dropdown-text" :title="option">{{ option }}</span>
        <checkmarkIcon :style="{visibility: isSelected(option) ? 'visible' : 'hidden' }" aria-label="Selected" />
      </cv-dropdown-item>
    </cv-dropdown>
  </div>
</template>

<script>
import { CvDropdown } from "@carbon/vue";
import info_icon from "@carbon/icons-vue/es/information/16";
import { eventBus } from "../../main.js";
import checkmarkIcon from "@carbon/icons-vue/es/checkmark/16.js"

export default {
  name: "DropDownField",
  components: { CvDropdown, checkmarkIcon },
  props: ["dropdown"],
  data: () => ({
    info_icon,
    selectedOption: ''
  }),
  mounted() {
    // fix dropdown arrow hight alignment
    const dropdownArrow = document.querySelector(".dropdown-field .bx--dropdown__arrow");
    if (dropdownArrow) {
      dropdownArrow.style.height = "10px";
    }
    if (this.dropdown.selectedOption) {
      this.selectedOption = this.dropdown.selectedOption;
    }

    eventBus.$on("DropDownField.clearSelected", () => {
      this.selectedOption = "";
    });
  },
  beforeDestroy() {
    eventBus.$off("DropDownField.clearSelected");
  },
  methods: {
    isSelected(key) {
      if (this.selectedOption === key) {
        return true;
      } else {
        return false;
      }
    },
    onDropDownChange(key) {
      // ignore onChange event generated when clearing out selection
      if (this.dropdown.onChangeEvent && key !== "") {
        eventBus.$emit(this.dropdown.onChangeEvent, key);
      }
    },
    onShowInformation() {
      if (this.dropdown.onShowInfo) {
        eventBus.$emit(this.dropdown.onShowInfo);
      }
    }
  }
};
</script>

<style>
.dropdown-field .bx--label {
  cursor: default;
}

.bx--dropdown-link {
  height: 34px;
}

/* .dropdown-field .bx--label .info_icon{
  color: #C6C6C6;
} */

.dropdown-field .bx--label .bx--btn--sm {
  background: transparent;
  margin: -8px;
}

.dropdown-field .bx--form-item {
  align-items: stretch;
  flex: 0;
}

.dropdown-field .bx--dropdown-link {
  /* border: none; */
  display: block;
}

.dropdown-field .bx--dropdown-link .dropdown-text {
  overflow: hidden;
  text-overflow: ellipsis;
}

.dropdown-field .bx--dropdown-link svg {
  fill: var(--app-carbon-icon-btn-color);
  float: right;
}

/* .dropdown-field .bx--dropdown--selected {
  background-color: #606060;
}

.dropdown-field .bx--dropdown-item:hover {
  background-color: #606060;
}

.dropdown-field svg {
  fill: #f4f4f4;
} */

.dropdown-field .bx--dropdown__wrapper {
  min-width: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

/* different css in the latest vue */
.dropdown-field .bx--dropdown-text__inner svg,
.dropdown-field .bx--list-box__label svg {
  visibility: hidden !important;
}

</style>