/********************************************************************
# Licensed Materials - Property of IBM
#
# (C) Copyright IBM Corp. 2020, 2021, 2022. All Rights Reserved.
#
# US Government Users Restricted Rights - Use, duplication or
# disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
**********************************************************************/

import store from "../../store";

const stats = {
  /**
   * Gets the use cases list for the overview panel or individual partition.
   *
   * @param {*} runtimeNodes For overview, pass in "mono_run_time_call_graph" json object.
   *                        Otherwise pass in natural seam or business logic data.
   * @param {*} partitionName For overview, pass null.  For individual partition, pass in partition name.
   */

  getUseCases(runtimeNodes, partitionName) {
    var useCases = {};
    runtimeNodes.nodes.forEach((node) => {
      var semantics = node.semantics;
      var checkPartitionResult = true;
      if (partitionName) {
        checkPartitionResult = node.category === partitionName;
      }
      if (node.construct.toLowerCase() === "class" && checkPartitionResult) {
        semantics.forEach((semantic) => {
          if (!(semantic in useCases)) {
            useCases[semantic] = 0;
          }
          useCases[semantic]++;
        });
      }
    });

    const ordered = {};
    Object.keys(useCases)
      .sort()
      .forEach(function(key) {
        ordered[key] = useCases[key];
      });
    console.log(JSON.stringify(ordered));
    store.commit("setUseCases", {
      data: ordered,
      viewName: store.getters.getKey,
      partition: "overview",
    });
  },

  /**
   * Gets the stats for a specified class.
   * The following stats will be returned:
   * Internal runtime call edges (internalRuntimeCallEdges)
   * Cross partition runtime calls (crossPartitionRuntimeCalls)
   * Internal data dependensy (internalDataDependencies)
   * Cross partition data dependencies (crossPartitionDataDependencies)
   * @param {*} cls The name of the class
   * @param {*} partition The partition name of the class
   * @param {*} source The object contains partition data
   * @param {*} dependencies The dependency object
   *
   */
  getClassStats(cls, partition, source, dependencies) {
    const links = source.links;
    const nodes = source.nodes;
    const depLinks = dependencies.links;
    var data = {
      internalPartitionRuntimeCalls: 0,
      crossPartitionRuntimeCalls: 0,
      internalPartitionDataDependencies: 0,
      crossPartitionDataDependencies: 0,
      crossPartitionUniqueCalls: 0,
      internalPartitionUniqueCalls: 0,
      internalCallsTo: 0,
      internalCallsFrom: 0,
      crossCallsTo: 0,
      crossCallsFrom: 0,
    };

    var nodeCatMap = {};

    nodes.forEach((node) => {
      nodeCatMap[node.name] = node.category;
    });

    links.forEach((link) => {
      const sourceCategory = nodeCatMap[link.source];
      const targetCategory = nodeCatMap[link.target];
      if (
        sourceCategory &&
        targetCategory &&
        //sourceCategory.toLowerCase() !== "unknown" &&
        //targetCategory.toLowerCase() !== "unknown" &&
        (link.source == cls || link.target == cls) &&
        (sourceCategory == partition || targetCategory == partition)
      ) {
        var calls = 0;
        if (link.method) {
          const uniqueCalls = Object.keys(link.method).length;
          calls = Object.values(link.method).reduce(function(a, b) {
            return a + b;
          }, 0);
          if (sourceCategory == targetCategory) {
            data.internalPartitionRuntimeCalls =
              calls + data.internalPartitionRuntimeCalls;
            data.internalPartitionUniqueCalls = uniqueCalls + data.internalPartitionUniqueCalls;

            if (link.target == cls )
            {
              data.internalCallsTo =  data.internalCallsTo + uniqueCalls;
            } else if (link.source == cls)
            {
              data.internalCallsFrom =  data.internalCallsFrom + uniqueCalls;
            }
          

          } else {
            data.crossPartitionRuntimeCalls =
              calls + data.crossPartitionRuntimeCalls;
              data.crossPartitionUniqueCalls = uniqueCalls + data.crossPartitionUniqueCalls;

              if ( targetCategory == partition ) {
                          data.crossCallsTo =  data.crossCallsTo + uniqueCalls;
              }
              else if (sourceCategory == partition ) {
                          data.crossCallsFrom =  data.crossCallsFrom + uniqueCalls;
              }
          }
        }
      }
    });

    depLinks.forEach((link) => {
      const sourceCategory = nodeCatMap[link.source];
      const targetCategory = nodeCatMap[link.target];
      if (
        sourceCategory &&
        targetCategory &&
        //sourceCategory.toLowerCase() !== "unknown" &&
        //targetCategory.toLowerCase() !== "unknown" &&
        (link.source == cls || link.target == cls) &&
        (sourceCategory == partition || targetCategory == partition)
      ) {
        if (sourceCategory !== targetCategory) {
          data.crossPartitionDataDependencies =
            data.crossPartitionDataDependencies + 1;

           

        } else {

          data.internalPartitionDataDependencies =
            data.internalPartitionDataDependencies + 1;
        }
      }
    });

    return data;
  },


  /**
   * Gets the stats for a specified partition.
   * The following stats will be returned:
   * Internal runtime call edges (internalRuntimeCallEdges)
   * Cross partition runtime calls (crossPartitionRuntimeCalls)
   * Internal data dependensy (internalDataDependencies)
   * Cross partition data dependencies (crossPartitionDataDependencies)
   * @param {*} source The object contains partition data
   * @param {*} dependencies The dependency object
   */
  getCrossPartitionStats(source, dependencies) {
    const nodes = source.nodes;
    const links = source.links;
    //const overview = source.overview;
    const depLinks = dependencies.links;
    let crossPartitionClassesSet = new Set();
    var data = {
      crossPartitionClasses: 0,
      crossPartitionRuntimeCalls: 0,
      crossPartitionDependendy: 0,
      partitionData: {},
      totalPartitionRuntimeCalls: 0,
      uniqueRuntimeCalls: 0,
      uniqueDependencyCalls: 0,
      crossPartitionUniqueCalls: 0,
      internalPartitionUniqueCalls: 0
    };

    /* 
    The key for partitionData will be the partition name.
    The value is:
    {
        internalRuntimeCallEdges: number,
        crossPartitionRuntimeCalls: number,
        internalDataDependencies: number,
        crossPartitionDataDependencies: number
    }
    */

    var nodeCatMap = {};
    nodes.forEach((node) => {
      //if (node.construct.toLowerCase() === "class") {
      nodeCatMap[node.name] = node.category;

      if (
        Object.prototype.hasOwnProperty.call(
          data.partitionData,
          node.category
        ) == false
      ) {
        data.partitionData[node.category] = {
          internalRuntimeCallEdges: 0,
          crossPartitionRuntimeCalls: 0,
          internalDataDependencies: 0,
          crossPartitionDataDependencies: 0,
          totalPartitionRuntimeCalls: 0,
          crossPartitionUniqueCalls: 0,
          internalPartitionUniqueCalls: 0,
          internalCallsTo: 0,
          internalCallsFrom: 0,
          crossCallsTo: 0,
          crossCallsFrom: 0,
        };
      }
      //}
    });

    links.forEach((link) => {
      const sourceCategory = nodeCatMap[link.source];
      const targetCategory = nodeCatMap[link.target];
      if (
        sourceCategory &&
        targetCategory //&&
        //sourceCategory.toLowerCase() !== "unknown" &&
        //targetCategory.toLowerCase() !== "unknown"
      ) {
        
        var calls = 0;
        if (link.method) {
          const uniqueCalls = Object.keys(link.method).length;
          data.uniqueRuntimeCalls = data.uniqueRuntimeCalls + uniqueCalls;
          calls = Object.values(link.method).reduce(function(a, b) {
            return a + b;
          }, 0);
          if (sourceCategory == targetCategory) {
            data.partitionData[targetCategory].internalCallsTo =  data.partitionData[targetCategory].internalCallsTo + uniqueCalls;
            data.partitionData[sourceCategory].internalCallsFrom =  data.partitionData[sourceCategory].internalCallsFrom + uniqueCalls;

            data.partitionData[sourceCategory].totalPartitionRuntimeCalls =
              calls +
              data.partitionData[sourceCategory].totalPartitionRuntimeCalls;

            data.internalPartitionUniqueCalls = data.internalPartitionUniqueCalls + uniqueCalls;
            data.partitionData[sourceCategory].internalPartitionUniqueCalls =
              uniqueCalls +
              data.partitionData[sourceCategory].internalPartitionUniqueCalls;

          } else {

            data.partitionData[targetCategory].crossCallsTo =  data.partitionData[targetCategory].crossCallsTo + uniqueCalls;
            data.partitionData[sourceCategory].crossCallsFrom =  data.partitionData[sourceCategory].crossCallsFrom + uniqueCalls;

            data.partitionData[sourceCategory].totalPartitionRuntimeCalls =
              calls +
              data.partitionData[sourceCategory].totalPartitionRuntimeCalls;
            data.partitionData[targetCategory].totalPartitionRuntimeCalls =
              calls +
              data.partitionData[targetCategory].totalPartitionRuntimeCalls;

              data.crossPartitionUniqueCalls = data.crossPartitionUniqueCalls + uniqueCalls;
              data.partitionData[targetCategory].crossPartitionUniqueCalls =
              uniqueCalls +
              data.partitionData[targetCategory].crossPartitionUniqueCalls;

              data.partitionData[sourceCategory].crossPartitionUniqueCalls =
              uniqueCalls +
              data.partitionData[sourceCategory].crossPartitionUniqueCalls;
          }
          data.totalPartitionRuntimeCalls =
            calls + data.totalPartitionRuntimeCalls;
        }

        if (sourceCategory !== targetCategory) {
          crossPartitionClassesSet.add(link.source);
          crossPartitionClassesSet.add(link.target);
          data.crossPartitionRuntimeCalls =
            calls + data.crossPartitionRuntimeCalls;
          data.partitionData[sourceCategory].crossPartitionRuntimeCalls =
            calls +
            data.partitionData[sourceCategory].crossPartitionRuntimeCalls;
          data.partitionData[targetCategory].crossPartitionRuntimeCalls =
            calls +
            data.partitionData[targetCategory].crossPartitionRuntimeCalls;
        } else {
          data.partitionData[sourceCategory].internalRuntimeCallEdges =
            data.partitionData[sourceCategory].internalRuntimeCallEdges + 1;
        }
      }
    });

    depLinks.forEach((link) => {
      const sourceCategory = nodeCatMap[link.source];
      const targetCategory = nodeCatMap[link.target];
      let value = link.value;
      if ( !value ) {
        value = 1;
      } else {
        value = parseInt(value);
      }
      if (
        sourceCategory &&
        targetCategory //&&
        //sourceCategory.toLowerCase() !== "unknown" &&
        //targetCategory.toLowerCase() !== "unknown"
      ) {
        //console.log("data.uniqueDependencyCalls=" + data.uniqueDependencyCalls);
        data.uniqueDependencyCalls++;
        if (sourceCategory !== targetCategory) {
          data.crossPartitionDependendy = data.crossPartitionDependendy + value;
          data.partitionData[sourceCategory].crossPartitionDataDependencies =
            data.partitionData[sourceCategory].crossPartitionDataDependencies +
            value;
        } else {
          data.partitionData[sourceCategory].internalDataDependencies =
            data.partitionData[sourceCategory].internalDataDependencies + value;
        }
      }
    });
    data.crossPartitionClasses = crossPartitionClassesSet.size;
    /*
    overview.links.forEach((link) => {
      const pvalue = link.value;
      var value = Math.round(Math.pow(10, pvalue));
      data.crossPartitionRuntimeCalls = value + data.crossPartitionRuntimeCalls;
      data.partitionData[link.source].crossPartitionRuntimeCalls =
        value + data.partitionData[link.source].crossPartitionRuntimeCalls;
    });
*/
    console.log(data);
    return data;
  },
};

export default stats;
