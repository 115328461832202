//import { _ } from "core-js"

/********************************************************************
# Licensed Materials - Property of IBM
#
# (C) Copyright IBM Corp. 2020, 2021, 2022. All Rights Reserved.
#
# US Government Users Restricted Rights - Use, duplication or
# disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
**********************************************************************/
import i18n from "@/plugins/i18n";

const messageUtils = {
  getMessage(messageKey) {
    let locale = i18n.locale;

    if ( !i18n.messages[locale] )
    {
      locale = locale.split("-")[0];
    }

    if (i18n.messages[locale] && i18n.messages[locale][messageKey]) {
      return i18n.messages[locale][messageKey];
    } else if (
      i18n.messages[i18n.fallbackLocale] &&
      i18n.messages[i18n.fallbackLocale][messageKey]
    ) {
      return i18n.messages[i18n.fallbackLocale][messageKey];
    } else {
      return messageKey;
    }
  },
};

export default messageUtils;
