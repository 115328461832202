<!--
# *****************************************************************
#
# Licensed Materials - Property of IBM
#
# (C) Copyright IBM Corp. 2019, 2020, 2021, 2022. All Rights Reserved.
#
# US Government Users Restricted Rights - Use, duplication or
# disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
#
# *****************************************************************
-->
<template>
  <div id="side-nav" class="sidenav" style="display: block;">
    <div class="bx--side-nav bx--side-nav--fixed" data-side-nav>
      <button v-show="isClose === 'open'" @click="openControlPanel"
        class="bx--btn bx--btn--primary bx--btn--icon-only control-panel-open-sidebar"
      >
        <svg
          focusable="false"
          preserveAspectRatio="xMidYMid meet"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 32 32"
          aria-hidden="true"
          style="will-change: transform;"
        >
          <polygon points="22,16 12,26 10.6,24.6 19.2,16 10.6,7.4 12,6" />
          <title>Graph control panel</title>
        </svg>
      </button>
      <button v-show="isClose === 'close'" @click="closeControlPanel"
        class="bx--btn bx--btn--primary bx--btn--icon-only control-panel-close-sidebar"
      >
        <svg
          focusable="false"
          preserveAspectRatio="xMidYMid meet"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 32 32"
          aria-hidden="true"
          style="will-change: transform;"
        >
          <polygon points="16,22 6,12 7.4,10.6 16,19.2 24.6,10.6 26,12" />
          <title>Graph control panel</title>
        </svg>
      </button>
      <button @click="goToDashboard" class="bx--btn bx--btn--primary bx--btn--icon-only home-sidebar">
        <svg
          focusable="false"
          preserveAspectRatio="xMidYMid meet"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 32 32"
          aria-hidden="true"
          style="will-change: transform;  fill: #fff"
        >
          <path
            d="M16.61,2.21a1,1,0,0,0-1.24,0L1,13.42,2.24,15,4,13.62V26a2,2,0,0,0,2,2H26a2,2,0,0,0,2-2V13.63L29.76,15,31,13.43ZM18,26H14V18h4Zm2,0h0V18a2,2,0,0,0-2-2H14a2,2,0,0,0-2,2v8H6V12.06L16,4.27l10,7.8V26Z"
          />
          <title>Home</title>
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
//import { eventBus } from "../../main.js";
// currently this component is not use, workaround latest vue error by using a local isClose
export default {
  name: "SideNav",
  components: {},
  //props: ["isClose"],
  data() {
    return {
      isClose: true
    };
  },
  methods: {
      openControlPanel: function() {
          this.isClose = 'close';
          console.log("in open control panel");
          this.$emit('graphPage-closeControlPanel');
      },
      closeControlPanel: function() {
          this.isClose = 'open';
          console.log('in close control panel');
          this.$emit('graphPage-openControlPanel');
      },
      goToDashboard: function() {
          this.isClose = "none";
          this.$router.push("/");
      }
  }
//   created() {
//     eventBus.$on("sideNav-controlPanel", isHidden => {
//         this.isOpen = !isHidden;
//     });
//   }
};

</script>

<style lang="scss" scoped>
.sidenav .bx--side-nav {
  top: 45px !important;
  height: calc(100% - 45px) !important;
  background-color: #161616;
  width: 48px;
  border-right: 1px solid;
}

.sidenav .bx--btn--icon-only {
  background-color: #161616;
  margin-left: 3px;
}

.sidenav .bx--btn--primary:focus {
  border-color: #161616;
  box-shadow: none;
}

.sidenav .bx--btn--primary:active {
  background-color: #161616;
}

.sidenav .bx--btn--icon-only:hover {
  background-color: #161616;
}

.side-nav .bx--btn {
  background-color: #161616;
  color: #ffffff;
}
</style>
