/********************************************************************
# Licensed Materials - Property of IBM
#
# (C) Copyright IBM Corp. 2020, 2021, 2022, 2023. All Rights Reserved.
#
# US Government Users Restricted Rights - Use, duplication or
# disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
**********************************************************************/

import store from "../../store";
import utils from "./graphUtil";
import constants from "../constants";

const highlightHandler = {
  highlightAll(ele, options) {
    if (ele.isNode() === false || utils.isExpandedPartition(ele) === false) {
      var neighbours = ele.closedNeighborhood(); //.filter(ele => (utils.isVisible(ele) || ele.data('dep') === true));
      const edgeFilterClasses = utils.getAllFilterClassesForEdges();
      const filterClasses = {};
      const depEdges = [];
      const isHighlighting = options.classes.includes(constants.m2mHighlightedClass);
      neighbours.forEach((el) => {
        if (el.data("id") !== ele.data("id")) { // ignore current node
          el.addClass(options.classes);
          // remove edge filters for "highlighted and connected" edges
          if (el.isEdge() && isHighlighting) {
            edgeFilterClasses.forEach((cls) => {
              if (el.hasClass(cls)) {
                filterClasses[cls] = filterClasses[cls] || [];
                filterClasses[cls].push(el);
                if (true === el.data('dep') && cls === constants.filterTypeShowEdge) {
                  depEdges.push(el);
                }
              }
            });
            el.removeClass(edgeFilterClasses);
          }
        }
      });
      store.commit("setEdgeFilterClasses", filterClasses);  // cache filtered edges
      store.commit("setDynamicDependencyEdges", depEdges);
      if (ele.isEdge() === true && utils.isVisible(ele) === true) {
        ele.source().addClass(options.classes);
        ele.target().addClass(options.classes);
      }
      // fade-out other elements
      if (utils.isHighlightedFocusedElement(ele) === true) {
        utils.fadeOutElements(ele.cy());
      }
    }
  },

  highlight(ele) {
    if (ele.isNode() === false || utils.isExpandedPartition(ele) === false) {
      ele.addClass(constants.m2mHighlightedFocusedClass);
      this.highlightAll(ele, {
        classes: constants.m2mHighlightedClass,
      });
    }
  },

  removeHighlights(ele, options) {
    const cy = ele ? ele.cy() : store.getters.getCYInstance();
    const highlightedEles = cy.$(`${utils.getClassSelectorForElement('', utils.getHighlightClasses())}, :selected`);
    cy.batch(() => {
      const newStyleEles = new Set();
      highlightedEles.forEach((node) => {
        if (node.selected() === true && (!options ||  options.unselect !== false)) {
          node.unselect();
        }
        if (node.hasClass(constants.m2mHighlightedClass) === true) {
          node.removeClass(constants.m2mHighlightedClass);
          newStyleEles.add(node);
        }
        if (node.hasClass(constants.m2mHoverClass) === true) {
          node.removeClass(constants.m2mHoverClass);
          newStyleEles.add(node);
        }
        if (node.hasClass(constants.m2mHighlightedFocusedClass) === true) {
          node.removeClass(constants.m2mHighlightedFocusedClass);
          newStyleEles.add(node);
        }
        if (node.hasClass(constants.m2mHoveredFocusedClass) === true) {
          node.removeClass(constants.m2mHoveredFocusedClass);
          newStyleEles.add(node);
        }
        if (node.hasClass(constants.m2mSearchedClass) === true) {
          node.removeClass(constants.m2mSearchedClass);
          newStyleEles.add(node);
        }
      });
      // remove style overrides
      cy.collection().add(Array.from(newStyleEles)).removeStyle();
      // fade-in other elements
      utils.fadeInElements(cy);
    });
  },

  hoverOn(ele) {
    if (ele.isNode() === false || utils.isExpandedPartition(ele) === false) {
      ele.addClass(constants.m2mHoveredFocusedClass);
    }
  },

  hoverOff(ele) {
    if (ele && ele.hasClass(constants.m2mHoveredFocusedClass) === true) {
      const cy = ele.cy();
      ele.removeClass(constants.m2mHoveredFocusedClass);
      const highlightedEles = cy.$(`.${constants.m2mHoverClass}`);
      highlightedEles.forEach((el) => {
        el.removeClass(constants.m2mHoverClass);
      });
      // fade-in other elements
      // **comment out, we don't fadeout elements for hovering
      // if (utils.hasFocusedNodes(cy) !== true) {
      //   utils.fadeInElements(cy);
      // }
    }
  },

  searchNode(node) {
    if (node.hasClass(constants.m2mSearchedClass) !== true) {
      node.addClass(constants.m2mSearchedClass);
      this.highlightAll(node, {
        classes: constants.m2mHighlightedClass,
      });
    }
  },
};

export default highlightHandler;
