<!--
# *****************************************************************
#
# Licensed Materials - Property of IBM
#
# (C) Copyright IBM Corp. 2020, 2021, 2022, 2023. All Rights Reserved.
#
# US Government Users Restricted Rights - Use, duplication or
# disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
#
# *****************************************************************
-->
<template>
  <div class="comparsion-bar">
    <div>
      <div class="view-name">
        {{viewName}}
        <span v-if="isActive">
          <svg width="16px" height="12px" viewBox="0 0 16 12" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <g id="Details-panel-(√)" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <g id="Fill" transform="translate(0.000000, -2.000000)" fill="#000000">
                      <path d="M8,10 C9.1045695,10 10,9.1045695 10,8 C10,6.8954305 9.1045695,6 8,6 C6.8954305,6 6,6.8954305 6,8 C6,9.1045695 6.8954305,10 8,10 Z M15.47,7.83 C14.2680152,4.72085062 11.3310488,2.62526414 8,2.5 C4.6689512,2.62526414 1.73198477,4.72085062 0.53,7.83 C0.490283624,7.9398538 0.490283624,8.0601462 0.53,8.17 C1.73198477,11.2791494 4.6689512,13.3747359 8,13.5 C11.3310488,13.3747359 14.2680152,11.2791494 15.47,8.17 C15.5097164,8.0601462 15.5097164,7.9398538 15.47,7.83 Z M8,11.25 C6.20507456,11.25 4.75,9.79492544 4.75,8 C4.75,6.20507456 6.20507456,4.75 8,4.75 C9.79492544,4.75 11.25,6.20507456 11.25,8 C11.2472467,9.79378374 9.79378374,11.2472467 8,11.25 Z"></path>
                  </g>
              </g>
          </svg>
        </span>
      </div>

      <div v-if="hasView">
        <table role="presentation" class="compare-view-table-bar">
          <tr>
            <td
              @mouseover="showPopup"
              @mouseleave="hidePopup"
              class="internal-ref bar-label"
              v-text="internalPercentageLabel"
              v-bind:style="{ width: internalWidth + '%' }"
            ></td>
            <td
              @mouseover="showPopup"
              @mouseleave="hidePopup"
              class="cross-ref bar-label"
              v-text="crossPercentageLabel"
              v-bind:style="{ width: externalWidth + '%' }"
            ></td>
          </tr>
        </table>
        <div v-show="showPopupInfo">
          <table class="compare-view-popup">
            <tr v-for="item in popupData" :key="item.key">
              <td></td>
              <td>
                {{item.title}}
              </td>
              <td>
                {{item.text}}
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div class="fine-label" v-html="fineLabel"></div>
    </div>
    <div class="create-custom-view" v-if="!hasView">
      <a
        class="text-decoration-none"
        href="#"
        v-html="$t('side-panel-top-container-link-create-custom-view')"
        @click="onClickCustomViewList()"
      ></a>
    </div>
    <div class="stringWidthTesterDiv bar-label"></div>
  </div>
</template>

<script>
import messageUtils from "@/js/messageUtils";
import { eventBus } from "../../main.js";

export default {
  name: "ComparsionBar",
  components: {},
  data() { return {internalWidth: 0, externalWidth: 0, internalPercentageLabel: "", crossPercentageLabel: "", fineLabel: "",
      showPopupInfo: false,
      popupData: {},
      intraPopupData:[{title:'Call volume', text: '90% = 200,000'},{title: 'Unique calls', text: '600'}],
      crossPopupData:[{title:'Call volume', text: '10% = 20,000'},{title: 'Unique calls', text: '600'}]}  },
  props: ["viewName","isActive","hasView","interalCalls","crossCalls", "type","internalDeps","crossDeps", "uniqueRuntimeCalls",
    "internalUniqueRuntimeCalls", "crossUniqueRuntimeCalls","callsType",
    "internalCallsTo","internalCallsFrom","crossCallsTo","crossCallsFrom"],

  mounted() {
    let icalls = this.internalUniqueRuntimeCalls;
    let ccalls = this.crossUniqueRuntimeCalls;
    if ( this.type != "runtimeCalls" ) {
      icalls = this.internalDeps;
      ccalls = this.crossDeps;
    }
    this.populateBar(icalls, ccalls, this.type);

  },
  methods: {
    populateBar(icalls, ccalls, type) {
      if ( this.callsType === undefined ) {
        return;
      }
      const langCode = navigator.language || navigator.systemLanguage;
      let percentageSign = "%";
      if (langCode) {
        const lang = langCode.toLowerCase().substr(0, 2);
        if (lang === "fr") {
          percentageSign = " %";
        }
      }

      
      let callsFromKey = "side-panel-top-container-bar-text-calls-from-" + this.callsType ;
      let callsToKey = "side-panel-top-container-bar-text-calls-to-" + this.callsType ;
      console.log(this.viewName, icalls, ccalls, type, this.callsType);
      
      this.internalWidth = 50;
      this.externalWidth = 50;
      let tempInternalLabel = this.$t('side-panel-top-container-bar-na');
      let tempCrossLabel = this.$t('side-panel-top-container-bar-na');
      if ( icalls + ccalls > 0 ) {
        this.internalWidth = 100 * ( icalls / (icalls + ccalls ));
        this.externalWidth = 100 - this.internalWidth;
      }
      

      let internal = Math.round((this.internalWidth*100)/100);
      let cross = 100 - internal;
      
      if ( icalls != 0 && internal == 0 ) {
        internal = 1;
        cross = 99;
      }
      if ( ccalls != 0 && cross == 0 ) {
        cross = 1;
        internal = 99;
      }
      if ( icalls + ccalls > 0 ) {
        tempInternalLabel = this.$t('intra-partition-comparsion-bar-label', {percentage: internal + percentageSign} );
        tempCrossLabel = this.$t('cross-partition-comparsion-bar-label', {percentage: cross + percentageSign});
      }

      const that = this;
      this.intraPopupData = [];
      this.crossPopupData = [];
      const d = new Date();

      let iText = "0";
      let cText = "0";
      if ( icalls + ccalls > 0 ) {
        iText = internal + percentageSign+ ' = ' + icalls;
        cText = cross + percentageSign + ' = ' + ccalls;
      }
      
      if ( type == "runtimeCalls" ) {

        this.intraPopupData.push({key: d.getTime() + "A", title: this.$t('side-panel-top-container-bar-text-unique-calls'), text: iText});
        
        if ( this.callsType !== "view" && this.callsType !== "partition" ) {
          this.intraPopupData.push({key: d.getTime() + "B", title: this.$t(callsToKey), text: this.internalCallsTo});
          this.intraPopupData.push({key: d.getTime() + "C", title: this.$t(callsFromKey), text: this.internalCallsFrom});
        }
        this.intraPopupData.push({key: d.getTime() + "D", title: this.$t('side-panel-top-container-bar-text-call-volume'), text: this.interalCalls});

        this.crossPopupData.push({key: d.getTime() + "E", title: this.$t('side-panel-top-container-bar-text-unique-calls'), text: cText});
        if ( this.callsType !== "view") {
          this.crossPopupData.push({key: d.getTime() + "F", title: this.$t(callsToKey), text: this.crossCallsTo});
          this.crossPopupData.push({key: d.getTime() + "G", title: this.$t(callsFromKey), text: this.crossCallsFrom});
        }
        this.crossPopupData.push({key: d.getTime() + "H", title: this.$t('side-panel-top-container-bar-text-call-volume'), text: this.crossCalls});
      } else {
        
        this.intraPopupData.push({key: d.getTime() + "I", title: this.$t('side-panel-top-container-bar-text-containment-relationship'), text: iText});
        this.crossPopupData.push({key: d.getTime() + "J", title: this.$t('side-panel-top-container-bar-text-containment-relationship'), text: cText});
      }
      console.log(this.viewName + " before next tick");
      this.$nextTick(function() {
        if ( that.hasView ) {
          console.log("tempInternalLabel=" + tempInternalLabel);
          console.log("tempCrossLabel=" + tempCrossLabel);
          const oldDisplay = document.getElementById("sidepanel").style.display;
          const oldOpacity = document.getElementById("sidepanel").style.opacity;
          document.getElementById("sidepanel").style.display = "block";
          document.getElementById("sidepanel").style.opacity = "0";
          const widthOfInternal = that.$el.getElementsByClassName('internal-ref')[0].clientWidth;
          const widthOfCross = that.$el.getElementsByClassName('cross-ref')[0].clientWidth;
          const testDiv = that.$el.getElementsByClassName("stringWidthTesterDiv")[0];
          testDiv.innerHTML = tempInternalLabel;
          let width = testDiv.clientWidth;
          that.fineLabel = "&nbsp;";
          if ( width > widthOfInternal ) {
            that.internalPercentageLabel = "";
            that.fineLabel = that.$t("side-panel-top-container-text-intra-partition-percentage",{percentage: internal});
            that.$el.getElementsByClassName('internal-ref')[0].style.paddingLeft = 0;
            that.$el.getElementsByClassName('internal-ref')[0].style.paddingRight = 0;
          } else {
            that.internalPercentageLabel = tempInternalLabel;
            
            that.$el.getElementsByClassName('internal-ref')[0].style.paddingLeft = "0.5rem";
            that.$el.getElementsByClassName('internal-ref')[0].style.paddingRight = "0.5rem";
          }

          testDiv.innerHTML = tempCrossLabel;
          width = testDiv.clientWidth;
          if ( width > widthOfCross ) {
            that.crossPercentageLabel = "";
            that.fineLabel = that.$t("side-panel-top-container-text-cross-partition-percentage",{percentage: cross});
            that.$el.getElementsByClassName('cross-ref')[0].style.paddingLeft = 0;
            that.$el.getElementsByClassName('cross-ref')[0].style.paddingRight = 0;
          } else {
            that.crossPercentageLabel = tempCrossLabel;
            that.$el.getElementsByClassName('cross-ref')[0].style.paddingLeft = "0.5rem";
            that.$el.getElementsByClassName('cross-ref')[0].style.paddingRight = "0.5rem";
          }
          if ( cross == 0 || internal ==0 ) {
            that.$el.getElementsByClassName('internal-ref')[0].style.minWidth = 0;
            that.$el.getElementsByClassName('cross-ref')[0].style.minWidth = 0;
            that.$el.getElementsByClassName('internal-ref')[0].style.borderRight = 0;
          } 

          document.getElementById("sidepanel").style.display = oldDisplay;
          document.getElementById("sidepanel").style.opacity = oldOpacity;
        }
      });
    },
    onClickCustomViewList() {
      eventBus.$emit("GraphSummaryTopPanel.viewOnChange", "custom_create");
    },
    showPopup (event) {
      
      const mouseX = event.clientX;
      const mouseY = event.clientY;
      const target = event.target;
      //const table = this.$el.getElementsByClassName("compare-view-popup")[0];
      //const firstTr = table.getElementsByTagName("tr").firstChild;
      //const firstTd = firstTr.getElementsByTagName("td").firstChild;

      if ( target.classList.contains("internal-ref") ) {
        this.popupData = this.intraPopupData;
      } else {
        this.popupData = this.crossPopupData;
      }
      
      this.showPopupInfo = true;
      const that = this;
      const offset = 10;
      
      this.$nextTick(function() {
        const width = that.$el.getElementsByClassName('compare-view-popup')[0].offsetWidth;
        const height = that.$el.getElementsByClassName('compare-view-popup')[0].offsetHeight;
        // set top or bottom of popup
        if ( ( mouseY + offset + height ) > (window.innerHeight || document.documentElement.clientHeight) ) {
          that.$el.getElementsByClassName('compare-view-popup')[0].style.bottom = offset + "px";
          that.$el.getElementsByClassName('compare-view-popup')[0].style.removeProperty('top');
        } else {
          that.$el.getElementsByClassName('compare-view-popup')[0].style.top = (mouseY + offset)  + "px";
          that.$el.getElementsByClassName('compare-view-popup')[0].style.removeProperty('bottom');
        }

        // set left or right of popup
        if ( ( mouseX + offset + width ) > (window.innerWidth || document.documentElement.clientWidth) ) {
          that.$el.getElementsByClassName('compare-view-popup')[0].style.right = offset + "px";
          that.$el.getElementsByClassName('compare-view-popup')[0].style.removeProperty('left');
        } else {
          that.$el.getElementsByClassName('compare-view-popup')[0].style.left = (mouseX + offset) + "px";
          that.$el.getElementsByClassName('compare-view-popup')[0].style.removeProperty('right');
        }

        const firstTd = that.$el.querySelector(".compare-view-popup tr:first-child td:first-child");

      if ( target.classList.contains("internal-ref") ) {
        
        firstTd.style.backgroundColor = "#0f62fe";
      } else {
        
        firstTd.style.backgroundColor = "#8a3ffc";
      }

        
      });
      
    },
    hidePopup(){
      this.showPopupInfo = false;
    },
    isRectInViewport (rect) {
      return (
          rect.top >= 0 &&
          rect.left >= 0 &&
          rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
          rect.right <= (window.innerWidth || document.documentElement.clientWidth)
      );
    }
  },
  computed: {
    showLabel() {
      return messageUtils.getMessage(this.iconButton.label);
    }
  }
};
</script>

<style lang="scss" scoped>
.compare-view-table-bar {
  width: 100%;
  tr {
    width: 100%;
    height:  1.5rem;
    color: #ffffff;
  }
  td {
    height: 100%;
  }
  td:first-child {
    background-color: #0f62fe;
    border-right: 0.0625rem solid #ffffff;
    min-width: 0.25rem;
  }
  td:last-child {
    background-color: #8a3ffc;
    border-right: 0.0625rem solid #ffffff;
    min-width: 0.25rem;
  }
  td:first-child:hover {
    background-color: #0043ce;
  }
  td:last-child:hover {
    background-color: #6929c4;
  }
}

.compare-view-popup {
  background-color: var(--app-compare-view-bar-popup-background-color);
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.3);
  position: fixed;
  z-index: 99999;
  white-space: nowrap;
  tr {
    height:  1.5rem;
  }
  tr:not(:last-child) {
    border-bottom: 0.0625rem solid var(--app-compare-view-bar-popup-row-border-color);
  }
  td {
    @include carbon--type-style("helper-text-01");
    vertical-align: middle;
  }
  td:first-child {
    width: 0.25rem;
  }

  tr:first-child td:first-child {
    background-color: #8a3ffc;
  }
  tr:not(:first-child) td:first-child {
    background-color: var(--app-compare-view-bar-popup-row-border-color);
  }

  tr:first-child td:last-child {
    font-weight: 600;
  }
  td:nth-child(2) {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }
  td:last-child {
    padding-right: 0.5rem;
    padding-left:  1.5rem;
    text-align: right;
  }
}

.bar-label {
  font-size: 0.875rem;
  font-weight: 600;
  vertical-align: middle;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.stringWidthTesterDiv {
  position: absolute;
  z-index: -999999;
  visibility: hidden;
  height: auto;
  width: auto;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  white-space: nowrap; /* Thanks to Herb Caudill comment */
}
.view-name {
  font-size: 0.75rem;
  color: #161616;
  line-height: 1rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  span {
    vertical-align: middle;
    line-height: 0.75rem;
    height: 0.75rem;
  }
}
.fine-label {
  font-size: 0.75rem;
  color: #171717;
  letter-spacing: 0.02rem;
  line-height: 1rem;
  font-weight: 400;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  text-align: end;
}
.create-custom-view {
  font-size: 0.75rem;
  color: #161616;
  letter-spacing: 0.02rem;
  line-height: 1rem;
  font-weight: 400;
  background-color: #f4f4f4;
  width: 100%;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  vertical-align: middle;
  line-height:  1.5rem;
  border-left: 0.0625rem;
  border-right: 0.0625rem;
  border-color: #{$ui-04};
  border-style: solid;
  a:hover {
    background-color: #{$hover-ui};
    color: #{$hover-primary-text};
  }
}
.text-decoration-none {
  text-decoration: none !important;
}
</style>
