<!--
# *****************************************************************
#
# Licensed Materials - Property of IBM
#
# (C) Copyright IBM Corp. 2021, 2022. All Rights Reserved.
#
# US Government Users Restricted Rights - Use, duplication or
# disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
#
# *****************************************************************
-->
<template>
  <section class="toolbar bx--toolbar">
    <div class="toolbar-content bx--toolbar-content">
      <div class="toolbar-view">
        <div class="toolbar-view-switcher">
          <cv-content-switcher size="sm" @selected="onSelected">
            <cv-content-switcher-button
              content-selector=".graph"
              :selected="isSelected(0)"
            >
              {{ $t("graph-view-label") }}
            </cv-content-switcher-button>
            <cv-content-switcher-button
              content-selector=".table-view-content"
              :selected="isSelected(1)"
            >
              {{ $t("table-view-label") }}
            </cv-content-switcher-button>
          </cv-content-switcher>
        </div>
      </div>
      <div class="graph-toolbar-action" v-show="showToolbarAction">
        <div class="toolbar-view-readonly">
          <cv-interactive-tooltip
            alignment="center"
            direction="bottom"
            v-show="readonly"
          >
            <template slot="label">
              {{ $t("toolbar-readonly") }}
            </template>
            <template slot="trigger"
              ><Information16
                class="bx--overflow-menu__icon bx--toolbar-filter-icon"
              />
            </template>
            <template slot="content">
              <p>{{ $t("toolbar-readonly-tooltip-content") }}</p>
              <cv-link href="https://www.ibm.com/docs/SS7H9Y/doc/m2m_rec_concepts.html" target="_blank">
                {{ $t("toolbar-readonly-tooltip-learn-more") }}
              </cv-link>
              <button
                class="bx--btn bx--btn--primary"
                @click="onTooltipDismiss"
              >
                {{ $t("toolbar-readonly-tooltip-dismiss") }}
              </button>
            </template>
          </cv-interactive-tooltip>
        </div>
        <!--           :id="search.id" -->
        <search-with-filter
          :class="search.class"
          :search="search"
        ></search-with-filter>
        <div class="toolbar-non-overflow-action" v-show="!showOverflowMenu">
          <div
            class="toolbar-lock-partition"
            v-show="!readonly && !showTableView"
          >
            <label class="toolbar-lock-partition-label">
              {{ lockPartition.label }}
            </label>
            <cv-toggle
              v-show="!readonly"
              :small="true"
              :title="lockPartition.value"
              :form-item="false"
              :value="lockPartition.value"
              v-model="lockPartition.state"
              @keydown.enter.prevent="onLockPartitionOverflowMenu"
              @keydown.space.prevent="onLockPartitionOverflowMenu"
              @change="onLockPartitionChange"
            >
              <template slot="text-left">{{
                lockPartition.editOffSlot
              }}</template>
              <template slot="text-right">{{
                lockPartition.editOnSlot
              }}</template>
            </cv-toggle>
          </div>
          <cv-icon-button
            v-bind:class="{ 'toolbar-reset-custom-view': true, 'toolbar-reset-custom-view-border': showTableView }"
            v-show="!readonly"
            kind="ghost"
            :icon="resetCustomViewIcon"
            :label="$t('reset-custom-dialog-title')"
            tip-position="bottom"
            tip-alignment="center"
            ref="resetCustomViewRef"
            @click="onResetCustomView"
          />
          <cv-icon-button
            class="toolbar-customize-table-column"
            v-show="showTableView"
            kind="ghost"
            :icon="columnIcon"
            :label="$t('toolbar-customerize-column-tooltip')"
            tip-position="bottom"
            tip-alignment="center"
            ref="customizeColumnRef"
            @click="onTableCustomization"
          />
          <cv-icon-button
            v-show="!showTableView && partitionSelected && showExpandPartition"
            kind="ghost"
            :icon="expandPartitionIcon"
            :label="$t('toolbar-expand-partition-tooltip')"
            tip-position="bottom"
            tip-alignment="center"
            ref="expandPartitionRef"
            @click="onExpandCollapsePartition"
          />
          <cv-icon-button
            v-show="!showTableView && partitionSelected && !showExpandPartition"
            kind="ghost"
            :icon="collapsePartitionIcon"
            :label="$t('toolbar-collapse-partition-tooltip')"
            tip-position="bottom"
            tip-alignment="center"
            ref="collapsePartitionRef"
            @click="onExpandCollapsePartition"
          />
          <div class="toolbar-filter-container">
            <cv-icon-button
              kind="ghost"
              :icon="filterIcon"
              :label="$t('toolbar-filters-tooltip')"
              tip-position="bottom"
              tip-alignment="center"
              ref="filterRef"
              @click="openFilterPanel"
            />
            <div class="toolbar-filter-indicator-overlay">
              <svg class="toolbar-filter-indicator toolbar-filter-indicator-1" width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <title>1 filter apply</title>
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <g transform="translate(-1090.000000, -342.000000)">
                    <g transform="translate(1090.000000, 342.000000)">
                      <rect fill="#0056FF" x="0" y="0" width="16" height="16" rx="4"></rect>
                      <g transform="translate(5.500000, 3.625000)" fill="#FFFFFF">
                        <path d="M2.5,0.625 L2.5,8.125 L2.5,8.125 L2.5,0.625 M3.125,0 L0,0 L0,1.25 L1.875,1.25 L1.875,7.5 L0,7.5 L0,8.75 L5,8.75 L5,7.5 L3.125,7.5 L3.125,0 Z"></path>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
              <svg class="toolbar-filter-indicator toolbar-filter-indicator-2" width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <title>2 filter apply</title>
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <g transform="translate(-1130.000000, -342.000000)">
                    <g transform="translate(1130.000000, 342.000000)">
                      <rect fill="#0056FF" x="0" y="0" width="16" height="16" rx="4"></rect>
                      <g transform="translate(5.812500, 3.625000)" fill="#FFFFFF">
                        <path d="M5,8.75 L0,8.75 L0,5 C0,4.30964406 0.559644063,3.75 1.25,3.75 L3.75,3.75 L3.75,1.25 L0,1.25 L0,0 L3.75,0 C4.44035594,0 5,0.559644063 5,1.25 L5,3.75 C5,4.44035594 4.44035594,5 3.75,5 L1.25,5 L1.25,7.5 L5,7.5 L5,8.75 Z"></path>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
              <svg class="toolbar-filter-indicator toolbar-filter-indicator-3" width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <title>3 filter apply</title>
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <g transform="translate(-1170.000000, -342.000000)">
                    <g transform="translate(1170.000000, 342.000000)">
                      <rect fill="#0056FF" x="0" y="0" width="16" height="16" rx="4"></rect>
                      <g transform="translate(5.500000, 3.625000)" fill="#FFFFFF">
                        <path d="M3.75,0 L0,0 L0,1.25 L3.75,1.25 L3.75,3.75 L1.25,3.75 L1.25,5 L3.75,5 L3.75,7.5 L0,7.5 L0,8.75 L3.75,8.75 C4.44035594,8.75 5,8.19035594 5,7.5 L5,1.25 C5,0.559644063 4.44035594,0 3.75,0 Z"></path>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
              <svg class="toolbar-filter-indicator toolbar-filter-indicator-4" width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <title>4 filter apply</title>
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <g transform="translate(-1210.000000, -342.000000)">
                    <g transform="translate(1210.000000, 342.000000)">
                      <rect fill="#0056FF" x="0" y="0" width="16" height="16" rx="4"></rect>
                      <g transform="translate(5.500000, 3.625000)" fill="#FFFFFF">
                        <path d="M3.75,0.625 L3.75,5.625 L3.75,5.625 L3.75,0.625 M4.375,0 L3.125,0 L3.125,5 L1.25,5 L1.25,0 L0,0 L0,6.25 L3.125,6.25 L3.125,8.75 L4.375,8.75 L4.375,6.25 L5,6.25 L5,5 L4.375,5 L4.375,0 Z"></path>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
              <svg class="toolbar-filter-indicator toolbar-filter-indicator-5" width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <title>5 filter apply</title>
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <g transform="translate(-1250.000000, -342.000000)">
                    <g transform="translate(1250.000000, 342.000000)">
                      <rect fill="#0056FF" x="0" y="0" width="16" height="16" rx="4"></rect>
                      <g transform="translate(5.500000, 3.625000)" fill="#FFFFFF">
                        <path d="M3.75,8.75 L0,8.75 L0,7.5 L3.75,7.5 L3.75,5 L0,5 L0,0 L5,0 L5,1.25 L1.25,1.25 L1.25,3.75 L3.75,3.75 C4.44035594,3.75 5,4.30964406 5,5 L5,7.5 C5,8.19035594 4.44035594,8.75 3.75,8.75 Z"></path>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
            </div>
          </div>
          <cv-icon-button
            kind="ghost"
            v-bind:class="{ 'toolbar-details-selected': showSidePanel }"
            :icon="openPanelRightIcon"
            :label="$t('toolbar-details-tooltip')"
            tip-position="bottom"
            tip-alignment="center"
            ref="detailsRef"
            @click="openSidePanel"
          />
          <cv-icon-button
            v-show="showCollapseHeader"
            kind="ghost"
            :icon="collapseHeaderIcon"
            :label="$t('toolbar-collapse-header-tooltip')"
            tip-position="bottom"
            tip-alignment="end"
            ref="hidePageHeaderRef"
            @click="onHidePageHeader"
          />
          <cv-icon-button
            v-show="!showCollapseHeader"
            kind="ghost"
            :icon="expandHeaderIcon"
            :label="$t('toolbar-expand-header-tooltip')"
            tip-position="bottom"
            tip-alignment="end"
            ref="showPageHeaderRef"
            @click="onShowPageHeader"
          />
        </div>
        <cv-overflow-menu
          class="toolbar-overflow-menu"
          :flip-menu="true"
          ref="overflowMenuRef"
        >
          <cv-overflow-menu-item
            v-show="!readonly"
            class="toolbar-overflow-menu-lock-partition"
            kind="ghost"
          >
            <cv-button
              tabindex="-1"
              kind="ghost"
              :icon="lockedIcon"
            ></cv-button>
            {{ lockPartition.label }}
            <cv-toggle
              class="toolbar-overflow-menu-lock-partition-toggle"
              v-show="!readonly"
              :small="true"
              :form-item="false"
              :value="lockPartition.value"
              v-model="lockPartition.state"
              @keydown.enter.prevent="onLockPartitionOverflowMenu"
              @keydown.space.prevent="onLockPartitionOverflowMenu"
              @click="onLockPartitionOverflowMenu"
            >
              <template slot="text-left">{{
                lockPartition.editOffSlot
              }}</template>
              <template slot="text-right">{{
                lockPartition.editOnSlot
              }}</template>
            </cv-toggle>
          </cv-overflow-menu-item>
          <cv-overflow-menu-item
            v-show="showTableView"
            kind="ghost"
            @click="onTableCustomization"
          >
            <cv-button
              tabindex="-1"
              kind="ghost"
              :icon="columnIcon"
            ></cv-button>
            {{ $t("toolbar-customerize-column-tooltip") }}
          </cv-overflow-menu-item>
          <cv-overflow-menu-item
            v-show="!showTableView && partitionSelected && showExpandPartition"
            kind="ghost"
            @click="onExpandCollapsePartition"
          >
            <cv-button
              tabindex="-1"
              kind="ghost"
              :icon="expandPartitionIcon"
            ></cv-button>
            {{ $t("toolbar-expand-partition-tooltip") }}
          </cv-overflow-menu-item>
          <cv-overflow-menu-item
            v-show="!showTableView && partitionSelected && !showExpandPartition"
            kind="ghost"
            @click="onExpandCollapsePartition"
          >
            <cv-button
              tabindex="-1"
              kind="ghost"
              :icon="collapsePartitionIcon"
            ></cv-button>
            {{ $t("toolbar-collapse-partition-tooltip") }}
          </cv-overflow-menu-item>
          <cv-overflow-menu-item
            class="toolbar-overflow-menu-filter"
            :class="graphViewKey+'_overflow'"
            kind="ghost"
            @click="openFilterPanel"
          >
            <cv-button
              tabindex="-1"
              kind="ghost"
              :icon="filterIcon"
            ></cv-button>
            <div style="padding-right: 0.5rem;">{{ $t("toolbar-filters-tooltip") }}</div>
            <svg class="toolbar-overflow-menu-filter-indicator toolbar-overflow-menu-filter-indicator-1" width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <title>1 filter apply</title>
              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g transform="translate(-1090.000000, -342.000000)">
                  <g transform="translate(1090.000000, 342.000000)">
                    <rect fill="#0056FF" x="0" y="0" width="16" height="16" rx="4"></rect>
                    <g transform="translate(5.500000, 3.625000)" fill="#FFFFFF">
                      <path d="M2.5,0.625 L2.5,8.125 L2.5,8.125 L2.5,0.625 M3.125,0 L0,0 L0,1.25 L1.875,1.25 L1.875,7.5 L0,7.5 L0,8.75 L5,8.75 L5,7.5 L3.125,7.5 L3.125,0 Z" id="Fill"></path>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
            <svg class="toolbar-overflow-menu-filter-indicator toolbar-overflow-menu-filter-indicator-2" width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <title>2 filters apply</title>
              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g transform="translate(-1130.000000, -342.000000)">
                  <g transform="translate(1130.000000, 342.000000)">
                    <rect fill="#0056FF" x="0" y="0" width="16" height="16" rx="4"></rect>
                    <g transform="translate(5.812500, 3.625000)" fill="#FFFFFF">
                      <path d="M5,8.75 L0,8.75 L0,5 C0,4.30964406 0.559644063,3.75 1.25,3.75 L3.75,3.75 L3.75,1.25 L0,1.25 L0,0 L3.75,0 C4.44035594,0 5,0.559644063 5,1.25 L5,3.75 C5,4.44035594 4.44035594,5 3.75,5 L1.25,5 L1.25,7.5 L5,7.5 L5,8.75 Z" id="Fill"></path>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
            <svg class="toolbar-overflow-menu-filter-indicator toolbar-overflow-menu-filter-indicator-3" width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <title>3 filters apply</title>
              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g transform="translate(-1170.000000, -342.000000)">
                  <g transform="translate(1170.000000, 342.000000)">
                    <rect fill="#0056FF" x="0" y="0" width="16" height="16" rx="4"></rect>
                    <g transform="translate(5.500000, 3.625000)" fill="#FFFFFF">
                      <path d="M3.75,0 L0,0 L0,1.25 L3.75,1.25 L3.75,3.75 L1.25,3.75 L1.25,5 L3.75,5 L3.75,7.5 L0,7.5 L0,8.75 L3.75,8.75 C4.44035594,8.75 5,8.19035594 5,7.5 L5,1.25 C5,0.559644063 4.44035594,0 3.75,0 Z" id="Fill"></path>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
            <svg class="toolbar-overflow-menu-filter-indicator toolbar-overflow-menu-filter-indicator-4" width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <title>4 filters apply</title>
              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g transform="translate(-1210.000000, -342.000000)">
                  <g transform="translate(1210.000000, 342.000000)">
                    <rect fill="#0056FF" x="0" y="0" width="16" height="16" rx="4"></rect>
                    <g transform="translate(5.500000, 3.625000)" fill="#FFFFFF">
                      <path d="M3.75,0.625 L3.75,5.625 L3.75,5.625 L3.75,0.625 M4.375,0 L3.125,0 L3.125,5 L1.25,5 L1.25,0 L0,0 L0,6.25 L3.125,6.25 L3.125,8.75 L4.375,8.75 L4.375,6.25 L5,6.25 L5,5 L4.375,5 L4.375,0 Z" id="Fill"></path>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
            <svg class="toolbar-overflow-menu-filter-indicator toolbar-overflow-menu-filter-indicator-5" width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <title>5 filters apply </title>
              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g transform="translate(-1250.000000, -342.000000)">
                  <g transform="translate(1250.000000, 342.000000)">
                    <rect fill="#0056FF" x="0" y="0" width="16" height="16" rx="4"></rect>
                    <g transform="translate(5.500000, 3.625000)" fill="#FFFFFF">
                      <path d="M3.75,8.75 L0,8.75 L0,7.5 L3.75,7.5 L3.75,5 L0,5 L0,0 L5,0 L5,1.25 L1.25,1.25 L1.25,3.75 L3.75,3.75 C4.44035594,3.75 5,4.30964406 5,5 L5,7.5 C5,8.19035594 4.44035594,8.75 3.75,8.75 Z" id="Fill"></path>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </cv-overflow-menu-item>
          <cv-overflow-menu-item
            v-bind:class="{
              'toolbar-overflow-menu-details-selected': showSidePanel,
            }"
            kind="ghost"
            @click="openSidePanel"
          >
            <cv-button
              tabindex="-1"
              kind="ghost"
              :icon="openPanelRightIcon"
            ></cv-button>
            {{ $t("toolbar-details-tooltip") }}
          </cv-overflow-menu-item>
          <cv-overflow-menu-item
            v-show="showCollapseHeader"
            kind="ghost"
            @click="onHidePageHeader"
          >
            <cv-button
              tabindex="-1"
              kind="ghost"
              :icon="collapseHeaderIcon"
            ></cv-button>
            {{ $t("toolbar-collapse-header-tooltip") }}
          </cv-overflow-menu-item>
          <cv-overflow-menu-item
            v-show="!showCollapseHeader"
            kind="ghost"
            @click="onShowPageHeader"
          >
            <cv-button
              tabindex="-1"
              kind="ghost"
              :icon="expandHeaderIcon"
            ></cv-button>
            {{ $t("toolbar-expand-header-tooltip") }}
          </cv-overflow-menu-item>
          <cv-overflow-menu-item
            danger
            v-show="!readonly"
            kind="ghost"
            @click="onResetCustomView"
          >
            <cv-button
              tabindex="-1"
              kind="ghost"
              :icon="resetCustomViewIcon"
            ></cv-button>
            {{ $t("toolbar-overflow-menu-reset-view") }}
          </cv-overflow-menu-item>
        </cv-overflow-menu>
      </div>
    </div>
  </section>
</template>

<script>
import { eventBus } from "../../main.js";
import Information16 from "@carbon/icons-vue/es/information/16";
import filterIcon from "@carbon/icons-vue/es/filter--edit/20";
import openPanelRightIcon from "@carbon/icons-vue/es/open-panel--right/20";
import collapseHeaderIcon from "@carbon/icons-vue/es/chevron--up/20";
import expandHeaderIcon from "@carbon/icons-vue/es/chevron--down/20";
import expandPartitionIcon from "@carbon/icons-vue/es/maximize/20";
import collapsePartitionIcon from "@carbon/icons-vue/es/minimize/20";
import resetCustomViewIcon from "@carbon/icons-vue/es/renew/20";
import columnIcon from "@carbon/icons-vue/es/column/20";
import lockedIcon from "@carbon/icons-vue/es/locked/16";
import SearchWithFilter from "../SearchWithFilter";
import apis from "../../lib/graph/graphAPIHandler";
import utils from "@/lib/graph/graphUtil";

export default {
  name: "Toolbar",
  components: {
    Information16,
    SearchWithFilter,
  },
  props: ["readonly", "graphViewKey"],
  data() {
    return {
      activeViewKey: 'micro_detail_partition_by_business_logic',
      filterIcon,
      openPanelRightIcon,
      collapseHeaderIcon,
      expandHeaderIcon,
      expandPartitionIcon,
      collapsePartitionIcon,
      resetCustomViewIcon,
      columnIcon,
      lockedIcon,
      initialSelected: 0,
      //isOpen: false,
      showFilterPanel: false,
      showSidePanel: false,
      showToolbarAction: true,
      showCollapseHeader: true,
      showExpandPartition: false,
      showOverflowMenu: false,
      showTableView: false,
      partitionSelected: false /* control the toolbar button to show expansion or collapse for selected partition */,
      selectedPartition: "",
      viewSelected: false,
      search: {
        id: "classSearchFilter",
        class: "class-search",
        label: this.$t("search-classes"),
        placeholder: this.$t("search-classes"),
        clearAriaLabel: this.$t("clear-search"),
        graphView: true,
      },
      lockPartition: {
        label: this.$t("toolbar-lock-partition"),
        value: "check",
        state: false,
        editOffSlot: this.$t("edit-class-off"),
        editOnSlot: this.$t("edit-class-on"),
      },
    };
  },
  mounted() {
    //this.setViewContentHeight();
  },
  created() {
    eventBus.$on("Toolbar.onGraphViewChange", (graphViewName) => {
      //console.log('--------- onGraphViewChange: graphViewKey is ' + this.graphViewKey);
      this.activeViewKey = graphViewName;
      if (this.graphViewKey === graphViewName) {
        this.setViewContentHeight();
        this.viewSelected = true;
        this.onGraphViewSelected();
        // hide and show the correct content with switcher
        if (this.initialSelected === 0) {
          document
            .querySelector('[class="cv-tab"][data-selected="true"]')
            .querySelector('button[data-target=".graph"]')
            .click();
        } else {
          document
            .querySelector('[class="cv-tab"][data-selected="true"]')
            .querySelector('button[data-target=".table-view-content"]')
            .click();
          this.showTableView = true;
        }
      } else {
        // need to reset the partition selected and details button
        this.viewSelected = false;
        eventBus.$emit("SidePanel.displayPanel", false);
        // changing view, if filter panel is showing, close it
        if (this.showFilterPanel === true) {
          this.openFilterPanel();
        }
      }
    });
    // This event may not be needed
    eventBus.$on("Toolbar.showToolbarAction", (showToolbarAction) => {
      if (this.viewSelected && !this.showToolbarAction) {
        this.showToolbarAction = showToolbarAction;
      }
    });
    // eventBus.$on("Toolbar.getNumberOfClasses", (numOfClasses) => {
    //   if (this.viewSelected) {
    //     this.numOfClasses = numOfClasses;
    //     let ptext = this.$t("search-classes-number", {
    //       numClasses: this.numOfClasses,
    //     });
    //     this.search.placeholder = ptext;
    //   }
    // });
    eventBus.$on("Toolbar.updateDetailsButton", (panelState) => {
      if (this.viewSelected) {
        //this.isOpen = panelState;
        this.showSidePanel = panelState;
      }
    });
    eventBus.$on("Toolbar.closeFilterPanel", () => {
      if (this.viewSelected) {
        this.showFilterPanel = false;
      }
    });
    eventBus.$on("Toolbar.closePanel", () => {
      if (this.viewSelected) {
        //this.isOpen = !this.isOpen;
        eventBus.$emit("SidePanel.displayPanel", false);
        this.showSidePanel = false;
      }
    });
    eventBus.$on("Toolbar.setViewContentHeight", () => {
      if (this.viewSelected) {
        this.$nextTick(() => {
          this.setViewContentHeight();
        });
      }
    });
    eventBus.$on("Toolbar.showMicroserviceToolbarButton", (partition) => {
      if (this.viewSelected) {
        const privateData = partition._private.data;
        if (privateData?.filepath == "Cluster") {
          this.selectedPartition = privateData.category;
          this.partitionSelected = true;
          this.showPartitionButton(partition);
        }
      }
    });
    eventBus.$on("Toolbar.hideMicroserviceToolbarButton", () => {
      if (this.viewSelected) {
        this.partitionSelected = false;
        this.selectedPartition = "";
      }
    });
    eventBus.$on("Toolbar.updateMicroserviceToolbarButton", (nodeEle) => {
      if (this.viewSelected) {
        this.showPartitionButton(nodeEle);
      }
    });
    eventBus.$on("Toolbar.updateLockPartition", (locked) => {
      if (this.viewSelected) {
        document
          .querySelector('[class="cv-tab"][data-selected="true"]')
          .querySelector(".toolbar-lock-partition .bx--toggle-input").checked =
          locked;
      }
    });
    // eventBus.$on("Toolbar.resetTable", (option) => {
    //   if (this.viewSelected) {
    //     eventBus.$emit("TableView.resetTable", option);
    //   }
    // });
    eventBus.$on("Toolbar.setFocus", (toolbarButtonId) => {
      if (this.viewSelected) {
        this.setFocus(toolbarButtonId);
      }
    });
    eventBus.$on("Toolbar.gotoGraph", (callbackEvent) => {
      // This event is called when a new json is loaded and the current view is Business logic view.
      // Before forcing the BL view to reload, switch back to graph tab first if in table view.
      // Otherwise the BL view loading is messed up.
      if (this.viewSelected) {
        document
          .querySelector('[class="cv-tab"][data-selected="true"]')
          .querySelector('button[data-target=".graph"]')
          .click();

        if (callbackEvent?.event?.name && callbackEvent?.event?.params) {
          this.$nextTick(function() {
            this.hideOpenedPanels('');
            eventBus.$emit(callbackEvent.event.name, callbackEvent.event.params);
          });
        }
      }
    });
    eventBus.$on('Toolbar.updateFilterIndicator', () => {
      this.updateFilterIndicator();
    });
    eventBus.$on('Toolbar.showGraphIfHidden', () => {
      if ( this.showTableView == true) {
        this.onSelected(".graph");
      }
    });
  },
  beforeDestroy() {
    eventBus.$off("Toolbar.onGraphViewChange");
    eventBus.$off("Toolbar.showToolbarAction");
    eventBus.$off("Toolbar.showGraphIfHidden");
    // eventBus.$off("Toolbar.getNumberOfClasses");
    eventBus.$off("Toolbar.updateDetailsButton");
    eventBus.$off("Toolbar.closeFilterPanel");
    eventBus.$off("Toolbar.closePanel");
    eventBus.$off("Toolbar.setViewContentHeight");
    eventBus.$off("Toolbar.showMicroserviceToolbarButton");
    eventBus.$off("Toolbar.hideMicroserviceToolbarButton");
    eventBus.$off("Toolbar.updateMicroserviceToolbarButton");
    eventBus.$off("Toolbar.updateLockPartition");
    // eventBus.$off("Toolbar.resetTable");
    eventBus.$off("Toolbar.setFocus");
    eventBus.$off("Toolbar.gotoGraph");
    eventBus.$off('Toolbar.updateFilterIndicator');
  },
  computed: {
    isSelected() {
      return (index) => this.initialSelected === index;
    },
  },
  methods: {
    hideOpenedPanels(panelToOpen) {
      if (panelToOpen !== "details") {
        if (this.showSidePanel) {
          this.openSidePanel();
        } else {
          // hide labels panels as they could be opened thru details panel
          eventBus.$emit("TableView.hideLabelsPanel");
        }
      }
      if (panelToOpen !== "filter") {
        if (this.showFilterPanel) {
          this.openFilterPanel();
        }
      }
    },
    onGraphViewSelected() {
      // reset toolbar button to its initial state
      this.partitionSelected = false;

      eventBus.$emit("GraphSummaryTopPanel.viewOnChange", this.graphViewKey);
    },
    onSelected(viewClass) {
      //console.log('view class is ' + viewClass + "; initialSelected is " + this.initialSelected);
      if (viewClass === ".graph" && this.initialSelected !== 0) {
        this.initialSelected = 0;
        this.showTableView = false;
        this.search.graphView = true;
        eventBus.$emit("TableView.hide");
        // close side panel for now until handling view transition is implemented
        //eventBus.$emit("SidePanel.displayPanel", false);
      } else if (
        viewClass === ".table-view-content" &&
        this.initialSelected !== 1
      ) {
        this.initialSelected = 1;
        this.showTableView = true;
        this.search.graphView = false;
        // do not close side panel for now until handling view transition is implemented
        // eventBus.$emit("SidePanel.displayPanel", false);
        // do not reset table view when switching from graph to table within the same view, 
        // instead just call TableView.show 
        //eventBus.$emit("TableView.resetTable", true);
        eventBus.$emit("TableView.show");
      }
    },
    onTooltipDismiss() {
      document
        .querySelector('[class="cv-tab"][data-selected="true"]')
        .querySelector(".toolbar-view-readonly")
        .querySelector("button.bx--tooltip__trigger")
        .click();
    },
    openFilterPanel() {
      this.hideOpenedPanels("filter");
      this.showFilterPanel = !this.showFilterPanel;
      eventBus.$emit("FilterPanel.showPanel", this.showFilterPanel);
    },    
    openSidePanel() {
      // if (e) {
      //   e.preventDefault();
      // }
      //this.isOpen = !this.isOpen;

      this.hideOpenedPanels("details");

      if (this.showSidePanel) {
        eventBus.$emit("SidePanel.displayPanel", false);
        eventBus.$emit("TableView.deselectRow");
        this.showSidePanel = false;
      } else {
        if (this.showTableView) {
          eventBus.$emit("TableView.clearOpenSidePanel", {
            event: {
              name: "SidePanel.displayPanel",
              params: true,
            },
          });
        } else {
          eventBus.$emit("SidePanel.displayPanel", true);
        }
        this.showSidePanel = true;
      }
    },
    onHidePageHeader() {
      this.showPageHeader(false);
      this.showCollapseHeader = false;
    },
    onShowPageHeader() {
      this.showPageHeader(true);
      this.showCollapseHeader = true;
    },
    showPageHeader(showPageHeader) {
      eventBus.$emit("PageHeader.showPageHeader", {
        showHeader: showPageHeader,
        events: [{ name: "Toolbar.setViewContentHeight", params: {} },
                { name: "GraphLegend.setLegendViewContentHeight", params: {status: showPageHeader} }],
      });
    },
    setViewContentHeight() {
      const content = document.getElementsByClassName("page-header-content");
      if (content && content.length > 0) {
        const pageHeaderHeight = `calc(${Math.ceil(
          content[0].getBoundingClientRect().height
        )}px)`;
        const availableHeight = `calc(100% - ${Math.ceil(
          content[0].getBoundingClientRect().height
        )}px)`;
        const cyElement = document.getElementById("cy");
        cyElement.style.height = availableHeight;

        // calculate side panel height
        const sidePanelElement = document.querySelector(".sidepanel");
        sidePanelElement.style.height = availableHeight;
        sidePanelElement.style.top = pageHeaderHeight;

        // calculate table height
        const tableViewElement = document.querySelector(".table-view-content");
        const mainContentElement = document.querySelector("#main-content");
        const mainContentHeight =
          mainContentElement?.getBoundingClientRect().height;
        const pageHeaderHeightOnly = content[0].getBoundingClientRect().height;
        tableViewElement.style.height =
          mainContentHeight - pageHeaderHeightOnly + "px";

        // adjust table data height
        eventBus.$emit("TableView.adjustTableDataHeight");
        // adjust table side panel height
        eventBus.$emit("TableView.adjustSidePanelHeight", {
          panelHeight: availableHeight,
          topHeight: pageHeaderHeight,
        });
      }
    },
    showPartitionButton(partition) {
      if (utils.isExpandedPartition(partition)) {
        this.showExpandPartition = false;
      } else {
        this.showExpandPartition = true;
      }
    },
    onExpandCollapsePartition() {
      // expand/collapse the partition
      apis.expandCollapsePartition(this.selectedPartition);
      this.showExpandPartition = !this.showExpandPartition;
    },
    onLockPartitionOverflowMenu() {
      //console.log('onlockpartitionOverflowMenu state is ' + this.lockPartition.state);
      //e.preventDefault();
      this.lockPartition.state = !this.lockPartition.state;
      this.onLockPartitionChange();
      // close the overflow menu
      this.$refs.overflowMenuRef.doClose();
    },
    onLockPartitionChange() {
      apis.activateDragAndDrop(!this.lockPartition.state);
    },
    onResetCustomView(e) {
      this.hideOpenedPanels("resetCustomView");
      eventBus.$emit("GraphSummaryTopPanel.resetCustomView", e);
    },
    onTableCustomization() {
      // The TableCustomization modal is located in a sibling element from that of page header content element which
      // has a relative postion and z-index css. This causes the modal overlay not able to apply to it. Hence, has to
      // unset the position temporarily. When the modal is closed, the position css is restored in TableCustomization
      // showTableCustomizationModal method.
      if (this.viewSelected) {
        this.hideOpenedPanels("tableCustomization");
        const pageHeaderContentElement = document.querySelector(".main-graph .page-header-content");
        if (pageHeaderContentElement) {
          pageHeaderContentElement.style.position = "unset";
        }
        eventBus.$emit("TableView.showTableCustomizationModal");
      }
    },
    setFocus(toolbarButtonId) {
      if (toolbarButtonId === "customizeColumn") {
        this.$refs.customizeColumnRef?.$el.focus();
      } else if (toolbarButtonId === "resetCustomView") {
        this.$refs.resetCustomViewRef?.$el.focus();
      }
      // To do for accessibility: add call to setFocus within toolbar and from other panels
    },
    updateFilterIndicator() {
      const filterAppliedCount = apis.getNumberOfAppliedFilters();
      // hide all indicators first
      document.querySelector("." + this.activeViewKey).querySelectorAll('.toolbar-filter-indicator').forEach(filterIndicator => {
        filterIndicator.style.opacity = 0;
      })
      document.querySelector("." + this.activeViewKey + "_overflow").querySelectorAll('.toolbar-overflow-menu-filter-indicator').forEach(filterIndicator => {
        filterIndicator.style.display = "none";
      })
      // show the one with the applied filter count
      if (filterAppliedCount > 0) {
        document.querySelector("." + this.activeViewKey).querySelector(`.toolbar-filter-indicator-${filterAppliedCount}`).style.opacity = 1;
        document.querySelector("." + this.activeViewKey + "_overflow").querySelector(`.toolbar-overflow-menu-filter-indicator-${filterAppliedCount}`).style.display = "inline-block";
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.toolbar {
  height: 3rem;
  background-color: var(--app-toolbar-background-color);

  &.bx--toolbar {
    margin: 0;
  }

  .toolbar-content {
    justify-content: flex-start;
    margin: 0; /* no margin */
  }

  .toolbar-view {
    margin-right: 1rem;
    width: 12.25rem; /* fixed width */

    .toolbar-view-switcher {
      margin: 0.5rem 0;
    }
  }

  .graph-toolbar-action {
    display: flex;
    justify-content: flex-end;
    flex: 1 0 auto;

    .toolbar-view-readonly {
      display: flex;
      align-items: center;
      margin-right: 1rem;

      .bx--tooltip__label {
        svg path {
          fill: #000000;
        }
      }
    }

    .class-search {
      width: 100%;
      max-width: 32rem;
      min-width: 20rem;
      display: block;
      position: relative;

      //margin-left: 2rem;
      // padding-left: 1rem;
      border-left: solid 1px var(--app-page-header-bottom-border-color);
      //border-right: solid 1px var(--app-page-header-bottom-border-color);

      @media (max-width: 40rem) {
        min-width: unset;
      }
    }

    .toolbar-non-overflow-action {
      display: flex;
      justify-content: flex-end;

      @media (max-width: 54rem) {
        display: none;
      }

      .bx--btn.bx--btn--icon-only {
        &.bx--btn--ghost:not(.toolbar-reset-custom-view), &.bx--btn--ghost.toolbar-reset-custom-view-border {
          border-left: solid 1px var(--app-page-header-bottom-border-color);
        }

        &.bx--btn--ghost {
          &:focus {
            border-color: var(
              --app-toolbar-selected-action-button-border-bottom-color
            ); // otherwise the left border color
          }

          svg {
            width: 1.25rem;
            height: 1.25rem;
          }
        }
      }

      .toolbar-lock-partition {
        display: flex;
        align-items: center;
        padding-left: 1rem;
        position: relative;
        margin-right: 2.25rem; /* to account for the on/off label */
        border-left: solid 1px var(--app-page-header-bottom-border-color);

        .toolbar-lock-partition-label {
          @include carbon--type-style("label-01");
        }
      }

      .toolbar-reset-custom-view {
        &:hover{
          background-color: var(
            --app-toolbar-reset-custom-view-background-hover-color
          );
        }
      }


      .toolbar-filter-indicator-overlay {
        position: relative;

        .toolbar-filter-indicator {
          position: absolute;
          top: -2.875rem; // 46px
          right: 0.125rem; // for 2px
          opacity: 0;
        }
      }
    }

    .toolbar-details-selected {
      border-bottom: solid 2px
        var(--app-toolbar-selected-action-button-border-bottom-color);
      background-color: var(
        --app-toolbar-selected-action-button-background-color
      );
    }

    .toolbar-overflow-menu {
      display: none;
      height: 3rem;
      width: 3rem;
      border-left: solid 1px var(--app-page-header-bottom-border-color);

      @media (max-width: 54rem) {
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}

.bx--tooltip__content {
  .bx--link {
    margin-top: 1.5rem;
  }

  .bx--btn--primary {
    margin-top: 1rem;
    padding-right: 4rem;
    min-height: unset;
    height: 2rem;
    float: right;
  }
}

.bx--overflow-menu-options {
  .toolbar-overflow-menu-lock-partition {
    height: 4rem;

    // .bx--overflow-menu-options__btn {
    //   pointer-events: none;  /* don't activate when clicking outside the toggle button */
    // }

    .toolbar-overflow-menu-lock-partition-toggle {
      margin-left: 1.5rem;
      margin-bottom: 0.2rem; /* to account for focus outline when using tab to navigate */
      margin-top: -0.2rem;
      pointer-events: auto; /* activate clicking only within the toggle button */
    }
  }

  .toolbar-overflow-menu-details-selected {
    border-left: solid 2px
      var(--app-toolbar-selected-action-button-border-bottom-color);
    background-color: var(
      --app-toolbar-selected-action-button-background-color
    );
  }

  .bx--overflow-menu-options__btn .bx--btn--ghost {
    min-height: 1rem;
    padding: 0;
    color: var(
      --app-toolbar-overflow-menu-icon-color
    ); /* override the blue color for button */
    padding-right: 0.25rem;
  }
}
</style>

<style lang="scss">
.graph-toolbar-action {
  .toolbar-view-readonly {
    .bx--tooltip__label {
      color: var(--app-toolbar-readonly-tooltip-label-color); /* override the default label color */
    }
  }

  .class-search {
    .bx--search-close {
      display: flex;

      svg {
        top: unset; /* remove top to center the search close button vertically */
      }
    }
  }
}

.toolbar-non-overflow-action {
  .bx--btn.bx--btn--icon-only.bx--btn--ghost svg {
    width: 1.25rem;
    height: 1.25rem;
  }

  .toolbar-lock-partition {
    .bx--toggle-input__label {
      padding-left: 1rem;

      .bx--toggle__switch {
        margin-top: 0;
      }
    }

    .bx--toggle__text--off,
    .bx--toggle__text--on {
      margin-right: 0.5rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 28px  
    }
  }

  .toolbar-reset-custom-view.bx--btn.bx--btn--icon-only.bx--tooltip__trigger:hover
    svg
    path{
    fill: var(--app-toolbar-reset-custom-view-hover-svg) !important;
  }
}

.toolbar-overflow-menu.bx--overflow-menu .bx--overflow-menu__trigger {
  width: 3rem;
  height: 3rem;

  &.bx--overflow-menu--open {
    background-color: var(--app-toolbar-overflow-menu-open-background-color);
  }

  // &:focus {
  //   border-color: transparent;
  //   box-shadow: none;
  //   background-color: var(--app-toolbar-action-button-focus-background-color);
  // }

  svg {
    width: 1.25rem;
    height: 1.25rem;
  }
}

.bx--overflow-menu-options {
  min-width: 13rem; /* override the default 10rem width to show all the menu items in full without truncation for en locale */

  .toolbar-overflow-menu-lock-partition {
    .bx--overflow-menu-options__btn {
      pointer-events: none;
    }
  }

  .bx--overflow-menu-options__option {
    height: 2rem;
    border-top: 1px solid var(--app-toolbar-overflow-menu-option-border-top-color);

    .bx--overflow-menu-options__btn {
      max-width: 14.5rem; /* override the default max-width of 11.5rem */

      .bx--btn--ghost svg {
        margin-left: 0;
      }
    }
  }

  .bx--overflow-menu-options__option--danger {
    .bx--btn--ghost:hover {
      background-color: transparent; /* don't show the default gray hover color */
    }
    .bx--overflow-menu-options__btn:hover svg path,
    .bx--overflow-menu-options__btn:focus svg path {
      fill: var(--app-toolbar-reset-custom-view-hover-svg) !important;
    }
  }

  .toolbar-overflow-menu-filter {
    .bx--overflow-menu-options__option-content {
      display: inline-flex;
      align-items: center;
    }
  }
}
</style>
