/*
# *****************************************************************
#
# Licensed Materials - Property of IBM
#
# (C) Copyright IBM Corp. 2019, 2020, 2021, 2022. All Rights Reserved.
#
# US Government Users Restricted Rights - Use, duplication or
# disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
#
# *****************************************************************
*/
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import cytoscape from "cytoscape";
import fcose from "cytoscape-fcose";
import popper from "cytoscape-popper";
//import cola from 'cytoscape-cola';

import compoundDragAndDrop from "cytoscape-compound-drag-and-drop";
import expandCollapse from "cytoscape-expand-collapse";
import $ from "jquery";
import contextMenus from "cytoscape-context-menus";
import navigator from "cytoscape-navigator";

import CarbonComponentsVue from "@carbon/vue";
import i18n from "@/plugins/i18n";
import store from "./store.js";
export const eventBus = new Vue();
Vue.use(CarbonComponentsVue);

Vue.config.productionTip = false;
cytoscape.use(fcose);
cytoscape.use(popper);
cytoscape.use(expandCollapse);
cytoscape.use(compoundDragAndDrop);
cytoscape.use(contextMenus, $);

//cytoscape.use( cola );
cytoscape.use(navigator);

Vue.prototype.$cytoscape = cytoscape;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
