/*
# *****************************************************************
#
# Licensed Materials - Property of IBM
#
# (C) Copyright IBM Corp. 2019, 2020, 2021, 2022. All Rights Reserved.
#
# US Government Users Restricted Rights - Use, duplication or
# disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
#
# *****************************************************************
*/
import Vue from "vue";
import Router from "vue-router";
import GraphPage from "./views/GraphPage";
import AboutPage from "./views/AboutPage";
import DashboardPage from "./views/DashboardPage";


Vue.use(Router);
export default new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "dashboard-page",
      component: DashboardPage
    },
    {
      path: "/graph",
      name: "graph-page",
      component: GraphPage
    },
    {
      path: "/about",
      name: "about",
      component: AboutPage
    },
  ]
});
