<!--
# *****************************************************************
#
# Licensed Materials - Property of IBM
#
# (C) Copyright IBM Corp. 2019, 2020, 2021, 2022, 2023. All Rights Reserved.
#
# US Government Users Restricted Rights - Use, duplication or
# disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
#
# *****************************************************************
-->
<template>
  <div>
    <top-nav></top-nav>
    <!-- <side-nav></side-nav> -->
    <div id="main-content" class="dashboard">
      <div class="dashboard-heading">
        <div class="logo-title">{{ $t("landing-welcome-title") }}</div>
        <div class="sub-heading-1">{{ $t("landing-welcome-desc") }}</div>
      </div>
      <div class="dashboard-upload">
        <div class="sub-title">
          {{ $t("landing-upload-title") }}
        </div>
        <i18n path="upload-desc" tag="p" class="sub-description">
          <a :href="url" target="_blank" rel="noopener noreferrer">{{ $t('upload-desc-link-text') }}</a>
        </i18n>
        <file-uploader :fileUploader="fileUploader"></file-uploader>
      </div>
    </div>
  </div>
</template>

<script>
import TopNav from "../../components/TopNav";
import FileUploader from "../../components/FileUploader";
import messageUtils from "@/js/messageUtils";

export default {
  name: "DashboardPage",
  components: { TopNav, FileUploader },
  data: () => ({
    fileUploader: {
      uploadText: "upload-text",
      ref: "dashboardFileUploader"
    },
    url: "https://www.ibm.com/docs/mono2micro"
  }),
  // mounted() {
  //   // ****** for an accessibility issue
  //   const fbBtnLabel = document.querySelector("label.bx--file-browse-btn");
  //   if (fbBtnLabel) {
  //     fbBtnLabel.classList.remove("bx--file-browse-btn");
  //     fbBtnLabel.removeAttribute('tabindex');
  //     const container = document.querySelector("div.bx--file__drop-container"); 
  //     if (container) {
  //       container.classList.add("bx--file-browse-btn");
  //       container.tabIndex = 0;
  //       container.setAttribute("role", "button");
  //     }
  //   }
  //   // ****** end. remove it once carbon-view is upgraded
  // },
  methods: {
    getMessage(msgKey) {
      return messageUtils.getMessage(msgKey);
    }
  }
};
</script>

<style lang="scss" scoped>
// @import "../../assets/css/dashboard.scss";
a:hover {
  color: #0043CE;
}
</style>
