<!--
# *****************************************************************
#
# Licensed Materials - Property of IBM
#
# (C) Copyright IBM Corp. 2020, 2021, 2022, 2023. All Rights Reserved.
#
# US Government Users Restricted Rights - Use, duplication or
# disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
#
# *****************************************************************
-->
<template>
  <div class="multi-select-dropdown-field">
    <cv-multi-select
      :label="dropdown.placeholder"
      :inline="false"
      :title="dropdown.title"
      :selection-feedback="dropdown.selectionFeedback"
      :options="dropdown.options"
      :filterable="true"
      :auto-filter="true"
      :auto-highlight="true"
      :value="dropdown.initialValue"
      @change="onChange"
      ref="ms"
    ></cv-multi-select>
  </div>
</template>

<script>
import { CvMultiSelect } from "@carbon/vue";
import { eventBus } from "../../main.js";

function debounce(fn, delay) {
  let timeoutID = null;
  return function() {
    clearTimeout(timeoutID);
    var args = arguments;
    var that = this;
    timeoutID = setTimeout(function() {
      fn.apply(that, args);
    }, delay);
  };
}

export default {
  name: "MultiSelectDropDownField",
  components: { CvMultiSelect },
  props: ["dropdown"],
  watch: {
    "dropdown.selectedOption": {
      handler: function(val) {
        this.resetToPlaceholder(!(val.length > 0));
      },
    },
  },  
  methods: {
    handleResize() {
      /*
      let listBoxSpanElement = document.querySelector(
        ".multi-select-dropdown-field .bx--list-box__label"
      );
      let listBoxFieldElement = document.querySelector(
        ".multi-select-dropdown-field .bx--list-box__field"
      );
      if (listBoxFieldElement) {
        const offsetWidth = listBoxFieldElement.offsetWidth;
        if (this.dropdown.selectedOption.length > 0) {
          listBoxSpanElement.style.width = offsetWidth - 120 + "px";
        } else {
          listBoxSpanElement.style.width = offsetWidth - 50 + "px";
        }
      }
      */
    },
    resetToPlaceholder(shouldReset) {
      let selectedNum = shouldReset ? this.dropdown.options.length : 0;
      const messageKey = shouldReset ? this.dropdown.placeHolderKeys[0] : this.dropdown.placeHolderKeys[1];

      // if (this.dropdown.clusterColorMap && shouldReset) {
      //   selectedNum = Object.values(this.dropdown.clusterColorMap).length;
      // }
      // if (this.dropdown.id === "usecasesDropdown" && shouldReset) {
      //   selectedNum = this.dropdown.options.length;
      // }

      this.$nextTick(function () {
        const input = document.querySelector(`#${this.dropdown.id} .bx--list-box__field .bx--text-input`);
        if (input) {
          input.placeholder = this.$t(messageKey, {0: selectedNum});
          input.title = input.placeholder;
          if (shouldReset) {
            input.classList.remove("white-placeholder");
          } else {
            input.classList.add("white-placeholder");
          }
        }
      });
    },
    onChange(keys) {
      // if (keys.length > 0) {
      //   this.resetToPlaceholder(false);
      // } else {
      //   this.resetToPlaceholder(true);
      // }

      // Latest carbon vue won't allow changing a prop passed in.
      // if (this.dropdown.selectedOption !== keys) {
      //   this.dropdown.selectedOption = keys;
      // }

      this.handleResize();
      this.updateCheckboxColorInMultiSelect();

      if (this.dropdown.onChangeEvent) {
        eventBus.$emit(this.dropdown.onChangeEvent, keys)
      }
    },
    updateCheckboxColorInMultiSelect() {
      // keep the placeholder text for now until US design comes back with a proposal
      // if (this.dropdown.selectedOption.length > 0) {
      //   // replace the place holder with the checked labels
      //   let listBoxLabelElement = document.querySelector(
      //     ".multi-select-dropdown-field .bx--list-box__label"
      //   );
      //   listBoxLabelElement.textContent = this.dropdown.selectedOption.toString();
      //   listBoxLabelElement.title = this.dropdown.selectedOption.toString();
      // } else {
      //   this.resetToPlaceholder();
      // }

      // if (this.dropdown.selectedOption.length === 0) {
      //   this.resetToPlaceholder(true);
      // } else {
      //     this.resetToPlaceholder(false);
      // }

      // update the checkbox with the matching partition color      
      if (this.dropdown.clusterColorMap) {
        const allCheckboxLabels = document.querySelectorAll(`#${this.dropdown.id} .bx--checkbox-label`);
        this.dropdown.options.forEach(option => {
          for (let i = 0; i < allCheckboxLabels.length; i++) {
            let checkboxLabel = allCheckboxLabels[i];
            let checkboxLabelSpan = checkboxLabel.querySelector("span");
            checkboxLabelSpan.classList.add("multi-select-partition-color");
            let checkboxText = checkboxLabel.querySelector("span").textContent.trim();
            if (checkboxText === option.label.trim()) {
              checkboxLabelSpan.style.setProperty("--multiSelectPartitionColor", this.dropdown.clusterColorMap[checkboxText]);
              return;
            }
          }
        });
      }
    },
    onDropdownContentChange(mutationsList) {
      if (mutationsList && mutationsList.length > 0) {
        this.updateCheckboxColorInMultiSelect();
      }
    },
    clearDropdownFilter() {
      if (this.$refs.ms.filterable === true && this.$refs.ms.filter.length > 0) {
        // do not call 'clearFilter()', it will set focus and open the dropdown area
        this.$refs.ms.filter = '';
        this.$refs.ms.updateOptions();
      }
    },
    addTooltipToDropdown() {
      document.querySelectorAll(`#${this.dropdown.id} .bx--checkbox-wrapper`).forEach(div => {
        div.style.pointerEvents = 'auto'; // need this so that the tooltip will show up
        // initialize tooltips
        div.querySelectorAll('.bx--checkbox-label-text').forEach(label => label.title = label.innerHTML.trim());
      });
    },
  },
  data: () => ({
    observer: null,
  }),
  mounted() {
    // fix accessibility issue ---
    const listBox = document.querySelector(`#${this.dropdown.id} .bx--list-box__field`);
    if (listBox) {
      listBox.setAttribute('role', 'combobox');
      listBox.removeAttribute('aria-haspopup');
    }
    // ---

    // update the filter checkbox
    this.updateCheckboxColorInMultiSelect();
    // listen to resize event to change the multi-select box size
    // use debounce to limit the firing of the callback
    window.addEventListener("resize", debounce(this.handleResize, 250));

    eventBus.$on("MultiSelectDropDownField.resetToPlaceholder", () => {
        this.resetToPlaceholder(true);
      }
    );
    this.resetToPlaceholder(true);

    // make sure the colour ring updates when the auto filtering happens
    if (this.dropdown.clusterColorMap) {
      // document.querySelector(`#${this.dropdown.id} .bx--text-input`).addEventListener('input',
      //   () => requestAnimationFrame(this.updateCheckboxColorInMultiSelect));

      // make sure when the clear button is clicked, the colour ring also updates
      // const node = document.querySelector(`#${this.dropdown.id} .bx--list-box__field`)
      // const updateColorRing = this.updateCheckboxColorInMultiSelect.bind(this);
      // const callback = function(mutationsList) {
      //   mutationsList.forEach(mutation => {
      //     if (mutation.addedNodes.length > 0 && mutation.addedNodes[0].attributes) {
      //       mutation.addedNodes[0].addEventListener('click', updateColorRing)
      //     }
      //   })
      // }
      // const observer = new MutationObserver(callback)
      // observer.observe(node, {childList: true})

      // monitor the dropdown area (add/remove items)
      const node = document.querySelector(`#${this.dropdown.id} .bx--list-box__menu`);
      if (node) {
        if (this.observer) {
          this.observer.disconnect();
        }
        this.observer = new MutationObserver(this.onDropdownContentChange);
        this.observer.observe(node, { childList: true });
      }
    }
    // Latest carbon vue won't allow changing a prop passed in. However, this doesn't seem
    // to be necessary as dropdown.selectedOption should initially setup thru GraphSummaryTopPanel.showPanel.
    //this.dropdown.selectedOption = this.$refs.ms.dataValue;
  },
  updated() {
    this.updateCheckboxColorInMultiSelect();
    this.addTooltipToDropdown();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
    eventBus.$off("MultiSelectDropDownField.resetToPlaceholder");
    if (this.observer) {
      this.observer.disconnect();
    }
  }
};
</script>

<style scoped>
/* #microservicesDropdown .bx--list-box__menu-item {
  height: 34px;
}

.multi-select-dropdown-field .cv-tag {
  height: 24px;
  margin-left: 0px;
} */
/* to be changed once all nav components width and height are the same as the redlines */
/* .multi-select-dropdown-field .bx--multi-select {
  height: 34px;
} */

  .multi-select-dropdown-field .bx--multi-select__wrapper {
    min-width: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

/* .multi-select-dropdown-field .bx--list-box__menu-item__option .bx--checkbox-wrapper {
  margin-top: 0;
} */

/* .multi-select-dropdown-field .bx--list-box__menu-item--highlighted {
  background-color: #606060;
} */

  .multi-select-dropdown-field .bx--text-input {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

</style>

<style lang="scss">

  .multi-select-partition-color::before {
    content: '';
    height: $spacing-05;
    width: $spacing-05;
    border-radius: 50%;
    border-color: var(--multiSelectPartitionColor);
    background: var(--multiSelectPartitionColor);
    margin-right: 0.1875rem;
    margin-top: -0.1875rem;
    margin-left: -0.375rem;
    display: inline-flex;
    vertical-align: middle;
  }

</style>