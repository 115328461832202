<!--
# *****************************************************************
#
# Licensed Materials - Property of IBM
#
# (C) Copyright IBM Corp. 2019, 2020, 2021, 2022, 2023. All Rights Reserved.
#
# US Government Users Restricted Rights - Use, duplication or
# disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
#
# *****************************************************************
-->
<template>
  <div id="app" role="main">
    <router-view />
  </div>
</template>

<script>
import i18n from "@/plugins/i18n";
// import apis from "./lib/graph/graphAPIHandler";
import { eventBus } from "./main.js";

export default {
  name: "App",
  data() {
    return {
      windowWidth: 0,
    };
  },
  created() {
    document.title = "IBM Mono2Micro";

    var link =
      document.querySelector("link[rel*='icon']") ||
      document.createElement("link");
    link.type = "image/x-icon";
    link.rel = "shortcut icon";
    link.href = "http://www.ibm.com/favicon.ico";
    document.getElementsByTagName("head")[0].appendChild(link);

    const styles = require("./styles/app.scss");
    this.$store.commit(
      "setThemeInfo",
      styles.is_dark_theme === "true" ? true : false
    );

    // create walkme lang function
    let systemLanguage = undefined;

    window.walkme_get_language = function () {
      if (systemLanguage === undefined) {
        var langCode = navigator.language || navigator.systemLanguage;
        var lan = "";
        if (langCode) {
          var lang = langCode.toLowerCase();
          if (lang === "zh_cn" || lang === "zh-cn") {
            lan = "zh-CN";
          } else if (lang === "zh_tw" || lang === "zh-tw") {
            lan = "zh-TW";
          } else if (lang === "zh_hk" || lang === "zh-hk") {
            lan = "zh-CN";
          } else if (lang === "zh") {
            lan = "zh-CN";
          } else {
            lang = lang.substr(0, 2);
            if (lang === "en") {
              lan = "";
            } else if (lang === "fr") {
              lan = "fr";
            } else if (lang === "es") {
              lan = "es";
            } else if (lang === "de") {
              lan = "de";
            } else if (lang === "it") {
              lan = "it";
            } else if (lang === "ja") {
              lan = "ja";
            } else if (lang === "ko") {
              lan = "ko";
            } else if (lang === "pt") {
              lan = "pt";
            } else {
              lan = "";
            }
          }
        }
        systemLanguage = lan;
        return systemLanguage;
      } else {
        return systemLanguage;
      }
    };

    this.handleResize = this.debounce(function () {
      // Take care of width resize to
      // - determin body element overflow css. Page header and table have
      // a min-width of 672px. If browser width is smaller than that, enable horizontal
      // scrolling.
      // - refresh the label display in table view
      if (this.windowWidth !== window.innerWidth) {
        this.windowWidth = window.innerWidth;
        let bodyEl = document.getElementsByTagName("body")[0];
        if (window.innerWidth <= 672) {
          bodyEl.style.overflowY = "hidden";
          bodyEl.style.overflowX = "scroll";
        } else {
          bodyEl.style.overflow = "hidden";
        }
        eventBus.$emit("TableView.adjustDisplayedLabels");
      }

      // check whether the resize affects the height
      // Note: since page header could be reflown because of browser width change, has to do
      // calculation to figure out whether height adjustment is necessary. Could not simply
      // use window.innerHeight to determine.
      const pageHeaderHeight = document
        .getElementsByClassName("page-header-content")[0]
        ?.getBoundingClientRect().height;
      const mainContentHeight = document
        .querySelector("#main-content")
        ?.getBoundingClientRect().height;
      const tableViewHeight = document.querySelector(".table-view-content")
        ?.style.height;
      // console.log("----- pageHeaderHeight: " + pageHeaderHeight + "; mainContentHeight: " + mainContentHeight + "; table height: " + tableViewHeight);

      if (tableViewHeight !== mainContentHeight - pageHeaderHeight + "px") {
        this.windowHeight = window.innerHeight;
        eventBus.$emit("Toolbar.setViewContentHeight");
      }
    });
  },
  mounted() {
    this.windowWidth = window.innerWidth;

    // Set the documents's lang code
    document.documentElement.setAttribute("lang", i18n.locale);
    // document.documentElement.setAttribute("theme", 'light');
    // document.addEventListener('mouseup', this.docmentOnClick);

    // listen to resize window event
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    // document.removeEventListener('mouseup', this.docmentOnClick)
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    // docmentOnClick: function () {
    //   // add other clear up calls here
    //   apis.clearCurrentCY();
    // }

    debounce: function (func, timeout = 100) {
      let timer;
      return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
          func.apply(this, args);
        }, timeout);
      };
    },
  },
};
</script>

<style lang="scss">
.bx--btn:disabled, .bx--btn:hover:disabled, .bx--btn:focus:disabled, .bx--btn.bx--btn--disabled, .bx--btn.bx--btn--disabled:hover, .bx--btn.bx--btn--disabled:focus {
    color: #4c4c4c !important;
}
.bx--btn--tertiary:disabled, .bx--btn--tertiary:hover:disabled, .bx--btn--tertiary:focus:disabled, .bx--btn--tertiary.bx--btn--disabled, .bx--btn--tertiary.bx--btn--disabled:hover, .bx--btn--tertiary.bx--btn--disabled:focus {
    color: #4c4c4c !important;
}
</style>
