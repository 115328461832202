<!--
# *****************************************************************
#
# Licensed Materials - Property of IBM
#
# (C) Copyright IBM Corp. 2019, 2020, 2021, 2022. All Rights Reserved.
#
# US Government Users Restricted Rights - Use, duplication or
# disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
#
# *****************************************************************
-->
<template>
  <div style="height: calc(100% - 47px);">
    <cv-tile v-show="loadPanel && showPanel" id="sidepanel" class="sidepanel">
      <div class="sidepanel-content" v-bind:class="{'sidepanel-content-path-opended': classView && pathViewOpen, 'no-path-component' : !classView }">
      <div id="sidepanel-top-container">
        <div>
        <div v-show="editPartitionView == false" class="sidepanel-top-container-header-text">{{sidePanelHeaderText}}</div>
        <!--h2 v-show="(classView || microserviceView) && !editPartitionView" class="side-panel-heading" v-bind:class="{'non-refactoring-heading': classView}">
          <div id="microservice-icon" v-bind:style='{background: partitionColor}' v-show="microserviceView && !classView"></div>
          <div id="class-icon"  v-bind:style='{background: partitionColor}' v-show="classView"></div>
          {{ microserviceName }}
          <cv-icon-button v-show="microserviceView && !classView && custom_view_active && !isUnObserved" kind="ghost" class="edit-partition-btn" :icon="edit_icon" :label="$t('rename-button-aria')" tip-position="bottom" tip-alignment="center" @click="editPartition" />
        </h2-->
        <cv-button aria-label="Close" class="close-panel-button edit-partition-mode-btn edit-partition-mode-cancel-btn" kind="ghost" @click="closeSidePanel">
          <svg focusable="false" preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg" fill="currentColor" width="20" height="20" viewBox="0 0 32 32" aria-hidden="true" style="position: static;"><path d="M24 9.4L22.6 8 16 14.6 9.4 8 8 9.4 14.6 16 8 22.6 9.4 24 16 17.4 22.6 24 24 22.6 17.4 16 24 9.4z"></path></svg>
        </cv-button>
        <!--h2 v-show="!microserviceView && !classView" class="side-panel-heading">{{ $t("structuralOverview") }}</h2>
        <p v-show="!microserviceView && !classView" id="class-num">{{ $t("captured-classes", {numOfClasses: numOfClasses}) }}</p>
        <cv-tooltip v-show="!microserviceView && !classView" alignment="center" direction="bottom" :tip="$t('totalClassesTooltip')" id="total-classes-tooltip">
          <info-icon />
        </cv-tooltip-->
        </div>
        <div  v-show="editPartitionView == false" class="sidepanel-top-container-header-title-container">
        <h2 :title="microserviceName">
        {{microserviceName}}
        </h2>
        <cv-icon-button aria-label="Edit partition" v-show="microserviceView && !classView && custom_view_active && !isUnObserved" kind="ghost" class="edit-partition-btn" :icon="edit_icon" :label="$t('rename-button-aria')" tip-position="bottom" tip-alignment="center" @click="editPartition" />
        </div>
        <div v-show="editPartitionView == true" class="edit-partition-panel">
          <cv-text-input  ref="edit-patition-input" v-model="microserviceName" v-show="microserviceView && editPartitionView" class="edit-partition-input" :invalid-message="rename_invalid_msg" v-on:keyup.enter="editPartitionName" v-on:keyup.esc="closeEditPartitionMode" v-on:blur="closeEditPartitionMode">
          </cv-text-input>
          
        </div>
        <div v-show="editPartitionView" class="edition-partition-input-helper-text">
          {{$t("edit-partition-name-help-text")}}</div>
        <h3 v-if="overallDescription" title="Overall description" v-show="editPartitionView == false">{{overallDescription}}</h3>
        <div v-if="!overallDescription" class="spacer"></div>
        
        <!--cv-button v-if="microserviceView && !classView && showClasses" kind="" class="side-panel-top-btn" :icon="add_icon" @click="showHideClasses">{{ $t("show-classes") }}</cv-button>
        <cv-button v-if="microserviceView && !classView && !showClasses" kind="" class="side-panel-top-btn" :icon="collapse_icon" @click="showHideClasses">{{ $t("hide-classes") }}</cv-button -->
      </div>
      <div id="sidepanel-bottom-container">
        <div class="compare-views">

        <div v-show="classView" class="class-view-key-details">

          <h2>
            {{$t("sidepanel-top-container-class-details-key-details")}}
          </h2>
          <div class="item-title">
            {{$t("sidepanel-top-container-class-details-key-details-partition")}}
          </div>
          <div class="item-detail partition-detail">
            <span v-show="selectedClassPreviousPartition" class="partition-name">{{selectedClassPreviousPartition}}</span>
            <svg v-show="selectedClassPreviousPartition" focusable="false" preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg" fill="currentColor" width="16" height="16"  viewBox="0 0 32 32" aria-hidden="true" class="bx--btn__icon">
              <polygon points="18 6 16.57 7.393 24.15 15 4 15 4 17 24.15 17 16.57 24.573 18 26 28 16 18 6"/>
              <rect data-name="&lt;Transparent Rectangle&gt;" style="fill: none;" width="32" height="32"/>
            </svg>

            <span class="partition-name">{{selectedClassCurrentPartition}}</span>
          </div>
          <div v-show="selectedClassOriginalPartition" class="item-title">
            {{$t("sidepanel-top-container-class-details-key-details-original-partition")}}
          </div>
          <div v-show="selectedClassOriginalPartition" class="item-detail partition-detail">
            <span class="partition-name">{{selectedClassOriginalPartition}}</span>
          </div>

          <div class="item-title">
             {{$t("sidepanel-top-container-class-details-key-details-path")}}
          </div>
          <div class="item-detail">
            <cv-code-snippet feedbackAriaLabel="Copied!" ariaLabel="Full class path">{{selectedClassPath}}</cv-code-snippet>
          </div>
          <div class="item-title">
            {{$t("sidepanel-top-container-class-details-key-details-labels")}}
          </div>
          <div class="item-detail class-detail-labels" :class="selectedClassLabels.length>0? '' : 'class-detail-labels-no-tags'">
            
              <cv-tag
                size="sm"
                kind="blue"
                v-for="(label, index) in selectedClassLabels"
                :key="index"
                :label="label"
              />
              <a href="#" @click="launchEditLabels"><span v-show="selectedClassLabels.length==0">{{$t("side-panel-bottom-container-edit-label-text")}}</span>
              <cv-button kind="ghost" aria-label="Edit partition" class="edit-partition-btn edit-partition-btn-details-panel" :icon="edit_icon" /></a>
          </div>
        </div>
          <hr v-show="classView" class="hr1"/>

          <h2>
            {{$t("side-panel-top-container-text-compare-view")}}
          </h2>
          <h3>
            {{$t("side-panel-top-container-text-data-type")}}
          </h3>
          <div>
            <cv-radio-group aria-label="Compare view group" role="radiogroup" ref="radio_compare_view">
              <cv-radio-button name="radio_compare_view_group" value="runtimeCalls" @change="radioCompareViewOnChange" :label="$t('side-panel-top-container-radio-runtime-calls')" v-model="radio_compare_view"></cv-radio-button>
              <cv-radio-button name="radio_compare_view_group" value="containmentRelationships" @change="radioCompareViewOnChange" :label="$t('side-panel-top-container-radio-containment-relationships')" v-model="radio_compare_view"></cv-radio-button>
            </cv-radio-group> 
          </div>
          
          <div class="unique-calls" v-html='compareViewUniqueCallsLabel'>
            
          </div>
          <div class="total-call-volume" v-html='compareViewRuntimeCallsLabel'>
            
          </div>
        </div>
        <div>
          <ComparsionBar class="compare-view-bar" 
            v-show="business_logic_view || false == ( microserviceView && !classView  )"
            :key="radio_compare_view + 'BL'+blBarData.key"
            :viewName="$t('businessLogic')" 
            :type="radio_compare_view"

            :internalDeps="blBarData.internalDeps"
            :crossDeps="blBarData.crossDeps"
            :interalCalls="blBarData.interalCalls"
            :uniqueRuntimeCalls="blBarData.uniqueRuntimeCalls"
            :internalUniqueRuntimeCalls="blBarData.internalUniqueRuntimeCalls"
            :crossUniqueRuntimeCalls="blBarData.crossUniqueRuntimeCalls"
            :callsType="blBarData.callsType"
            :internalCallsTo="blBarData.internalCallsTo"
            :internalCallsFrom="blBarData.internalCallsFrom"
            :crossCallsTo="blBarData.crossCallsTo"
            :crossCallsFrom="blBarData.crossCallsFrom"
            :crossCalls="blBarData.crossCalls"

            :hasView="true" :isActive="business_logic_view">
          </ComparsionBar>
        </div>
        <div>
          <ComparsionBar class="compare-view-bar"
            v-show="natural_seam_view || false == ( microserviceView && !classView  )"
            :key="radio_compare_view + 'NS'+nsBarData.key"
            :viewName="$t('naturalSeams')" 

            :type="radio_compare_view"
            :internalDeps="nsBarData.internalDeps"
            :crossDeps="nsBarData.crossDeps"
            :interalCalls="nsBarData.interalCalls"
            :uniqueRuntimeCalls="nsBarData.uniqueRuntimeCalls"
            :internalUniqueRuntimeCalls="nsBarData.internalUniqueRuntimeCalls"
            :crossUniqueRuntimeCalls="nsBarData.crossUniqueRuntimeCalls"
            :callsType="nsBarData.callsType"
            :internalCallsTo="nsBarData.internalCallsTo"
            :internalCallsFrom="nsBarData.internalCallsFrom"
            :crossCallsTo="nsBarData.crossCallsTo"
            :crossCallsFrom="nsBarData.crossCallsFrom"
            :crossCalls="nsBarData.crossCalls"
            
            :hasView="true" :isActive="natural_seam_view">
          </ComparsionBar>
        </div>
        <div>
          <ComparsionBar class="compare-view-bar" 
            v-show="custom_view_active || false == ( microserviceView && !classView  )"
            :key="radio_compare_view + 'CV'+cvBarData.key"
            :viewName="$t('customView')" 
            :type="radio_compare_view"

            :internalDeps="cvBarData.internalDeps"
            :crossDeps="cvBarData.crossDeps"
            :interalCalls="cvBarData.interalCalls"
            :uniqueRuntimeCalls="cvBarData.uniqueRuntimeCalls"
            :internalUniqueRuntimeCalls="cvBarData.internalUniqueRuntimeCalls"
            :crossUniqueRuntimeCalls="cvBarData.crossUniqueRuntimeCalls"
            :callsType="cvBarData.callsType"
            :internalCallsTo="cvBarData.internalCallsTo"
            :internalCallsFrom="cvBarData.internalCallsFrom"
            :crossCallsTo="cvBarData.crossCallsTo"
            :crossCallsFrom="cvBarData.crossCallsFrom"
            :crossCalls="cvBarData.crossCalls"

            :hasView="add_custom_view" :isActive="custom_view_active">
          </ComparsionBar>
        </div>
        <div class="compare-view-bar-legend">
          <div></div>{{$t("side-panel-top-container-text-intra-partition")}}<div></div>{{$t("side-panel-top-container-text-cross-partition")}}
        </div>

        <hr class="hr2"/>
        <div  class="view-more-details">
          <div class="view-more-details-title">{{$t("side-panel-bottom-container-more-details-title")}}</div>
          <div v-show="!classView" class="view-more-details-details">
            <h5>{{$t("side-panel-bottom-container-more-details-classes")}}</h5>
            <h1 v-text="totalClasses.toLocaleString(locale)"></h1>
          </div>
          <div v-show="!microserviceView && !classView" class="view-more-details-details">
            <h5>{{$t("side-panel-bottom-container-more-details-partitions")}}</h5>
            <h1 v-text="totalNumberOfPartitions.toLocaleString(locale)"></h1>
          </div>
          <div class="view-more-details-details">
            <h5>{{$t("side-panel-bottom-container-more-details-use-cases")}}</h5>
            <h1 v-text="use_cases.length.toLocaleString(locale)"></h1>
          </div>
          <div class="no-table-data-overlay-parent" v-show="(microserviceView || classView)">
            <!-- no use case overlay -->
            <div class="no-table-data-overlay" v-show="use_cases.length == 0">
              <img src = "../../assets/img/empty-state-bright-box.svg" alt="No containment relationships icon"/>

              <div class="no-table-data-header">{{$t('side-panel-bottom-container-no-use-case-header')}}</div>
              <div class="no-table-data-description">{{$t('side-panel-bottom-container-no-use-case-description')}}</div>
            </div>
            
            <!-- use case table -->
            <div class="usecases-table-search-div">
              <cv-search
                  :placeholder="$t('side-panel-bottom-container-more-details-usecase-search-placeholder')"
                  :title="$t('side-panel-bottom-container-more-details-usecase-search-placeholder')"
                  size="large"
                  ariaLabel="Use case search"
                  v-model="usecaseSearchValue"
                  ref="usecaseSearchInput"/>
            </div>
                  
            <cv-data-table
              class="usecases-table-data"
              :columns="usecasesTablecolumns"
              :pagination="usecasesTablePagination"
              @pagination="onUsecasesTablePagination"
              @sort="onSort"
              :borderless=true
              ref="usecaseTable">
              <template slot="data">
                <cv-data-table-row
                class="usecases-table-row"
                  v-for="(row, rowIndex) in usecasesInTable"
                  :key="rowIndex"
                  :value="row">
                  <cv-data-table-cell>
                    <div>
                      <span>
                      {{ row }}
                      </span>
                    </div>
                  </cv-data-table-cell>
                </cv-data-table-row>
              </template>
              <template slot="of-n-pages">
                {{ $t("table-pagination-of-pages",
                  {total: Math.max(1, Math.ceil(usecasesTablePagination.numberOfItems / usecasesPaginationInfo.length)) }) }}
              </template>
            </cv-data-table>
          </div>
          <div class="view-more-details-details">
            <h5>{{$t("side-panel-bottom-container-more-details-dependencies")}}</h5>
            <h1 v-text="totalDependenciesInMoreDetails"></h1>
          </div>

          <div class="no-table-data-overlay-parent" v-show="classView">
            <!-- no use case overlay -->
            <div class="no-table-data-overlay" v-show="dependencies.length == 0 ">
              <img src = "../../assets/img/empty-state-bright-box.svg" alt="No containment relationships icon"/>
              <div class="no-table-data-header">{{$t('side-panel-bottom-container-no-dependency-header')}}</div>
              <div class="no-table-data-description">{{$t('side-panel-bottom-container-no-dependency-description')}}</div>
            </div>
            <!-- use case table -->
            <div class="usecases-table-search-div">
              <cv-search
                  :placeholder="$t('side-panel-bottom-container-more-details-dependency-search-placeholder')"
                  :title="$t('side-panel-bottom-container-more-details-dependency-search-placeholder')"
                  size="large"
                  ariaLabel="Dependency search"
                  v-model="dependencySearchValue"/>
            </div>
                  
            <cv-data-table
              class="usecases-table-data"
              :columns="dependencyTablecolumns"
              :pagination="dependencyTablePagination"
              @pagination="onDependencyTablePagination"
              @sort="onSortDependency"
              :borderless=true>
              <template slot="data">
                <cv-data-table-row
                class="usecases-table-row"
                  v-for="(row, rowIndex) in dependenciesInTable"
                  :key="rowIndex"
                  :value="`${row.name}`">
                  <cv-data-table-cell>
                    <div>
                      <span>
                      {{ row.name }}
                      </span>
                    </div>
                  </cv-data-table-cell>
                  <cv-data-table-cell>
                    <div>
                    <span v-show="row.dir==='to'">
                      <svg focusable="false" preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg" fill="currentColor" width="16" height="16"  viewBox="0 0 32 32" aria-hidden="true" class="bx--btn__icon">
                        <polygon points="18 6 16.57 7.393 24.15 15 4 15 4 17 24.15 17 16.57 24.573 18 26 28 16 18 6"/>
                        <rect data-name="&lt;Transparent Rectangle&gt;" style="fill: none;" width="32" height="32"/>
                      </svg>
                    </span>
                      <span v-show="row.dir==='from'">                    
                        <svg focusable="false" preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg" fill="currentColor" width="16" height="16"  viewBox="0 0 32 32" aria-hidden="true" class="bx--btn__icon">
                          <polygon points="14 26 15.41 24.59 7.83 17 28 17 28 15 7.83 15 15.41 7.41 14 6 4 16 14 26"/>
                          <rect data-name="&lt;Transparent Rectangle&gt;" style="fill: none;" width="32" height="32"/>
                        </svg>
                      </span>
                    </div>
                  </cv-data-table-cell>
                </cv-data-table-row>
              </template>
              <template slot="of-n-pages">
                {{ $t("table-pagination-of-pages",
                  {total: Math.max(1, Math.ceil(dependencyTablePagination.numberOfItems / dependencyPaginationInfo.length)) }) }}
              </template>
            </cv-data-table>
          </div>
          
        </div>
        <!--
        <cv-accordion ref="acc" >
          <div v-if="data_dependency_view">
            <div v-show="microserviceView && !classView" class="data-dep-msv">
              <hr class="data-dep-hr">
              <p class="bx--accordion__title">{{ $t("containment" ) }}<br /><span class="containment-label">{{ containmentLabel }} </span>
              </p>
              <h3 v-for="className in containment[this.microserviceName]" v-bind:key="className">{{className}}</h3>
            </div>
            <div v-show="!microserviceView || classView">
              <cv-accordion-item v-show="!microserviceView || business_logic_view || classView" ref="businessLogicRef">
                <template slot="title">
                  {{ $t("businessLogic") }} <br />
                  <div v-show="!microserviceView && !classView" class="partition-label">{{ BLNumberedPartitionsLabel }}</div>
                  <div v-show="microserviceView && classView" class="partition-label">{{ BL_partition_name }}</div>
                </template>
                <template slot="content">
                  <div class="accordion-subheading">{{ $t("crossPartition") }}</div>
                  <div v-show="!microserviceView" class="side-panel-data"><p>{{ $t("runtimeCalls") }} </p><span class="right-num">{{ business_logic.cross_partition_runtime_calls }}</span> </div>
                  <div v-show="!microserviceView" class="linear-background-crossPartition">
                    <div v-show="!microserviceView" :style="{width: bl_crossPartitionRuntimeCallsPercentage}" class="linear"></div>
                  </div>
                  <div v-show="!microserviceView" class="side-panel-data"><p>{{ $t("dataDependencies") }} </p><span class="right-num">{{ business_logic.cross_partition_data_dependencies }}</span> </div>
                  <div v-show="!microserviceView" class="linear-background-crossPartition">
                    <div v-show="!microserviceView" :style="{width: bl_crossPartitionDataDependenciesPercentage}" class="linear"></div>
                  </div>
                  <div v-show="classView" class="side-panel-data"><p>{{ $t("runtimeCalls") }} </p><span class="right-num">{{ businessLogicElementViewData.crossPartitionRuntimeCalls }}</span> </div>
                  <div v-show="classView" class="linear-background-crossPartition">
                    <div v-show="classView" :style="{width: bl_element_crossPartitionRuntimeCallsPercentage}" class="linear"></div>
                  </div>
                  <div v-show="classView" class="side-panel-data"><p>{{ $t("dataDependencies") }}</p><span class="right-num">{{ businessLogicElementViewData.crossPartitionDataDependencies }}</span> </div>
                  <div v-show="classView" class="linear-background-crossPartition">
                    <div v-show="classView" :style="{width: bl_element_crossPartitionDataDependenciesPercentage}" class="linear"></div>
                  </div>
                  <div class="accordion-subheading">{{ $t("intraPartition") }}</div>
                  <div v-show="!microserviceView" class="side-panel-data"><p>{{ $t("runtimeCalls") }} </p><span class="right-num">{{ business_logic.internal_partition_runtime_calls}}</span> </div>
                  <div v-show="!microserviceView" class="linear-background-intraPartition">
                    <div v-show="!microserviceView" :style="{width: bl_intraPartitionRuntimeCallsPercentage}" class="linear"></div>
                  </div>
                  <div v-show="!microserviceView" class="side-panel-data"><p>{{ $t("dataDependencies") }} </p><span class="right-num">{{ business_logic.internal_data_dependencies }}</span> </div>
                  <div v-show="!microserviceView" class="linear-background-intraPartition">
                    <div v-show="!microserviceView" :style="{width: bl_intraPartitionDependenciesPercentage}" class="linear"></div>
                  </div>
                  <div v-show="classView" class="side-panel-data"><p>{{ $t("runtimeCalls") }} </p><span class="right-num">{{ businessLogicElementViewData.internalRuntimeCalls }}</span> </div>
                  <div v-show="classView" class="linear-background-intraPartition">
                    <div v-show="classView" :style="{width: bl_element_intraPartitionRuntimeCallsPercentage}" class="linear"></div>
                  </div>
                  <div v-show="classView" class="side-panel-data"><p>{{ $t("dataDependencies") }} </p><span class="right-num">{{ businessLogicElementViewData.internalDataDependencies }}</span> </div>
                  <div v-show="classView" class="linear-background-intraPartition">
                    <div v-show="classView" :style="{width: bl_element_intraPartitionDependenciesPercentage}" class="linear"></div>
                  </div>
                </template>
              </cv-accordion-item>
              <cv-accordion-item v-show="!microserviceView || natural_seam_view || classView" ref="naturalSeamsRef">
                <template slot="title">
                  {{ $t("naturalSeams") }} <br />
                  <div v-show="!microserviceView && !classView" class="partition-label">{{ NSNumberedPartitionsLabel }}</div>
                  <div v-show="microserviceView && classView" class="partition-label">{{ NS_partition_name }}</div>
                </template>
                <template slot="content">
                  <div class="accordion-subheading">{{ $t("crossPartition") }}</div>
                  <div v-show="!microserviceView" class="side-panel-data"><p>{{ $t("runtimeCalls") }} </p><span class="right-num">{{ natural_seam.cross_partition_runtime_calls }}</span> </div>
                  <div v-show="!microserviceView" class="linear-background-crossPartition">
                    <div v-show="!microserviceView" :style="{width: ns_crossPartitionRuntimeCallsPercentage}" class="linear"></div>
                  </div>
                  <div v-show="!microserviceView" class="side-panel-data"><p>{{ $t("dataDependencies") }} </p><span class="right-num">{{ natural_seam.cross_partition_data_dependencies }}</span> </div>
                  <div v-show="!microserviceView" class="linear-background-crossPartition">
                    <div v-show="!microserviceView" :style="{width: ns_crossPartitionDataDependenciesPercentage}" class="linear"></div>
                  </div>
                  <div v-show="classView" class="side-panel-data"><p>{{ $t("runtimeCalls") }} </p><span class="right-num">{{ naturalSeamElementViewData.crossPartitionRuntimeCalls }}</span> </div>
                  <div v-show="classView" class="linear-background-crossPartition">
                    <div v-show="classView" :style="{width: ns_element_crossPartitionRuntimeCallsPercentage}" class="linear"></div>
                  </div>
                  <div v-show="classView" class="side-panel-data"><p>{{ $t("dataDependencies") }}</p><span class="right-num">{{ naturalSeamElementViewData.crossPartitionDataDependencies }}</span> </div>
                  <div v-show="classView" class="linear-background-crossPartition">
                    <div v-show="classView" :style="{width: ns_element_crossPartitionDataDependenciesPercentage}" class="linear"></div>
                  </div>
                  <div class="accordion-subheading">{{ $t("intraPartition") }}</div>
                  <div v-show="!microserviceView" class="side-panel-data"><p>{{ $t("runtimeCalls") }} </p><span class="right-num">{{ natural_seam.internal_partition_runtime_calls}}</span> </div>
                  <div v-show="!microserviceView" class="linear-background-intraPartition">
                    <div v-show="!microserviceView" :style="{width: ns_intraPartitionRuntimeCallsPercentage}" class="linear"></div>
                  </div>
                  <div v-show="!microserviceView" class="side-panel-data"><p>{{ $t("dataDependencies") }} </p><span class="right-num">{{ natural_seam.internal_data_dependencies }}</span> </div>
                  <div v-show="!microserviceView" class="linear-background-intraPartition">
                    <div v-show="!microserviceView" :style="{width: ns_intraPartitionDependenciesPercentage}" class="linear"></div>
                  </div>
                  <div v-show="classView" class="side-panel-data"><p>{{ $t("runtimeCalls") }} </p><span class="right-num">{{ naturalSeamElementViewData.internalRuntimeCalls }}</span> </div>
                  <div v-show="classView" class="linear-background-intraPartition">
                    <div v-show="classView" :style="{width: ns_element_intraPartitionRuntimeCallsPercentage}" class="linear"></div>
                  </div>
                  <div v-show="classView" class="side-panel-data"><p>{{ $t("dataDependencies") }} </p><span class="right-num">{{ naturalSeamElementViewData.internalDataDependencies }}</span> </div>
                  <div v-show="classView" class="linear-background-intraPartition">
                    <div v-show="classView" :style="{width: ns_element_intraPartitionDependenciesPercentage}" class="linear"></div>
                  </div>
                </template>
              </cv-accordion-item>
              <cv-accordion-item id="custom-view-accordion" :class="{'disable-custom-view': !add_custom_view}" ref="customViewRef">
                <template slot="title">
                  {{ $t("customView") }}  <br />
                  <div v-show="!microserviceView && !classView" class="partition-label" :class="{'disable-custom-view': !add_custom_view}">{{ CVNumberedPartitionsLabel }} </div>
                  <div v-show="microserviceView && classView" class="partition-label" :class="{'disable-custom-view': !add_custom_view}">{{ CV_partition_name }} </div>
                </template>
                <template slot="content" v-if="add_custom_view" class="custom-view-cross-partitions">
                  <div class="accordion-subheading">{{ $t("crossPartition") }}</div>
                  <div v-show="!microserviceView" class="side-panel-data"><p>{{ $t("runtimeCalls") }} </p><span class="right-num">{{ custom_view.cross_partition_runtime_calls }}</span> </div>
                  <div v-show="!microserviceView" class="linear-background-crossPartition">
                    <div v-show="!microserviceView" :style="{width: cv_crossPartitionRuntimeCallsPercentage}" class="linear"></div>
                  </div>
                  <div v-show="!microserviceView" class="side-panel-data"><p>{{ $t("dataDependencies") }} </p><span class="right-num">{{ custom_view.cross_partition_data_dependencies }}</span> </div>
                  <div v-show="!microserviceView" class="linear-background-crossPartition">
                    <div v-show="!microserviceView" :style="{width: cv_crossPartitionDataDependenciesPercentage}" class="linear"></div>
                  </div>
                  <div v-show="classView" class="side-panel-data"><p>{{ $t("runtimeCalls") }} </p><span class="right-num">{{ selectedElementViewData.crossPartitionRuntimeCalls }}</span> </div>
                  <div v-show="classView" class="linear-background-crossPartition">
                    <div v-show="classView" :style="{width: cv_element_crossPartitionRuntimeCallsPercentage}" class="linear"></div>
                  </div>
                  <div v-show="classView" class="side-panel-data"><p>{{ $t("dataDependencies") }} </p><span class="right-num">{{ selectedElementViewData.crossPartitionDataDependencies }}</span> </div>
                  <div v-show="classView" class="linear-background-crossPartition">
                    <div v-show="classView" :style="{width: cv_element_crossPartitionDataDependenciesPercentage}" class="linear"></div>
                  </div>
                  <div class="accordion-subheading">{{ $t("intraPartition") }}</div>
                  <div v-show="!microserviceView" class="side-panel-data"><p>{{ $t("runtimeCalls") }} </p><span class="right-num">{{ custom_view.internal_partition_runtime_calls}}</span> </div>
                  <div v-show="!microserviceView" class="linear-background-intraPartition">
                    <div v-show="!microserviceView" :style="{width: cv_intraPartitionRuntimeCallsPercentage}" class="linear"></div>
                  </div>
                  <div v-show="!microserviceView" class="side-panel-data"><p>{{ $t("dataDependencies") }} </p><span class="right-num">{{ custom_view.internal_data_dependencies }}</span> </div>
                  <div v-show="!microserviceView" class="linear-background-intraPartition">
                    <div v-show="!microserviceView" :style="{width: cv_intraPartitionDependenciesPercentage}" class="linear"></div>
                  </div>
                  <div v-show="classView" class="side-panel-data"><p>{{ $t("runtimeCalls") }} </p><span class="right-num">{{ selectedElementViewData.internalRuntimeCalls }}</span> </div>
                  <div v-show="classView" class="linear-background-intraPartition">
                    <div v-show="classView" :style="{width: cv_element_intraPartitionRuntimeCallsPercentage}" class="linear"></div>
                  </div>
                  <div v-show="classView" class="side-panel-data"><p>{{ $t("dataDependencies") }} </p><span class="right-num">{{ selectedElementViewData.internalDataDependencies }}</span> </div>
                  <div v-show="classView" class="linear-background-intraPartition">
                    <div v-show="classView" :style="{width: cv_element_intraPartitionDependenciesPercentage}" class="linear"></div>
                  </div>
                </template>
              </cv-accordion-item>
            </div>
          </div>
          <div v-else-if="business_logic_view">
            <cv-accordion-item v-show="!microserviceView || business_logic_view || classView" ref="businessLogicRef">
              <template slot="title">
                {{ $t("businessLogic") }} <br />
                <div v-show="!microserviceView && !classView" class="partition-label">{{ BLNumberedPartitionsLabel }}</div>
                <div v-show="microserviceView && !classView" class="partition-label">{{ display_num_classes }}</div>
                <div v-show="microserviceView && classView" class="partition-label">{{ BL_partition_name }}</div>
              </template>
              <template slot="content">
                <div class="accordion-subheading">{{ $t("crossPartition") }}</div>
                <div v-show="!microserviceView" class="side-panel-data"><p>{{ $t("runtimeCalls") }} </p><span class="right-num">{{ business_logic.cross_partition_runtime_calls }}</span> </div>
                <div v-show="!microserviceView" class="linear-background-crossPartition">
                  <div v-show="!microserviceView" :style="{width: bl_crossPartitionRuntimeCallsPercentage}" class="linear"></div>
                </div>
                <div v-show="!microserviceView" class="side-panel-data"><p>{{ $t("dataDependencies") }} </p><span class="right-num">{{ business_logic.cross_partition_data_dependencies }}</span> </div>
                <div v-show="!microserviceView" class="linear-background-crossPartition">
                  <div v-show="!microserviceView" :style="{width: bl_crossPartitionDataDependenciesPercentage}" class="linear"></div>
                </div>
                <div v-show="microserviceView || classView" class="side-panel-data"><p>{{ $t("runtimeCalls") }} </p><span class="right-num">{{ businessLogicElementViewData.crossPartitionRuntimeCalls }}</span> </div>
                <div v-show="microserviceView || classView" class="linear-background-crossPartition">
                  <div v-show="microserviceView || classView" :style="{width: bl_element_crossPartitionRuntimeCallsPercentage}" class="linear"></div>
                </div>
                <div v-show="microserviceView || classView" class="side-panel-data"><p>{{ $t("dataDependencies") }}</p><span class="right-num">{{ businessLogicElementViewData.crossPartitionDataDependencies }}</span> </div>
                <div v-show="microserviceView || classView" class="linear-background-crossPartition">
                  <div v-show="microserviceView || classView" :style="{width: bl_element_crossPartitionDataDependenciesPercentage}" class="linear"></div>
                </div>
                <div class="accordion-subheading">{{ $t("intraPartition") }}</div>
                <div v-show="!microserviceView" class="side-panel-data"><p>{{ $t("runtimeCalls") }} </p><span class="right-num">{{ business_logic.internal_partition_runtime_calls}}</span> </div>
                <div v-show="!microserviceView" class="linear-background-intraPartition">
                  <div v-show="!microserviceView" :style="{width: bl_intraPartitionRuntimeCallsPercentage}" class="linear"></div>
                </div>
                <div v-show="!microserviceView" class="side-panel-data"><p>{{ $t("dataDependencies") }} </p><span class="right-num">{{ business_logic.internal_data_dependencies }}</span> </div>
                <div v-show="!microserviceView" class="linear-background-intraPartition">
                  <div v-show="!microserviceView" :style="{width: bl_intraPartitionDependenciesPercentage}" class="linear"></div>
                </div>
                <div v-show="microserviceView || classView" class="side-panel-data"><p>{{ $t("runtimeCalls") }} </p><span class="right-num">{{ businessLogicElementViewData.internalRuntimeCalls }}</span> </div>
                <div v-show="microserviceView || classView" class="linear-background-intraPartition">
                  <div v-show="microserviceView || classView" :style="{width: bl_element_intraPartitionRuntimeCallsPercentage}" class="linear"></div>
                </div>
                <div v-show="microserviceView || classView" class="side-panel-data"><p>{{ $t("dataDependencies") }} </p><span class="right-num">{{ businessLogicElementViewData.internalDataDependencies }}</span> </div>
                <div v-show="microserviceView || classView" class="linear-background-intraPartition">
                  <div v-show="microserviceView || classView" :style="{width: bl_element_intraPartitionDependenciesPercentage}" class="linear"></div>
                </div>
              </template>
            </cv-accordion-item>
            <cv-accordion-item v-show="!microserviceView || natural_seam_view || classView" ref="naturalSeamsRef">
              <template slot="title">
                {{ $t("naturalSeams") }} <br />
                <div v-show="!microserviceView && !classView" class="partition-label">{{ NSNumberedPartitionsLabel }}</div>
                <div v-show="microserviceView && classView" class="partition-label">{{ NS_partition_name }}</div>
              </template>
              <template slot="content">
                  <div class="accordion-subheading">{{ $t("crossPartition") }}</div>
                  <div v-show="!microserviceView" class="side-panel-data"><p>{{ $t("runtimeCalls") }} </p><span class="right-num">{{ natural_seam.cross_partition_runtime_calls }}</span> </div>
                  <div v-show="!microserviceView" class="linear-background-crossPartition">
                    <div v-show="!microserviceView" :style="{width: ns_crossPartitionRuntimeCallsPercentage}" class="linear"></div>
                  </div>
                  <div v-show="!microserviceView" class="side-panel-data"><p>{{ $t("dataDependencies") }} </p><span class="right-num">{{ natural_seam.cross_partition_data_dependencies }}</span> </div>
                  <div v-show="!microserviceView" class="linear-background-crossPartition">
                    <div v-show="!microserviceView" :style="{width: ns_crossPartitionDataDependenciesPercentage}" class="linear"></div>
                  </div>
                  <div v-show="microserviceView || classView" class="side-panel-data"><p>{{ $t("runtimeCalls") }} </p><span class="right-num">{{ naturalSeamElementViewData.crossPartitionRuntimeCalls }}</span> </div>
                  <div v-show="microserviceView || classView" class="linear-background-crossPartition">
                    <div v-show="microserviceView || classView" :style="{width: ns_element_crossPartitionRuntimeCallsPercentage}" class="linear"></div>
                  </div>
                  <div v-show="microserviceView || classView" class="side-panel-data"><p>{{ $t("dataDependencies") }}</p><span class="right-num">{{ naturalSeamElementViewData.crossPartitionDataDependencies }}</span> </div>
                  <div v-show="microserviceView || classView" class="linear-background-crossPartition">
                    <div v-show="microserviceView || classView" :style="{width: ns_element_crossPartitionDataDependenciesPercentage}" class="linear"></div>
                  </div>
                  <div class="accordion-subheading">{{ $t("intraPartition") }}</div>
                  <div v-show="!microserviceView" class="side-panel-data"><p>{{ $t("runtimeCalls") }} </p><span class="right-num">{{ natural_seam.internal_partition_runtime_calls}}</span> </div>
                  <div v-show="!microserviceView" class="linear-background-intraPartition">
                    <div v-show="!microserviceView" :style="{width: ns_intraPartitionRuntimeCallsPercentage}" class="linear"></div>
                  </div>
                  <div v-show="!microserviceView" class="side-panel-data"><p>{{ $t("dataDependencies") }} </p><span class="right-num">{{ natural_seam.internal_data_dependencies }}</span> </div>
                  <div v-show="!microserviceView" class="linear-background-intraPartition">
                    <div v-show="!microserviceView" :style="{width: ns_intraPartitionDependenciesPercentage}" class="linear"></div>
                  </div>
                  <div v-show="microserviceView || classView" class="side-panel-data"><p>{{ $t("runtimeCalls") }} </p><span class="right-num">{{ naturalSeamElementViewData.internalRuntimeCalls }}</span> </div>
                  <div v-show="microserviceView || classView" class="linear-background-intraPartition">
                    <div v-show="microserviceView || classView" :style="{width: ns_element_intraPartitionRuntimeCallsPercentage}" class="linear"></div>
                  </div>
                  <div v-show="microserviceView || classView" class="side-panel-data"><p>{{ $t("dataDependencies") }} </p><span class="right-num">{{ naturalSeamElementViewData.internalDataDependencies }}</span> </div>
                  <div v-show="microserviceView || classView" class="linear-background-intraPartition">
                    <div v-show="microserviceView || classView" :style="{width: ns_element_intraPartitionDependenciesPercentage}" class="linear"></div>
                  </div>
              </template>
            </cv-accordion-item>
            <cv-accordion-item id="custom-view-accordion" v-show="!microserviceView || (classView && !data_dependency_view)" :class="{'disable-custom-view': !add_custom_view}" ref="customViewRef">
              <template slot="title">
                {{ $t("customView") }} <br />
                <div v-show="!microserviceView && !classView" class="partition-label" :class="{'disable-custom-view': !add_custom_view}">{{ CVNumberedPartitionsLabel }} </div>
                <div v-show="microserviceView && classView" class="partition-label" :class="{'disable-custom-view': !add_custom_view}">{{ CV_partition_name }} </div>
              </template>
              <template slot="content" v-if="add_custom_view" class="custom-view-cross-partitions">
                <div class="accordion-subheading">{{ $t("crossPartition") }}</div>
                <div v-show="!microserviceView" class="side-panel-data"><p>{{ $t("runtimeCalls") }} </p><span class="right-num">{{ custom_view.cross_partition_runtime_calls }}</span> </div>
                <div v-show="!microserviceView" class="linear-background-crossPartition">
                  <div v-show="!microserviceView" :style="{width: cv_crossPartitionRuntimeCallsPercentage}" class="linear"></div>
                </div>
                <div v-show="!microserviceView" class="side-panel-data"><p>{{ $t("dataDependencies") }} </p><span class="right-num">{{ custom_view.cross_partition_data_dependencies }}</span> </div>
                <div v-show="!microserviceView" class="linear-background-crossPartition">
                  <div v-show="!microserviceView" :style="{width: cv_crossPartitionDataDependenciesPercentage}" class="linear"></div>
                </div>
                <div v-show="microserviceView || classView" class="side-panel-data"><p>{{ $t("runtimeCalls") }} </p><span class="right-num">{{ selectedElementViewData.crossPartitionRuntimeCalls }}</span> </div>
                <div v-show="microserviceView || classView" class="linear-background-crossPartition">
                  <div v-show="microserviceView || classView" :style="{width: cv_element_crossPartitionRuntimeCallsPercentage}" class="linear"></div>
                </div>
                <div v-show="microserviceView || classView" class="side-panel-data"><p>{{ $t("dataDependencies") }}</p><span class="right-num">{{ selectedElementViewData.crossPartitionDataDependencies }}</span></div>
                <div v-show="microserviceView || classView" class="linear-background-crossPartition">
                  <div v-show="microserviceView || classView" :style="{width: cv_element_crossPartitionDataDependenciesPercentage}" class="linear"></div>
                </div>
                <div class="accordion-subheading">{{ $t("intraPartition") }}</div>
                <div v-show="!microserviceView" class="side-panel-data"><p>{{ $t("runtimeCalls") }} </p><span class="right-num">{{ custom_view.internal_partition_runtime_calls}}</span> </div>
                <div v-show="!microserviceView" class="linear-background-intraPartition">
                  <div v-show="!microserviceView" :style="{width: cv_intraPartitionRuntimeCallsPercentage}" class="linear"></div>
                </div>
                <div v-show="!microserviceView" class="side-panel-data"><p>{{ $t("dataDependencies") }} </p><span class="right-num">{{ custom_view.internal_data_dependencies }}</span> </div>
                <div v-show="!microserviceView" class="linear-background-intraPartition">
                  <div v-show="!microserviceView" :style="{width: cv_intraPartitionDependenciesPercentage}" class="linear"></div>
                </div>
                <div v-show="microserviceView || classView" class="side-panel-data"><p>{{ $t("runtimeCalls") }} </p><span class="right-num">{{ selectedElementViewData.internalRuntimeCalls }}</span> </div>
                <div v-show="microserviceView || classView" class="linear-background-intraPartition">
                  <div v-show="microserviceView || classView" :style="{width: cv_element_intraPartitionRuntimeCallsPercentage}" class="linear"></div>
                </div>
                <div v-show="microserviceView || classView" class="side-panel-data"><p>{{ $t("dataDependencies") }} </p><span class="right-num">{{ selectedElementViewData.internalDataDependencies }}</span> </div>
                <div v-show="microserviceView || classView" class="linear-background-intraPartition">
                  <div v-show="microserviceView || classView" :style="{width: cv_element_intraPartitionDependenciesPercentage}" class="linear"></div>
                </div>
              </template>
            </cv-accordion-item>
          </div>
          <div v-else-if="natural_seam_view">
            <cv-accordion-item v-show="!microserviceView || natural_seam_view || classView" ref="naturalSeamsRef">
              <template slot="title">
                {{ $t("naturalSeams") }} <br />
                <div v-show="!microserviceView && !classView" class="partition-label">{{ NSNumberedPartitionsLabel }}</div>
                <div v-show="microserviceView && !classView" class="partition-label">{{ display_num_classes }}</div>
                <div v-show="microserviceView && classView" class="partition-label">{{ NS_partition_name }}</div>
              </template>
              <template slot="content">
                <div class="accordion-subheading">{{ $t("crossPartition") }}</div>
                <div v-show="!microserviceView" class="side-panel-data"><p>{{ $t("runtimeCalls") }} </p><span class="right-num">{{ natural_seam.cross_partition_runtime_calls }}</span> </div>
                <div v-show="!microserviceView" class="linear-background-crossPartition">
                  <div v-show="!microserviceView" :style="{width: ns_crossPartitionRuntimeCallsPercentage}" class="linear"></div>
                </div>
                <div v-show="!microserviceView" class="side-panel-data"><p>{{ $t("dataDependencies") }} </p><span class="right-num">{{ natural_seam.cross_partition_data_dependencies }}</span> </div>
                <div v-show="!microserviceView" class="linear-background-crossPartition">
                  <div v-show="!microserviceView" :style="{width: ns_crossPartitionDataDependenciesPercentage}" class="linear"></div>
                </div>
                <div v-show="microserviceView || classView" class="side-panel-data"><p>{{ $t("runtimeCalls") }} </p><span class="right-num">{{ naturalSeamElementViewData.crossPartitionRuntimeCalls }}</span> </div>
                <div v-show="microserviceView || classView" class="linear-background-crossPartition">
                  <div v-show="microserviceView || classView" :style="{width: ns_element_crossPartitionRuntimeCallsPercentage}" class="linear"></div>
                </div>
                <div v-show="microserviceView || classView" class="side-panel-data"><p>{{ $t("dataDependencies") }}</p><span class="right-num">{{ naturalSeamElementViewData.crossPartitionDataDependencies }}</span> </div>
                <div v-show="microserviceView || classView" class="linear-background-crossPartition">
                  <div v-show="microserviceView || classView" :style="{width: ns_element_crossPartitionDataDependenciesPercentage}" class="linear"></div>
                </div>
                <div class="accordion-subheading">{{ $t("intraPartition") }}</div>
                <div v-show="!microserviceView" class="side-panel-data"><p>{{ $t("runtimeCalls") }} </p><span class="right-num">{{ natural_seam.internal_partition_runtime_calls}}</span> </div>
                <div v-show="!microserviceView" class="linear-background-intraPartition">
                  <div v-show="!microserviceView" :style="{width: ns_intraPartitionRuntimeCallsPercentage}" class="linear"></div>
                </div>
                <div v-show="!microserviceView" class="side-panel-data"><p>{{ $t("dataDependencies") }} </p><span class="right-num">{{ natural_seam.internal_data_dependencies }}</span> </div>
                <div v-show="!microserviceView" class="linear-background-intraPartition">
                  <div v-show="!microserviceView" :style="{width: ns_intraPartitionDependenciesPercentage}" class="linear"></div>
                </div>
                <div v-show="microserviceView || classView" class="side-panel-data"><p>{{ $t("runtimeCalls") }} </p><span class="right-num">{{ naturalSeamElementViewData.internalRuntimeCalls }}</span> </div>
                <div v-show="microserviceView || classView" class="linear-background-intraPartition">
                  <div v-show="microserviceView || classView" :style="{width: ns_element_intraPartitionRuntimeCallsPercentage}" class="linear"></div>
                </div>
                <div v-show="microserviceView || classView" class="side-panel-data"><p>{{ $t("dataDependencies") }} </p><span class="right-num">{{ naturalSeamElementViewData.internalDataDependencies }}</span> </div>
                <div v-show="microserviceView || classView" class="linear-background-intraPartition">
                  <div v-show="microserviceView || classView" :style="{width: ns_element_intraPartitionDependenciesPercentage}" class="linear"></div>
                </div>
              </template>
            </cv-accordion-item>
            <cv-accordion-item v-if="!microserviceView || business_logic_view || classView" ref="businessLogicRef">
              <template slot="title">{{ $t("businessLogic") }} <br />
                <div v-show="!microserviceView && !classView" class="partition-label">{{ BLNumberedPartitionsLabel }}</div>
                <div v-show="microserviceView && classView" class="partition-label">{{ BL_partition_name }}</div>
              </template>
              <template slot="content">
                <div class="accordion-subheading">{{ $t("crossPartition") }}</div>
                <div v-show="!microserviceView" class="side-panel-data"><p>{{ $t("runtimeCalls") }} </p><span class="right-num">{{ business_logic.cross_partition_runtime_calls }}</span> </div>
                <div v-show="!microserviceView" class="linear-background-crossPartition">
                  <div v-show="!microserviceView" :style="{width: bl_crossPartitionRuntimeCallsPercentage}" class="linear"></div>
                </div>
                <div v-show="!microserviceView" class="side-panel-data"><p>{{ $t("dataDependencies") }} </p><span class="right-num">{{ business_logic.cross_partition_data_dependencies }}</span> </div>
                <div v-show="!microserviceView" class="linear-background-crossPartition">
                  <div v-show="!microserviceView" :style="{width: bl_crossPartitionDataDependenciesPercentage}" class="linear"></div>
                </div>
                <div v-show="microserviceView || classView" class="side-panel-data"><p>{{ $t("runtimeCalls") }} </p><span class="right-num">{{ businessLogicElementViewData.crossPartitionRuntimeCalls }}</span> </div>
                <div v-show="microserviceView || classView" class="linear-background-crossPartition">
                  <div v-show="microserviceView || classView" :style="{width: bl_element_crossPartitionRuntimeCallsPercentage}" class="linear"></div>
                </div>
                <div v-show="microserviceView || classView" class="side-panel-data"><p>{{ $t("dataDependencies") }}</p><span class="right-num">{{ businessLogicElementViewData.crossPartitionDataDependencies }}</span> </div>
                <div v-show="microserviceView || classView" class="linear-background-crossPartition">
                  <div v-show="microserviceView || classView" :style="{width: bl_element_crossPartitionDataDependenciesPercentage}" class="linear"></div>
                </div>
                <div class="accordion-subheading">{{ $t("intraPartition") }}</div>
                <div v-show="!microserviceView" class="side-panel-data"><p>{{ $t("runtimeCalls") }} </p><span class="right-num">{{ business_logic.internal_partition_runtime_calls}}</span> </div>
                <div v-show="!microserviceView" class="linear-background-intraPartition">
                  <div v-show="!microserviceView" :style="{width: bl_intraPartitionRuntimeCallsPercentage}" class="linear"></div>
                </div>
                <div v-show="!microserviceView" class="side-panel-data"><p>{{ $t("dataDependencies") }} </p><span class="right-num">{{ business_logic.internal_data_dependencies }}</span> </div>
                <div v-show="!microserviceView" class="linear-background-intraPartition">
                  <div v-show="!microserviceView" :style="{width: bl_intraPartitionDependenciesPercentage}" class="linear"></div>
                </div>
                <div v-show="microserviceView || classView" class="side-panel-data"><p>{{ $t("runtimeCalls") }} </p><span class="right-num">{{ businessLogicElementViewData.internalRuntimeCalls }}</span> </div>
                <div v-show="microserviceView || classView" class="linear-background-intraPartition">
                  <div v-show="microserviceView || classView" :style="{width: bl_element_intraPartitionRuntimeCallsPercentage}" class="linear"></div>
                </div>
                <div v-show="microserviceView || classView" class="side-panel-data"><p>{{ $t("dataDependencies") }} </p><span class="right-num">{{ businessLogicElementViewData.internalDataDependencies }}</span> </div>
                <div v-show="microserviceView || classView" class="linear-background-intraPartition">
                  <div v-show="microserviceView || classView" :style="{width: bl_element_intraPartitionDependenciesPercentage}" class="linear"></div>
                </div>
              </template>
            </cv-accordion-item>
            <cv-accordion-item id="custom-view-accordion" v-show="!microserviceView || (classView && !data_dependency_view)" :class="{'disable-custom-view': !add_custom_view}" ref="customViewRef">
              <template slot="title">
                {{ $t("customView") }} <br />
                <div v-show="!microserviceView && !classView" class="partition-label" :class="{'disable-custom-view': !add_custom_view}">{{ CVNumberedPartitionsLabel }} </div>
                <div v-show="microserviceView && classView" class="partition-label" :class="{'disable-custom-view': !add_custom_view}">{{ CV_partition_name }}</div>
              </template>
              <template slot="content" v-if="add_custom_view" class="custom-view-cross-partitions">
                <div class="accordion-subheading">{{ $t("crossPartition") }}</div>
                <div v-show="!microserviceView" class="side-panel-data"><p>{{ $t("runtimeCalls") }} </p><span class="right-num">{{ custom_view.cross_partition_runtime_calls }}</span> </div>
                <div v-show="!microserviceView" class="linear-background-crossPartition">
                  <div v-show="!microserviceView" :style="{width: cv_crossPartitionRuntimeCallsPercentage}" class="linear"></div>
                </div>
                <div v-show="!microserviceView" class="side-panel-data"><p>{{ $t("dataDependencies") }} </p><span class="right-num">{{ custom_view.cross_partition_data_dependencies }}</span> </div>
                <div v-show="!microserviceView" class="linear-background-crossPartition">
                  <div v-show="!microserviceView" :style="{width: cv_crossPartitionDataDependenciesPercentage}" class="linear"></div>
                </div>
                <div v-show="microserviceView || classView" class="side-panel-data"><p>{{ $t("runtimeCalls") }} </p><span class="right-num">{{ selectedElementViewData.crossPartitionRuntimeCalls }}</span> </div>
                <div v-show="microserviceView || classView" class="linear-background-crossPartition">
                  <div v-show="microserviceView || classView" :style="{width: cv_element_crossPartitionRuntimeCallsPercentage}" class="linear"></div>
                </div>
                <div v-show="microserviceView || classView" class="side-panel-data"><p>{{ $t("dataDependencies") }} </p><span class="right-num">{{ selectedElementViewData.crossPartitionDataDependencies }}</span> </div>
                <div v-show="microserviceView || classView" class="linear-background-crossPartition">
                  <div v-show="microserviceView || classView" :style="{width: cv_element_crossPartitionDataDependenciesPercentage}" class="linear"></div>
                </div>
                <div class="accordion-subheading">{{ $t("intraPartition") }}</div>
                <div v-show="!microserviceView" class="side-panel-data"><p>{{ $t("runtimeCalls") }} </p><span class="right-num">{{ custom_view.internal_partition_runtime_calls}}</span> </div>
                <div v-show="!microserviceView" class="linear-background-intraPartition">
                  <div v-show="!microserviceView" :style="{width: cv_intraPartitionRuntimeCallsPercentage}" class="linear"></div>
                </div>
                <div v-show="!microserviceView" class="side-panel-data"><p>{{ $t("dataDependencies") }} </p><span class="right-num">{{ custom_view.internal_data_dependencies }}</span> </div>
                <div v-show="!microserviceView" class="linear-background-intraPartition">
                  <div v-show="!microserviceView" :style="{width: cv_intraPartitionDependenciesPercentage}" class="linear"></div>
                </div>
                <div v-show="microserviceView || classView" class="side-panel-data"><p>{{ $t("runtimeCalls") }} </p><span class="right-num">{{ selectedElementViewData.internalRuntimeCalls }}</span> </div>
                <div v-show="microserviceView || classView" class="linear-background-intraPartition">
                  <div v-show="microserviceView || classView" :style="{width: cv_element_intraPartitionRuntimeCallsPercentage}" class="linear"></div>
                </div>
                <div v-show="microserviceView || classView" class="side-panel-data"><p>{{ $t("dataDependencies") }} </p><span class="right-num">{{ selectedElementViewData.internalDataDependencies }}</span> </div>
                <div v-show="microserviceView || classView" class="linear-background-intraPartition">
                  <div v-show="microserviceView || classView" :style="{width: cv_element_intraPartitionDependenciesPercentage}" class="linear"></div>
                </div>
              </template>
            </cv-accordion-item>
          </div>
          <div v-else-if="add_custom_view">
            <cv-accordion-item id="custom-view-accordion" v-show="!data_dependency_view || (data_dependency_view && !(microserviceView || classView))" :class="{'disable-custom-view': !add_custom_view}" ref="customViewRef">
              <template slot="title">
                {{ $t("customView") }} <br />
                <div v-show="!microserviceView && !classView" class="partition-label" :class="{'disable-custom-view': !add_custom_view}">{{ CVNumberedPartitionsLabel }} </div>
                <div v-show="microserviceView && !classView" class="partition-label" :class="{'disable-custom-view': !add_custom_view}">{{ display_num_classes }}</div>
                <div v-show="microserviceView && classView" class="partition-label" :class="{'disable-custom-view': !add_custom_view}">{{ CV_partition_name }}</div>
              </template>
              <template slot="content" v-if="add_custom_view" class="custom-view-cross-partitions">
                <div class="accordion-subheading">{{ $t("crossPartition") }}</div>
                <div v-show="!microserviceView" class="side-panel-data"><p>{{ $t("runtimeCalls") }} </p><span class="right-num">{{ custom_view.cross_partition_runtime_calls }}</span> </div>
                <div v-show="!microserviceView" class="linear-background-crossPartition">
                  <div v-show="!microserviceView" :style="{width: cv_crossPartitionRuntimeCallsPercentage}" class="linear"></div>
                </div>
                <div v-show="!microserviceView" class="side-panel-data"><p>{{ $t("dataDependencies") }} </p><span class="right-num">{{ custom_view.cross_partition_data_dependencies }}</span> </div>
                <div v-show="!microserviceView" class="linear-background-crossPartition">
                  <div v-show="!microserviceView" :style="{width: cv_crossPartitionDataDependenciesPercentage}" class="linear"></div>
                </div>
                <div v-show="microserviceView || classView" class="side-panel-data"><p>{{ $t("runtimeCalls") }} </p><span class="right-num">{{ selectedElementViewData.crossPartitionRuntimeCalls }}</span> </div>
                <div v-show="microserviceView || classView" class="linear-background-crossPartition">
                  <div v-show="microserviceView || classView" :style="{width: cv_element_crossPartitionRuntimeCallsPercentage}" class="linear"></div>
                </div>
                <div v-show="microserviceView || classView" class="side-panel-data"><p>{{ $t("dataDependencies") }} </p><span class="right-num">{{ selectedElementViewData.crossPartitionDataDependencies }}</span> </div>
                <div v-show="microserviceView || classView" class="linear-background-crossPartition">
                  <div v-show="microserviceView || classView" :style="{width: cv_element_crossPartitionDataDependenciesPercentage}" class="linear"></div>
                </div>
                <div class="accordion-subheading">{{ $t("intraPartition") }}</div>
                <div v-show="!microserviceView" class="side-panel-data"><p>{{ $t("runtimeCalls") }} </p><span class="right-num">{{ custom_view.internal_partition_runtime_calls}}</span> </div>
                <div v-show="!microserviceView" class="linear-background-intraPartition">
                  <div v-show="!microserviceView" :style="{width: cv_intraPartitionRuntimeCallsPercentage}" class="linear"></div>
                </div>
                <div v-show="!microserviceView" class="side-panel-data"><p>{{ $t("dataDependencies") }} </p><span class="right-num">{{ custom_view.internal_data_dependencies }}</span> </div>
                <div v-show="!microserviceView" class="linear-background-intraPartition">
                  <div v-show="!microserviceView" :style="{width: cv_intraPartitionDependenciesPercentage}" class="linear"></div>
                </div>
                <div v-show="microserviceView || classView" class="side-panel-data"><p>{{ $t("runtimeCalls") }}</p><span class="right-num">{{ selectedElementViewData.internalRuntimeCalls }}</span></div>
                <div v-show="microserviceView || classView" class="linear-background-intraPartition">
                  <div v-show="microserviceView || classView" :style="{width: cv_element_intraPartitionRuntimeCallsPercentage}" class="linear"></div>
                </div>
                <div v-show="microserviceView || classView" class="side-panel-data"><p>{{ $t("dataDependencies") }} </p><span class="right-num">{{ selectedElementViewData.internalDataDependencies }}</span> </div>
                <div v-show="microserviceView || classView" class="linear-background-intraPartition">
                  <div v-show="microserviceView || classView" :style="{width: cv_element_intraPartitionDependenciesPercentage}" class="linear"></div>
                </div>
              </template>
            </cv-accordion-item>
            <cv-accordion-item v-show="!microserviceView || classView" ref="businessLogicRef">
              <template slot="title">
                {{ $t("businessLogic") }} <br />
                <div v-show="!microserviceView && !classView" class="partition-label">{{ BLNumberedPartitionsLabel }}</div>
                <div v-show="microserviceView && classView" class="partition-label">{{ BL_partition_name }}</div>
              </template>
              <template slot="content">
                <div class="accordion-subheading">{{ $t("crossPartition") }}</div>
                <div v-show="!microserviceView" class="side-panel-data"><p>{{ $t("runtimeCalls") }} </p><span class="right-num">{{ business_logic.cross_partition_runtime_calls }}</span> </div>
                <div v-show="!microserviceView" class="linear-background-crossPartition">
                  <div v-show="!microserviceView" :style="{width: bl_crossPartitionRuntimeCallsPercentage}" class="linear"></div>
                </div>
                <div v-show="!microserviceView" class="side-panel-data"><p>{{ $t("dataDependencies") }} </p><span class="right-num">{{ business_logic.cross_partition_data_dependencies }}</span> </div>
                <div v-show="!microserviceView" class="linear-background-crossPartition">
                  <div v-show="!microserviceView" :style="{width: bl_crossPartitionDataDependenciesPercentage}" class="linear"></div>
                </div>
                <div v-show="microserviceView || classView" class="side-panel-data"><p>{{ $t("runtimeCalls") }} </p><span class="right-num">{{ businessLogicElementViewData.crossPartitionRuntimeCalls }}</span> </div>
                <div v-show="microserviceView || classView" class="linear-background-crossPartition">
                  <div v-show="microserviceView || classView" :style="{width: bl_element_crossPartitionRuntimeCallsPercentage}" class="linear"></div>
                </div>
                <div v-show="microserviceView || classView" class="side-panel-data"><p>{{ $t("dataDependencies") }}</p><span class="right-num">{{ businessLogicElementViewData.crossPartitionDataDependencies }}</span> </div>
                <div v-show="microserviceView || classView" class="linear-background-crossPartition">
                  <div v-show="microserviceView || classView" :style="{width: bl_element_crossPartitionDataDependenciesPercentage}" class="linear"></div>
                </div>
                <div class="accordion-subheading">{{ $t("intraPartition") }}</div>
                <div v-show="!microserviceView" class="side-panel-data"><p>{{ $t("runtimeCalls") }} </p><span class="right-num">{{ business_logic.internal_partition_runtime_calls}}</span> </div>
                <div v-show="!microserviceView" class="linear-background-intraPartition">
                  <div v-show="!microserviceView" :style="{width: bl_intraPartitionRuntimeCallsPercentage}" class="linear"></div>
                </div>
                <div v-show="!microserviceView" class="side-panel-data"><p>{{ $t("dataDependencies") }} </p><span class="right-num">{{ business_logic.internal_data_dependencies }}</span> </div>
                <div v-show="!microserviceView" class="linear-background-intraPartition">
                  <div v-show="!microserviceView" :style="{width: bl_intraPartitionDependenciesPercentage}" class="linear"></div>
                </div>
                <div v-show="microserviceView || classView" class="side-panel-data"><p>{{ $t("runtimeCalls") }} </p><span class="right-num">{{ businessLogicElementViewData.internalRuntimeCalls }}</span> </div>
                <div v-show="microserviceView || classView" class="linear-background-intraPartition">
                  <div v-show="microserviceView || classView" :style="{width: bl_element_intraPartitionRuntimeCallsPercentage}" class="linear"></div>
                </div>
                <div v-show="microserviceView || classView" class="side-panel-data"><p>{{ $t("dataDependencies") }} </p><span class="right-num">{{ businessLogicElementViewData.internalDataDependencies }}</span> </div>
                <div v-show="microserviceView || classView" class="linear-background-intraPartition">
                  <div v-show="microserviceView || classView" :style="{width: bl_element_intraPartitionDependenciesPercentage}" class="linear"></div>
                </div>
              </template>
            </cv-accordion-item>
            <cv-accordion-item v-show="!microserviceView || classView" ref="naturalSeamsRef">
              <template slot="title">
                {{ $t("naturalSeams") }} <br />
                <div v-show="!microserviceView && !classView" class="partition-label">{{ NSNumberedPartitionsLabel }}</div>
                <div v-show="microserviceView && classView" class="partition-label">{{ NS_partition_name }} </div>
              </template>
              <template slot="content">
                <div class="accordion-subheading">{{ $t("crossPartition") }}</div>
                <div v-show="!microserviceView" class="side-panel-data"><p>{{ $t("runtimeCalls") }} </p><span class="right-num">{{ natural_seam.cross_partition_runtime_calls }}</span> </div>
                <div v-show="!microserviceView" class="linear-background-crossPartition">
                  <div v-show="!microserviceView" :style="{width: ns_crossPartitionRuntimeCallsPercentage}" class="linear"></div>
                </div>
                <div v-show="!microserviceView" class="side-panel-data"><p>{{ $t("dataDependencies") }} </p><span class="right-num">{{ natural_seam.cross_partition_data_dependencies }}</span> </div>
                <div v-show="!microserviceView" class="linear-background-crossPartition">
                  <div v-show="!microserviceView" :style="{width: ns_crossPartitionDataDependenciesPercentage}" class="linear"></div>
                </div>
                <div v-show="microserviceView || classView" class="side-panel-data"><p>{{ $t("runtimeCalls") }} </p><span class="right-num">{{ naturalSeamElementViewData.crossPartitionRuntimeCalls }}</span> </div>
                <div v-show="microserviceView || classView" class="linear-background-crossPartition">
                  <div v-show="microserviceView || classView" :style="{width: ns_element_crossPartitionRuntimeCallsPercentage}" class="linear"></div>
                </div>
                <div v-show="microserviceView || classView" class="side-panel-data"><p>{{ $t("dataDependencies") }}</p><span class="right-num">{{ naturalSeamElementViewData.crossPartitionDataDependencies }}</span> </div>
                <div v-show="microserviceView || classView" class="linear-background-crossPartition">
                  <div v-show="microserviceView || classView" :style="{width: ns_element_crossPartitionDataDependenciesPercentage}" class="linear"></div>
                </div>
                <div class="accordion-subheading">{{ $t("intraPartition") }}</div>
                <div v-show="!microserviceView" class="side-panel-data"><p>{{ $t("runtimeCalls") }} </p><span class="right-num">{{ natural_seam.internal_partition_runtime_calls}}</span> </div>
                <div v-show="!microserviceView" class="linear-background-intraPartition">
                  <div v-show="!microserviceView" :style="{width: ns_intraPartitionRuntimeCallsPercentage}" class="linear"></div>
                </div>
                <div v-show="!microserviceView" class="side-panel-data"><p>{{ $t("dataDependencies") }} </p><span class="right-num">{{ natural_seam.internal_data_dependencies }}</span> </div>
                <div v-show="!microserviceView" class="linear-background-intraPartition">
                  <div v-show="!microserviceView" :style="{width: ns_intraPartitionDependenciesPercentage}" class="linear"></div>
                </div>
                <div v-show="microserviceView || classView" class="side-panel-data"><p>{{ $t("runtimeCalls") }} </p><span class="right-num">{{ naturalSeamElementViewData.internalRuntimeCalls }}</span> </div>
                <div v-show="microserviceView || classView" class="linear-background-intraPartition">
                  <div v-show="microserviceView || classView" :style="{width: ns_element_intraPartitionRuntimeCallsPercentage}" class="linear"></div>
                </div>
                <div v-show="microserviceView || classView" class="side-panel-data"><p>{{ $t("dataDependencies") }} </p><span class="right-num">{{ naturalSeamElementViewData.internalDataDependencies }}</span> </div>
                <div v-show="microserviceView || classView" class="linear-background-intraPartition">
                  <div v-show="microserviceView || classView" :style="{width: ns_element_intraPartitionDependenciesPercentage}" class="linear"></div>
                </div>
              </template>
            </cv-accordion-item>
          </div>
        </cv-accordion>
        <div v-if="(microserviceView || classView) && !data_dependency_view && show_dependency" class="partition-use-case-list">
          <h4><strong>{{ $t("dataDependencies") }}</strong></h4>
          <div class="use-cases-list">
            <h5 v-for="(dependency, index) in dependencies" :key="index" class="runtime-list" :label=dependency :value=dependency :title="dependency">{{dependency}}</h5>
          </div>
        </div>
        <div v-if="(microserviceView || classView) && !data_dependency_view" class="partition-use-case-list">
          <h4><strong>{{ $t("useCases") }}</strong></h4>
          <div class="use-cases-list">
            <h5 v-for="(runtime_call, index) in use_cases" :key="index" class="runtime-list" :label=runtime_call :value=runtime_call :title="runtime_call">{{runtime_call}}</h5>
          </div>
        </div>
        
        <div class="copy-path-panel copy-path-panel--opened" v-show="classView">
        <div class="copy-path-label">{{ $t("path")}}</div>
        <cv-icon-button class="copy-path-btn" kind="ghost" :icon="copy_icon" @click="copyPath"
         :label="$t('copy-button-label')" tip-position="top" tip-alignment="center" />
        <cv-icon-button v-if="pathViewOpen" class="show-panel-btn" kind="ghost" :icon="open_path_icon" @click="showPathPanel"/>
        <cv-icon-button v-else class="show-panel-btn" kind="ghost" :icon="close_path_icon" @click="showPathPanel"/>
        <div v-show="pathViewOpen" class="path-label" id="class-path">{{this.classPath}}</div>
        </div>
        -->
      </div>
      </div>
    </cv-tile>
    <!-- <UnobservedTable /> -->

  </div>
</template>

<script>
import { CvTile } from "@carbon/vue";
import { eventBus } from "../../main.js";
import messageUtils from "@/js/messageUtils";
import apis from "../../lib/graph/graphAPIHandler";
import utils from "@/lib/graph/graphUtil";
import constants from "../../lib/constants";
import stats from "../../lib/graph/stats.js";
import icon_right_arrow from "@carbon/icons-vue/es/chevron--right/16";
import icon_left_arrow from "@carbon/icons-vue/es/chevron--left/16";
import add_icon from "@carbon/icons-vue/es/add/16";
import collapse_icon from "@carbon/icons-vue/es/subtract/16";
import reset_icon from "@carbon/icons-vue/es/reset/16";
import edit_icon from "@carbon/icons-vue/es/edit/16";
import checkmark_icon from "@carbon/icons-vue/es/checkmark/16";
import close_icon from "@carbon/icons-vue/es/close/16";
import copy_icon from "@carbon/icons-vue/es/copy/16";
// import open_path_icon from "@carbon/icons-vue/es/caret--down/16";
// import close_path_icon from "@carbon/icons-vue/es/caret--up/16";
import close_path_icon from "@carbon/icons-vue/es/chevron--down/16";
import open_path_icon from "@carbon/icons-vue/es/chevron--up/16";
//import infoIcon from "@carbon/icons-vue/es/information/16";
//import UnobservedTable from '../UnobservedTable';
import launch_icon from "@carbon/icons-vue/es/launch/16";
import ComparsionBar from '../ComparsionBar';

export default {
  name: "SidePanel",
  components: { CvTile, /*UnobservedTable,*/ ComparsionBar, /* infoIcon */},
  data() { return {
    uniqueCallVolumnData: {
      uniqueRuntimeCalls: 0,
      uniqueDependencyCalls: 0,
    },
    blBarData: {},
    nsBarData: {},
    cvBarData: {},
    dependencySearchValue:"",
    selectedClassPreviousPartition: "",
    selectedClassCurrentPartition: "",
    selectedClassOriginalPartition: "",
    selectedClassPath: "",
    selectedClassLabels: [],
    usecasesInTable: [],
    dependenciesInTable: [],
    // total use cases usecases for the overview
    totalUseCases: [],
    totalClasses :0,
    locale:  navigator.language || navigator.systemLanguage,
    usecasesTablePagination: {
        page: 1,
        numberOfItems: 0,
        pageSizes: [10]
      },
    dependencyTablePagination: {
        page: 1,
        numberOfItems: 0,
        pageSizes: [10]
      },
    usecasesPaginationInfo: {
        start: 0,
        page: 0,
        length: 10
      },
    dependencyPaginationInfo: {
        start: 0,
        page: 0,
        length: 10
      },
    usecasesTablecolumns: [
        {
          label: this.$t("side-panel-bottom-container-more-details-usecase-table-column1"),
          sortable: true
        }
      ],
    dependencyTablecolumns: [
        {
          label: this.$t("side-panel-bottom-container-more-details-dependency-table-column1"),
          sortable: true
        },
        {
          label: this.$t("side-panel-bottom-container-more-details-dependency-table-column2"),
          sortable: true
        },
      ],
    selectedPartitionStats: undefined,
    selectedClassStats: undefined,
    usecaseSearchValue: "",
    radio_compare_view: "runtimeCalls",
    sidePanelHeaderText: "",
    overallDescription: "",
    loadPanel: false,
    showPanel: false,
    numOfClasses: "",
    showClasses: true,
    classView: false,
    microserviceView: false,
    microserviceName: "",   // Name of partition or class selected for viewing
    selectedPartition: "",
    isUnObserved: false,
    classPath: "",
    copyClassPath: "",
    selected_view: "",
    natural_seam_view: false,
    business_logic_view: false,
    data_dependency_view: false,
    show_dependency: false,
    isShowDependencyOn: true,
    icon_right_arrow,
    icon_left_arrow,
    add_icon,
    collapse_icon,
    reset_icon,
    edit_icon,
    launch_icon,
    checkmark_icon,
    close_icon,
    copy_icon,
    open_path_icon,
    close_path_icon,
    unobservedArr: [],
    unobservedNodes: [],
    // Percentages used on linear graphs
    bl_intraPartitionRuntimeCallsPercentage: 0,
    bl_crossPartitionRuntimeCallsPercentage: 0,
    bl_intraPartitionDependenciesPercentage: 0,
    bl_crossPartitionDataDependenciesPercentage: 0,
    ns_intraPartitionRuntimeCallsPercentage: 0,
    ns_crossPartitionRuntimeCallsPercentage: 0,
    ns_intraPartitionDependenciesPercentage: 0,
    ns_crossPartitionDataDependenciesPercentage: 0,
    cv_intraPartitionRuntimeCallsPercentage: 0,
    cv_crossPartitionRuntimeCallsPercentage: 0,
    cv_intraPartitionDependenciesPercentage: 0,
    cv_crossPartitionDataDependenciesPercentage: 0,
    bl_element_intraPartitionRuntimeCallsPercentage: 0,
    bl_element_crossPartitionRuntimeCallsPercentage: 0,
    bl_element_intraPartitionDependenciesPercentage: 0,
    bl_element_crossPartitionDataDependenciesPercentage: 0,
    ns_element_intraPartitionRuntimeCallsPercentage: 0,
    ns_element_crossPartitionRuntimeCallsPercentage: 0,
    ns_element_intraPartitionDependenciesPercentage: 0,
    ns_element_crossPartitionDataDependenciesPercentage: 0,
    cv_element_intraPartitionRuntimeCallsPercentage: 0,
    cv_element_crossPartitionRuntimeCallsPercentage: 0,
    cv_element_intraPartitionDependenciesPercentage: 0,
    cv_element_crossPartitionDataDependenciesPercentage: 0,
    add_custom_view: false,
    reset_show_dialog: false,
    custom_view_active: false,  // True if 'Custom view' is the currently active Graph view
    editPartitionView: false,   // True if editing partition name in custom view
    rename_invalid_msg: null,
    // show_unobserved_table: false,
    pathViewOpen: false,
    partitionColor: "",
    display_num_classes: "",    // Number of classes to be displayed when in microserviceView (viewing partition data)
    resetStartViewSelection: "micro_detail_partition_by_business_logic", // Reset custom graph view starting point selection - Business logic or Natural seams
    natural_seam: {
      number_of_partitions: 0,
      total_partition_runtime_calls: 0,
      cross_partition_runtime_calls: 0,
      cross_partition_data_dependencies: 0,
      internal_partition_runtime_calls: 0,
      internal_data_dependencies: 0,
      uniqueRuntimeCalls: 0,
      uniqueDependencyCalls: 0,
    },
    business_logic: {
      number_of_partitions: 0,
      total_partition_runtime_calls: 0,
      cross_partition_runtime_calls: 0,
      cross_partition_data_dependencies: 0,
      internal_partition_runtime_calls: 0,
      internal_data_dependencies: 0,
      uniqueRuntimeCalls: 0,
      uniqueDependencyCalls: 0,
    },
    custom_view: {
      number_of_partitions: 0,
      total_partition_runtime_calls: 0,
      cross_partition_runtime_calls: 0,
      cross_partition_data_dependencies: 0,
      internal_partition_runtime_calls: 0,
      internal_data_dependencies: 0,
      uniqueRuntimeCalls: 0,
      uniqueDependencyCalls: 0,
    },
    /* This is use cases */
    use_cases: [],
    dependencies: [],
    selectedElementViewData: {
      // Holds custom view data for the selected partition or class
      crossPartitionDataDependencies: 0,
      crossPartitionRuntimeCalls: 0,
      internalDataDependencies: 0,
      internalRuntimeCalls: 0,
      totalRuntimeCalls: 0,
    },
    businessLogicElementViewData: {
      crossPartitionDataDependencies: 0,
      crossPartitionRuntimeCalls: 0,
      internalDataDependencies: 0,
      internalRuntimeCalls: 0,
      totalRuntimeCalls: 0,
    },
    naturalSeamElementViewData: {
      crossPartitionDataDependencies: 0,
      crossPartitionRuntimeCalls: 0,
      internalDataDependencies: 0,
      internalRuntimeCalls: 0,
      totalRuntimeCalls: 0,
    },
    BL_partition_name: "", // Partition the element belongs to in Business Logic view
    NS_partition_name: "", // Partition the element belongs to in Natural Seams view
    CV_partition_name: "", // Partition the element belongs to in Custom View
    containment: {},
    editLabelsLaunchClose: false,
  }},
  computed: {
    
    totalNumberOfPartitions: function() {
      let num = this.business_logic.number_of_partitions;
      if (this.microserviceName == this.$t('naturalSeams')) {
        num = this.natural_seam.number_of_partitions;
      } else if ( this.microserviceName == this.$t('customView')) {
        num = this.custom_view.number_of_partitions;
      }
      if (num == undefined) {
        return 1;
      } else return num;
    },

    totalContainmentRelationships: function() {
      const total = this.business_logic.cross_partition_data_dependencies +
          this.business_logic.internal_data_dependencies;
          return total;
    },

    totalDependenciesInMoreDetails: function() {
      if ( this.classView && this.microserviceView ) {
        
        // get dependencies for the selected class
        let total = 0;
        if ( this.selectedClassStats ) {
          total = this.selectedClassStats.internalPartitionDataDependencies + this.selectedClassStats.crossPartitionDataDependencies;
        }
        return total.toLocaleString(this.locale);
        
      } else if ( this.microserviceView && !this.classView ) {
        const data  = this.selectedPartitionStats;
        
          // get dependencies for the selected partition
          let total = 0;
          if ( data && 
            Object.prototype.hasOwnProperty.call(data, "data") &&
            Object.prototype.hasOwnProperty.call(data.data, "partitionData") &&
            Object.prototype.hasOwnProperty.call(data.data.partitionData, this.microserviceName) ) {
            total = data.data.partitionData[this.microserviceName].crossPartitionDataDependencies + 
              data.data.partitionData[this.microserviceName].internalDataDependencies ;
          }
          return total.toLocaleString(this.locale);
        
      } else {
        let total = this.business_logic.cross_partition_data_dependencies +
            this.business_logic.internal_data_dependencies;
        
        return total.toLocaleString(this.locale);
      }
    },

    /* Need to calculate runtime calls for view, partition and class
     * If containment relationship is selected, calculate containment 
     * relationship instead of runtime calls
     *
     * classView: true is for class,
     * microserviceView: true is the partition view
     * microserviceName: is the selected partition name
     */
    compareViewRuntimeCallsLabel: function() {
      if ( this.classView && this.microserviceView ) {
        

        if ( this.radio_compare_view==="runtimeCalls" ) {
          // get runtime calls for the selected class
          let total = 0;
          if ( this.selectedClassStats ) {
            total = this.selectedClassStats.internalPartitionRuntimeCalls + this.selectedClassStats.crossPartitionRuntimeCalls;
          }
          
          return this.$t("side-panel-top-container-text-total-call-volume",{volume: total.toLocaleString(this.locale)});
        } else {
          // get dependencies for the selected class
          let total = 0;
          if ( this.selectedClassStats ) {
            total = this.selectedClassStats.internalPartitionDataDependencies + this.selectedClassStats.crossPartitionDataDependencies;
          }
          return this.$t("side-panel-top-container-text-total-containment-relationships",{volume: total.toLocaleString(this.locale)});
        }
      } else if ( this.microserviceView && !this.classView ) {
        const data  = this.selectedPartitionStats;
        if ( this.radio_compare_view==="runtimeCalls" ) {
          let total = 0;
          // get runtime calls for the selected partition
          // Get current view name
          
          // microserviceName is the partition name
          if ( data && 
            Object.prototype.hasOwnProperty.call(data, "data") &&
            Object.prototype.hasOwnProperty.call(data.data, "partitionData") &&
            Object.prototype.hasOwnProperty.call(data.data.partitionData, this.microserviceName) ) {
            total = data.data.partitionData[this.microserviceName].totalPartitionRuntimeCalls;
          }
          return this.$t("side-panel-top-container-text-total-call-volume",{volume: total.toLocaleString(this.locale)});
        } else {
          // get dependencies for the selected partition
          let total = 0;
          if ( data && 
            Object.prototype.hasOwnProperty.call(data, "data") &&
            Object.prototype.hasOwnProperty.call(data.data, "partitionData") &&
            Object.prototype.hasOwnProperty.call(data.data.partitionData, this.microserviceName) ) {
            total = data.data.partitionData[this.microserviceName].crossPartitionDataDependencies + 
              data.data.partitionData[this.microserviceName].internalDataDependencies ;
          }
          return this.$t("side-panel-top-container-text-total-containment-relationships",{volume: total.toLocaleString(this.locale)});
        }
      } else {
        let total = this.business_logic.cross_partition_data_dependencies +
            this.business_logic.internal_data_dependencies;
        if ( this.radio_compare_view==="runtimeCalls" ) {
          total = this.business_logic.cross_partition_runtime_calls +
            this.business_logic.internal_partition_runtime_calls;
            return this.$t("side-panel-top-container-text-total-call-volume",{volume: total.toLocaleString(this.locale)});
        }
        return this.$t("side-panel-top-container-text-total-containment-relationships",{volume: total.toLocaleString(this.locale)});
      }
    },

    /*
     *  Need to calculate uniqie calls for view, partition and class
     */
    compareViewUniqueCallsLabel: function() {
      let total = 0;
      if ( this.radio_compare_view==="runtimeCalls" ) {
        if ( this.microserviceView  && this.classView) {
          // class level unique calls
          if ( this.selectedClassStats ) {
            total = this.selectedClassStats.crossPartitionUniqueCalls + this.selectedClassStats.internalPartitionUniqueCalls;
          }
        } else if ( !this.classView && this.microserviceView ) {
          // partition level unique calls
          const data  = this.selectedPartitionStats;
          if ( data && 
            Object.prototype.hasOwnProperty.call(data, "data") &&
            Object.prototype.hasOwnProperty.call(data.data, "partitionData") &&
            Object.prototype.hasOwnProperty.call(data.data.partitionData, this.microserviceName) ) {
            total = data.data.partitionData[this.microserviceName].internalPartitionUniqueCalls + 
                data.data.partitionData[this.microserviceName].crossPartitionUniqueCalls;
          }

        } else {
          total = this.business_logic.uniqueRuntimeCalls;
        }
        return this.$t("side-panel-top-container-text-unique-calls",{uniqueCalls: total.toLocaleString(this.locale)});
      }
      return "";
    },
    containmentLabel: function() {
      const containmentList = this.containment[this.microserviceName];
      if (containmentList && containmentList.length == 1) {
        return this.$t("one-class");
      } else if (containmentList) {
        return this.$t("numbered-classes", {
          numberClasses: containmentList.length,
        });
      } else {
        return this.$t("numbered-classes", { numberClasses: 0 });
      }
    },
    BLNumberedPartitionsLabel: function() {
      const num = this.business_logic.number_of_partitions;
      if (num == undefined) {
        return this.$t("numbered-partitions", { numberPartitions: 0 });
      } else if (num === 1) {
        return this.$t("one-partition");
      } else {
        return this.$t("numbered-partitions", { numberPartitions: num })
      }
    },
    NSNumberedPartitionsLabel: function() {
      const num = this.natural_seam.number_of_partitions;
      if (num == undefined) {
        return this.$t("numbered-partitions", { numberPartitions: 0 });
      }else if (num === 1) {
        return this.$t("one-partition");
      } else {
        return this.$t("numbered-partitions", { numberPartitions: num })
      }
    },
    CVNumberedPartitionsLabel: function() {
      const num = this.custom_view.number_of_partitions;
      if (num == undefined) {
        return this.$t("numbered-partitions", { numberPartitions: 0 });
      } else if (num === 1) {
        return this.$t("one-partition");
      } else {
        return this.$t("numbered-partitions", { numberPartitions: num })
      }
    }
  },
  methods: {
    setPartitionOrClassStats() {
      let key = constants.custom_view;
      if (this.natural_seam_view) {
            key = constants.micro_detail_partition_by_natural_seam;
          } else if ( this.business_logic_view) {
            key = constants.micro_detail_partition_by_business_logic;
        }

        let storeJson = this.$store.getters.getJson;
        let dependencyData = storeJson[constants.dependendyKey];
        const class_name = this.microserviceName;
        // Determine which partition the element is in within each graph view...
        let nodes = storeJson[key][constants.nodeId].filter((element) => element.name === class_name);
        
        if (nodes.length == 1) {
          const partitionName = nodes[0].category;
          let business_logic_data = storeJson[key];
          this.selectedClassStats = stats.getClassStats(class_name, partitionName, business_logic_data, dependencyData);
        }

        this.selectedPartitionStats = this.$store.getters.getPartitionDataBusinessLogic;
        if (this.microserviceName == this.$t('naturalSeams')) {
            this.selectedPartitionStats = this.$store.getters.getPartitionDataNaturalSeam;
          } else if ( this.microserviceName == this.$t('customView')) {
            this.selectedPartitionStats = this.$store.getters.getPartitionDataCustomView;
      }
      this.blBarData = this.setBarData(constants.micro_detail_partition_by_business_logic);
      this.nsBarData = this.setBarData(constants.micro_detail_partition_by_natural_seam);
      this.cvBarData = this.setBarData(constants.custom_view);
    },

    /*
    * Sets the bar date for different view.
    */
    setBarData(viewKey) {
      let bd = {
        internalDeps: 0,
        crossDeps: 0,
        interalCalls: 0,
        uniqueRuntimeCalls: 0,
        internalUniqueRuntimeCalls: 0,
        crossUniqueRuntimeCalls: 0,
        callsType: "partition",
        internalCallsTo: 0,
        internalCallsFrom: 0,
        crossCallsTo: 0,
        crossCallsFrom: 0,
        crossCalls: 0,
        key: 0
      };

      const d = new Date();
      bd.key = d.getTime();
      console.log("bd.key=" + bd.key);
      let storeJson = this.$store.getters.getJson;
      if (!storeJson || !Object.prototype.hasOwnProperty.call(storeJson, viewKey) 
      ||  !Object.prototype.hasOwnProperty.call(storeJson, constants.dependendyKey))
      {
        return bd;
      }
      let dependencyData = storeJson[constants.dependendyKey];
      
      if ( this.classView && this.microserviceView ) {
        // class level
        
        let key = viewKey;
        const class_name = this.microserviceName;
        // Determine which partition the element is in within each graph view...
        let nodes = storeJson[key][constants.nodeId].filter((element) => element.name === class_name);
        
        if (nodes.length == 1) {
          const partitionName = nodes[0].category;
          let vdata = storeJson[key];
          const classLevelStats  = stats.getClassStats(class_name, partitionName, vdata, dependencyData);
              


          bd.internalDeps = classLevelStats.internalPartitionDataDependencies;
          bd.crossDeps = classLevelStats.crossPartitionDataDependencies;
          bd.interalCalls = classLevelStats.internalPartitionRuntimeCalls;
          bd.uniqueRuntimeCalls = classLevelStats.crossPartitionUniqueCalls + classLevelStats.internalPartitionUniqueCalls;
          bd.internalUniqueRuntimeCalls = classLevelStats.internalPartitionUniqueCalls;
          bd.crossUniqueRuntimeCalls = classLevelStats.crossPartitionUniqueCalls;
          bd.callsType = "class";
          bd.crossCalls = classLevelStats.crossPartitionRuntimeCalls;
          bd.internalCallsTo=classLevelStats.internalCallsTo;
          bd.internalCallsFrom=classLevelStats.internalCallsFrom;
          bd.crossCallsTo=classLevelStats.crossCallsTo;
          bd.crossCallsFrom=classLevelStats.crossCallsFrom; 
        }

      } else if ( !this.classView && this.microserviceView ) {
      // partition level
        
        const data = stats.getCrossPartitionStats(storeJson[viewKey], dependencyData);
        let viewAndPartitionData = data.partitionData[this.microserviceName];
        if (viewAndPartitionData )
        {
          bd.internalDeps = viewAndPartitionData.internalDataDependencies;
          bd.crossDeps = viewAndPartitionData.crossPartitionDataDependencies;
          bd.interalCalls = viewAndPartitionData.totalPartitionRuntimeCalls - viewAndPartitionData.crossPartitionRuntimeCalls;
          bd.uniqueRuntimeCalls = viewAndPartitionData.crossPartitionUniqueCalls + viewAndPartitionData.internalPartitionUniqueCalls;
          bd.internalUniqueRuntimeCalls = viewAndPartitionData.internalPartitionUniqueCalls;
          bd.crossUniqueRuntimeCalls = viewAndPartitionData.crossPartitionUniqueCalls;
          bd.callsType = "partition";
          bd.crossCalls = viewAndPartitionData.crossPartitionRuntimeCalls;
          bd.internalCallsTo=viewAndPartitionData.internalCallsTo;
          bd.internalCallsFrom=viewAndPartitionData.internalCallsFrom;
          bd.crossCallsTo=viewAndPartitionData.crossCallsTo;
          bd.crossCallsFrom=viewAndPartitionData.crossCallsFrom;
        }
      
      } else {
        // view level
        let viewAndPartitionData = stats.getCrossPartitionStats(storeJson[viewKey], dependencyData);
        
        Object.keys(viewAndPartitionData.partitionData).forEach((partition) => {
          let partition_info = viewAndPartitionData.partitionData[partition];

          bd.internalDeps = bd.internalDeps + partition_info.internalDataDependencies;
        });

        bd.crossDeps = viewAndPartitionData.crossPartitionDependendy;
        bd.interalCalls = viewAndPartitionData.totalPartitionRuntimeCalls - viewAndPartitionData.crossPartitionRuntimeCalls;
        bd.uniqueRuntimeCalls = viewAndPartitionData.uniqueRuntimeCalls;
        bd.internalUniqueRuntimeCalls = viewAndPartitionData.internalPartitionUniqueCalls;
        bd.crossUniqueRuntimeCalls = viewAndPartitionData.crossPartitionUniqueCalls;
        bd.callsType = "view";
        bd.crossCalls = viewAndPartitionData.crossPartitionRuntimeCalls;
        //bd.internalCallsTo=100;
        //bd.internalCallsFrom=200;
        //bd.crossCallsTo=300;
        //bd.crossCallsFrom=400;
      }
      return bd;
    },
    launchEditLabels () {
      eventBus.$emit("TableView.onEditLabelsFromSidePanel", this.microserviceName);
      this.editLabelsLaunchClose = true;
      this.closeSidePanel();
    },

    computeDependenciesInTable() {
      
      let deps = this.dependencies;
      if (this.dependencySearchValue.length > 0) {
        const key = this.dependencySearchValue.toLowerCase();
        deps = deps.filter(obj => {
          const string = obj.name;
          return string.toLowerCase().indexOf(key) >= 0;
        });    
      }
      this.setNumberOfItemsInDependenciesTable(deps);
      if (this.dependencyPaginationInfo && this.dependencySearchValue.length == 0) {
        const startIndex = this.dependencyPaginationInfo.start - 1;
        const ret =  deps.slice(startIndex, startIndex + this.dependencyPaginationInfo.length);
        this.dependenciesInTable= ret;
      } else {
        const ret =  deps.slice(0,  this.dependencyTablePagination.pageSizes[0]);
        this.dependenciesInTable= ret;
      }
    },
    computeUsecasesInTable() {
      
      let usecases = this.use_cases;
      if (this.usecaseSearchValue.length > 0) {
        const key = this.usecaseSearchValue.toLowerCase();
        usecases = usecases.filter(string => {
          return string.toLowerCase().indexOf(key) >= 0;
        });    
      }
      this.setNumberOfItemsInUseCaseTable(usecases);
      if (this.usecasesPaginationInfo && this.usecaseSearchValue.length == 0) {
        const startIndex = this.usecasesPaginationInfo.start - 1;
        const ret =  usecases.slice(startIndex, startIndex + this.usecasesPaginationInfo.length);
        this.usecasesInTable= ret;
      } else {
        const ret =  usecases.slice(0,  this.usecasesTablePagination.pageSizes[0]);
        this.usecasesInTable= ret;
      }
    },

    setNumberOfItemsInUseCaseTable(usecases) {
       this.usecasesTablePagination.numberOfItems = usecases.length;
    },

    setNumberOfItemsInDependenciesTable(deps) {
       this.dependencyTablePagination.numberOfItems = deps.length;
    },
    onSort: function(sortBy) {
      if (sortBy.index === "0" && sortBy.order !== "none") {
        if ( sortBy.order === 'ascending' )
        {
          this.use_cases = this.use_cases.sort();
        } else {
          this.use_cases = this.use_cases.sort().reverse();
        }
      }
      this.computeUsecasesInTable();
    },

    onSortDependency: function(sortBy) {
      if (sortBy.index === "0" && sortBy.order !== "none") {
        if ( sortBy.order === 'ascending' )
        {
          this.dependencies = this.dependencies.sort(
            (a, b) => (a.name < b.name)? -1:((a.name > b.name)?1: 0)
          );
        } else {
          this.dependencies = this.dependencies.sort(
            (a, b) => (a.name < b.name)? 1:((a.name > b.name)? -1: 0)
          );
        }
      } else if (sortBy.index === "1" && sortBy.order !== "none") {
        if ( sortBy.order === 'ascending' )
        {
          this.dependencies = this.dependencies.sort(
            (a, b) => (a.dir < b.dir)? -1:((a.dir > b.dir)?1: 0)
          );
        } else {
          this.dependencies = this.dependencies.sort(
            (a, b) => (a.dir < b.dir)? 1:((a.dir > b.dir)? -1: 0)
          );
        }
      }
      this.computeDependenciesInTable();
    },


   onDependencyTablePagination(paginationInfo) {
      this.dependencyPaginationInfo = paginationInfo;
      this.dependencyTablePagination.page = paginationInfo.page;
      this.computeDependenciesInTable();
      this.$nextTick(function() {
        this.setTableBottomBorder();
      });
    },

    adjustSidePanelBottomContainerHeight() {
      // adjust the bottom container height as BL/NS/CV each has different top container height
      const sidePanelTopContainerHeight = document.querySelector(".sidepanel #sidepanel-top-container")?.offsetHeight;
      document.querySelector(".sidepanel #sidepanel-bottom-container").style.maxHeight = `calc(100% - ${sidePanelTopContainerHeight}px)`;

      setTimeout(this.setSidePanelFocus, 200);
    },

    onUsecasesTablePagination(paginationInfo) {
      this.usecasesPaginationInfo = paginationInfo;
      this.usecasesTablePagination.page = paginationInfo.page;
      this.computeUsecasesInTable();
      this.$nextTick(function() {
        this.setTableBottomBorder();
      });
    },

    setTableBottomBorder() {
      // determine whether to have border-bottom on the last row of the table to avoid double border with pagination top border
      const theadElementHeight = document.querySelector('edit-labels-table-data .bx--data-table thead')?.getBoundingClientRect().height;
      const tbodyElement = document.querySelector('.edit-labels-table-data .bx--data-table tbody');
      const tbodyHeight = tbodyElement?.getBoundingClientRect().height;
      const tableHeight = document.querySelector('.edit-labels-table-data .bx--data-table-container')?.getBoundingClientRect().height;
      // console.log("table height is " + tableHeight + "; tbody height is " + tbodyHeight);

      // add a class to set border-bottom of last row of the table to 0 if table total height (head + body) needs scrolling
      if (tbodyHeight + theadElementHeight >= tableHeight) {
        tbodyElement.className = "overflow-table-body";
      } else {
        tbodyElement.className = "";
      }
    },

    radioCompareViewOnChange() {
      
    },
    getMessage(messageKey) {
      return messageUtils.getMessage(messageKey);
    },
    editPartition: function(e) {
      e.preventDefault();
      this.editPartitionView = true;
      if (this.rename_invalid_msg) {
        this.rename_invalid_msg = null;
      }
      this.$nextTick(() => {
        this.$refs['edit-patition-input'].focus();
      });

    },
    closeEditPartitionMode: function(e) {
      e.preventDefault();
      this.editPartitionView = false;
      this.microserviceName = this.selectedPartition;
      if (this.rename_invalid_msg) {
        this.rename_invalid_msg = null;
      }
    },
    closeSidePanel: function(e) {
      if ( e ) {
        e.preventDefault();
      }
      eventBus.$emit("Toolbar.closePanel");
      // clear out the single row selection in table view only when edit labels is not launched from side panel
      if (e) {
        eventBus.$emit("TableView.deselectRow");
      }
      // reset minimap to original position;
      if ( e ) {
        // coming from clicking x on side panel, in this case move minimap all the way to the right
        eventBus.$emit("SidePanel.displayPanel", false);
      } else {
        // called when we launch the edit label panel, in this case the side panel is still open, so no action
      }
    },
    editPartitionName: function(e) {
      e.preventDefault();

      if (this.selectedPartition === this.microserviceName) {
        // Renaming to the same name.   Just close the edit partition section.
        this.editPartitionView = false;
        this.microserviceView = true;
        return;
      }

      let nameValidation = apis.validatePartitionName(this.microserviceName);
      // nameValidation is an array of booleans.  If the indexed entry is true, then
      // the associated error exists with the microserviceName.  Refer to the validate
      // partition name section of src/lib/constants.js to see what error each index
      // indicates.
      //
      // When nameValidation[0]==true it indicates the name passed validation.
      if (nameValidation[0]) {
        // Name is good! ....continue with the partition rename
        let options = {
          newName: this.microserviceName,
        };
        apis.renamePartition(this.selectedPartition, options);
        // set the selectedPartition to the newly renamed partition
        this.selectedPartition = this.microserviceName;
        this.editPartitionView = false;
        this.microserviceView = true;
        this.updateGraphFilterPartitionColor();
      } else {
        // Name has an error ....set the message(s) in the UI
        let errorMsg = "";
        const errorMsgs = [
          "",
          "rename-partition-duplicate-error",
          "rename-partition-invalid-error",
          "rename-partition-invalid-char-error",
          "rename-partition-unobserved-not-allowed",
        ];
        for (let x = 1; x < nameValidation.length; x++) {
          if (nameValidation[x]) {
            if (errorMsg !== "") {
              errorMsg += "\n";
            }
            errorMsg += messageUtils.getMessage(errorMsgs[x]);
          }
        }
        this.rename_invalid_msg = errorMsg; // Init the field so that it can be displayed
        this.$nextTick(() => {
          // After the field is initialized, put in the error message(s) to be displayed.
          document.querySelector(".bx--form-requirement").innerHTML = errorMsg;
          document.querySelector(".edit-partition-panel .edit-partition-mode-save-btn").blur();
        });
      }
    },
    updateCustomView: function() {
      // Don't unconditional update custom view data unless it is active
      let custom_view_data = { data: {} };
      if (this.add_custom_view) {
        // Custom view is enabled on the side panel
        custom_view_data = this.$store.getters.getPartitionDataCustomView;
        // When an existing json is loaded from top right link, this method is called
        // before custom_view_data is set in Graph.vue, so we need to load it manually,
        // otherwise the details panel won't have any custom view data.
        if ( custom_view_data === undefined || custom_view_data.data === undefined || 
             custom_view_data.data.partitionData === undefined ) {
          let json = this.$store.getters.getJson;
          let data = stats.getCrossPartitionStats(
            json["custom_view"],
            json[constants.dependendyKey]
          );
          custom_view_data.data = data;
        }
        let cv_partition_data = custom_view_data.data.partitionData;
        let partitions = [];
        if (this.custom_view_active) {
          // Since custom view partition is editable, the up to date number of partitions are kept in getPartitions
          // if it is the current selected view
          partitions = apis.getPartitionNamesArray();
          if (constants.keepUnobserved !== true) {
            partitions = partitions.filter(function(e) {
              return !utils.isUnobservedCategory(e);
            });
          }
        } else {
          partitions = Object.keys(custom_view_data.data.partitionData);
          if (constants.keepUnobserved !== true) {
            partitions = partitions.filter(function(e) {
              return !utils.isUnobservedCategory(e);
            });
          }
        }
        this.custom_view.number_of_partitions = partitions.length;
        this.custom_view.uniqueDependencyCalls = 
          custom_view_data.data.uniqueDependencyCalls;
        this.custom_view.uniqueRuntimeCalls = 
          custom_view_data.data.uniqueRuntimeCalls;
        this.custom_view.total_partition_runtime_calls =
          custom_view_data.data.totalPartitionRuntimeCalls;
        this.custom_view.cross_partition_runtime_calls =
          custom_view_data.data.crossPartitionRuntimeCalls;
        this.custom_view.internal_partition_runtime_calls =
          custom_view_data.data.totalPartitionRuntimeCalls -
          custom_view_data.data.crossPartitionRuntimeCalls;
        this.custom_view.cross_partition_data_dependencies =
          custom_view_data.data.crossPartitionDependendy;
        // Summarize the internal partition information from each partition
        let cv_internalDependencies = 0;
        Object.keys(cv_partition_data).forEach((partition) => {
          let partition_info = cv_partition_data[partition];
          if (partition_info.internalDataDependencies) {
            cv_internalDependencies =
              partition_info.internalDataDependencies + cv_internalDependencies;
          }
        });
        this.custom_view.internal_data_dependencies = cv_internalDependencies;

        // Get the info needed for the linear graphs.
        let total_crossPartitionRuntimeCalls = Math.max(
          this.custom_view.cross_partition_runtime_calls +
            this.custom_view.internal_partition_runtime_calls,
          1
        );
        let total_intraPartitionRuntimeCalls = total_crossPartitionRuntimeCalls;
        let total_crossPartitionDataDependencies = Math.max(
          this.custom_view.cross_partition_data_dependencies +
            this.custom_view.internal_data_dependencies,
          1
        );
        let total_intraPartitionDataDependencies = total_crossPartitionDataDependencies;

        let cv_intra_runtimeCallsLength =
          100 -
          Math.ceil(
            (this.custom_view.internal_partition_runtime_calls /
              total_intraPartitionRuntimeCalls) *
              100
          );
        let cv_crossp_runtimeCallsLength =
          100 -
          Math.ceil(
            (this.custom_view.cross_partition_runtime_calls /
              total_crossPartitionRuntimeCalls) *
              100
          );
        let cv_intra_dependenciesLength =
          100 -
          Math.ceil(
            (this.custom_view.internal_data_dependencies /
              total_intraPartitionDataDependencies) *
              100
          );
        let cv_crossp_dependenciesLength =
          100 -
          Math.ceil(
            (this.custom_view.cross_partition_data_dependencies /
              total_crossPartitionDataDependencies) *
              100
          );
        this.cv_intraPartitionRuntimeCallsPercentage =
          cv_intra_runtimeCallsLength + "%";
        this.cv_crossPartitionRuntimeCallsPercentage =
          cv_crossp_runtimeCallsLength + "%";
        this.cv_intraPartitionDependenciesPercentage =
          cv_intra_dependenciesLength + "%";
        this.cv_crossPartitionDataDependenciesPercentage =
          cv_crossp_dependenciesLength + "%";

        if (this.custom_view_active) {
          if (
            !this.editPartitionView &&
            this.microserviceView &&
            !this.classView &&
            this.microserviceName !== ""
          ) {
            // Update the class number if in partition view but not during editing the partition name
            let nodeElement = utils.getNodeByName(
              this.$store.getters.getCYInstance(),
              this.microserviceName
            );
            if (nodeElement) {
              this.updatePartitionNumOfClass(nodeElement._private.data);
              this.use_cases = nodeElement._private.data.semantics; // Update use cases for the partition
            } else {
              // highlighted partition is not there anymore due to partition editing, show refactoring view instead
              this.microserviceView = false;
            }
          } else if (this.classView && this.microserviceName !== "") {
            const storeJson = this.$store.getters.getJson;
            if (storeJson["custom_view"]) {
              let CVNodes = storeJson["custom_view"][constants.nodeId].filter((element) => element.name === this.microserviceName);
              if (
                CVNodes.length == 1 &&
                this.CV_partition_name !== CVNodes[0].category
              ) {
                // Update the partition name
                this.CV_partition_name = CVNodes[0].category;
                // Update the partition color
                this.partitionColor = apis.getCurrentViewsClusterColorMap()[CVNodes[0].category];
              }
              // Update the stats for the class
              let dependencyData = storeJson[constants.dependendyKey];
              let custom_view_data = storeJson["custom_view"];
              let cv_stats = stats.getClassStats(this.microserviceName, this.CV_partition_name, custom_view_data, dependencyData);
              this.selectedElementViewData.internalRuntimeCalls = cv_stats.internalPartitionRuntimeCalls;
              this.selectedElementViewData.internalDataDependencies = cv_stats.internalPartitionDataDependencies;
              this.selectedElementViewData.crossPartitionRuntimeCalls = cv_stats.crossPartitionRuntimeCalls;
              this.selectedElementViewData.crossPartitionDataDependencies = cv_stats.crossPartitionDataDependencies;
              this.selectedElementViewData.totalRuntimeCalls = cv_stats.internalPartitionRuntimeCalls + cv_stats.crossPartitionRuntimeCalls;
            }
          }
        }
      } else {
        // Re-initialize the sidePanel custom view data
        this.custom_view.number_of_partitions = 0;
      }
      return custom_view_data;
    },

    updateSelectedClassLabelsFromEvent(data) {
      const labels = data.labels;
        const classNames = data.classNames;
        // only apply changes to the current selected class
        if ( labels && classNames && classNames.length==1) {
          // check if class name equals to selected class
          if ( this.microserviceView == true && this.classView == true && this.microserviceName == classNames[0]) {
            // selectedClassLabels
            for (const [key, value] of Object.entries(labels)) {
              console.log(`${key}: ${value}`);
              if (value) {
                this.selectedClassLabels.push(key);
              } else {
                var index = this.selectedClassLabels.indexOf(key);
                if (index !== -1) {
                  this.selectedClassLabels.splice(index, 1);
                }
              }
            }
            this.selectedClassLabels.sort();
          }
        }
    },
    updatePartitionNumOfClass: function(privateData) {
      let numClass = "";
      if (Number.isInteger(privateData.ui_classNumber)) {
        if (privateData.ui_classNumber !== 1) {
          numClass = this.$t("numbered-classes", {
            numberClasses: privateData.ui_classNumber,
          });
        } else {
          numClass = this.$t("one-class");
        }
      }
      if (this.display_num_classes !== numClass) {
        this.display_num_classes = numClass;
      }
    },
    updateBottomHeight: function() {
      //const topHeight = document.querySelector("#sidepanel-top-container").offsetHeight;
      //document.querySelector("#sidepanel-bottom-container").setAttribute("style", `max-height: calc(100% - ${topHeight}px)`);
    },
    showMicroserviceClassButton: function(nodeEle) {
      // handle show/hide classes button
      if (utils.isExpandedPartition(nodeEle)) {
        this.showClasses = false;
      } else {
        this.showClasses = true;
      }
    },
    showHideClasses: function() {
      // expand/collapse the partition
      apis.expandCollapsePartition(this.selectedPartition);
      this.showClasses = !this.showClasses;
    },
    copyPath: function() {
      let temp = document.createElement("input");
      temp.setAttribute("value", this.copyClassPath);
      document.body.appendChild(temp);
      temp.select();
      document.execCommand("copy");
      document.body.removeChild(temp);
    },
    showPathPanel: function() {
      if (!this.pathViewOpen) {
        this.pathViewOpen = true;
        this.calculateDisplayPath();
      } else {
        this.pathViewOpen = false;
      }
    },
    updateGraphFilterPartitionColor: function() {
      eventBus.$emit("FilterPanel.updatePanel", {
        filtersToBeUpdated: [constants.filterTypePartition],
      });
    },
    calculateDisplayPath(beginIndex) {
      this.$nextTick(() => {
        if (this.pathViewOpen) {
          let displayPathElement = document.querySelector(".path-label");
          if (this.classPath && this.classPath.length > 0) {
            displayPathElement.style.overflow = "visible";
            const noellipsisHeight = displayPathElement.offsetHeight;
            // The path panel is setup to display maximum of 4 lines with 16px as the line height.
            // If more than 4 lines are needed to display the path, need to trim off the path and
            // prepend ... at the beginning.
            if (noellipsisHeight > 64) {
              const pathString = this.copyClassPath.trim();
              let startIndex = 2;
              if (beginIndex) {
                startIndex = beginIndex + 1;
              } else if (noellipsisHeight > 80) {
                // Get a rough estimate on where to start trimming the path.
                // For each overflow line above 5 lines, trim off 30 characters.
                const numOfOverflowLines = noellipsisHeight / 16 - 5;
                startIndex = startIndex + numOfOverflowLines * 30;
              }
              let displayPathString = "..." + pathString.substring(startIndex);
              this.classPath = displayPathString;
              displayPathElement.style.overflow = "visible";
              this.calculateDisplayPath(startIndex);
            } else {
              displayPathElement.style.overflow = "hidden";
            }
          }
        }
      });
    },
    setClassPartitionInfo: function(class_name) {
      let storeJson = this.$store.getters.getJson;
      let dependencyData = storeJson[constants.dependendyKey];

      // Determine which partition the element is in within each graph view...
      let BLNodes = storeJson["micro_detail_partition_by_business_logic"][constants.nodeId].filter((element) => element.name === class_name);
      if (BLNodes.length == 1) {
        this.BL_partition_name = BLNodes[0].category;
        let business_logic_data = storeJson["micro_detail_partition_by_business_logic"];
        let bl_stats = stats.getClassStats(class_name, this.BL_partition_name, business_logic_data, dependencyData);
        this.businessLogicElementViewData.internalRuntimeCalls = bl_stats.internalPartitionRuntimeCalls;
        this.businessLogicElementViewData.internalDataDependencies = bl_stats.internalPartitionDataDependencies;
        this.businessLogicElementViewData.crossPartitionRuntimeCalls = bl_stats.crossPartitionRuntimeCalls;
        this.businessLogicElementViewData.crossPartitionDataDependencies = bl_stats.crossPartitionDataDependencies;
        this.businessLogicElementViewData.totalRuntimeCalls = bl_stats.internalPartitionRuntimeCalls + bl_stats.crossPartitionRuntimeCalls;
      }
      let NSNodes = storeJson["micro_detail_partition_by_natural_seam"][constants.nodeId].filter((element) => element.name === class_name);
      if (NSNodes.length == 1) {
        this.NS_partition_name = NSNodes[0].category;
        let natural_seam_data = storeJson["micro_detail_partition_by_natural_seam"];
        let ns_stats = stats.getClassStats(class_name, this.NS_partition_name, natural_seam_data, dependencyData);
        this.naturalSeamElementViewData.internalRuntimeCalls = ns_stats.internalPartitionRuntimeCalls;
        this.naturalSeamElementViewData.internalDataDependencies = ns_stats.internalPartitionDataDependencies;
        this.naturalSeamElementViewData.crossPartitionRuntimeCalls = ns_stats.crossPartitionRuntimeCalls;
        this.naturalSeamElementViewData.crossPartitionDataDependencies = ns_stats.crossPartitionDataDependencies;
        this.naturalSeamElementViewData.totalRuntimeCalls = ns_stats.internalPartitionRuntimeCalls + ns_stats.crossPartitionRuntimeCalls;
      }
      this.CV_partition_name = "";
      if (storeJson["custom_view"]) {
        let CVNodes = storeJson["custom_view"][constants.nodeId].filter((element) => element.name === class_name);
        if (CVNodes.length == 1) {
          this.CV_partition_name = CVNodes[0].category;
          let custom_view_data = storeJson["custom_view"];
          let cv_stats = stats.getClassStats(class_name, this.CV_partition_name, custom_view_data, dependencyData);
          this.selectedElementViewData.internalRuntimeCalls = cv_stats.internalPartitionRuntimeCalls;
          this.selectedElementViewData.internalDataDependencies = cv_stats.internalPartitionDataDependencies;
          this.selectedElementViewData.crossPartitionRuntimeCalls = cv_stats.crossPartitionRuntimeCalls;
          this.selectedElementViewData.crossPartitionDataDependencies = cv_stats.crossPartitionDataDependencies;
          this.selectedElementViewData.totalRuntimeCalls = cv_stats.internalPartitionRuntimeCalls + cv_stats.crossPartitionRuntimeCalls;
        }
      }
    },
    initializeContainment: function() {
      this.containment = {};
      this.$store.getters.getNodes.forEach((node) => {
        const parent = node.parent;
        const name = node.name;
        if (parent in this.containment) {
          this.containment[parent].push(name);
        } else {
          this.containment[parent] = [name];
        }
      });
    },
    setSidePanelFocus: function() {
      if (this.selected_view === "micro_detail_partition_by_natural_seam") {
        this.$refs?.naturalSeamsRef?.focus();
      } else if (this.selected_view === "micro_detail_partition_by_business_logic") {
        this.$refs?.businessLogicRef?.focus();
      } else if (this.selected_view === "custom_view") {
        this.$refs?.customViewRef?.focus();
      }
    },
      resetTableToFirstPage() {
      if (this.usecasesPaginationInfo.page != 1) {
        this.usecasesTablePagination.page = 1;
        this.usecasesTablePagination.numberOfItems = this.use_cases.length;
        this.usecasesPaginationInfo.page = 1;
        this.usecasesPaginationInfo.start = 1;
      }

      if (this.dependencyPaginationInfo.page != 1) {
        this.dependencyTablePagination.page = 1;
        this.dependencyTablePagination.numberOfItems = this.dependencies.length;
        this.dependencyPaginationInfo.page = 1;
        this.dependencyPaginationInfo.start = 1;
      }
    },
  },
  mounted() {},
  updated() {
     
      
      
      let business_logic_data = this.$store.getters.getPartitionDataBusinessLogic;
      // At the beginning, FileUploader calls event, SidePanel.newFileInitialization
      // which triggers this updated hook before the PartitionDataBusinessLogic is initialized in the store.
      // therefore, needs this small check
      if (!Object.prototype.hasOwnProperty.call(business_logic_data, 'data')) {
        return
      }
      let bl_partition_data = business_logic_data.data.partitionData;
      let natural_seam_data = this.$store.getters.getPartitionDataNaturalSeam;
      let ns_partition_data = natural_seam_data.data.partitionData;

      let custom_view_data = this.updateCustomView();

      this.selected_view = this.$store.getters.getKey;
      let selected_data_to_use;
      if (this.selected_view == "micro_detail_partition_by_natural_seam") {
        this.natural_seam_view = true;
        this.business_logic_view = false;
        this.data_dependency_view = false;
        selected_data_to_use = natural_seam_data;
      } else if (this.selected_view == "micro_detail_partition_by_business_logic") {
        this.business_logic_view = true;
        this.natural_seam_view = false;
        this.data_dependency_view = false;
        selected_data_to_use = business_logic_data;
      } else if (this.selected_view == "custom_view") {
        this.business_logic_view = false;
        this.natural_seam_view = false;
        this.data_dependency_view = false;
        selected_data_to_use = custom_view_data;
      } else if (this.selected_view == "mono_data_dependency_graph") {
        this.data_dependency_view = true;
        this.business_logic_view = false;
        this.natural_seam_view = false;
        selected_data_to_use = { data: { partitionData: {} } };
      }

    if (!this.microserviceView && !this.classView) {
      // Refactoring view....

      let partitions = Object.keys(natural_seam_data.data.partitionData);
      if (constants.keepUnobserved !== true) {
        partitions = partitions.filter(function(e) {
          return !utils.isUnobservedCategory(e);
        });
      }
      this.natural_seam.number_of_partitions = partitions.length;
      this.natural_seam.uniqueDependencyCalls = 
          natural_seam_data.data.uniqueDependencyCalls;
      this.natural_seam.uniqueRuntimeCalls = 
          natural_seam_data.data.uniqueRuntimeCalls;

      this.natural_seam.total_partition_runtime_calls =
        natural_seam_data.data.totalPartitionRuntimeCalls;
      this.natural_seam.cross_partition_runtime_calls =
        natural_seam_data.data.crossPartitionRuntimeCalls;
      this.natural_seam.internal_partition_runtime_calls =
        natural_seam_data.data.totalPartitionRuntimeCalls -
        natural_seam_data.data.crossPartitionRuntimeCalls;
      this.natural_seam.cross_partition_data_dependencies =
        natural_seam_data.data.crossPartitionDependendy;
      // Summarize the internal partition information from each partition
      let ns_internalDependencies = 0;
      Object.keys(ns_partition_data).forEach((partition) => {
        let partition_info = ns_partition_data[partition];
        if (partition_info.internalDataDependencies) {
          ns_internalDependencies =
            partition_info.internalDataDependencies + ns_internalDependencies;
        }
      });
      this.natural_seam.internal_data_dependencies = ns_internalDependencies;

      partitions = Object.keys(business_logic_data.data.partitionData);
      if (constants.keepUnobserved !== true) {
        partitions = partitions.filter(function(e) {
          return !utils.isUnobservedCategory(e);
        });
      }
      this.business_logic.number_of_partitions = partitions.length;
      this.business_logic.uniqueDependencyCalls = 
          business_logic_data.data.uniqueDependencyCalls;
      this.business_logic.uniqueRuntimeCalls = 
          business_logic_data.data.uniqueRuntimeCalls;
      this.business_logic.total_partition_runtime_calls =
        business_logic_data.data.totalPartitionRuntimeCalls;
      this.business_logic.cross_partition_runtime_calls =
        business_logic_data.data.crossPartitionRuntimeCalls;
      this.business_logic.internal_partition_runtime_calls =
        business_logic_data.data.totalPartitionRuntimeCalls -
        business_logic_data.data.crossPartitionRuntimeCalls;
      this.business_logic.cross_partition_data_dependencies =
        business_logic_data.data.crossPartitionDependendy;
      // Summarize the internal partition information from each partition
      let bl_internalDependencies = 0;
      Object.keys(bl_partition_data).forEach((partition) => {
        let partition_info = bl_partition_data[partition];
        if (partition_info.internalDataDependencies) {
          bl_internalDependencies =
            partition_info.internalDataDependencies + bl_internalDependencies;
        }
      });
      this.business_logic.internal_data_dependencies = bl_internalDependencies;

      // Get the info needed for the linear graphs.
      let total_runtimeCalls = Math.max(
        this.natural_seam.cross_partition_runtime_calls +
          this.natural_seam.internal_partition_runtime_calls,
        1
      );
      let total_dataDependencies = Math.max(
        this.natural_seam.cross_partition_data_dependencies +
          this.natural_seam.internal_data_dependencies,
        1
      );

      let bl_intra_runtimeCallsLength =
        100 -
        Math.ceil(
          (this.business_logic.internal_partition_runtime_calls /
            total_runtimeCalls) *
            100
        );
      let bl_crossp_runtimeCallsLength =
        100 -
        Math.ceil(
          (this.business_logic.cross_partition_runtime_calls /
            total_runtimeCalls) *
            100
        );
      let bl_intra_dependenciesLength =
        100 -
        Math.ceil(
          (this.business_logic.internal_data_dependencies /
            total_dataDependencies) *
            100
        );
      let bl_crossp_dependenciesLength =
        100 -
        Math.ceil(
          (this.business_logic.cross_partition_data_dependencies /
            total_dataDependencies) *
            100
        );
      this.bl_intraPartitionRuntimeCallsPercentage =
        bl_intra_runtimeCallsLength + "%";
      this.bl_crossPartitionRuntimeCallsPercentage =
        bl_crossp_runtimeCallsLength + "%";
      this.bl_intraPartitionDependenciesPercentage =
        bl_intra_dependenciesLength + "%";
      this.bl_crossPartitionDataDependenciesPercentage =
        bl_crossp_dependenciesLength + "%";

      let ns_intra_runtimeCallsLength =
        100 -
        Math.ceil(
          (this.natural_seam.internal_partition_runtime_calls /
            total_runtimeCalls) *
            100
        );
      let ns_crossp_runtimeCallsLength =
        100 -
        Math.ceil(
          (this.natural_seam.cross_partition_runtime_calls / total_runtimeCalls) *
            100
        );
      let ns_intra_dependenciesLength =
        100 -
        Math.ceil(
          (this.natural_seam.internal_data_dependencies /
            total_dataDependencies) *
            100
        );
      let ns_crossp_dependenciesLength =
        100 -
        Math.ceil(
          (this.natural_seam.cross_partition_data_dependencies /
            total_dataDependencies) *
            100
        );
      this.ns_intraPartitionRuntimeCallsPercentage =
        ns_intra_runtimeCallsLength + "%";
      this.ns_crossPartitionRuntimeCallsPercentage =
        ns_crossp_runtimeCallsLength + "%";
      this.ns_intraPartitionDependenciesPercentage =
        ns_intra_dependenciesLength + "%";
      this.ns_crossPartitionDataDependenciesPercentage =
        ns_crossp_dependenciesLength + "%";

    } else if (this.microserviceView || this.classView) {
      // Viewing a partition or class

      if (natural_seam_data) {
        let partitionName;
        if (this.NS_partition_name.length > 0) {
          // Viewing a class.  The class is a member of NS_partition_name
          partitionName = this.NS_partition_name;
        } else if (this.natural_seam_view) {
          // Viewing a partition.
          partitionName = this.selectedPartition;
        }

        if (partitionName) {
          if (!this.classView) {
            //Set the data to display for this partition
            let selectedPartitionData = ns_partition_data[partitionName];
            this.naturalSeamElementViewData.crossPartitionDataDependencies =
              selectedPartitionData.crossPartitionDataDependencies;
            this.naturalSeamElementViewData.crossPartitionRuntimeCalls =
              selectedPartitionData.crossPartitionRuntimeCalls;
            this.naturalSeamElementViewData.totalRuntimeCalls =
              selectedPartitionData.totalPartitionRuntimeCalls;
            this.naturalSeamElementViewData.internalRuntimeCalls =
              selectedPartitionData.totalPartitionRuntimeCalls -
              selectedPartitionData.crossPartitionRuntimeCalls;
            this.naturalSeamElementViewData.internalDataDependencies =
              selectedPartitionData.internalDataDependencies;
          }
          // Else viewing a class. The data to display for the class was set when the
          // class was selected in the naturalSeamElementViewData object. (See setClassPartitionInfo())

          // Get the info needed for the linear graphs.
          let total_ns_runtimeCalls = Math.max(
            this.naturalSeamElementViewData.totalRuntimeCalls,
            1
          );
          let total_ns_dataDependencies = Math.max(
            this.naturalSeamElementViewData.crossPartitionDataDependencies +
              this.naturalSeamElementViewData.internalDataDependencies,
            1
          );

          // Set up the linear graphs
          let ns_intra_runtimeCallsLength =
            100 -
            Math.ceil(
              (this.naturalSeamElementViewData.internalRuntimeCalls /
                total_ns_runtimeCalls) *
                100
            );
          let ns_crossp_runtimeCallsLength =
            100 -
            Math.ceil(
              (this.naturalSeamElementViewData.crossPartitionRuntimeCalls /
                total_ns_runtimeCalls) *
                100
            );
          let ns_intra_dependenciesLength =
            100 -
            Math.ceil(
              (this.naturalSeamElementViewData.internalDataDependencies /
                total_ns_dataDependencies) *
                100
            );
          let ns_crossp_dependenciesLength =
            100 -
            Math.ceil(
              (this.naturalSeamElementViewData.crossPartitionDataDependencies /
                total_ns_dataDependencies) *
                100
            );
          this.ns_element_intraPartitionRuntimeCallsPercentage =
            ns_intra_runtimeCallsLength + "%";
          this.ns_element_crossPartitionRuntimeCallsPercentage =
            ns_crossp_runtimeCallsLength + "%";
          this.ns_element_intraPartitionDependenciesPercentage =
            ns_intra_dependenciesLength + "%";
          this.ns_element_crossPartitionDataDependenciesPercentage =
            ns_crossp_dependenciesLength + "%";
        }
      }

      if (business_logic_data) {
        let partitionName;
        if (this.BL_partition_name.length > 0) {
          // Viewing a class.  The class is a member of BL_partition_name
          partitionName = this.BL_partition_name;
        } else if (this.business_logic_view) {
          // Viewing a partition.
          partitionName = this.selectedPartition;
        }

        if (partitionName) {
          if (!this.classView) {
            //Set the data to display for this partition
            let selectedPartitionData = bl_partition_data[partitionName];
            this.businessLogicElementViewData.crossPartitionDataDependencies =
              selectedPartitionData.crossPartitionDataDependencies;
            this.businessLogicElementViewData.crossPartitionRuntimeCalls =
              selectedPartitionData.crossPartitionRuntimeCalls;
            this.businessLogicElementViewData.totalRuntimeCalls =
              selectedPartitionData.totalPartitionRuntimeCalls;
            this.businessLogicElementViewData.internalRuntimeCalls =
              selectedPartitionData.totalPartitionRuntimeCalls -
              selectedPartitionData.crossPartitionRuntimeCalls;
            this.businessLogicElementViewData.internalDataDependencies =
              selectedPartitionData.internalDataDependencies;
          }
          // Else viewing a class. The data to display for the class was set when the
          // class was selected in the businessLogicElementViewData object. (See setClassPartitionInfo())

          // Get the info needed for the linear graphs.
          let total_bl_rutimeCalls = Math.max(
            this.businessLogicElementViewData.totalRuntimeCalls,
            1
          );
          let total_bl_dataDependencies = Math.max(
            this.businessLogicElementViewData.crossPartitionDataDependencies +
              this.businessLogicElementViewData.internalDataDependencies,
            1
          );

          // Set up the linear graphs
          let bl_intra_runtimeCallsLength =
            100 -
            Math.ceil(
              (this.businessLogicElementViewData.internalRuntimeCalls /
                total_bl_rutimeCalls) *
                100
            );
          let bl_crossp_runtimeCallsLength =
            100 -
            Math.ceil(
              (this.businessLogicElementViewData.crossPartitionRuntimeCalls /
                total_bl_rutimeCalls) *
                100
            );
          let bl_intra_dependenciesLength =
            100 -
            Math.ceil(
              (this.businessLogicElementViewData.internalDataDependencies /
                total_bl_dataDependencies) *
                100
            );
          let bl_crossp_dependenciesLength =
            100 -
            Math.ceil(
              (this.businessLogicElementViewData.crossPartitionDataDependencies /
                total_bl_dataDependencies) *
                100
            );
          this.bl_element_intraPartitionRuntimeCallsPercentage =
            bl_intra_runtimeCallsLength + "%";
          this.bl_element_crossPartitionRuntimeCallsPercentage =
            bl_crossp_runtimeCallsLength + "%";
          this.bl_element_intraPartitionDependenciesPercentage =
            bl_intra_dependenciesLength + "%";
          this.bl_element_crossPartitionDataDependenciesPercentage =
            bl_crossp_dependenciesLength + "%";
        }
      }

      if (this.add_custom_view) {
        let partitionName;
        if (this.CV_partition_name.length > 0) {
          // Viewing a class.  The class is a member of CV_partition_name
          partitionName = this.CV_partition_name;
        } else if (this.custom_view_active) {
          // Viewing a partition.
          partitionName = this.selectedPartition;
        }

        if (partitionName) {
          if (!this.classView) {
            //Set the data to display for this partition
            let selectedPartitionData = selected_data_to_use.data.partitionData[partitionName];
            this.selectedElementViewData.crossPartitionDataDependencies =
              selectedPartitionData.crossPartitionDataDependencies;
            this.selectedElementViewData.crossPartitionRuntimeCalls =
              selectedPartitionData.crossPartitionRuntimeCalls;
            this.selectedElementViewData.totalRuntimeCalls =
              selectedPartitionData.totalPartitionRuntimeCalls;
            this.selectedElementViewData.internalRuntimeCalls =
              selectedPartitionData.totalPartitionRuntimeCalls -
              selectedPartitionData.crossPartitionRuntimeCalls;
            this.selectedElementViewData.internalDataDependencies =
              selectedPartitionData.internalDataDependencies;
          }
          // Else viewing a class. The data to display for the class was set when the
          // class was selected in the selectedElementViewData object. (See setClassPartitionInfo())

          // Get the info needed for the linear graphs.
          let total_cv_runtimeCalls = Math.max(
            this.selectedElementViewData.totalRuntimeCalls,
            1
          );
          let total_cv_dataDependencies = Math.max(
            this.selectedElementViewData.crossPartitionDataDependencies +
              this.selectedElementViewData.internalDataDependencies,
            1
          );

          // Set up the linear graphs
          let cv_intra_runtimeCallsLength =
            100 -
            Math.ceil(
              (this.selectedElementViewData.internalRuntimeCalls /
                total_cv_runtimeCalls) *
                100
            );
          let cv_crossp_runtimeCallsLength =
            100 -
            Math.ceil(
              (this.selectedElementViewData.crossPartitionRuntimeCalls /
                total_cv_runtimeCalls) *
                100
            );
          let cv_intra_dependenciesLength =
            100 -
            Math.ceil(
              (this.selectedElementViewData.internalDataDependencies /
                total_cv_dataDependencies) *
                100
            );
          let cv_crossp_dependenciesLength =
            100 -
            Math.ceil(
              (this.selectedElementViewData.crossPartitionDataDependencies /
                total_cv_dataDependencies) *
                100
            );
          this.cv_element_intraPartitionRuntimeCallsPercentage =
            cv_intra_runtimeCallsLength + "%";
          this.cv_element_crossPartitionRuntimeCallsPercentage =
            cv_crossp_runtimeCallsLength + "%";
          this.cv_element_intraPartitionDependenciesPercentage =
            cv_intra_dependenciesLength + "%";
          this.cv_element_crossPartitionDataDependenciesPercentage =
            cv_crossp_dependenciesLength + "%";
        }
      }
    }
  },
  watch: {

    use_cases: function() {
      this.computeUsecasesInTable();
    },

    dependencies: function() {
      this.computeDependenciesInTable();
    },

    dependencySearchValue: function() {
      this.computeDependenciesInTable();
    },

    usecaseSearchValue: function() {
      this.computeUsecasesInTable();
    },
  },
  created() {
    eventBus.$on("SidePanel.displayPanel", (open) => {
      if (this.editLabelsLaunchClose) {
        // continue to keep minimap to the left
        this.editLabelsLaunchClose = false;
      } else {
        eventBus.$emit("Graph.sidePanelOpen", open);
      }
      eventBus.$emit("Toolbar.updateDetailsButton", false);
      // call to take care of moving the table when details panel is shown/hide
      eventBus.$emit("TableView.onDetailsPanel", open)
      this.showPanel = open;
      if (open) {
        this.$nextTick(() => {
          this.adjustSidePanelBottomContainerHeight();
        });
      }
    });

    /*
     * Show the overall view
     */
    eventBus.$on("SidePanel.showPanel", (open) => {
      this.sidePanelHeaderText = this.$t("side-panel-top-container-header-text-overall");
      this.loadPanel = true;
      // if no parameter is passed in, keep the current state of showPanel data
      if (open !== undefined) {
        this.showPanel = open;
      }
      this.microserviceView = false;
      this.classView = false;
      this.classPath = "";
      this.copyClassPath = "";
      this.editPartitionView = false;
      this.reset_show_dialog = false;
      this.pathViewOpen = false;
      this.data_dependency_view = false;
      eventBus.$emit("GraphSummaryTopPanel.expandGraphTopPanel", false);
      eventBus.$emit("Toolbar.updateDetailsButton", open);

      this.selected_view = this.$store.getters.getKey;
      if (this.selected_view == "micro_detail_partition_by_natural_seam") {
        this.natural_seam_view = true;
        this.business_logic_view = false;
        this.microserviceName = this.$t("naturalSeams");
        this.overallDescription = this.$t("side-panel-top-container-text-overall-ns");
      } else if (this.selected_view == "micro_detail_partition_by_business_logic") {
        this.business_logic_view = true;
        this.natural_seam_view = false;
        this.microserviceName = this.$t("businessLogic");
        this.overallDescription = this.$t("side-panel-top-container-text-overall-bl");
      } else if (this.selected_view == "custom_view") {
        this.business_logic_view = false;
        this.natural_seam_view = false;
        this.microserviceName = this.$t("customView");
        this.overallDescription = this.$t("side-panel-top-container-text-overall-cv");
      } else if (this.selected_view == "mono_data_dependency_graph") {
        this.data_dependency_view = true;
        this.business_logic_view = false;
        this.natural_seam_view = false;
        this.microserviceName = this.$t("dataDependencies");
        this.overallDescription = this.$t("side-panel-top-container-text-overall-cd");
      }

      // total use cases
      
      const json = this.$store.getters.getJson;
      stats.getUseCases(json[constants.runtimeKey], null);
      const useCases = this.$store.getters.getUseCases;
      const key = this.$store.getters.getKey;
      this.totalUseCases = [];
      if ( key && useCases && Object.prototype.hasOwnProperty.call(useCases, key)) {
        const view = useCases[key];
        if (Object.prototype.hasOwnProperty.call(view, "overview")) {
          const v = view["overview"];
          console.log(v);
          this.totalUseCases =  Object.keys(v);
          console.log(this.totalUseCases);
          this.totalUseCases.sort();
          this.computeUsecasesInTable();
        }
      }
      this.use_cases = this.totalUseCases;

      
      if ( !this.microserviceView && !this.classView )
      {
        this.totalClasses = json[constants.micro_detail_partition_by_business_logic][constants.nodeId].length;
      } 

      
    

      this.$nextTick(() => {
        // close any opened accordion view that is not for the selected view and
        // open the selected accordion view if it is closed
        if (
          this.$refs.naturalSeamsRef &&
          ((this.$refs.naturalSeamsRef.dataOpen &&
            this.selected_view !== "micro_detail_partition_by_natural_seam") ||
            (!this.$refs.naturalSeamsRef.dataOpen &&
              this.selected_view === "micro_detail_partition_by_natural_seam"))
        ) {
          this.$refs.naturalSeamsRef.toggle();
        }
        if (
          this.$refs.businessLogicRef &&
          ((this.$refs.businessLogicRef.dataOpen &&
            this.selected_view !==
              "micro_detail_partition_by_business_logic") ||
            (!this.$refs.businessLogicRef.dataOpen &&
              this.selected_view ===
                "micro_detail_partition_by_business_logic"))
        ) {
          this.$refs.businessLogicRef.toggle();
        }
        if (
          (this.add_custom_view &&
            this.$refs.customViewRef &&
            this.$refs.customViewRef.dataOpen &&
            this.selected_view !== "custom_view") ||
          (this.add_custom_view &&
            this.$refs.customViewRef &&
            !this.$refs.customViewRef.dataOpen &&
            this.selected_view === "custom_view")
        ) {
          this.$refs.customViewRef.toggle();
        }
        this.initializeContainment();

        
      });
    });

    /* Show partition details  or class details*/
    eventBus.$on("SidePanel.showMicroservicePanel", (partition) => {
      if (this.editPartitionView) {
        this.editPartitionView = false;
      }

      if (this.classPath) {
        this.classPath = "";
        this.copyClassPath = "";
      }

      this.BL_partition_name = this.NS_partition_name = this.CV_partition_name =
        "";

      const privateData = partition._private.data;
      const classConstructTypes = [
        "Class",
        "Entity",
        "Service Entry",
        "Service and Class",
      ];
      this.overallDescription = "";
      // If the clicked element is a cluster ...
      if (privateData.filepath == "Cluster") {
        if (privateData.ui_partitionColor) {
          this.partitionColor = privateData.ui_partitionColor;
        }
        this.sidePanelHeaderText = this.$t("side-panel-top-container-header-text-partition");
        this.microserviceView = true;
        this.classView = false;
        this.updatePartitionNumOfClass(privateData);
        // If the clicked element is a class in views other than data dependency...
      } else if (
        classConstructTypes.includes(privateData.construct) ||
        !("FQCN" in privateData)
      ) {
        // Determine which partition the element is in within each graph view...
        this.setClassPartitionInfo(privateData.name);

        this.partitionColor = apis.getCurrentViewsClusterColorMap()[
          privateData.category
        ];
        if (privateData.filepath) {
          this.classPath = privateData.filepath.replace(/Class,/g, "").trim();
          this.copyClassPath = this.classPath;
        } else {
          this.classPath = "";
          this.copyClassPath = "";
        }
        this.microserviceView = true;
        this.classView = true;
        this.sidePanelHeaderText = this.$t("side-panel-top-container-header-text-class");
        this.calculateDisplayPath();
      } else {
        this.microserviceView = false;
        this.setClassPartitionInfo(privateData.name);
        this.classView = true;
        this.sidePanelHeaderText = this.$t("side-panel-top-container-header-text-class");
      }

      if (this.classView && privateData.name != privateData.category) {
        this.selectedClassCurrentPartition = privateData.category;
        this.selectedClassOriginalPartition = privateData.originalCategory;
        this.selectedClassPath = privateData.filepath;
        // get labels
        this.allLabels = this.$store.getters.getLabels;
        this.selectedClassLabels= [];
        // loop through all labels to construct it for each class it is assigned to
        this.allLabels.forEach((label) => {
          const classesInLabel = label.assignedClasses;
          if ( classesInLabel.includes(privateData.name)) {
            this.selectedClassLabels.push(label.name);
          }
        });
        this.selectedClassLabels.sort();
      }
      this.microserviceName = privateData.name;       // Name of class or partition being viewed.
      this.selectedPartition = privateData.category;
      if ( this.selectedPartition === "Unobserved") {
        this.isUnObserved = true;
      } else {
        this.isUnObserved = false;
      }
      this.use_cases = privateData.semantics;
      console.log(this.use_cases);

      // show dependency
  
      this.dependencies=[];
      if ( this.isShowDependencyOn ) {
        // const json = this.$store.getters.getJson;
        // const depData = json[constants.dependendyKey];
        // depData.links.forEach(element => {
        //   if ( element.source == privateData.name) {
        //     this.dependencies.push({name: element.target, dir: "from"});
        //   } else if ( element.target == privateData.name) {
        //     this.dependencies.push({name: element.source, dir: "to"});
        //   }
        // });
        const depEdges = partition.connectedEdges().filter(edge => edge.data("dep"));
        depEdges.forEach(edge => {
          const data = edge.data();
          if ( data.source === privateData.name) {
            let target = data.target;
            if (edge.hasClass("cy-expand-collapse-meta-edge") === true) { // 'collapsed' edge
              const originalEnds = edge.data("originalEnds");
              if (originalEnds) {
                target = originalEnds.target.data("name");
              }
            }
            this.dependencies.push({name: target, dir: "from"});
          } else if ( data.target === privateData.name) {
            let source = data.source;
            if (edge.hasClass("cy-expand-collapse-meta-edge") === true) { // 'collapsed' edge
              const originalEnds = edge.data("originalEnds");
              if (originalEnds) {
                source = originalEnds.source.data("name");
              }
            }
            this.dependencies.push({name: source, dir: "to"});
          }
        });
        if (this.dependencies.length>0 ) {
          this.show_dependency = true;
        } else {
          this.show_dependency = false;
        }

      } else {
        this.show_dependency = false;
        this.dependencies= [];
      }

      this.showMicroserviceClassButton(partition);
      this.$nextTick(this.updateBottomHeight);

      if (this.microserviceView) {
        // total class of the the partition
        // const that = this;
        // const totalNodes = utils.getAllNodes(this.$store.getters.getCYInstance()).filter(
        //   function (node) {
        //     return that.microserviceName === node.data("category");
        //   }
        // );

        // this.totalClasses = totalNodes.length -1 ;// remove partition itself

        this.totalClasses = 0;
        if (privateData.filepath == "Cluster" && privateData.ui_classNumber) {  // only show total classes for partition nodes
          this.totalClasses = privateData.ui_classNumber;
        }
        console.log(this.totalClasses);
        console.log(this.microserviceName);
      }

      this.setPartitionOrClassStats();

      this.$nextTick(() => {
          this.adjustSidePanelBottomContainerHeight();
        });
      this.resetTableToFirstPage();
    });

    /* back to view details */
    eventBus.$on("SidePanel.hideMicroservicePanel", () => {
      this.microserviceView = false;
      this.classView = false;
      this.show_dependency = false;
      this.sidePanelHeaderText = this.$t("side-panel-top-container-header-text-overall");
      this.$nextTick(this.updateBottomHeight);

      if (this.selected_view == "micro_detail_partition_by_natural_seam") {
        this.microserviceName = this.$t("naturalSeams");
        this.overallDescription = this.$t("side-panel-top-container-text-overall-ns");
      } else if (this.selected_view == "micro_detail_partition_by_business_logic") {
        this.microserviceName = this.$t("businessLogic");
        this.overallDescription = this.$t("side-panel-top-container-text-overall-bl");
      } else if (this.selected_view == "custom_view") {
        this.microserviceName = this.$t("customView");
        this.overallDescription = this.$t("side-panel-top-container-text-overall-cv");
      } else if (this.selected_view == "mono_data_dependency_graph") {
        this.microserviceName = this.$t("dataDependencies");
        this.overallDescription = this.$t("side-panel-top-container-text-overall-cd");
      }

      const json = this.$store.getters.getJson;
      this.totalClasses = json[constants.micro_detail_partition_by_business_logic][constants.nodeId].length;
      this.use_cases = this.totalUseCases;

      this.setPartitionOrClassStats();
      this.$nextTick(() => {
          this.adjustSidePanelBottomContainerHeight();
        });
    });


    eventBus.$on(
      "SidePanel.updateSelectedClassLabelAfterApplyLabelsAction", (data) => {
        this.updateSelectedClassLabelsFromEvent(data);
      }
    );

    eventBus.$on(
      "SidePanel.showMicroserviceClassButton", (partitionNodeEle) => {
        this.showMicroserviceClassButton(partitionNodeEle);
      }
    );

    eventBus.$on("SidePanel.enableCustomView", (enableCustomView) => {
      // Enable/Disable Custom view section on the side panel.
      console.log("Custom view added!!!!!");
      this.add_custom_view = enableCustomView;
    });

    eventBus.$on("inCustomView", (active) => {
      // Indicate if currently selected graph view is 'Custom view'
      this.custom_view_active = active;
    });

    eventBus.$on("SidePanel.updateCustomView", () => {
      this.updateCustomView();
    });

    eventBus.$on("SidePanel.newFileInitialization", (newFileInitInfo) => {
      // Re-initialize the side panel after reading in a new JSON file...
      // Indicate if the new JSON file includes a Custom view
      this.add_custom_view =  newFileInitInfo.includesCustomView ? newFileInitInfo.includesCustomView : false;
      // Reset 'customView' processing to not active.
      // This is needed when uploading a new file...specifically when you had previously
      // been in the Custom view graph view to ensure that editing is no longer enabled.
      eventBus.$emit("inCustomView", false);

      // Set the number of classes in the SidePanel for this JSON file
      this.numOfClasses = newFileInitInfo.numOfClasses ? String(newFileInitInfo.numOfClasses) : "";
    });
    eventBus.$on("SidePanel.getPanelState", (callbackEvent) => {
      // This event is called by the graph/view transition to figure out which view is shown in the details
      // panel.
      let state= "";
      if (this.showPanel) {
        state = this.classView ? "class" : (this.microserviceView ? "microservice" : "view");
      }
      if (callbackEvent?.event?.name) {
        eventBus.$emit(callbackEvent.event.name, state);
      }
    })
  },
  beforeDestroy() {
    eventBus.$off("SidePanel.displayPanel");
    eventBus.$off("SidePanel.showPanel");
    eventBus.$off("SidePanel.showMicroservicePanel");
    eventBus.$off("SidePanel.hideMicroservicePanel");
    eventBus.$off("SidePanel.showMicroserviceClassButton");
    eventBus.$off("SidePanel.enableCustomView");
    eventBus.$off("inCustomView");
    eventBus.$off("SidePanel.updateCustomView");
    eventBus.$off("SidePanel.newFileInitialization");
    eventBus.$off("SidePanel.updateSelectedClassLabelAfterApplyLabelsAction");
    eventBus.$off("SidePanel.getPanelState");
  }
};
</script>

<style lang="scss">



.edit-partition-btn-details-panel {
    .bx--btn__icon {
      margin-left:0!important;
      path {
        fill: #000000 !important;
      }
    }
  }


.class-detail-labels button:hover .bx--btn__icon path {
    fill: #003ED6 !important;
}


.class-view-key-details {
  .item-title {
    color: #161616;
    line-height: 1rem;
    font-weight: 600;
    text-transform: capitalize;
    margin-top: 1.5rem;
  }
  .partition-detail {
      display: table;
      span {
        display:table-cell;
        vertical-align:middle;
      }
      svg {
        display:table-cell;
        vertical-align: middle;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
      }
    }
  .item-detail {
    margin-top: 0.5rem;
    .bx--snippet-container {
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
    .bx--snippet--single {
      height: unset;
    }
    pre {
      white-space: pre-wrap;
      white-space: -moz-pre-wrap;
      white-space: -pre-wrap;
      white-space: -o-pre-wrap;
      overflow-wrap: anywhere;

    }
    
    .partition-name {
      font-size: 0.875rem;
      letter-spacing: 0.01rem;
      line-height: 1.125rem;
      font-weight: 400;
    }
  }
  .class-detail-labels-no-tags {
    margin-top: 0.5rem;
  }
  .class-detail-labels {
    margin-bottom: 1.5rem;
    margin-top: 0.25rem;
    a:hover {
      color: #0043CE;
    }
    button {
      min-height: unset;
      width: 1.5rem;
      height: 1.5rem;
      margin: 0rem;
      padding: 0.5rem;
      border: 1;
      vertical-align: middle;
      justify-content: space-evenly;
      margin-left: 0.25rem;
    }
    
    .cv-tag:nth-last-child(2) {
      margin-right: 0rem;
    }
    .cv-tag {
      margin-top: 0.25rem;
      margin-bottom: 0.25rem;
      margin-left: 0rem;
      margin-right: 0.5rem;
    }
    
  
    a {
        text-decoration: none;
        span {
          vertical-align: middle;
        }
      }
  }
}
.usecases-table-search-div {
  margin-bottom: 1rem;
    .cv-search {
      //padding-bottom: 1rem;

      .bx--search {
        height: 2rem;
        width: 19.75rem; // this would reduce it to about 18.75rem which is 300px

        .bx--search-input {
          position: relative;
          // left: -1rem; // to include search icon inside the input instead of outside the input by carbon-vue
          height: 2rem;
          padding-right: 2rem;
        }

        .bx--search-magnifier {
          z-index: 2;
          position: relative;
          // left: 0.5rem;
          // top: 25%;
        }

        .bx--search-close {
          height: 2rem;
          width: 2rem;
          right: 1rem;
          z-index: 1; // FF won't show the search close button unless z-index > 0
        }
      }
    }
}


  .usecases-table-data {
    .bx--data-table-content {
      min-height: 22.0625rem;
      background: #f4f4f4;
      
    }

    //padding: 0 1rem 1rem 1rem;
    padding: 0;
    margin-bottom:  1.5rem;

    .bx--pagination {
      position: initial !important;
      width: 100% !important;
    
      height: 2rem;
      justify-content: flex-end;
      position: sticky;
      bottom: 0;
      border-top: 1px solid var(--app-table-pagination-top-border-color);
      .bx--pagination__left {
        display: none;
      }
      min-height: 2rem;
      max-height: 2rem;
      button {
        width: 2rem;
        height: 2rem;
      }
      .bx--select-input {
        line-height: 2rem;
      }
    }
    
    .bx--data-table-container {
      .bx--data-table-content {
        .bx--data-table {
          height: 2rem;
        }
      }
      tr {
        height: 2rem;
      }
      th {
        height: 2rem !important;
      }
      td {
        border-top: 0;
        .usecases-table-row {
          display: flex;
        }
      }
    }
  }

</style>
<style lang="scss" scoped>


#sidepanel-top-container {
  .edition-partition-input-helper-text {
    @include carbon--type-style("helper-text-01");
    margin-bottom: 1rem;
    margin-left: 1rem;
    margin-top: 0.5rem;
  }
  .sidepanel-top-container-header-text {
    @include carbon--type-style("label-01");
    margin-top: 1rem;
    margin-left: 1rem;
    word-break: break-word;
    display: inline-flex;
  }
  .sidepanel-top-container-header-title-container {
    display: flex;
    button {
      margin: 0rem;
      padding: 0.25rem;
      align-self: self-end;
    }
  }
  h2 {
    @include carbon--type-style("productive-heading-03");
    padding-left: 1rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  h3 {
    @include carbon--type-style("label-02");
    padding-left: 1rem;
    padding-top: 0.5rem;
    padding-right: 5rem;
    padding-bottom: 1rem;
  }
  .spacer {
    height: 1rem;
    padding: 0rem;
  }
 
}

#sidepanel-bottom-container {
  
   .hr2 {
     margin-left: 1.5rem;
     margin-right: 1.5rem;
     background-color: #e0e0e0;
     height: 0.0625rem;
     border: 0rem;
   }
   .hr1 {
     margin-left: 0rem;
     margin-right: 0rem;
     margin-top: 0rem;
     margin-bottom: 1.5rem;
     background-color: #e0e0e0;
     height: 0.0625rem;
     border: 0rem;
   }
   .compare-views {
    margin: 1.5rem;
  }
  h2 {
    @include carbon--type-style("productive-heading-02");
    margin-bottom: 1.5rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  h3 {
    @include carbon--type-style("helper-text-01");
    padding-bottom: 0.5rem;
  }
  .total-call-volume {
    @include carbon--type-style("body-short-01");
    margin-bottom: 1.5rem;
    text-align: end;
    /deep/ b {
      font-weight: 600;
    }
  }
  .unique-calls {
    @include carbon--type-style("body-short-01");
    margin-top: 1.5rem;
    margin-bottom: 0.25rem;
    text-align: end;
    /deep/ b {
      font-weight: 600;
    }
  }
  .compare-view-bar:first-child {
    /* margin-top:  1.5rem; */
  }
  .compare-view-bar {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .compare-view-bar-legend {
    margin: 1.5rem;
    @include carbon--type-style("helper-text-01");
    text-align: right;
    div:first-child {
      display: inline-block;
      vertical-align: middle;
      width: 0.75rem;
      margin-right: 0.25rem;
      height: 0.75rem;
      background-color: #0F62FE;
      margin-bottom: 0.125rem;
    }
    div:last-child {
      display: inline-block;
      vertical-align: middle;
      width: 0.75rem;
      margin-right: 0.25rem;
      margin-left: 0.5rem;
      height: 0.75rem;
      background-color: #8A3FFC;
      margin-bottom: 0.125rem;
    }
  }
}

.view-more-details {
  margin-top: 1.5rem;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  .no-table-data-overlay-parent {
    position: relative;
    margin-top: -1rem;
    .no-table-data-overlay {
      position: absolute;
      top: calc(2rem + 1rem + 3rem + 1.5rem);
      z-index: 100;
      margin-left: 1.5rem;
      .no-table-data-header {
        margin-top: 1rem;
        margin-bottom: 0.5rem;
        font-size: 1.25rem;
      }
      .no-table-data-description {
        font-size: 0.875rem;
      }
    }
  }
  .view-more-details-title
  {
    @include carbon--type-style("productive-heading-02");
    margin-bottom: 1.5rem;
  }
  .view-more-details-details
  {
    h5 {
      @include carbon--type-style("label-01");
    }
    h1 {
      @include carbon--type-style("productive-heading-05");
      margin-bottom: 1.5rem;
    }
  }
}

.cv-tile-standard {
  background-color: var(--app-panel-background-color);
  float: right;
  width: 20%;
  max-width: 30rem;
  height: 95vh;
  margin-top: 2.8125rem
}

#sidepanel-top-container {
  border-bottom: 1px solid var(--app-panel-divider-color);
  
}

#sidepanel-bottom-container {
  max-height: calc(100% - 5.375rem);
  overflow-y: auto;
  overflow-x: hidden;
  
}

h2.side-panel-heading {
  word-break: break-word;
  display: inline-flex;
  font-size: 1.25rem;
  // color: #FFFFFF;
  letter-spacing: 0;
  line-height: 1.75rem;
  font-weight: 600;
  margin-left: 1rem;
  margin-top: 1rem;
  width: 11.875rem;
}

h3 {
  font-size: 0.875rem;
  // color: #f4f4f4;
  letter-spacing: 0.01rem;
  line-height: 1.125rem;
}

h4 {
  font-size: 0.875rem;
  // color: #f4f4f4;
  letter-spacing: 0.01rem;
  line-height: 1.125rem;
  margin-top: 1.25rem;
  margin-bottom: 0.9375rem;
}

.non-refactoring-heading {
  margin-bottom: 0.3125rem; /* combining with margin bottom of 11px for the edit-partition-panel will make total margin-bottom of 16px */
}

#class-num {
  display: inline;
  font-size: 0.875rem;
  color: var(--app-panel-label-02-color);
  letter-spacing: 0.01rem;
  line-height: 1.125rem;
  font-weight: 600;
  margin-left: 1rem;
  margin-bottom: 1.5rem; /* combining with margin bottom of 11px for the edit-partition-panel will make total margin-bottom of 16px */
}

// #total-classes-tooltip svg {
//   fill: #C6C6C6;
// }

#total-classes-tooltip {
  vertical-align: bottom;
  margin-left: 0.5rem;
}

// svg {
//   fill: #f4f4f4;
// }

.sidepanel {
  position: fixed;
  //top: 11.5rem;
  right: 0;
  overflow: hidden;
  height: calc(100vh - 3rem - 2rem - 12.1875rem - 1rem);;
  width: 30rem;
  margin-top: 0;
  margin-right: 0;
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 6px var(--app-panel-box-shadow-color);
  z-index: 2;
  padding: 0;
  border-left-width: 0.0625rem;
  border-style: solid;
  border-color: #e0e0e0;
}

// .sidepanel .bx--btn--icon-only {
//     color: var(--app-panel-icon-color);
// }

// .sidepanel-close {
//   position: fixed;
//   right: 0;
//   height: 100%;
//   border-left: 1px solid #393939;
//   width: 3.125rem;
//   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
//   z-index: 2;
//   min-width: 1.5625rem;
// }

.sidepanel .accordion-subheading {
  font-size: 0.875rem;
  // color: #F4F4F4;
  letter-spacing: 0.01rem;
  line-height: 1.125rem;
  font-weight: 400;
  margin-bottom: 0.5625rem;
}

.sidepanel .sidepanel-content {
  overflow: hidden;
  height: 100%;
}

.sidepanel .sidepanel-content-path-opended {
  overflow: auto;
  height: 100%;
}

.sidepanel .no-path-component {
  height: 100%;
}

.bx--accordion p {
  font-size: 0.75rem;
  color: var(--app-panel-label-02-color);
  letter-spacing: 0.02rem;
  line-height: 1rem;
  padding-right: 0.3125rem;
}

.side-panel-data {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.3125rem;
  font-size: 0.75rem;
  // color: #C6C6C6;
  letter-spacing: 0.02rem;
  line-height: 1rem;
  font-weight: 400;
}

.right-num {
  padding-right: 1rem;
  font-size: 0.875rem;
  // color: #F4F4F4;
  letter-spacing: 0.01rem;
  text-align: right;
  line-height: 1.125rem;
  font-weight: 400;
}

.data-dep-msv .bx--accordion__title {
  // color: #f4f4f4;
  margin-left: 1.3125rem;
  margin-bottom: 1rem;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.data-dep-msv h3 {
  margin-left: 1.3125rem;
  margin-right: 1.3125rem;
  word-break: break-all;
  margin-bottom: 0.5rem;
}

.data-dep-cv p {
  margin-left: 0.625rem;
}

.data-dep-hr {
  width: 14rem;
  margin-bottom: 1rem;
  border-color: var(--app-panel-divider-color);
  margin-top: -0.25rem;
}

.bx--accordion__item {
  // border-top: 1px solid var(--app-panel-divider-color);
  border-bottom: 1px solid var(--app-panel-divider-color);
}

.linear {
  background-image: linear-gradient(90deg, var(--app-panel-linear-default-color), var(--app-panel-linear-default-color) 100%);
  height: 0.125rem;
  margin-bottom: 0.3125rem;
  float: right;
}

.linear-background-crossPartition {
  background-image: linear-gradient(
    90deg,
    #0f62fe 0%,
    #a6c8ff 46%,
    #ffafd2 56%,
    #d12771 100%
  );
  height: 0.125rem;
  margin-bottom: 0.625rem;
  margin-right: 1rem;
}

.linear-background-intraPartition {
  background-image: linear-gradient(
    90deg,
    #0f62fe 0%,
    #a6c8ff 46%,
    #ffafd2 56%,
    #d12771 100%
  );
  height: 0.125rem;
  margin-bottom: 0.625rem;
  margin-right: 1rem;
}

// .side-panel-bottom {
//   width: 100%;
//   height: 3rem;
//   background: #262626;
//   position: fixed;
//   bottom: 0;
// }

// .side-panel-btn {
//   width: 3rem;
//   background: #262626;
//   position: fixed;
//   bottom: 0;
// }

// .side-panel-btn:hover {
//   background: #353535;
//   transition: 0.2s;
// }

// .open-side-panel-btn {
//   background-color: #262626;
//   position: fixed;
//   bottom: 0;
//   margin-left: -1rem;
// }

// .open-side-panel-btn:hover {
//   background-color: #353535;
//   transition: 0.2s;
// }

.runtime-list {
  font-size: 0.875rem;
  // color: #f4f4f4;
  margin-bottom: 0.625rem;
  font-weight: 400;
  line-height: 1.1rem;
}

.side-panel-top-btn {
  // background-color: transparent;
  // border-color: var(--app-panel-btn-border-color);
  margin-bottom: 1rem;
  width: 14rem;
  min-height: 2rem;
  margin-left: 1rem;
  margin-top: 0.625rem;
  padding-top: 0.4375rem;
  padding-bottom: 0.4375rem;
  margin-right: 1rem;
}

.custom-view-radio-group {
  margin-bottom: 1.25rem;
}

.edit-partition-panel {
  display: inline-flex;
  align-items: flex-start;
  margin-left: 1rem;
  margin-top: 1rem;
  max-width: 11.875rem;
}

.edit-partition-btn {
  background-color: transparent;
  margin-left: 0.3125rem;
  padding: 0.3125rem 0.5rem 0.3125rem 0.5rem;
  min-height: 1rem;

  width: 1.5rem;
  height: 1.5rem;
  padding-left: 0.25rem;
}

.edit-partition-mode-btn {
  background-color: transparent;
  padding: 0.5rem;
  min-height: 1rem;

}

.close-panel-button {
  display: flex;
  justify-content: center;
  float: right;
  width: 2rem;
  height: 2rem;
  margin-top: 0.5rem;
  margin-right: 0.5rem;
  svg {
    fill: #000000;
  }
}
.close-panel-button:hover {
  background-color: var(--app-toolbar-selected-action-button-background-color);
}

.disable-custom-view {
  opacity: 0.6;
  pointer-events: none;
}

.partition-label {
  font-size: 0.875rem;
  color: var(--app-panel-label-02-color);
  letter-spacing: 0.01rem;
  line-height: 1.125rem;
  font-weight: 400;
}

.containment-label {
  font-size: 0.75rem;
  font-weight: 400;
  text-transform: capitalize;
}

.class-view-heading-margin {
  margin-bottom: 0.3125rem;
}
</style>

<style>
#walkme-balloon-5151871 {
  top: 6.25rem !important;
}

.walkme-custom-flow-balloon-5151871{
  top: 11.25rem !important;
}

#custom-view-accordion {
  padding-top: 0.5rem;
}

#total-classes-tooltip .bx--assistive-text {
  width: 9.0625rem;
}
#total-classes-tooltip span {
  margin-left: -4.375rem;
}

.sidepanel .bx--accordion__title {
  font-size: 1rem;
  /* color: #F4F4F4; */
  letter-spacing: 0;
  line-height: 1.375rem;
  font-weight: 600;
  margin-left: 1rem;
}

.sidepanel .bx--accordion__content {
  padding-right: 0rem;
  margin-left: 1rem;
}

.partition-use-case-list {
  margin-left: 1rem;
}

.partition-use-case-list h4 {
  font-size: 1rem;
  /* color: #f4f4f4; */
  letter-spacing: 0.01rem;
  line-height: 1.125rem;
  margin-top: 1rem;
  margin-bottom: 0.625rem;
}

.partition-use-case-list h5 {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  margin-right: 1rem;
}

.edit-partition-btn.bx--tooltip__trigger.bx--tooltip--bottom.bx--tooltip--align-center
  .bx--assistive-text,
.edit-partition-mode-btn.bx--tooltip__trigger.bx--tooltip--bottom.bx--tooltip--align-center
  .bx--assistive-text,
.custom-view-reset-btn.bx--tooltip--top.bx--tooltip--align-center
  .bx--assistive-text,
.copy-path-btn.bx--tooltip--top.bx--tooltip--align-center .bx--assistive-text {
  /* background: #f3f3f3; */
  border-radius: 0.125rem;
  /* color: #161616; */
}

.edit-partition-btn.bx--tooltip__trigger.bx--tooltip--a11y::before,
.edit-partition-mode-btn.bx--tooltip__trigger.bx--tooltip--a11y::before {
  transform: translateY(100%) !important;
}

.edit-partition-btn.bx--tooltip__trigger.bx--tooltip--bottom.bx--tooltip--align-center
  .bx--assistive-text,
.edit-partition-mode-save-btn.bx--tooltip__trigger.bx--tooltip--bottom.bx--tooltip--align-center
  .bx--assistive-text {
  transform: translate(-50%, 100%) !important;
}

.edit-partition-mode-cancel-btn.bx--tooltip__trigger.bx--tooltip--bottom.bx--tooltip--align-center
  .bx--assistive-text {
  transform: translate(-80%, 100%) !important;
}

.copy-path-panel {
  border-top: var(--app-panel-divider-color);
  border-top-style: solid;
  border-top-width: 0.0625rem;
  padding-top: 0.9375rem;
}

.copy-path-label {
  font-size: 1rem;
  font-weight: 600;
  /* color: #f8f8f8; */
  margin-left: 0.9375rem;
  float: left;
  letter-spacing: 0.01rem;
  line-height: 1.125rem;
  margin-right: 0.375rem;
}

.copy-path-panel button {
  background-color: transparent;
  margin-top: -0.8125rem;
  height: 3rem;
  width: 3rem;
}

.show-panel-btn:hover {
  background-color: transparent !important;
}

/* make all other button hover used the same css */
.edit-partition-btn:hover,
.edit-partition-mode-btn:hover,
.copy-path-panel button:hover {
  /* background-color: var(--app-carbon-btn-hover-background-color); */
  outline: none;
}

/* change the default active color cuz it doesn't work well with the updated hover color as if no click action is performed */
/* .edit-partition-btn:active,
.edit-partition-mode-btn:active,
.copy-path-panel button:active {
  background-color: #262626;
} */

.path-label {
  font-size: 0.75rem;
  /* color: #f5f5f5; */
  letter-spacing: 0.02rem;
  line-height: 1rem;
  word-break: break-all;
  width: 13.75rem;
  margin-left: 1rem;
  margin-bottom: 1rem;
  overflow: hidden;
}

.show-panel-btn {
  float: right;
}

#class-icon {
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 50%;
  margin-top: 0.5625rem;
  margin-right: 0.3125rem;
  /* background: #f3f3f3; */
  padding-right: 0.75rem;
}

#microservice-icon {
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
  /* --border-radius: 50%;
  --border-width: 75%; */
  --mask: radial-gradient(
      circle farthest-side at center center,
      transparent var(--border-radius),
      transparent calc(var(--border-radius) + 2px)
        calc(var(--border-radius) + 2px + var(--border-width)),
      transparent calc(var(--border-radius) + 2px + var(--border-width) + 2px)
        100%
    )
    50% 50%/100% 100% no-repeat;
  -webkit-mask: var(--mask);
  mask: var(--mask);
  /* background: #f3f3f3; */
  margin-right: 0.5rem;
  padding-right: 1rem;
  margin-top: 0.5rem;
}

.sidepanel .bx--text-input {
  /* background-color: transparent;
  border: 2px solid #ffffff; */
  height: 2.1rem;
  padding-right: 1.25rem;
  width: 18.75rem;
  height: 2rem;
  background-color: var(--app-edit-patition-input-background-color);
  border: var(--app-edit-patition-input-border);
}

.sidepanel .bx--text-input.bx--text-input--invalid {
  border: none;
  /* padding-right: 1.25rem;*/
}

.sidepanel .bx--text-input__field-wrapper .bx--text-input__invalid-icon {
  right: 0.5rem;
}

.sidepanel .edit-partition-panel .edit-partition-input .bx--label {
  display: none;
}

/*.sidepanel .bx--text-input--invalid {
  border: none;
}*/

.sidepanel .bx--form-requirement {
  width: 140%;
}

/* .sidepanel .unobserved-heading {
  color: #0f62fe;
  background-color: #171717;
  font-weight: bold;
} */
</style>
