<!--
# *****************************************************************
#
# Licensed Materials - Property of IBM
#
# (C) Copyright IBM Corp. 2021, 2022, 2023. All Rights Reserved.
#
# US Government Users Restricted Rights - Use, duplication or
# disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
#
# *****************************************************************
-->
<template>
  <div class="table-view-content" ref="tableViewContent">
    <cv-data-table-skeleton 
      v-show="table_loading"
      :rows="tableView.skeletonRows"
    ></cv-data-table-skeleton>

    <div
      class="table-overlay"
      v-show="showTableOverlay"
      ref="tableOverlay"
    />
    <cv-data-table
      class="table-graph-data"
      :pagination="tableView.pagination"
      @pagination="onPagination"
      @sort="onSort"
      :action-bar-aria-label="actionBarAriaLabel"
      :batch-cancel-label="batchCancelLabel"
      :sortable="false"
      v-model="rowSelects" @row-select-changes="actionRowSelectChange"
      ref="table"
      v-show="showTable"
      :borderless=true
    >
      <template v-if="use_batchActions" slot="batch-actions">
        <cv-button
          kind="primary"
          @click="onMoveClasses"
          :disabled="!custom_view_move_classes"
          v-bind:class="{'batch-action-toolbar-button-selected': moveClassesSelected}"
          ref="moveClassesButton"
        >
          {{ $t("table-move-classes") }}
        </cv-button>
        <cv-button
          kind="primary"
          @click="onEditLabels(true)"
          :disabled="!tableColumnShow.labels"
          v-bind:class="{'batch-action-toolbar-button-selected': editLabelsSelected}"
          ref="editLabelsButton"
        >
          {{ $t("table-edit-labels") }}
        </cv-button>
      </template>
      <template slot="items-selected">
        {{ $t("unobserved-selected-items-count", {count: rowSelects.length}) }}
      </template>
      <template slot="headings">
        <!--
        <cv-data-table-heading
          v-for="(columnId) in tableColumnOrder"
          :key="columnId"
          :data-id="columnId"
          :heading="$t(`table-${columnId}-column`)"
          :class="`table-column table-${columnId}-header`"
          :style="{width: getTableColumnWidth(columnId)}"
          :sortable="columnId !== 'labels'? true : false"
          :order="columnId === 'classname'? 'ascending': 'none'"
        />
        -->
        <!-- v-bind:class="{'table-header-no-bottom-border': singleRowSelectedRowIndex === 0}" -->
        <cv-data-table-heading
          data-id="classname"
          :heading="$t('table-classname-column')"
          :style="{width: `${tableColumnWidth.classname}` === 'auto'? 'auto' : `${tableColumnWidth.classname}%`}"
          class="table-column table-classname-header"
          sortable
          order="ascending"
        />
        <cv-data-table-heading
          v-show="tableColumnShow.partition"
          data-id="partition"
          :heading="$t('table-partition-column')"
          :style="{width: `${tableColumnWidth.partition}` === 'auto'? 'auto' : `${tableColumnWidth.partition}%`}"
          class="table-column table-partition-header"
          sortable
        />
        <cv-data-table-heading
          v-show="tableColumnShow.usecases"
          data-id="usecases"
          :heading="$t('table-usecases-column')"
          :style="{width: `${tableColumnWidth.usecases}` === 'auto'? 'auto' : `${tableColumnWidth.usecases}%`}"
          v-bind:class="{'table-column': true, 'table-usecases-header': true, 'table-usecases-header-padding': isUsecasesLastTableColumn()}"
          sortable
          @mouseover.native="onUsecasesHeadingHover()"
          @mouseleave.native="onUsecasesHeadingBlur()"
          @focusout.native="onUsecasesHeadingFocusout()"
        />
        <cv-data-table-heading
          v-show="tableColumnShow.path"
          data-id="path"
          class="table-column table-path-header"
          :heading="$t('table-path-column')"
          :style="{width: `${tableColumnWidth.path}` === 'auto'? 'auto' : `${tableColumnWidth.path}%`}"
          sortable
        />
        <cv-data-table-heading
          v-show="tableColumnShow.labels"
          data-id="labels"
          class="table-column table-labels-header"
          :style="{width: `${tableColumnWidth.labels}` === 'auto'? 'auto' : `${tableColumnWidth.labels}%`}"
        >
          {{ $t('table-labels-column') }}
          <cv-button 
            class="manage-label-link-in-header" 
            @click="onManageLabels(true)"
            tabindex="0"
            kind="ghost"
            ref="manageLabelsButton"
          >
            {{ $t("table-manage-label") }}
          </cv-button>
        </cv-data-table-heading>
      </template>
      <template slot="data">
        <cv-data-table-row
          v-for="(row, rowIndex) in filteredData"
          :key="classesInfo[row.name].idx"
          :value="`${row.name}`"
          @click.native="onTableRowClick($event, rowIndex, row, true)"
          v-bind:class="{'table-row-selected': singleRowSelectedRowIndex === rowIndex, 'bx--data-table--selected': isRowSelectedForBatchAction(row.name), 'table-row-before-single-selected': singleRowSelectedRowIndex -1 === rowIndex}"
        >
          <cv-data-table-cell
            data-id="classname"
            class="table-column td-classname td-overflow-ellipsis"
            :title=row.name
          >
            {{ row.name }}
          </cv-data-table-cell>
          <cv-data-table-cell
            v-show="tableColumnShow.partition"
            data-id="partition"
            class="table-column td-partition td-overflow-ellipsis"
            :title=row.category
          >
            {{ row.category }}
          </cv-data-table-cell>
          <cv-data-table-cell
            v-show="tableColumnShow.usecases"
            data-id="usecases"
            v-bind:class="{'table-column': true, 'td-usecases': true, 'td-usecases-padding': isUsecasesLastTableColumn()}"
          >
            {{ row.semantics.length }}
          </cv-data-table-cell>
          <cv-data-table-cell
            v-show="tableColumnShow.path"
            data-id="path"
            class="table-column td-overflow-ellipsis td-path"
            :title=row.FQCN
          >
            {{ row.FQCN }}
          </cv-data-table-cell>
          <cv-data-table-cell
            v-show="tableColumnShow.labels"
            data-id="labels"
            class="table-column td-labels"
          >
            <span v-if="row.unobserved">
              <cv-tag
                size="sm"
                kind="blue"
                key="unobserved-tag"
                :label="$t('table-unobserved-label-tag')"
                class="unobserved-tag"
              />
            </span>
            <span v-if="row.isUtilityClass">
              <cv-tag
                size="sm"
                kind="blue"
                key="utility-tag"
                :label="$t('table-utility-label-tag')"
                class="utility-tag"
              />
            </span>
            <span v-if="tableColumnShow.labels && allClassesLabels.length > 0 && classesInfo[row.name] && allClassesLabels[classesInfo[row.name].idx].length > 0">
              <cv-tag
                size="sm"
                kind="blue"
                v-for="(label, index) in allClassesLabels[classesInfo[row.name].idx]"
                :key="index"
                :label="label"
              />
            </span>
            <span>
              <cv-tooltip
                tabindex="-1"
                aria-label="Overflow tooltip"
                class="overflow-label-tooltip"
                name="overflowTooltip"
                alignment="end"
                :direction="(rowIndex + 1 === filteredData.length)? 'top' : 'bottom'"
                tip=""
                @mouseover.native="onLabelTooltipHover(rowIndex, row)"
              >
                <cv-tag
                  size="sm"
                  kind="blue"
                  key="overflow-tag"
                  label="+..."
                  class="overflow-tag"
                />
              </cv-tooltip>
            </span>
          </cv-data-table-cell>
        </cv-data-table-row>
      </template>
      <template slot="range-text">
        {{ $t("table-pagination-range-text", {
          start: pagination.start,
          end: Math.min(pagination.start + pagination.length - 1, tableView.pagination.numberOfItems) ,
          total: tableView.pagination.numberOfItems
        }) }}
      </template>
      <template slot="of-n-pages">
        {{ $t("table-pagination-of-pages",
          {total: Math.max(1, Math.ceil(tableView.pagination.numberOfItems/ pagination.length)) }) }}
      </template>
    </cv-data-table>

    <move-classes-panel
      :selectedClasses="rowSelects"
      onMoveClassesEvent="TableView.onMoveClasses"
      ref="moveClassesPanel"
    />

    <table-customization
      :columnOrder="tableColumnOrder"
      :columnShow="tableColumnShow"
    />
  </div>
</template>

<script>
import { eventBus } from "../../main.js";
import MoveClassesPanel from "../MoveClassesPanel";
import TableCustomization from "../TableCustomization";
import apis from "../../lib/graph/graphAPIHandler";
import utils from "@/lib/graph/graphUtil";
import $ from "jquery";

export default {
  name: "TableView",
  components: {
    MoveClassesPanel,
    TableCustomization,
  },
  data() {
    return {
    showTable: false,
    table_loaded: false,
    table_loading: false,
    tableOriginalColumnOrder: [ // used by sort as the original column index is passed in
      "classname",
      "partition",
      "usecases",
      "path",
      "labels"
    ],
    tableColumnOrder: [ // actual column order
      "classname",
      "partition",
      "usecases",
      "path",
      "labels"
    ],
    tableColumnShow: {
      "classname": true,
      "partition": true,
      "usecases": true,
      "path": false,
      "labels": true
    },
    tableInitialColumnWidth: {
      "classname": 20,
      "partition": 15,
      "usecases": 10,
      "path": 30,
      "labels": 23
    },
    tableColumnWidth: {},
    requireReorderColumn: false,
    allClassesArr: [],
    allClassesNodes: [],
    allClassesLabels: [],
    rowSelects: [],
    actionBarAriaLabel: this.$t("table-batch-action-aria-label"),
    batchCancelLabel: this.$t("cancel-button-label"),
    use_batchActions: true,
    custom_view_move_classes: false,
    selected_view: '',
    filterValue: '',
    saveFilterValue: '',
    options: false,
    heights: {},
    allLabels: [],
    singleRowSelectedRowIndex: -1,
    singleRowSelectedRowValue: '',
    moveClassesSelected: false,
    editLabelsSelected: false,
    manageLabelsSelected: false,
    detailsPanelSelected: false,
    reloadLabelsForClass: false,
    refreshLabelsInTable: false,
    refreshLabelsOnPagination: true,
    reorderColumnsOnPage: false,
    showTableOverlay: false,
    labelsColumnWidth: 0,
    // defaultClassNameSort: "ascending",
    pagination: {start:0, page:0, length:50},
    tableView: {
      skeletonRows: 10,
      pagination: {
        numberOfItems: 0, //this.allClassesNodes.length,
        page: 1,
        pageSizesLabel: this.$t('table-pagination-sizes-of-page'),
        pageSizes: [
          100,
          150,
          200
        ]
      }
    },
    classesInfo: {  // class information (class name is the key), such as the index of json object array (allClassesNodes) etc.
    },
  }},
  watch: {
    tableColumnOrder: function(newColumnOrder, oldColumnOrder) {
      if (newColumnOrder.toString() !== oldColumnOrder.toString()) {
        // console.log("reodrer column");
        this.requireReorderColumn = true;
        this.reorderColumns();
      }
    },
    tableColumnShow: function(newColumnShow, oldColumnShow) {
      let isSame = true;
      Object.keys(newColumnShow).forEach(columnId => {
        if (newColumnShow[columnId] !== oldColumnShow[columnId]) {
          isSame = false;
        }
      });
      if (!isSame) {
        this.setTableColumnWidth();

        this.$nextTick(function() {
          this.adjustDisplayedLabelTagsForTablePage();
        })
      }
    },
    rowSelects: function(newRowSelects) {
      // since rowSelects is not passed in as a prop to EditLabelsPanel anymore, has to update the selectedClasses.
      if (this.editLabelsSelected) {
        eventBus.$emit("EditLabelsPanel.updateSelectedClasses", newRowSelects);
      }
      // if MoveClassPanel is shown, update dropdown list
      if (this.moveClassesSelected) {
        eventBus.$emit("MoveClassesPanel.updatePartitionDropdown");
      }
    }
  },
  computed: {
    filteredData: function () {
      // console.log("on filtered data");
      // whenever a page is rendered, labels on the new page have to be taken care of and columns
      // need to be reordered
      this.refreshLabelOnPage();
      this.setReorderColumnsOnPage();

      if (this.filterValue) {
        const regex = new RegExp(this.filterValue, 'i');
        let nodes = this.allClassesNodes.filter(item => {
          return item.name.search(regex) >= 0;
        });
        this.setPageNumberOfItems(nodes);
        const startIndex = this.pagination.start - 1;
        return nodes.slice(startIndex, startIndex + this.pagination.length);
      } else if (this.pagination) {
        this.setPageNumberOfItems(this.allClassesNodes);
        const startIndex = this.pagination.start - 1;
        let nodes = this.allClassesNodes.slice(startIndex, startIndex + this.pagination.length);
        return nodes;
      } else {
        let nodes = this.allClassesNodes.slice(0, 50);
        return nodes;
      }
    }
  },
  methods: {
    onLabelTooltipHover(rowIndex, row) {
      const tableContentElement = document.querySelector('.table-graph-data .bx--data-table-content table tbody');
      let overflowTooltipElement = tableContentElement.querySelector(`tr[value='${row.name}'] td.td-labels .overflow-label-tooltip`);
      // flip the tooltip back to the bottom first before determining what direction to set
      overflowTooltipElement.classList.add('bx--tooltip--bottom');
      overflowTooltipElement.classList.remove('bx--tooltip--top');
      const overflowTooltipTextElement = overflowTooltipElement.querySelector('.bx--assistive-text');

      // Get the bottom co-ordinate of the table content and the bottom co-ordinate of the tooltip element.
      // If the tooltip bottom is beyond the table content bottom, set top as the tooltip direction,
      // otherwise set bottom as the tooltip direction.
      // Note: If tooltip stays at the bottom without changing its direction, the tooltip will cause
      // extra amount of blank space at the bottom of the table.
      const tableContentElementBottom = tableContentElement?.getBoundingClientRect().bottom;
      // console.log('---------- tooltip: table content rect element ', tableContentElement?.getBoundingClientRect());
      // console.log('---------- tooltip: tooltip rect element ', overflowTooltipTextElement?.getBoundingClientRect());
      const overflowTooltipTextElementBottom = overflowTooltipTextElement?.getBoundingClientRect().bottom;
      if (overflowTooltipTextElementBottom >= tableContentElementBottom) {
        overflowTooltipElement.classList.add('bx--tooltip--top');
        overflowTooltipElement.classList.remove('bx--tooltip--bottom');
      } else {
        overflowTooltipElement.classList.add('bx--tooltip--bottom');
        overflowTooltipElement.classList.remove('bx--tooltip--top');
      }

    },
    setTableColumnWidth() {
      // based on the number of columns to be displayed, calculate the width for each column by
      // distributing the width of not shown columns to the displayed columns
      let columnNotShowWidthPercent = 0;
      let numberOfColumnsToDisplay = 0;
      let lastDisplayColumnId;
      // console.log("tableColumnOrder is ", this.tableColumnOrder);
      this.tableColumnOrder.forEach(columnId => {
        if (!this.tableColumnShow[columnId]) {
          columnNotShowWidthPercent += this.tableInitialColumnWidth[columnId];
        } else {
          numberOfColumnsToDisplay++;
          lastDisplayColumnId = columnId;
        }
      });
      // console.log("-----columnNotShowWidthPercent = " + columnNotShowWidthPercent + "; lastDisplayColumnId = " + lastDisplayColumnId);
      if (columnNotShowWidthPercent > 0) {
        // for now proportionally apply the percentage to the rest of the displayed columns
        const widthPercentToAddPerColumn = columnNotShowWidthPercent / numberOfColumnsToDisplay;
        // console.log("percentage to distribute is " + columnNotShowWidthPercent + "; per column is " + widthPercentToAddPerColumn);

        // add the extra percentage to all displayed columns
        this.tableColumnWidth = {};
        Object.keys(this.tableColumnShow).forEach(columnId => {
          if (this.tableColumnShow[columnId]) {
            this.tableColumnWidth[columnId] = this.tableInitialColumnWidth[columnId] + widthPercentToAddPerColumn;
          }
        })
      } else {
        this.tableColumnWidth = {...this.tableInitialColumnWidth};
      }
      // last displayed column has an auto width
      this.tableColumnWidth[lastDisplayColumnId] = "auto";
      // console.log("-------- tableColumnWidth after setting width is ", this.tableColumnWidth);
    },
    findColumnByNameAndMoveToEndOfRow(rowElement, columnName) {
      const columnElement = rowElement.querySelector(`.table-column[data-id='${columnName}']`);
      rowElement.insertAdjacentElement('beforeend', columnElement);
    },
    reorderColumns() {
      // The column reorder approach does not reorder the columns in the initial table layout. Instead it keeps the initial column order.
      // When a page is rendered cuz of pagination or search, the column elements in each row (to be rendered on the page) are then ordered.

      // Take care of initial table column width calculation
      if (Object.keys(this.tableColumnWidth).length === 0) {
        this.setTableColumnWidth();
      }

      // if last table column order is maintained (even with column hiding), then no reordering is required This approach
      // will work only if we reordered all the rows in the table.
      // console.log("new table column order is " + this.tableColumnOrder.toString() + "; last column order is " + this.tableLastColumnOrder.toString());
      // if (this.tableColumnOrder.toString() === this.tableLastColumnOrder.toString()) {
      //   console.log("------ no reorder is required as the same as last column order");
      //   return;
      // }
      
      // if there is no reodering required, skip the rest of the codes
      if (this.requireReorderColumn) {
        // Note: Always has to reorder columns as we don't know which displayed row has already reordered the columns due to pagination and search.

        // look for all tr rows including the one for thead
        const rowElementList = document.querySelectorAll(".table-view-content .table-graph-data .bx--data-table tr");

        // loop thru each row and reorder the columns per order in tableColumnOrder
        rowElementList.forEach(currentRowElement => {
          // loop thru the column orders and move the column to the end of the row
          this.tableColumnOrder.forEach(columnId => {
            this.findColumnByNameAndMoveToEndOfRow(currentRowElement, columnId);
          });
        });
      }
    },
    isUsecasesLastTableColumn() {
      let isLastColumn = false;
      if (this.tableColumnShow.usecases) {
        const usecasesIndexInColumnOrder = this.tableColumnOrder.findIndex(columnId => columnId === "usecases");
        if (usecasesIndexInColumnOrder !== -1) {
          isLastColumn = true;
          // loop thru the rest of the columns after usecases and check if the columns are to be displayed
          for(let index = usecasesIndexInColumnOrder + 1; index < this.tableColumnOrder.length && isLastColumn; index++) {
            //console.log("column id to check for last column is " + this.tableColumnOrder[index]);
            if (this.tableColumnShow[this.tableColumnOrder[index]]) {
              isLastColumn = false;
            }
          }
        }
      }
      // console.log("islastColumn: " + isLastColumn);
      return isLastColumn;
    },
    refreshLabelOnPage() {
      // whenever a page is rendered, need to take care of showing the labels
      this.refreshLabelsOnPagination = true;
    },
    getClassIndexByName(className) {
      let nameFoundIndex = -1;
      this.allClassesNodes.filter((aClass, index) => {
        if (aClass.name === className) {
          nameFoundIndex = index;
          return true;
        }
      });
      // console.log("finding " + className + " in index " + nameFoundIndex + ":", this.allClassesNodes[nameFoundIndex]);
      return nameFoundIndex;
    },
    setPageNumberOfItems(searchNodes) {
      this.tableView.pagination.numberOfItems = searchNodes.length;
    },
    setReorderColumnsOnPage() {
      this.reorderColumnsOnPage = true;
    },
    onPagination(paginationInfo) {
      //console.log("paginationInfo", paginationInfo);
      this.pagination = paginationInfo;
      this.tableView.pagination.page = paginationInfo.page;
      this.resetTablePageAndToolbar();
    },
    onSort(sortBy) {
      if (sortBy.order === "none") {
        return;
      }

      // for now clear table row batch action selection and close any open side panel, otherwise
      // existing row selection may mess up the batch action toolbar display leaving an empty
      // space there
      this.clearOpenSidePanel(false, true);
      this.resetTablePageAndToolbar();

      let usecasesSort = false;
      const sortColumnIndex = parseInt(sortBy.index); // - 1;
      // console.log("==================== sort column index is " + sortColumnIndex);
      const me = this;
      this.allClassesNodes.sort(function(node1, node2) {
        let item1, item2;
        if (me.tableOriginalColumnOrder[sortColumnIndex] === "classname") {
          item1 = node1.name;
          item2 = node2.name;
        } else if (me.tableOriginalColumnOrder[sortColumnIndex] === "partition") {
          item1 = node1.category;
          item2 = node2.category;
        } else if (me.tableOriginalColumnOrder[sortColumnIndex] === "usecases") {
          item1 = node1.semantics.length;
          item2 = node2.semantics.length;
          usecasesSort = true;
        } else if (me.tableOriginalColumnOrder[sortColumnIndex] === "path") {
          item1 = node1.FQCN;
          item2 = node2.FQCN;
        }

        if (usecasesSort) {
          if (sortBy.order === "ascending") {
            return (item1 - item2);
          } else if (sortBy.order === "descending") {
            return (item2 - item1);
          }
        } else {
          if (sortBy.order === "ascending") {
            return (item1.localeCompare(item2));
          } else if (sortBy.order === "descending") {
            return (item2.localeCompare(item1));
          }
        }
      });

      // redo the labels since it is not attached to the nodes
      this.getLabelsForClasses();

      // hide/show unsorted arrow for usecases column, otherwise the heading title
      // is not aligned all the way to the right.
      if (usecasesSort) {
        if (sortBy.order === "none") {
          this.showUsecasesHeadingUnsortedIcon(true);
        } else {
          this.showUsecasesHeadingUnsortedIcon(false);
        }
      }
    },
    onUsecasesHeadingHover() {
      // when hover over the use cases column, show the unsorted icon if sorting is set to none
      let useCaseColumnElement = document.querySelector(".table-graph-data .bx--data-table--sort thead tr th.table-usecases-header");
      if (useCaseColumnElement) {
        if (useCaseColumnElement.getAttribute("aria-sort") === "none") {
          this.showUsecasesHeadingUnsortedIcon(true);
        }
      }
    },
    onUsecasesHeadingBlur() {
      // don't hide the unsorted icon if the button still has focus, otherwise hide it when hover is off
      const useCasesSortButton = document.querySelector(".table-graph-data .bx--data-table--sort thead tr th.table-usecases-header .bx--table-sort");
      if (useCasesSortButton !== document.activeElement) {
        this.showUsecasesHeadingUnsortedIcon(false);
      }
    },
    onUsecasesHeadingFocusout() {
      // always hide the unsorted icon when use cases heading column is out of focus
      this.showUsecasesHeadingUnsortedIcon(false);
    },
    showUsecasesHeadingUnsortedIcon: function(show) {
      let useCaseUnsortedElement = document.querySelector(".table-graph-data .bx--data-table--sort thead tr th.table-usecases-header .bx--table-sort svg.bx--table-sort__icon-unsorted");
      if (useCaseUnsortedElement) {
        if (show) {
          useCaseUnsortedElement.classList.add("table-unsorted-icon-show");
        } else {
          useCaseUnsortedElement.classList.remove("table-unsorted-icon-show");
        }
      }
    },
    resetTableColumnSortedSetting() {
      // set classname column as sorted in ascending order whenever table view is displayed
      let tableClassnameColumnElement = document.querySelector(".table-graph-data .bx--data-table--sort thead tr th.table-classname-header");
      if (tableClassnameColumnElement) {
        const sortOrder = tableClassnameColumnElement.getAttribute("aria-sort");
        // console.log("------ classname sort order is " + sortOrder);
        // has to simulate the click instead of setting aria-sort, adding bx--table-sort--active and bx--table-sort--ascending or descending classes,
        // otherwise carbon vue won't know that the classname button element is sorted and manage the select/desselect sort button correctly
        const tableClassnameColumnButtonElement = tableClassnameColumnElement.querySelector("button.bx--table-sort");
        if (sortOrder !== "ascending") {
          tableClassnameColumnButtonElement.click();
        }
        if (sortOrder === "descending") {
          // click one more time as it is now unsorted
          tableClassnameColumnButtonElement.click();
        }
      }
    },
    setTableBottomBorder() {
      const theadElementHeight = document.querySelector('.table-view-content .table-graph-data .bx--data-table thead').getBoundingClientRect().height;
      const tbodyElement = document.querySelector('.table-view-content .table-graph-data .bx--data-table tbody');
      const tbodyHeight = tbodyElement?.getBoundingClientRect().height;
      const tableHeight = document.querySelector('.table-view-content .table-graph-data .bx--data-table-container')?.getBoundingClientRect().height;
      // console.log("table height is " + tableHeight + "; tbody height is " + tbodyHeight);
      // add a class to take out border-bottom to the last row of the table if table total height (head + body) needs scrolling
      if (tbodyHeight + theadElementHeight >= tableHeight) {
        // tbodyElement.className = "overflow-table-body";
        tbodyElement.classList.add("overflow-table-body");
      } else {
        // tbodyElement.className = "";
        tbodyElement.classList.remove("overflow-table-body");
      }
    },
    resetTablePageAndToolbar() {
      // clear out all selection and hide the batch action toolbar
      this.$refs?.table?.deselect();
      const toolbarElement = document.querySelector('.table-view-content .table-graph-data .bx--table-toolbar');
      if (toolbarElement) {
        toolbarElement.style.display = "none";
      }

      // reset single row selection
      this.singleRowSelectedRowIndex = -1;
      this.singleRowSelectedRowValue = '';
    },
    resetTableToFirstPage() {
      if (this.pagination.page != 1) {
        this.tableView.pagination.page = 1;
        this.pagination.page = 1;
        this.pagination.start = 1;
      }

      this.tableView.pagination.numberOfItems = this.allClassesNodes.length;
    },
    resetTable() {
      this.resetTablePageAndToolbar();

      // reset the table height without toolbar
      // Note: resetTablePageAndToolbar will call setTableDataHeight cuz of the this.$refs.table.desselect call.
      // If deselect is not called once carbon vue fixes the batch selection with page problem, the call to
      // setTableDataHeight has to be enabled.
      // this.setTableDataHeight(false); // no batch action toolbar

      // reset to first page and change page content
      this.resetTableToFirstPage();
      if (this.allClassesArr.selected) {
        this.filterValue = "";
      //   this.saveFilterValue = "";
      }

      // close any open side panel
      // defaunt #1419, item 9
      // this.clearOpenSidePanel(true, false);
    },
    actionRowSelectChange(rowsSelected) {
      // show/hide the batch action toolbar
      const toolbarElement = document.querySelector('.table-view-content .table-graph-data .bx--table-toolbar');
      if (toolbarElement) {
        if (rowsSelected.length >= 1 && toolbarElement.style.display !== "block") {
          toolbarElement.style.display = "block";
          this.setTableDataHeight(true); // include batch action toolbar

          if (this.manageLabelsSelected) {
            // adjust the opened manage label side panel height since batch action toolbar is shown
            eventBus.$emit("ManageLabelsPanel.setPanelHeight", (this.heights));
          }
        } else if (rowsSelected.length === 0 && toolbarElement.style.display !== "none"){
          // do not show batch action toolbar space when no row is selected
          toolbarElement.style.display = "none";
          this.setTableDataHeight(false);  // not include batch action toolbar

          // if no row is selected and move classes panel is opened, close the panel and adjust the table width to its original width
          if (this.moveClassesSelected) {
            this.moveClassesSelected = false;
            eventBus.$emit("MoveClassesPanel.showPanel", this.moveClassesSelected);
            this.adjustTableWidth(false);
          }
          // do the same adjustment for edit labels panel
          if (this.editLabelsSelected) {
            this.editLabelsSelected = false;
            eventBus.$emit("EditLabelsPanel.showPanel", {
              show: this.editLabelsSelected,
              selectedClasses: this.rowSelects,
              fromView: "table",
            });
            this.adjustTableWidth(false);
          }
          if (this.manageLabelsSelected) {
            // adjust the opened manage label side panel height since batch action toolbar is gone
            eventBus.$emit("ManageLabelsPanel.setPanelHeight", (this.heights));
          }
        }
      }
    },
    // updateTable() {
    //   this.table_loading = true;
    //   this.showTable = false;
    //   //this.unobservedArr = this.$store.getters.getUnobservedData();
    //   this.getAllClasses().then(response => {
    //     this.allClassesArr = response ;
    //     this.selected_view = this.$store.getters.getKey;
    //     this.allClassesNodes = this.allClassesArr.nodes;
    //     //this.updateClassDependencies();
    //     this.$refs.table?.updateRowsSelected();
    //     this.table_loading = false;
    //     this.showTable = true;
    //   });
    // },
    // temporary method to get all classes unitl api is ready
    getAllClasses() {
      // const source = this.$store.getters.getSource;
      
      // var allClasses = {};
      // allClasses[constants.nodeId]=[];
      // allClasses[constants.linkId]=[];
      // allClasses[constants.overviewId] = source[constants.overviewId];

      // source[constants.nodeId].forEach(function(element) {
      //   allClasses[constants.nodeId].push(element);
      // });

      // source[constants.linkId].forEach(function(element) {
      //   allClasses[constants.linkId].push(element);
      // });

      // // sort nodes
      // allClasses[constants.nodeId].sort(function(a,b) {
      //   return (""+a.name).localeCompare(""+b.name);
      // });

      // //console.log('allClasses return ', allClasses);
      // return allClasses;

      return new Promise(resolve => {
        const classNodes = apis.getCYClassNodesInJson();
        // sort nodes
        classNodes.nodes.sort(function(a,b) {
          return a.name.localeCompare(b.name);
        });
        setTimeout(function(){
          resolve(classNodes);
        }, 50);
      });
    },
    loadTableData(alreadyInView) {
      // console.log('key is ' + this.$store.getters.getKey + "; show table is " + this.showTable + "; table loaded is " + this.table_loaded );
      // console.log("already in view is " + alreadyInView);
      //if (this.$store.getters.getKey && this.show_unobserved_table && this.table_loaded === false ) {
      if (this.$store.getters.getKey && this.showTable && this.table_loaded === false ) {
        this.table_loading = true;
        this.showTable = false;
        this.$parent.showBusyIcon(true);
        this.table_loaded = true;
        const me = this;
        this.getAllClasses().then(response => {
          //this.unobservedArr = this.$store.getters.getUnobservedData();
          me.allClassesArr = response;
          this.populateClassesInfo();
          me.selected_view = me.$store.getters.getKey;
          me.$refs.table?.updateRowsSelected();

          if (me.selected_view == "custom_view") {
            me.custom_view_move_classes = true;
          } else {
            me.custom_view_move_classes = false;
          }
          // do not clear out the current selection and reset to page 1 if table is already in view
          if (!alreadyInView) {
            me.resetTable();
          }

          me.table_loading = false;
          me.showTable = true;
          // me.defaultClassNameSort = "ascending";

          this.$nextTick(function() {
            // Note: reorderColumns is called in updated
            this.graphToTableTransition();
            this.$nextTick(function() {
              // now figure out the labels column width and determine how many label tags should be displayed
              this.adjustDisplayedLabelTagsForTablePage();
            })
          });
          this.$parent.showBusyIcon(false);
        });
      } else {
        if (!alreadyInView && this.showTable) {
          this.resetTable();
          this.$nextTick(function () {
            this.graphToTableTransition();
          });
        }
      }
    },
    populateClassesInfo() {
      this.classesInfo = {};  // reset
      this.allClassesArr.nodes.forEach((node, i) => {
        this.classesInfo[node.name] = {
          idx: i,
        }
      });
      // get labels for classes
      this.getLabelsForClasses();
      this.allClassesNodes = this.allClassesArr.nodes;
    },
    graphToTableTransition() {
      // console.log("graph to table transition: selected is " + this.allClassesArr.selected + "; filter is " + this.saveFilterValue);
      // if both search and selected class are going on, honor selected class first
      if (this.allClassesArr.selected) {
        // if the row is already selected, don't do anything. Otherwise, it will be deselected.
        if (this.singleRowSelectedRowValue !== this.allClassesArr.selected.name) {
          // show selected class
          this.showClassInTable(this.allClassesArr.selected.name, { select: false });
        }
      } else { //if (this.saveFilterValue) {
        // set filterValue even when it is an empty string so as to trigger data to be re-rendered with correct content and pagination data
        if (this.saveFilterValue === this.filterValue) {
          this.filterValue = "";
        }
        this.filterValue = this.saveFilterValue;

        // if details panel is opened, close it if it is partition view
        eventBus.$emit("SidePanel.getPanelState", {
          event: {name: "TableView.handleDetailsPanelTransition", params: {}},
        })
      }
    },
    adjustTableDataHeight() {
      // table data height needs to be adjust after page header is collapsed/expanded
      const toolbarElement = document.querySelector('.table-view-content .table-graph-data .bx--table-toolbar');
      if (toolbarElement.style.display === "block") {
        this.setTableDataHeight(true); // include batch action toolbar
      } else {
        this.setTableDataHeight(false);  // not include batch action toolbar
      }
    },
    setTableDataHeight(includeToolbar) {
      const tableViewContentHeight = this.$refs.tableViewContent.style.height;
      this.$refs.tableOverlay.style.height = tableViewContentHeight;
      // console.log("------------------ tableViewCOntent height is " + tableViewContentHeight);
      // const dataTableElement = document.querySelector('.table-view-content .table-graph-data .bx--data-table');
      const dataTableElement = document.querySelector('.table-view-content .table-graph-data .bx--data-table-content');
      const dataTableContainerElement = document.querySelector('.table-view-content .table-graph-data .bx--data-table-container'); // when using default table display
      // no need to anymore when height is set in bx--data-table-container
      let tableHeaderHeight = "3rem"; // for pagination footer
      if (includeToolbar) {
        tableHeaderHeight = "6rem";
      }
      if (dataTableElement) {
        // console.log('setting height to ' + `calc(${tableViewContentHeight} - ${tableHeaderHeight})`);

        // table height to subtract from pagination footer and batch action toolbar if included
        dataTableElement.style.height = `calc(${tableViewContentHeight} - ${tableHeaderHeight})`;
      }
      if (dataTableContainerElement) {
        //console.log('setting height to ' + `calc(${tableViewContentHeight} - 3rem)`);

        // container height to subtract from pagination footer
        dataTableContainerElement.style.height = `calc(${tableViewContentHeight} - 3rem)`;
      }
      // set the height for other table side panels is now taking care of by calling TableView.adjustSidePanelHeight in Toolbar
      //this.$refs.moveClassesPanel.style.height = tableViewContentHeight;
      //eventBus.$emit("MoveClassesPanel.setPanelHeight");
    },
    adjustTableWidth(showSidePanel) {
      // when side panel is shown, the table will be moved inwards to show its entirely next to the side panel
      // but the batch action toolbar will still be shown across the original table width.
      let tableWidth = "100%";
      let toolbarWidth = "100%";
      if (showSidePanel) {
        // side panel has a fixed 30rem width
        tableWidth = `calc(100% - 30rem)`;
        if (this.detailsPanelSelected !== true) {  
          toolbarWidth = `calc(100% + 30rem)`;  // when details panel is closed, toolbar width has to be full size 
        }
      }
      const tableElement = document.querySelector('.table-view-content .table-graph-data');
      const toolbarElement = document.querySelector('.table-view-content .table-graph-data .bx--table-toolbar');
      if (tableElement) {
        tableElement.style.width = tableWidth;
      }
      if (toolbarElement) {
        toolbarElement.style.width = toolbarWidth;
      }
    },
    onTableRowClick(e, rowIndex, rowData, clearSelectedFromGraph) {
      // ignore event caused by checkbox click
      if (e && (e.target?.className === "bx--checkbox-label" || e.target?.className === "bx--checkbox")) {
        // ignore the event as it is for the checkbox,
        return;
      }
      if (this.singleRowSelectedRowIndex != rowIndex) {
        this.clearOpenSidePanel(false, clearSelectedFromGraph);

        this.singleRowSelectedRowIndex = rowIndex;
        this.singleRowSelectedRowValue = rowData.name;

        // let matchedNode = this.allClassesNodes.filter(classNode => {
        //   return classNode.name === this.singleRowSelectedRowValue;
        // });

        // if (matchedNode.length === 1) {
        //   const classElement = apis.getNodeVisibility(this.singleRowSelectedRowValue);
        if (this.classesInfo[this.singleRowSelectedRowValue]) {
          const classElement = apis.getNodeByParent(null, this.singleRowSelectedRowValue, this.allClassesNodes[this.classesInfo[this.singleRowSelectedRowValue].idx].parent);
          //console.log('classElement is ', classElement);
          // open side panel
          if (classElement && classElement.length > 0) {
            // unobserved class is not in cy at this point, show overview side panel for unobserved class
            eventBus.$emit("SidePanel.showMicroservicePanel", classElement[0]);
            eventBus.$emit("SidePanel.displayPanel", true);
            // update the toolbar details button with selected state
            eventBus.$emit("Toolbar.updateDetailsButton", true);

            this.adjustTableWidth(true);
            this.$nextTick(function() {
              this.adjustDisplayedLabelTagsForTablePage();
            })

            // set search history if filter is on
            // console.log("filter value is " + this.filterValue + "; row selected value is " + this.singleRowSelectedRowValue);
            if (this.filterValue && this.singleRowSelectedRowValue.toLowerCase().indexOf(this.filterValue.toLowerCase()) > -1) {
              this.$store.commit("addSearchItem", this.singleRowSelectedRowValue);
            }
          } else {
            eventBus.$emit("SidePanel.hideMicroservicePanel");
          }
        }
      } else {
        this.clearSingleRowTableSelect(true, true);
      }
    },
    onMoveClasses() {
      if (!this.moveClassesSelected) {
        this.clearOpenSidePanel(false, true);
      }

      this.moveClassesSelected = !this.moveClassesSelected;
      eventBus.$emit("MoveClassesPanel.showPanel", this.moveClassesSelected);
      this.handlePostMoveClasses(false);
    },
    onEditLabels(fromTableView, selectedClass) {
      // close any opened side panel in table view
      if (!this.editLabelsSelected && fromTableView) {
        this.clearOpenSidePanel(false, true);
      }
      this.editLabelsSelected = !this.editLabelsSelected;
      if (fromTableView) {
        eventBus.$emit("EditLabelsPanel.showPanel", {
          show: this.editLabelsSelected,
          selectedClasses: this.rowSelects,
          fromView: "table",
        });
        this.handlePostEditLabels(false);
      } else {
        eventBus.$emit("EditLabelsPanel.showPanel", {
          show: this.editLabelsSelected,
          selectedClasses: [selectedClass],
          fromView: "sidePanel"
        });
      }
    },
    onManageLabels(closeOpenedSidePanel) {
      // don't clear if manage label panel is initiated from edit label panel
      if (closeOpenedSidePanel && !this.manageLabelsSelected) {
        this.clearOpenSidePanel(false, true);
      }
      this.manageLabelsSelected = !this.manageLabelsSelected;
      eventBus.$emit("ManageLabelsPanel.setPanelHeight", (this.heights));
      eventBus.$emit("ManageLabelsPanel.showPanel", this.manageLabelsSelected);
      this.showTableOverlay = this.manageLabelsSelected;
    },
    isRowSelectedForBatchAction(className) {
      return this.rowSelects.includes(className);
    },
    clearOpenSidePanel(reset, clearSelectedFromGraph) {
      if (this.moveClassesSelected) {
        this.moveClassesSelected = false;
        eventBus.$emit("MoveClassesPanel.showPanel", this.moveClassesSelected);
        this.adjustTableWidth(false);
      }
      if (this.editLabelsSelected) {
        this.editLabelsSelected = false;
        eventBus.$emit("EditLabelsPanel.showPanel", {
          show: this.editLabelsSelected,
          selectedClasses: this.rowSelects,
          fromView: "table",
        });
        this.adjustTableWidth(false);
      }
      if (this.manageLabelsSelected) {
        // console.log('clear open side panel with manage label opened ' + this.refreshLabelsInTable);
        this.manageLabelsSelected = false;
        eventBus.$emit("ManageLabelsPanel.showPanel", this.manageLabelsSelected);
        if (this.reloadLabelsForClass) {
          this.reloadLabelsForClass = false;
          this.getLabelsForClasses();
        }
        if (this.refreshLabelsInTable) {
          this.refreshLabelsInTable = false;
          this.adjustDisplayedLabelTagsForTablePage();
        }
        //this.adjustTableWidth(false);
      }
      if (this.showTableOverlay) {
        this.showTableOverlay = false;
      }
      // detail side panel could be opened thru toolbar
      if (reset) {
        this.clearSingleRowTableSelect(true, false);
      } else {
        this.clearSingleRowTableSelect(true, clearSelectedFromGraph);
      }

      // filter panel
      eventBus.$emit("Toolbar.closeFilterPanel");
      eventBus.$emit("FilterPanel.showPanel", false);
    },
    clearSingleRowTableSelect(closePanel, clearHighlights) {
      // deselect the selected row per design
      this.singleRowSelectedRowIndex = -1;
      this.singleRowSelectedRowValue = '';

      // clean up highlight in the graph if there was highlight previously in the graph with the graph to view transition
      if (clearHighlights && this.allClassesArr.selected) {
        apis.cleanupAllHighlights({ unselect: false });
        this.allClassesArr.selected = undefined;
      }

      if (closePanel) {
        // close side panel
        eventBus.$emit("SidePanel.displayPanel", false);
        // remove the selected state for the toolbar details button
        eventBus.$emit("Toolbar.updateDetailsButton", false);
      }
      // adjust the table width and refresh label tags
      this.adjustTableWidth(false);
      this.$nextTick(function() {
        this.adjustDisplayedLabelTagsForTablePage();
      })

      // default back to overview only if in table view
      if (this.showTable) {
        eventBus.$emit("SidePanel.hideMicroservicePanel");
      }
    },
    showClassInTable(className, options) {
      const idx = this.classesInfo[className].idx; //this.allClassesNodes.findIndex(node => node.name === className);
      if (idx >= 0) {
        this.pagination.page = Math.ceil((idx + 1) / this.pagination.length);
        this.pagination.start = (this.pagination.page - 1) * this.pagination.length + 1;
        this.tableView.pagination.page = this.pagination.page;
        if (options) {
          if (options.select === true) {
            if (this.rowSelects.indexOf(className) < 0) {
              this.rowSelects.push(className);
            }
            this.actionRowSelectChange(this.rowSelects);
          } else {  // highlight the row only
            this.onTableRowClick(null, idx % this.pagination.length, this.allClassesNodes[idx], false); // the last param is added to preserve the allClassesArr.selected
          }
        }
        this.$nextTick(function () {
          const rows = document.querySelectorAll(`tr[value=${className}]`);
          if (rows.length > 0) {
            rows[0].scrollIntoView({ block: "end", inline: "nearest" });
          }
        });
      }
    },
    handlePostMoveClasses(setFocus) {
      if (this.moveClassesSelected) {
        this.adjustTableWidth(true);
      } else {
        this.adjustTableWidth(false);
      }

      this.$nextTick(function() {
        this.adjustDisplayedLabelTagsForTablePage(); // calculate the label display as table is restored back to its original size
        if (setFocus) {
          this.$refs.moveClassesButton?.$el.focus(); // for accessibility to put focus back after side panel is closed
        }
      })
    },
    handlePostEditLabels(setFocus) {
      if (this.editLabelsSelected) {
        this.adjustTableWidth(true);
      } else {
        this.adjustTableWidth(false);
      }

      this.$nextTick(function() {
        this.adjustDisplayedLabelTagsForTablePage(); // calculate the label display as table is restored back to its original size
        if (setFocus) {
          this.$refs.editLabelsButton?.$el.focus(); // for accessibility to put focus back after side panel is closed
        }
      })
    },
    handlePostDetails(open) {
      this.detailsPanelSelected = open;
      this.adjustTableWidth(open);
      this.$nextTick(function() {
        this.adjustDisplayedLabelTagsForTablePage(); // calculate the label display as table is restored back to its original size
      })
    },
    getLabelsColumnWidth() {
      const labelsColumnElement = document.querySelector('.td-labels');
      // console.log("-------------- bounding rectangel height is ", labelsColumnElement?.getBoundingClientRect().width);
      const width = labelsColumnElement?.getBoundingClientRect().width - 16 - 64; // less padding-left (16) and empty trailing space requirement (64) from design
      if (this.labelsColumnWidth !== width) {
        this.labelsColumnWidth = width;
        return true;
      }
      return false;
      // console.log("labelsColumnWidth is " + this.labelsColumnWidth);
    },
    getTagElementWidth(tagElement) {
      let tagElementWidth = 0;
      if (tagElement) {
        // has to display the tag to calculate its width
        if (tagElement.style.display === "none") {
          tagElement.style.display = "inline-block";
        }
        const tagElementComputedStyle = window.getComputedStyle(tagElement);
        // console.log("offsetWidth is  " + tagElement.offsetWidth);
        // console.log("bounding client rect width is " + Math.ceil(tagElement.getBoundingClientRect().width));
        // console.log("left margin is " + tagElementComputedStyle.marginLeft);
        // console.log("right margin is " + tagElementComputedStyle.marginRight);
        tagElementWidth = Math.ceil(tagElement.getBoundingClientRect().width) +
                          Math.ceil(tagElementComputedStyle.marginLeft.replace(/[^0-9.]/g, "")) +
                          Math.ceil(tagElementComputedStyle.marginRight.replace(/[^0-9.]/g, ""));
        // console.log("tag total width is " + tagElementWidth);
      }
      return tagElementWidth;
    },
    adjustDisplayedLabelTagsForTablePage() {
      if (this.tableColumnShow.labels) {
        if (this.getLabelsColumnWidth() === true) { // as table could move to the left or back to its original size
          const tableRowsInPage = document.querySelectorAll(".table-graph-data table tbody tr");
          tableRowsInPage.forEach((tableRowElement) => {
            const className = tableRowElement.getAttribute('value');
            this.adjustDisplayedLabelTagsForClass(className);
          });
        }
      }
    },
    adjustDisplayedLabelTagsForClass(className) {
      // get the width of the overflow tag
      let overflowTagElement = document.querySelector(`.table-graph-data .bx--data-table-content table tbody tr[value='${className}'] td.td-labels .cv-tag.overflow-tag`);
      const overflowTagElementWidth = this.getTagElementWidth(overflowTagElement);
      if (overflowTagElement && (!overflowTagElement.style || overflowTagElement.style.display !== 'none')) {
        overflowTagElement.style.display = "none"; // hide it for now after getting the width
      }

      // get all tags belonging to the class from the table and determine whether each tag is shown or not.
      // Per design, label tag never gets closer than 64px to the edge of the table row.
      // Before that limit is reached, the +#ofRemainingTags tag will be shown.
      let labelTagsInTableRow = document.querySelectorAll(`.table-graph-data .bx--data-table-content table tbody tr[value='${className}'] td.td-labels .cv-tag:not(.overflow-tag)`);
      // console.log("-------- tags for " + className + ": ", labelTagsInTableRow);
      let tagsWidth = 0;
      let numOverflowTags = 0;
      let overflowTooltip = '';
      const me = this;
      labelTagsInTableRow.forEach((tagElement, index) => {
        const tagElementWidth = this.getTagElementWidth(tagElement);
        // not using <= as I've encountered a case during manual testing where it is equal and the last displayed tag is still shown on the next line
        if (tagsWidth + tagElementWidth < me.labelsColumnWidth) {
          if (index === labelTagsInTableRow.length - 1 && numOverflowTags === 0) {
            // last tag element and no overflow - display it
            tagElement.style.display = "inline-block";
            tagsWidth += tagElementWidth;
            //overflowTagElement.style.display = "none";
          } else {
            // check whether overflow tag will fit if not last element in case overflow tag has to show.
            // if it is the last element and overflow is to be shown, do the same check
            if (tagsWidth + tagElementWidth + overflowTagElementWidth < me.labelsColumnWidth) {
              tagElement.style.display = "inline-block";
              tagsWidth += tagElementWidth;
            } else {
              tagElement.style.display = "none";
              numOverflowTags += 1;
              // construct the hidden label as part of the tooltip for the overflow tag
              overflowTooltip = overflowTooltip +  "<div>" + tagElement.innerText + "</div>";
            }
          }
        } else {
          tagElement.style.display = "none";
          numOverflowTags += 1;
          // construct the hidden label as part of the tooltip for the overflow tag
          overflowTooltip = overflowTooltip +  "<div>" + tagElement.innerText + "</div>";
        }
      })
      if (numOverflowTags> 0) {
        let overflowTooltipElement = document.querySelector(`.table-graph-data .bx--data-table-content table tbody tr[value='${className}'] td.td-labels .overflow-label-tooltip .bx--assistive-text`);
        overflowTagElement.innerText = "+" + numOverflowTags;
        overflowTagElement.style.display = "inline-block";
        overflowTooltipElement.innerHTML = overflowTooltip;
      }
    },
    addLabelToClass(classLabel, labelName, addToBeginning) {
      if (classLabel) {
        if (!classLabel.includes(labelName)) {
          if (addToBeginning) {
            classLabel.unshift(labelName);
          } else {
            classLabel.push(labelName);
          }
        }
      }
    },
    getLabelsForClasses() {
      if (this.tableColumnShow.labels) {
        this.allClassesLabels = [];
        this.allClassesArr.nodes.forEach(() => {
          this.allClassesLabels.push([]);
        });

        this.allLabels = this.$store.getters.getLabels;
        // loop through all labels to construct it for each class it is assigned to
        this.allLabels.forEach((label) => {
          const classesInLabel = label.assignedClasses;
          classesInLabel.forEach((className) => {
            // find the matching class in the nodes
            if (this.classesInfo[className]) {
              let classLabel = this.allClassesLabels[this.classesInfo[className].idx];
              this.addLabelToClass(classLabel, label.name, false);
            }
          })
        });
      }
    },
    assignUnassignLabelsToSelectedClassesInTable(changeLabelsInfo) {
      // This method is to assign and unassign labels to selected classes when labels are applied
      let classes = this.rowSelects;
      if (changeLabelsInfo.fromView && changeLabelsInfo.fromView === "sidePanel") {
        classes = [this.singleRowSelectedRowValue];
      } 
      classes.forEach((className) => {
        const index = this.classesInfo[className].idx; //this.allClassesNodes.findIndex(node => node.name === className);
        if (index > -1) {
          // display the new labels in the same order as the label list
          // To do: call new api to get the labels instead of from the store
          this.allLabels = this.$store.getters.getLabels; // get a copy of the latest labels
          const labelsToAdd = this.allLabels.filter((label) => {
            if (Object.keys(changeLabelsInfo.changes).includes(label.name)) {
              return true;
            }
          });
          // console.log("labels to add in order ", labelsToAdd);
          labelsToAdd.reverse().forEach((labelObject) => {
            const label = labelObject.name;
            let classLabel = this.allClassesLabels[index];
            if (changeLabelsInfo.changes[label] === true) {
              this.addLabelToClass(classLabel, label, true);
            } else {
              const deleteIndex = classLabel.findIndex(labelName => labelName === label);
              if (deleteIndex > -1) {
                classLabel.splice(deleteIndex, 1);
              }
              // console.log("remove labels from classes", classLabel);
            }
          });
        }
      });
    },
    updateLabelForClassInTable(isDelete, labelData, newLabelName) {
      // This method is called when the label itself is deleted or changed.
      // When a label is deleted, have to delete the label from its assigned classes.
      // When a label is edited, change the label name in this.allClassesLabels array.
      labelData.assignedClasses.forEach((className) => {
        const index = this.classesInfo[className].idx; //this.allClassesNodes.findIndex(node => node.name === className);
        if (index > -1) {
          let classLabels = this.allClassesLabels[index];
          const labelIndex = classLabels.findIndex(labelName => labelName === labelData.name);
          if (labelIndex > -1) {
            // For now, it is either delete or edit label name
            if (isDelete) {
              // The display of the tag will be adjusted when adjustDisplayedLabelTagsForTablePage is called as
              // it involves more than just deleting the tag in the table row.
              classLabels.splice(labelIndex, 1);
            } else {
              // when label name is changed, changing innerText of its existing cv-tag will refresh the tag in the table row. However, it causes
              // wrong label to show in a subsequent new tag when a label is added. Hence, the tags in the table rows are refreshed by 
              // TableView.deselectManageLabels event when the manage label panel is closed.
              classLabels[labelIndex] = newLabelName;
              // update label tag
              // let labelTagsInTableRow = document.querySelector(`.table-graph-data .bx--data-table-content table tbody tr[value='${className}'] td.td-labels .cv-tag[value='${labelData.name}'] span`);
              // if (labelTagsInTableRow) {
              //   labelTagsInTableRow.innerText = newLabelName;
              //   labelTagsInTableRow.parentElement.setAttribute('value', newLabelName);
              // }
              // let labelTagsInTableRow = document.querySelectorAll(`.table-graph-data .bx--data-table-content table tbody tr[value='${className}'] td.td-labels .cv-tag:not(.overflow-tag) span`);
              // labelTagsInTableRow.forEach((labelTag) => {
              //   if (labelTag.innerText === labelData.name) {
              //     console.log('found matching old label name ' + labelTag.innerText);
              //     // replace it with the new name
              //     labelTag.innerText = newLabelName;
              //   }
              // });
            }
          }
        }
      });
    },
    hideLabelsPanel() {
      // always clear out the manage and edit labels panels as they could be opened by either graph or table view.
      // Scenarios:
      // 1. from graph to table tab of same view (BL/NS/CV)
      // 2. from table to graph tab of same view (BL/NS/CV)
      // 3. while in graph tab switch to different view
      if (this.editLabelsSelected) {
        this.editLabelsSelected = !this.editLabelsSelected;
        eventBus.$emit("EditLabelsPanel.showPanel", {
          show: false,
          selectedClasses: [],
          fromView: ""
        });
        this.adjustTableWidth(false);
      }
      if (this.manageLabelsSelected) {
        this.manageLabelsSelected = !this.manageLabelsSelected;
        eventBus.$emit("ManageLabelsPanel.showPanel", false);
      }
      // check if side pane is open

      const ele = document.getElementById('sidepanel');
      if ( ele && ele.style.display == "none")
      {
        eventBus.$emit("Graph.sidePanelOpen", false);
      } else {
        eventBus.$emit("Graph.sidePanelOpen", true);
      }
    }
  },
  updated() {
    // console.log("in updated: " + this.showTable);
    this.$refs.table?.updateRowsSelected();
    this.$nextTick(function () {
      this.loadTableData(this.showTable);
      // console.log("--------- updated: reorderColumnsOnPage " + this.reorderColumnsOnPage);
      if (this.showTable && this.reorderColumnsOnPage) {
        this.reorderColumnsOnPage = false;
        this.reorderColumns();
        this.setTableBottomBorder();
      }
      // Note: Update is called when a label is deleted, not when a label is edited.
      // Refresh the label tags on the page when
      // - a different page is rendered
      // - when a label is deleted and manage label is initiated from the edit label panel with the table shifted
      // to the left of the side panel. Otherwise don't refresh until the managed label panel is closed
      // (See TableView.deselectManageLabels) unless pagination happens to trigger the adjustment
      // of the tags.
      if (this.showTable && (this.refreshLabelsOnPagination || (this.refreshLabelsInTable && this.editLabelsSelected && this.manageLabelsSelected))) {
        // console.log("going to readjust the table tag");
        this.labelsColumnWidth = -1;  // reset the variable in order to update label column
        this.adjustDisplayedLabelTagsForTablePage();
        if (this.refreshLabelsOnPagination) {
          this.refreshLabelsOnPagination = false;
        } else {
          this.refreshLabelsInTable = false;
        }
      }
    })
  },
  mounted() {
    $('label.manage-label-link-in-header').on('keydown', function(event) {
      //console.log(event.keyCode);
      if (event.keyCode==13 || event.keyCode==32) {
        $(this).trigger("click");
      }
    });

    this.$refs.table.updateRowsSelected();
    eventBus.$on(
      "TableView.show", () => {
        let alreadyInView = false;
        if (this.showTable) {
          alreadyInView = true;
        // } else {
        //   this.clearOpenSidePanel();
        }
        this.showTable = true;
        // will reload if TableView.resetTable is called earlier, otherwise shouldn't trigger a reload
        this.loadTableData(alreadyInView);

        // This path is exercised when switching from graph to table tab.
        this.hideLabelsPanel();
      }
    )
    eventBus.$on(
      "TableView.hide", () => {
          // only do highlight in graph within same view
          if (this.selected_view === this.$store.getters.getKey) {
            // highlight selected node in graph
            apis.cleanupAllHighlights({ unselect: false });
            if (this.singleRowSelectedRowValue.length <= 0 && !this.filterValue) {
              // clear the details panel if opened to see details other than class
              // eventBus.$emit('SidePanel.displayPanel', false);
              // clear out graph highlights if it is detail view
              eventBus.$emit("SidePanel.getPanelState", {
                event: {name: "TableView.handleDetailsPanelTransition", params: {}},
              });
            } else {
              (async () => {
                await apis.showLoadingIcon(true, utils.getFunctionCallDelay(this.$store.getters.getCYInstance()));
                if (this.singleRowSelectedRowValue.length > 0) {
                  // apis.showNodeDependencies(this.singleRowSelectedRowValue);
                  await apis.highlight(this.singleRowSelectedRowValue);
                  if (utils.hasFocusedNodes() !== true) { // nothing is highlighted (class in a closed partition), clear selected single row
                    //this.clearSingleRowTableSelect(true, false); // close details panel if opened and don't clear AllClassesArr.selected
                  } else {
                    // as the highlight is set here, set allClassesArr.selected in case switching back to table without clicking anything 
                    // else in the graph
                    // find the real index
                    const selectedIndex = this.classesInfo[this.singleRowSelectedRowValue].idx; //this.allClassesNodes.findIndex(node => node.name === this.singleRowSelectedRowValue);
                    this.allClassesArr.selected = this.allClassesNodes[selectedIndex];
                  }
                } else if (this.filterValue) {
                  // make matching case insensitive
                  const regex = new RegExp(this.filterValue, 'i');
                  const matchedIndex = this.allClassesNodes.findIndex(item => item.name.search(regex) >= 0);
                  // const matchedIndex = this.allClassesNodes.findIndex(node => node.name.toLowerCase() === this.filterValue.toLowerCase());
                  // console.log("---------- transition to graph with filter value " + this.filterValue + " and index in table is " + matchedIndex);
                  if (matchedIndex > -1) {
                    // apis.showNodeDependencies(this.allClassesNodes[matchedIndex].name); // (this.filterValue);
                    await apis.highlight(this.allClassesNodes[matchedIndex].name);
                    if (utils.hasFocusedNodes() === true) {
                      const classElement = apis.getNodeVisibility(this.allClassesNodes[matchedIndex].name);
                      if (classElement?.classNode) {
                        eventBus.$emit("SidePanel.showMicroservicePanel", classElement.classNode);
                        // set selected
                        this.allClassesArr.selected = this.allClassesNodes[matchedIndex];
                      }
                    }
                  }
                }
                apis.showLoadingIcon(false);
              })();
            }
          }
          // The hideLabelsPanel call is necessary as this path is exercised when switching from table to graph tab
          this.hideLabelsPanel();
          this.resetTableColumnSortedSetting();
          this.showTable = false;
      }
    )
    eventBus.$on("TableView.deselectRow", () => {
      // This event is called when the details panel is closed from the SidePanel or Toolbar details button
      if (this.showTable) {
        // clear out allClassesArr.selected saved for graph to table transition if detail panel is closed while in table view
        this.clearSingleRowTableSelect(false, true);
      } else {
        // closing detail panel doesn't mean selected class is deselected in the graph view.
        // Don't clear allClassesArr.selected saved for graph to table transition for now.
        this.clearSingleRowTableSelect(false, false);
      }
    });
    eventBus.$on("TableView.resetTable", (showTableOpt) => {
      // console.log("---------- TableView.resetTable with showTable to " + showTable);
      const showTable = (showTableOpt === undefined) ? this.showTable : showTableOpt;
      if (showTable) {
        let alreadyInView = false;
        if (this.showTable) {
          alreadyInView = true;
        }
        // force an immediate reloading of the table if table is in view
        this.showTable = true;
        this.table_loaded = false;

        this.loadTableData(alreadyInView);
      } else if (this.table_loaded) {
        // reset to trigger loading of table when the table is actually shown
        this.table_loaded = false;
        this.showTable = false;
      }
      // clear out any opened side panel now to avoid seeing data left earlier before clearing them out
      if (showTable) {
        this.clearOpenSidePanel(true, false);
      }
    });
    eventBus.$on("TableView.adjustTableDataHeight", () => {
      // table data height needs to be adjust after page header is collapsed/expanded
      this.adjustTableDataHeight();
    });
    eventBus.$on("TableView.adjustSidePanelHeight", (heights) => {
      this.heights = heights;
      eventBus.$emit("MoveClassesPanel.setPanelHeight", (heights));
      eventBus.$emit("EditLabelsPanel.setPanelHeight", (heights));
      eventBus.$emit("ManageLabelsPanel.setPanelHeight", (heights));
    });
    eventBus.$on("TableView.adjustDisplayedLabels", () => {
      if (this.showTable) {
        this.$nextTick(function() {
          this.adjustDisplayedLabelTagsForTablePage();
        });
      }
    });
    eventBus.$on("TableView.deselectMoveClassesButton", () => {
      this.moveClassesSelected = false;
      this.handlePostMoveClasses(true);
    });
    eventBus.$on("TableView.onMoveClasses", (moveToPartition) => {
      if (moveToPartition) {
        // this.rowSelects.forEach((selectedClass) => {
        //   // move class to an existing partition
        //   apis.changeClassPartition(selectedClass, {
        //     partition: moveToPartition,
        //     removeEmptyPartition: true,
        //     updateCY: true
        //   });
        // })
        if (this.rowSelects.length > 0) {
          apis.moveClassesToMultiplePartitions({ [moveToPartition]: { classes: this.rowSelects} });
        }
      }
      this.moveClassesSelected = false;
      this.handlePostMoveClasses(true);
      // if partition is sorted, resort it
      // let tablePartitionColumnElement = document.querySelector(".table-graph-data .bx--data-table--sort thead tr th.table-partition-header");
      // if (tablePartitionColumnElement) {
      //   const sortOrder = tablePartitionColumnElement.getAttribute("aria-sort");
      //   if (sortOrder !== "none") {
      //     // has to simulate the click instead of setting aria-sort, and removing bx--table-sort--active and bx--table-sort--ascending or descending classes,
      //     // otherwise carbon vue won't know that the partition button element is unsorted and manage the select/desselect sort button correctly
      //     const tablePartitionColumnButtonElement = tablePartitionColumnElement.querySelector("button.bx--table-sort");
      //     if (sortOrder === "ascending") {
      //       // click to get to descending
      //       tablePartitionColumnButtonElement.click();
      //     }
      //     // now click to get to unsorted
      //     tablePartitionColumnButtonElement.click();
      //     // click to get to ascending
      //     tablePartitionColumnButtonElement.click();
      //     if (sortOrder === "descending") {
      //       // click to get to descending
      //       tablePartitionColumnButtonElement.click();
      //     }
      //   }
      // }
    });
    // Note: this event shouldn't be called by EditLabelsPanel if Edit button is clicked to
    // apply label assignment to classes. This event should only call when the close (x) button
    // is clicked in EditLabelsPanel
    eventBus.$on("TableView.deselectEditLabelsButton", () => {
      this.editLabelsSelected = false;
      this.handlePostEditLabels(true);
    });
    eventBus.$on("TableView.onEditLabelsFromSidePanel", (selectedClass) => {
      if (!this.showTable) {
        // launch edit labels panel when in graph view
        this.onEditLabels(false, selectedClass);
        this.adjustTableWidth(true);
      } else {
        // simulate a batch action to provide consistent pattern
        if (this.singleRowSelectedRowIndex !== -1) {
          // const me = this;
          this.$nextTick(function() {
            // // simulate a checkbox click on the selected row
            // const rowElement = document.querySelectorAll(".table-view-content .table-graph-data table tbody tr")[this.singleRowSelectedRowIndex];
            // if (rowElement) {
            //   const checkboxElement = rowElement.querySelector("td .cv-checkbox input");
            //   if (checkboxElement && checkboxElement.checked !== true/*&& checkboxElement.getAttribute("aria-checked") !== "true"*/) {
            //     checkboxElement.click();
            //   }
            // }
            // // now bring up the edit labels panel
            // this.onEditLabels(true);

            // comment out code above, as the side panel only shows information of the particular class,
            // should edit labels for the class only
            this.onEditLabels(false, selectedClass);
            if (this.rowSelects.length > 0 && !this.detailsPanelSelected) {
              this.detailsPanelSelected = true;
              this.adjustTableWidth(true);
              this.detailsPanelSelected = false;
            } else {
              this.adjustTableWidth(true);
            }            
          });
        }
      }
    });
    eventBus.$on("TableView.onEditLabels", (changeLabelsInfo) => {
      // avoid potential racing problem - deselect edit label button and adjust table width here instead
      // of having EditLabelsPanel calling TableView.deselectEditLabelsButton event
      this.editLabelsSelected = false;
      if (this.showTable) {
        this.assignUnassignLabelsToSelectedClassesInTable(changeLabelsInfo);
        this.handlePostEditLabels(true);
      }
    });
    eventBus.$on("TableView.deselectManageLabels", (hasChanges) => {
      // this event is called when the manage label side panel is closed. If any label has been edited, it will
      // take care of reloading the labels and refreshing the label tags on the current page. If any label is deleted
      // and tag refresh is delayed, it will take care of refreshing the label tags.
      this.manageLabelsSelected = false;
      // this.adjustTableWidth(false);
      // console.log("deselectManageLabels: refreshLabels - " + this.refreshLabelsInTable + "; reloadLabels - " + this.reloadLabelsForClass);
      this.$nextTick(function() {
        if (hasChanges && this.showTable) {
          if (this.reloadLabelsForClass) {
            // console.log("--- reloading label with deselect manage label");
            this.reloadLabelsForClass = false;
            // has to reload the labels when label changes its name in manage label panels. otherwise the tags are not updated correctly.
            this.getLabelsForClasses();
            this.$nextTick(() => {  // needs to wait for tags/labels updates, otherwise, the width of changed tags/labels won't be correct
              this.labelsColumnWidth = -1;  // reset the variable in order to update label column
              this.adjustDisplayedLabelTagsForTablePage();
            });
          } else if (this.refreshLabelsInTable) {
            // console.log("---refreshing label in table with deselect manage label");
            this.labelsColumnWidth = -1;  // reset the variable in order to update label column
            this.adjustDisplayedLabelTagsForTablePage();
          }
          this.refreshLabelsInTable = false; // reset here outside the if-else as both editing and deleting labels could have been performed
        }
        // if manage label panel is closed and table overlay is applied, remove table overlay
        if (this.showTableOverlay) {
          this.showTableOverlay = false;
        }
        if (this.editLabelsSelected) {
          // put focus on edit label panel as manage labels panel is opened from edit labels panel
          eventBus.$emit("EditLabelsPanel.setFocus");
        } else {
            this.$refs.manageLabelsButton?.$el.focus(); // for accessibility to put focus back after side panel is closed
        }
      })
    });
    eventBus.$on("TableView.selectManageLabels", () => {
      // the manage label is called from the edit label panel, hence don't clear the edit label side panel
      this.onManageLabels(false);
    });
    eventBus.$on("TableView.deleteLabel", (labelData) => {
      this.updateLabelForClassInTable(true, labelData);
      this.refreshLabelsInTable = true;
    });
    eventBus.$on("TableView.editLabelName", (labelData) => {
      this.updateLabelForClassInTable(false, labelData.oldLabelData, labelData.newLabelName);
      // The reload of the labels and refresh of the label tags on the current page are delayed until manage label panel is closed.
      this.reloadLabelsForClass = true; // reload label will refresh label in table too
      // this.refreshLabelsInTable = true;
    });
    eventBus.$on("TableView.search", (filterValue) => {
      this.saveFilterValue = filterValue;
      if (this.showTable) {
        if (this.filterValue !== filterValue) {
          this.resetTableToFirstPage();
          // clear out any selected row for batch action as the count could be mileading
          this.$refs?.table?.deselect();
        }
        this.filterValue = filterValue;
        this.reorderColumnsOnPage = true;
      }
    });
    eventBus.$on("TableView.clearOpenSidePanel", (nextAction) => {
      if (this.showTable) {
        this.clearOpenSidePanel(true, false);
        if (nextAction?.event?.name && nextAction?.event?.params) {
          eventBus.$emit(nextAction.event.name, nextAction.event.params);
        }
      }
    });
    eventBus.$on("TableView.showTableCustomizationModal", () => {
      eventBus.$emit("TableCustomization.showModal");
    });
    eventBus.$on("TableView.setTableCustomization", (tableColumnCustomization) => {
      if (tableColumnCustomization.showColumn) {
        // this will trigger the watch event for tableColumnShow
        this.tableColumnShow = tableColumnCustomization.showColumn;
      }
      if (tableColumnCustomization.columnOrder) {
        // this will trigger the watch event for tableColumnOrder
        this.tableColumnOrder = tableColumnCustomization.columnOrder;
      }
    });
    eventBus.$on("TableView.hideLabelsPanel", () => {
      this.hideLabelsPanel();
    });
    eventBus.$on("TableView.onDetailsPanel", (open) => {
      this.handlePostDetails(open);
    })
    eventBus.$on("TableView.handleDetailsPanelTransition", (panelState) => {
      // This event is called during graph/table transition
      if (panelState === "microservice") {
        // close partition view details panel
        eventBus.$emit("SidePanel.displayPanel", false);
        this.adjustTableWidth(false);
      } else if (panelState === "view") {
        // clear out highlights in graph for view details
        apis.cleanupAllHighlights();
      }
    });
  },
  beforeDestroy() {
    eventBus.$off("TableView.show");
    eventBus.$off("TableView.hide");
    eventBus.$off("TableView.deselectRow");
    eventBus.$off("TableView.resetTable");
    eventBus.$off("TableView.adjustTableDataHeight");
    eventBus.$off("TableView.adjustSidePanelHeight");
    eventBus.$off("TableView.adjustDisplayedLabels");
    eventBus.$off("TableView.deselectMoveClassesButton");
    eventBus.$off("TableView.onMoveClasses");
    eventBus.$off("TableView.deselectEditLabelsButton");
    eventBus.$off("TableView.onEditLabelsFromSidePanel");
    eventBus.$off("TableView.onEditLabels");
    eventBus.$off("TableView.deselectManageLabels");
    eventBus.$off("TableView.selectManageLabels");
    eventBus.$off("TableView.deleteLabel");
    eventBus.$off("TableView.editLabelName");
    eventBus.$off("TableView.search");
    eventBus.$off("TableView.clearOpenSidePanel");
    eventBus.$off("TableView.showTableCustomizationModal");
    eventBus.$off("TableView.setTableCustomization");
    eventBus.$off("TableView.hideLabelsPanel");
    eventBus.$off("TableView.onDetailsPanel");
    eventBus.$off("TableView.handleDetailsPanelTransition");
  }
};
</script>

<style lang="scss" scoped>
.table-view-content {
  width: 100%;
  background-color: var(--app-graph-background-color);
  margin-top: -1px;
  position: fixed;

  @media (max-width: 41.98rem) {
    position: unset;  // remove to allow horizontal scrolling for table
  }

  .table-overlay {
    transition: opacity 240ms cubic-bezier(0, 0, 0.3, 1), visibility 0ms linear;
    background-color: var( --app-table-overlay-background-color);
    z-index: 1;
    position: fixed;
    width: 100%;
  }

  .cv-data-table.table-graph-data {
    .bx--data-table-content {

      .td-overflow-ellipsis {
        //overflow-wrap: break-word;
        //word-break: break-all;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        // display: block;
        // border-top: 0;
      }

      .td-usecases {
        text-align: right;;
        padding-right: 0;
      }

      .td-usecases-padding {
        padding-right: 1rem; // only add if this is the last column in the table
      }

      .td-labels {
        padding-right: 4rem;
      }

      // won't work with sticky header
      // .width-min-content, .td-classname {
      //   inline-size: min-content;
      // }

      thead {
        .table-usecases-header-padding {
          padding-right: 1rem; // only add if this is the last column in the table
        }

        .table-labels-header {
          padding-right: 4rem;

          .bx--table-header-label {

            button {
              padding-left: 0.5rem;

              @media (max-width: 41.98rem) {
                & {
                  padding-right: 0; // remove the right padding for the manage button to fix the cutoff outline in chrome
                }
              }

              &:hover, :focus {
                background-color: transparent;
                border: none;
              }
            }
          }
        }
      }

      tbody {        
        tr {
          cursor: pointer;
        }

        td {
          height: 3rem;
        }

        .table-row-selected {
          //border-top-color: var(--app-table-row-selected-top-border-color);
          border-left: solid 2px var(--app-table-row-selected-left-border-color);

          td {
            background-color: var(--app-table-row-selected-background-color);
            border-top-color: var(--app-table-row-selected-top-border-color);
            color: var(--app-table-row-selected-text-color);
          }
        }

        .table-row-before-single-selected td {
          border-bottom: 0;
        }

        .unobserved-tag .utility-tag{
          background-color: transparent;
          border: 1px solid var(--app-table-unobserved-label-tag-border-color);
        }
      }
    }
  }
}

.cv-data-table {
  position: sticky;
}
</style>

<style lang="scss">
.table-unsorted-icon-show {
  display: block !important;
}

.table-view-content .table-graph-data {
  .bx--table-toolbar {
    display: none;
    position: sticky;
    top: 0;
    z-index: 2;

    .bx--action-list {
      position: absolute;
      right: 0;
    }
    .batch-action-toolbar-button-selected {
      background: var(--app-table-batch-action-button-selected-background-color);
      outline: none;
    }
  }

  .bx--data-table-content {
      height: 100%;
  }

  .bx--data-table {
    // overflow-y: scroll;
    // overflow-x: hidden;
    table-layout: fixed;

    thead {
      position: sticky;
      z-index: 1;
      top: 0;
      width: 100%;
      will-change: transform;


      tr {
        th {
          .bx--table-header-label {
            display: block;
            overflow-x: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding-top: 0.9375rem;
            padding-bottom: 1rem;
            overflow-y: hidden;
            height: 3rem;
          }

          // &.table-partition-header .bx--table-header-label, &.table-usecases-header .bx--table-header-label, &.table-labels-header .bx--table-header-label {
          // .bx--table-sort span.bx--table-header-label, &.table-labels-header .bx--table-header-label {
          .bx--table-header-label {
            display: flex;
            align-items: center;
          }
           .manage-label-link-in-header {
              margin-left: 0.5rem;
              padding-right: 0.5rem;
              color: #0738e9;// was #0f62fe; but does not meet contrast of 4.5. (only 4.11)
              font-size: 0.875rem;
              font-weight: 400;
              cursor: pointer;
              min-height: unset;
              height: 2rem;
          }
          .manage-label-link-in-header:hover {
            color: #0043CE;
            background: #cacaca !important;
          }
        }
      
        // use cases right aligned with numeric value
        .table-usecases-header .bx--table-sort {
          justify-content: flex-end;

          .bx--table-sort__icon-unsorted {
            display: none;
          }
        }

        th.bx--table-column-checkbox {
          width: 2.25rem;
          min-width: 2.25rem;
          // align-items: center;
        }
      }
    }

    tbody {
      &.overflow-table-body tr:last-of-type {
        td {
          border-bottom: 0;
        }
      }  

      td.bx--table-column-checkbox {
        width: 2.25rem;
        min-width: 2.25rem;
        // align-items: center;
        // border-top: 0;
      }

      td.td-labels .bx--tag__label {
        vertical-align: middle;
      }

      td:not(.bx--table-column-menu):not(.bx--table-column-checkbox):not(.td-overflow-ellipsis) {
        padding-top: 0;
        align-items: center;
        // border-top: 0;
      }

      .table-row-selected {
        td.bx--table-column-checkbox {
          background-color: var(--app-table-row-selected-background-color);
          // border-top-color: var(--app-table-row-selected-top-border-color);
          color: var(--app-table-row-selected-text-color);
        }
      }

      .table-row-before-single-selected td.bx--table-column-checkbox {
        border-bottom: 0;
      }

      .overflow-label-tooltip .bx--assistive-text {
        padding-top: 1rem;
        padding-bottom: 1rem;
        display: block;

        div {
          padding-bottom: 0.25rem;
        }

        div:last-of-type {
          padding-bottom: 0;
        }
      }
    }
  }

  .bx--pagination {
    position: sticky;
    bottom: 0;
    border-top: 1px solid var(--app-table-pagination-top-border-color);
    height: 3rem;

    // override the hiding of the pagination from carbon
    @media (max-width: 41.98rem) {
      .bx--pagination__left > *, .bx--pagination__right > * {
        display: flex;
        overflow-x: scroll;
      }
    }
  }
}

.table-customization-modal .bx--modal-container--sm  .bx--modal-content {
    padding-right: 1rem;
}
</style>
