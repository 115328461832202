<!--
# *****************************************************************
#
# Licensed Materials - Property of IBM
#
# (C) Copyright IBM Corp. 2020, 2021, 2022, 2023. All Rights Reserved.
#
# US Government Users Restricted Rights - Use, duplication or
# disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
#
# *****************************************************************
-->
<template>
  <div>
    <div class="legend-icon-container">
    <cv-icon-button
              kind="ghost"
              :icon="legend_icon"
              :label="$t('legend-button-help-label')"
              tip-position="bottom"
              tip-alignment="center"
              @click="openLegend"
            />
        <!--svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 32 32">
            <defs></defs><title>legend</title><rect x="16" y="22" width="14" height="2"/>
            <rect x="4" y="20" width="6" height="6" rx="1"/><rect x="16" y="8" width="14" height="2"/>
            <path d="M9.5,12h-5a.5.5,0,0,1-.4473-.7236l2.5-5.0224a.5206.5206,0,0,1,.8945,0l2.5,5.0225A.5.5,0,0,1,9.5,12Z"/>
            <rect style="fill:none;" width="32" height="32"/>
        </svg-->
    </div>
    <div class="legend-pane-container" :class="legendPaneClass" v-show="showPanel">
      <div>
        <!--cv-button class="close-panel-button" kind="ghost" :icon="close_icon" @click="closeLegend" /-->
        <cv-button aria-label="Close" class="close-panel-button edit-partition-mode-btn edit-partition-mode-cancel-btn" kind="ghost" @click="closeLegend">
          <svg focusable="false" preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg" fill="currentColor" width="20" height="20" viewBox="0 0 32 32" aria-hidden="true" style="position: static;"><path d="M24 9.4L22.6 8 16 14.6 9.4 8 8 9.4 14.6 16 8 22.6 9.4 24 16 17.4 22.6 24 24 22.6 17.4 16 24 9.4z"></path></svg>
        </cv-button>
      </div>
      <div class="header">
        <span>{{$t("legend-pane-graph")}}</span>
        <h1>{{$t("legend-pane-legend")}}</h1>
      </div>
      <div class="section">
        <h2>{{$t("legend-pane-partitions")}}</h2>
        <h3>{{$t("legend-pane-partitions-description")}}</h3>
        <div class="partition-diagram">
            <svg width="50px" height="54px" viewBox="0 0 50 54" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <title>{{$t("legend-pane-title-collapsed-icon")}}</title>
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g transform="translate(-967.000000, -524.000000)">
                        <g  transform="translate(968.000000, 525.000000)">
                            <rect stroke="#02274A" x="0" y="0" width="48" height="48"></rect>
                            <text  font-family="IBMPlexSans, IBM Plex Sans" font-size="20" font-weight="normal" line-spacing="28" fill="#02274A">
                                <tspan x="17" y="31">#</tspan>
                            </text>
                            <line x1="15" y1="52" x2="31" y2="52"  stroke="#000000" stroke-dasharray="1"></line>
                        </g>
                    </g>
                </g>
            </svg>
            <span class="right-m16">{{$t('legend-pane-text-collapsed')}}</span>
            <svg width="50px" height="54px" viewBox="0 0 50 54" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <title>{{$t("legend-pane-title-expanded-icon")}}</title>
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g transform="translate(-1104.000000, -524.000000)">
                        <g  transform="translate(1105.000000, 525.000000)">
                            <rect stroke="#02274A" x="0" y="0" width="48" height="48"></rect>
                            <line x1="16" y1="52" x2="32" y2="52"  stroke="#000000" stroke-dasharray="1"></line>
                            <circle  fill="#02274A" cx="7" cy="9" r="3"></circle>
                            <circle  fill="#02274A" cx="14" cy="25" r="3"></circle>
                            <circle  fill="#02274A" cx="37" cy="39" r="3"></circle>
                            <circle  fill="#02274A" cx="21" cy="14" r="3"></circle>
                            <line x1="7" y1="9" x2="21" y2="14"  stroke="#000000"></line>
                            <line x1="7" y1="9" x2="37" y2="39"  stroke="#000000"></line>
                        </g>
                    </g>
                </g>
            </svg>
            <span>{{$t('legend-pane-text-expanded')}}</span>
        </div>
      </div>
      <div class="section">
        <h2>{{$t("legend-pane-classes")}}</h2>
        <h3>{{$t("legend-pane-classes-description")}}</h3>
        <div class="partition-diagram">
            <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <title>{{$t('legend-pane-title-normal-class-icon')}}</title>
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g transform="translate(-968.000000, -684.000000)" fill="#02274A">
                        <circle id="NormalClass_icon" cx="978" cy="694" r="10"></circle>
                    </g>
                </g>
            </svg>
            <span class="right-m16">{{$t('legend-pane-text-normal')}}</span>
            <svg width="22px" height="22px" viewBox="0 0 22 22" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <title>{{$t('legend-pane-title-unobserved-class-icon')}}</title>
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g transform="translate(-1061.000000, -683.000000)" stroke="#02274A">
                        <circle id="UnobservedClass_icon" cx="1072" cy="694" r="10"></circle>
                    </g>
                </g>
            </svg>
            <!-- <span>{{$t('legend-pane-text-unobserved')}}</span> -->
            <span class="right-m16">{{$t('legend-pane-text-unobserved')}}</span>
            <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="transform: rotate(45deg)">
                <title>{{$t('legend-pane-title-utility-class-icon')}}</title>
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g transform="translate(-968.000000, -684.000000)" fill="#02274A">
                      <rect x="971" y="687" width="14" height="14"></rect>
                    </g>
                </g>
            </svg>
            <span>{{$t('table-utility-label-tag')}}</span>
        </div>
      </div>
      <div class="section">
        <h2>{{$t("legend-pane-runtime-calls")}}</h2>
        <h3>{{$t("legend-pane-runtime-calls-description")}}</h3>
        <div class="partition-diagram">
          <svg width="140px" height="20px" viewBox="0 0 140 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <title>{{$t('legend-pane-title-runtime-calls-icon')}}</title>
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g transform="translate(-968.000000, -792.000000)">
                    <g id="RuntimeCalls_icon" transform="translate(968.000000, 792.000000)">
                        <circle  fill="#DDE1E6" cx="130" cy="10" r="10"></circle>
                        <path  d="M111.5,5.5 L120.5,10 L111.5,14.5 L111.5,10.5 L20,10.5 L20,9.5 L111.5,9.5 L111.5,5.5 Z" fill="#000000" fill-rule="nonzero"></path>
                        <circle  fill="#DDE1E6" cx="10" cy="10" r="10"></circle>
                    </g>
                </g>
            </g>
          </svg>
        </div>
      </div>
      
      <div class="section">
        <h2>{{$t("legend-pane-containment-relationships")}}</h2>
        <h3>{{$t("legend-pane-containment-relationships-descriptipon")}}</h3>
        <div class="partition-diagram bottom-m24px">
          <svg width="140px" height="20px" viewBox="0 0 140 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <title>{{$t('legend-pane-title-containment-relationship-icon')}}</title>
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g transform="translate(-968.000000, -918.000000)">
                    <g id="ContainmentRelationships_icon" transform="translate(968.000000, 918.000000)">
                        <circle  fill="#DDE1E6" cx="130" cy="10" r="10"></circle>
                        <path  d="M111.5,5.5 L120.5,10 L111.5,14.5 L111.5,10.5 L108,10.5 L108,9.5 L111.5,9.5 L111.5,5.5 Z M24,9.5 L24,10.5 L20,10.5 L20,9.5 L24,9.5 Z M32,9.5 L32,10.5 L28,10.5 L28,9.5 L32,9.5 Z M40,9.5 L40,10.5 L36,10.5 L36,9.5 L40,9.5 Z M48,9.5 L48,10.5 L44,10.5 L44,9.5 L48,9.5 Z M56,9.5 L56,10.5 L52,10.5 L52,9.5 L56,9.5 Z M64,9.5 L64,10.5 L60,10.5 L60,9.5 L64,9.5 Z M72,9.5 L72,10.5 L68,10.5 L68,9.5 L72,9.5 Z M80,9.5 L80,10.5 L76,10.5 L76,9.5 L80,9.5 Z M88,9.5 L88,10.5 L84,10.5 L84,9.5 L88,9.5 Z M96,9.5 L96,10.5 L92,10.5 L92,9.5 L96,9.5 Z M104,9.5 L104,10.5 L100,10.5 L100,9.5 L104,9.5 Z" fill="#000000" fill-rule="nonzero"></path>
                        <circle  fill="#DDE1E6" cx="10" cy="10" r="10"></circle>
                    </g>
                </g>
            </g>
          </svg>
        </div>
      </div>
      <div class="footer section">
        <div></div>
        <div class="partition-diagram force-margin-16">
          <a href="https://www.ibm.com/docs/SS7H9Y/doc/m2m_rec_interacting_graph.html" target="_blank">
            <svg id="icon" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 32 32">
                <path fill="#0f62fe" d="M26,28H6a2.0027,2.0027,0,0,1-2-2V6A2.0027,2.0027,0,0,1,6,4H16V6H6V26H26V16h2V26A2.0027,2.0027,0,0,1,26,28Z"/>
                <polygon fill="#0f62fe" points="20 2 20 4 26.586 4 18 12.586 19.414 14 28 5.414 28 12 30 12 30 2 20 2"/>
                <rect fill="#0f62fe" data-name="&lt;Transparent Rectangle&gt;" style="fill: none;" width="32" height="32"/>
            </svg>
            <span>{{$t("legend-pane-learn-more")}}</span>
           </a>
        </div>
        <div></div>
      </div>
    </div>
  </div>
</template>

<script>
import { eventBus } from "../../main.js";
import close_icon from "@carbon/icons-vue/es/close/16";
import legend_icon from "@carbon/icons-vue/es/legend/16";
  export default {
    name: "GraphLegend",
    //components: { CvSlider },
    data: () => ({
        legendPaneClass: "legend-pane-container-height-compact",
        showPanel: false,
        close_icon,
        legend_icon
    }),
    methods: {
        closeLegend() {
            this.showPanel = false;
            eventBus.$emit("Graph.legendAction", {isLegendOpen: this.showPanel});
        },
        openLegend() {
            this.showPanel = true;
            eventBus.$emit("Graph.legendAction", {isLegendOpen: this.showPanel});
        },
        setLegendViewContentHeight(params) {
            if ( !params.status) {
                this.legendPaneClass = "legend-pane-container-height-full";
            } else 
            {
                this.legendPaneClass = "legend-pane-container-height-compact";
            }
        },
    },
    created() {
      eventBus.$on("GraphLegend.setLegendViewContentHeight", (params) => {
        this.$nextTick(() => {
            this.setLegendViewContentHeight(params);
        });

      });
    },
    mounted() {
    },
    beforeDestroy() {
    },
  };
</script>

<style lang="scss">

.legend-icon-container .bx--btn--ghost.bx--btn--icon-only .bx--btn__icon {
  fill: unset;
}

.legend-icon-container:hover .bx--btn--ghost.bx--btn--icon-only .bx--btn__icon {
  fill: unset;
}

.legend-panel-open {
  .legend-icon-container {
    right: calc(15rem + 31.5rem);
  }
  .legend-pane-container {
      right: 31rem;
  }
}
.legend-panel-closed {
  .legend-icon-container {
    right: 16.5rem;
  }
  .legend-pane-container {
      right: 1rem;
  }
}
  .legend-pane-container {
      .close-panel-button {
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
        float: right;
        width: 2rem;
        height: 2rem;
        margin-top: 0.5rem;
        margin-right: 0.5rem;
        background-color: transparent;
        padding: 0.5rem;
        min-height: 1rem;
        svg {
          fill: #000000;
        }
      }
      .close-panel-button:hover {
        background-color: var(--app-toolbar-selected-action-button-background-color);
      }
      .footer {
          border-top: 0.0625rem solid var(--app-panel-divider-color);
          span {
              @include carbon--type-style("helper-text-01");
              margin-left: 1rem;
              marign-right: 1rem;
          }
          div {
              height: 1rem;
              max-height: 1rem;
              min-height: 1rem;
              margin-left: 1rem;
              marign-right: 1rem;
          }
      }
      .header {
          border-bottom: 0.0625rem solid var(--app-panel-divider-color);
          span {
            @include carbon--type-style("label-01");
            margin-top: 1rem;
            margin-left: 1rem;
            word-break: break-word;
            display: inline-flex;
          }
      }
      h1 {
        @include carbon--type-style("productive-heading-03");
        padding-left: 1rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 1.25rem;
      }
      .section {
          h2 {
                @include carbon--type-style("productive-heading-02");
                margin-bottom: 0.5rem;
                margin-left: 1.5rem;
                margin-top: 1.5rem;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            h3 {
                @include carbon--type-style("helper-text-01");
                margin-bottom: 1rem;
                margin-left: 1.5rem;
                margin-right: 1.5rem;
            }
            .partition-diagram {
                @include carbon--type-style("helper-text-01");
                margin-left: 1.5rem;
                margin-right: 1rem;
                display: flex;
                align-items: center;
                span {
                    margin-left: 0.5rem;
                }
                .right-m16 {
                    margin-right: 1rem;
                }
                a {
                    display: flex;
                }
                a:link { text-decoration: none; }
                a:visited { text-decoration: none; }
                a:hover { text-decoration: none; }
                a:active { text-decoration: none; }
            }
            .bottom-m24px {
                margin-bottom: 1.5rem;
            }
            .force-margin-16 {
              margin-left: 1rem !important;
            }
      }
  
}

#legend {
  .legend-icon-container {
    position: fixed;
    background: var(--app-minimap-background-color);
    z-index: 10;
    width: 2rem;
    height: 2rem;
    bottom: 8.5rem;
    overflow: visible;
    /* backdrop-filter: blur(8px); */
    box-shadow: 0 0.125rem 0.375rem 0 var(--app-panel-box-shadow-color);
    button {
      width: 2rem;
      height: 2rem;
      padding: 0;
      margin: 0;
      padding-left: 0.375rem;
      min-height: 2rem;
      opacity: 0.5;
    }
     button:hover {
      opacity: 1;
      background: #C6C6C6;
    }
  }

  .legend-icon-container:hover {
    opacity: 1;
  }

  .legend-pane-container {
    position: fixed;
    width: 30rem;
    max-width: 30rem;
    
    
    background: var(--app-minimap-background-color);
    z-index: 10;
    bottom: 1rem;
    overflow: auto;
    /* backdrop-filter: blur(8px); */
    box-shadow: 0 0.125rem 0.375rem 0 var(--app-panel-box-shadow-color);
  }
  .legend-pane-container-height-full {
    max-height: calc(100% - 7rem);
  }
  .legend-pane-container-height-compact {
    max-height: calc(100% - 14.5625rem - 1rem);
  }
}

</style>