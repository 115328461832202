<!--
# *****************************************************************
#
# Licensed Materials - Property of IBM
#
# (C) Copyright IBM Corp. 2019, 2020, 2021. All Rights Reserved.
#
# US Government Users Restricted Rights - Use, duplication or
# disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
#
# *****************************************************************
-->
<template>
  <div>
    <top-nav></top-nav>
    <div id="main-content" class="main-graph" role="application" aria-label="graph">
      <cv-loading id="app-busy-icon" :active="isLoading" overlay></cv-loading>
      <graph-summary-top-panel></graph-summary-top-panel>
      <keep-alive>
        <graph></graph>
      </keep-alive>
      <side-panel></side-panel>
    </div>
  </div>
</template>

<script>
import { eventBus } from "../../main.js";
import legendIcon from "@carbon/icons-vue/es/data--1/32";
import TopNav from "../../components/TopNav";
import { CvLoading } from "@carbon/vue";
import Graph from "../../components/Graph";
import GraphSummaryTopPanel from "../../components/GraphSummaryTopPanel";
import SidePanel from '../../components/SidePanel';

export default {
  name: "GraphPage",
  components: {
    TopNav,
    CvLoading,
    Graph,
    GraphSummaryTopPanel,
    SidePanel
  },
  data: () => ({
    moveable: {
      draggable: true,
    },
    isLegendHidden: true,
    legIcon: legendIcon,
    publicPath: process.env.BASE_URL,
    isLoading: false,
  }),
  methods: {
    handleDrag({ target, transform }) {
      target.style.transform = transform;
    },
  },
  created() {
    // on refresh, the filename will be gone, redirect to /
    if (!this.$store.getters.getFilename) {
      window.location = '/'
    }
    Object.assign(this, {
      legendIcon,
    });
    // Enable confirmation when user leaves.
    window.onbeforeunload = function(){
      return "You may lose any unsaved changes.";
    };
  },
  mounted() {
    // add WalkMe to the page head
    let walkmeScript = document.createElement('script');
    walkmeScript.src = this.publicPath + "walkme/fa3be8766d5a4f3e98411fd7de1144ed/walkme_fa3be8766d5a4f3e98411fd7de1144ed_https.js";
    walkmeScript.async = false;
    walkmeScript.type = "text/javascript";
    document.head.appendChild(walkmeScript);

    // check the browser width to determin overflow css. Page header and table have
    // a min-width of 672px. If browser width is smaller than that, enable horizontal
    // scrolling.
    let bodyEl = document.getElementsByTagName("body")[0]
    if (window.innerWidth <= 672) {
      bodyEl.style.overflowY = 'hidden';
      bodyEl.style.overflowX = 'scroll';
    } else {
      bodyEl.style.overflow = 'hidden';
    }
  },
  // When the user clicks on the back button on the graph page,
  // emit an event to show the exit dialog.
  beforeRouteLeave(to, from, next) {
    if (to.path == "/" && from.path == "/graph") {
      history.pushState(null, '', '/graph');
      eventBus.$emit("TopNav.showExitDialog", next)
    }
  },
  // When leaving the page, disable confirmation dialog.
  beforeDestroy() {
    window.onbeforeunload = null;
    // reset overflow and remove bx--body--with-modal-open css class when leaving the graph page
    // Note: when exiting the graph page with the exit confirm dialog, bx--body--with-modal-open class is still 
    //       there after going back to the home page. To work around this problem, remove the css here.
    let bodyEl = document.getElementsByTagName("body")[0];
    bodyEl.style.overflow = '';
    bodyEl.classList.remove('bx--body--with-modal-open');
  },
};
</script>

<style lang="scss" scoped>
.main-graph {
  /* background-color: #262626; */
  height: 100vh;
  min-width: 41.99rem;
}

.moveable-line {
  width: 0px;
  height: 0px;
  transform-origin: 0px 0.5px;
  float: right;
  margin-top: 50px;
  margin-right: 90px;
}

/* .menu-btn {
  background-color: #ffffff;
  color: black;
} */

.mono-graph {
  margin-top: 60px;
  width: 100%;
  height: calc(100% - 60px);
}

.graph {
  margin-left: 300px;
}

.legend {
  /* color: black; */
  background-color: transparent;
}
</style>
