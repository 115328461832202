/********************************************************************
# Licensed Materials - Property of IBM
#
# (C) Copyright IBM Corp. 2019, 2020, 2021, 2022, 2023. All Rights Reserved.
#
# US Government Users Restricted Rights - Use, duplication or
# disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
**********************************************************************/

const constants = {
  micro_detail_partition_by_business_logic: "micro_detail_partition_by_business_logic",
  micro_detail_partition_by_natural_seam: "micro_detail_partition_by_natural_seam",
  custom_view: "custom_view",
  animationDuration: 350,
  defaultAjaxCallTimeout: 10000,
  extendedAjaxCallTimeout: 60000,
  newPartitionNamePrefix: "CustomPartition",
  unobservedKeys: ["unknown", "unobserved"],
  runtimeKey: "mono_run_time_call_graph",
  dependendyKey: "mono_data_dependency_graph",
  keepUnobserved: true,
  unobservedColor: "#dddddd",
  demoFile: "final_graph.json",
  demoFileFolder: "demo",
  emptyString: "",
  clusterIdentifier: "Cluster",
  nodeId: "nodes",
  unobservedId: "unobserved",
  linkId: "links",
  overviewId: "overview",
  originalPartitionId: "originalCategory",

  // visibility status
  dataNodeIsNotFound: 0,
  dataNodeIsShown: 1,
  dataNodeIsCollapsed: 2,
  dataNodeIsFilteredOut: 3,
  dataNodeIsFilteredOutAndCollapsed: 4,
  dataNodeIsOutOfViewPort: 5,
  dataNodeIsOutOfViewPortAndCollapsed: 6,
  dataNodeIsOutOfViewPortAndFilteredOut: 7,
  dataNodeIsOutOfViewPortAndFilteredOutAndCollapsed: 8,
  // end of visibility status

  // validate partition name
  partitionNameIsOk: 0,
  partitionNameIsDuplicated: 1,
  partitionNameIsInvalid: 2,
  partitionNameBadFormat: 3,
  partitionNameUnobserved: 4,

  // styles
  defaultPartitionSize: 100,

  m2mHighlightedClass: "m2m-highlighted",
  m2mFadeOutClass: "m2m-fadeout",
  m2mPartitionFadeOutClass: "m2m-partition-fadeout",
  m2mHoverClass: "m2m-hovered",
  m2mSearchedClass: "m2m-searched",
  m2mHighlightedFocusedClass: "m2m-highlighted-focused",
  m2mHoveredFocusedClass: "m2m-hovered-focused",

  m2mFilteredCollapsedNodeClass: "cy-expand-collapse-collapsed-node-greyout",
  m2mFilteredClass: "greyout",  // partition

  // filter types
  filterTypePartition: "greyout",
  filterTypeUseCase: "filter-usecase",  // use case filter
  filterTypeRuntimeCall: "filter-runtime-call",  // runtime call filter
  filterTypeLabel: "filter-label",  // label filter
  filterTypeShowEdge: "filter-show-edge",  // show edge filter

  // in memory scratch attributes
  m2mScratchFilters: "filters",
  m2mScratchRuntimeCallRange: "runtime_call_range",
  m2mScratchFunctionCallDelay: "function_call_delay",
  m2mScratchTotalElements: "total_elements",

  fontFamily: "IBM Plex Sans, Helvetica, sans-serif",

  tooltipsDelayTime: 250,
  minElementNumberForBusyIcon: 2500,
  minFunctionCallDelay: 20,
  minFunctionCallTimeout: 250,
  partitionCornerOffset: 35,
  unobservedLabelFilterValue: "Unobserved",
  utilityLabelFilterValue: "Utility",

  // edge grouping type
  edgeGroupDependency: "edge_dep",
  edgeGroupUnknown: "unknown",

  // node tags
  utilityTag: "utility",   // utility tag in JSON

  // show edge filter values
  showRuntimeCalls: "1",
  showUtilityCalls: "2",
  showDataDependencies: "3",

};

export default constants;
