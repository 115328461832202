<!--
# *****************************************************************
#
# Licensed Materials - Property of IBM
#
# (C) Copyright IBM Corp. 2020, 2021, 2022, 2023. All Rights Reserved.
#
# US Government Users Restricted Rights - Use, duplication or
# disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
#
# *****************************************************************
-->
<template>
  <div>
    <cv-search
      ref="search"
      :label="search.label"
      :ariaLabel="search.label"
      :placeholder="search.placeholder"
      :clear-aria-label="search.clearAriaLabel"
      @input="onInput"
      @focus="onFocus"
      @keydown.down.prevent="onDown()"
      @keydown.tab="setShowClasses(false)"
      @blur="onClassBlur"
      v-model="searchFilter"
      aria-haspopup="listbox"
      v-bind:class="[isSelected ? 'class-selected' : '']"
    ></cv-search>
    <ul
      v-show="showClasses"
      :aria-label="$t('search-result')"
      role="listbox"
      z-index="1000"
      @focusout="onClassBlur"
      class="search-dropdown-list"
    >
      <li
        role="option"
        tabindex="-1"
        v-for="(aclass, index) in classList"
        :key=index
        @mousedown="onClassMousedown"
        @click="setSelectedItem(aclass)"
        @keydown.down.prevent="onDown()"
        @keydown.up.prevent="onUp"
        @keydown.esc.prevent="onEsc"
        @keydown.enter.prevent="setSelectedItem(aclass)"
        @keydown.space.prevent="setSelectedItem(aclass)"
        v-bind:class="{'search-dropdown-item': true, 'search-history-title': (index === 0) && (! aclass.partitionColor)}"
        ref="items"
        :title="aclass.dropDownTitle"
      >
        <!--
        <svg class="search-history-icon" v-if="(! aclass.partitionColor) && (index !== 0)" width="16px" height="16px" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <title>{{ $t("search-recent-search") }}</title>
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g transform="translate(-849.000000, -288.000000)" fill="#8D8D8D">
                    <g transform="translate(848.000000, 287.000000)">
                        <path d="M10,18.75 C5.16750844,18.75 1.25,14.8324916 1.25,10 C1.25,5.16750844 5.16750844,1.25 10,1.25 C14.8324916,1.25 18.75,5.16750844 18.75,10 C18.75,12.3206443 17.8281276,14.546241 16.1871843,16.1871843 C14.546241,17.8281276 12.3206443,18.75 10,18.75 Z M10,2.5 C5.85786438,2.5 2.5,5.85786438 2.5,10 C2.5,14.1421356 5.85786438,17.5 10,17.5 C14.1421356,17.5 17.5,14.1421356 17.5,10 C17.5,5.85786438 14.1421356,2.5 10,2.5 Z M12.86875,13.75 L9.375,10.25625 L9.375,4.375 L10.625,4.375 L10.625,9.7375 L13.75,12.86875 L12.86875,13.75 L12.86875,13.75 Z" id="Fill"></path>
                    </g>
                </g>
            </g>
        </svg>
        -->
        <svg class="search-history-icon" v-if="(! aclass.partitionColor) && (index !== 0)" width="42px" height="20px" viewBox="0 0 42 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <title>{{ $t("search-recent-search") }}</title>
          <g id="HistoryIcon_addedMargins" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Planning-/-Time-/-time-/-16" transform="translate(6.000000, 0.000000)" fill="#8D8D8D">
              <path d="M10,18.75 C5.16750844,18.75 1.25,14.8324916 1.25,10 C1.25,5.16750844 5.16750844,1.25 10,1.25 C14.8324916,1.25 18.75,5.16750844 18.75,10 C18.75,12.3206443 17.8281276,14.546241 16.1871843,16.1871843 C14.546241,17.8281276 12.3206443,18.75 10,18.75 Z M10,2.5 C5.85786438,2.5 2.5,5.85786438 2.5,10 C2.5,14.1421356 5.85786438,17.5 10,17.5 C14.1421356,17.5 17.5,14.1421356 17.5,10 C17.5,5.85786438 14.1421356,2.5 10,2.5 Z M12.86875,13.75 L9.375,10.25625 L9.375,4.375 L10.625,4.375 L10.625,9.7375 L13.75,12.86875 L12.86875,13.75 L12.86875,13.75 Z" id="Fill"></path>
            </g>
            <rect id="Rectangle" x="26" y="0" width="16" height="20"></rect>
            <rect id="Rectangle" x="0" y="0" width="6" height="20"></rect>
          </g>
        </svg>

        <svg v-if="aclass.partitionColor && search.graphView"
          class="search-dropdown-item-class-color"
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 32 32"
          :style="aclass.partitionColor"
        >
          <title>{{ $t("partition-color") }}</title>
          <circle cx="16" cy="16" r="10" />
          <rect
            data-name="&lt;Transparent Rectangle&gt;"
            style="fill: none;"
            width="16"
            height="16"
          />
        </svg>
        <span v-html="highlight(aclass.displayName)"></span>
      </li>
    </ul>
  </div>
</template>

<script>
import apis from "../../lib/graph/graphAPIHandler";
import { eventBus } from "../../main.js";
import utils from "@/lib/graph/graphUtil";
import constants from "@/lib/constants";

let globalTypingTimeout;

export default {
  name: "SearchWithFilter",
  components: {},
  props: ["search"],
  data() {
    return {
      showClasses: false,
      activeIndex: -1,
      searchFilter: "",
      classList: [],
      selectedClass: {},
      enterDropdown: false,
      classSelected: "",
      isSelected: false,
      clusterColorMap: [],
      utilityPartitions: null, 
    };
  },
  methods: {
    highlight(classDisplayName) {
      if (!this.searchFilter) {
        return classDisplayName;
      }
      return classDisplayName.replace(
        new RegExp(this.searchFilter, "gi"),
        (match) => {
          return "<span class='search-highlight-text'>" + match + "</span>";
        }
      );
    },
    setAriaExpanded(isExpanded) {
      document
        .getElementsByClassName(this.search.class)[0]
        .getElementsByClassName("search-dropdown-list")[0]
        .setAttribute("aria-expanded", isExpanded);
    },
    setShowClasses(show, inputFocus) {
      this.showClasses = show;
      this.activeIndex = -1;

      if (inputFocus) {
        // put focus back to search input field
        this.$refs.search.$refs.input.focus();
      }
    },
    onInput() {
      if (this.isSelected) { this.isSelected = false;}

      // pass filter value to table even when not in table view
      eventBus.$emit("TableView.search", this.searchFilter);

      if (this.searchFilter.length > 0) {
        if (this.search.graphView) {
          if (globalTypingTimeout !== null) {
            clearTimeout(globalTypingTimeout);
          }
          const me = this;
          globalTypingTimeout = setTimeout(function() {
            globalTypingTimeout = null;
            var allNodes = apis
              .getClassBySearchFilter(me.searchFilter)
              .slice(0, 250);
            me.classList = [];
            allNodes.forEach((element) => {
              var node = {};
              node.name = element.name;
              node.category = element.category;
              node.dropDownTitle = node.name + " (" + node.category + ")";
              node.displayName = node.dropDownTitle
                .replace("<", "&lt")
                .replace(">", "&gt");
              // if (node.category != "Unobserved") {
                node.partitionColor = "fill: " + me.getNodeColor(element);  // me.clusterColorMap[node.category];
              // } else {
              //   node.partitionColor = "fill: var(--app-graph-default-node-color)";
              // }
              me.classList.push(node);
            });

            if (me.classList.length > 0) {
              me.setShowClasses(true);
              me.setAriaExpanded(true);
            } else {
              me.setShowClasses(false);
              me.setAriaExpanded(false);
            }
          }, 1);
        } else {
          // hide the search history if user provides input instead of clicking on one of the search history items
          this.setShowClasses(false);
          this.setAriaExpanded(false);
        }
      } else  {
        const history = this.$store.getters.getSearchItems;
        if ( history.length == 0) {
          this.setShowClasses(false);
          this.setAriaExpanded(false);
        } else {
          const me = this;
          me.classList=[];
          // add recent searches title
          let node = {};
          node.displayName = this.$t("search-recent-history");
          node.dropDownTitle = this.$t("search-recent-history");
          me.classList.push(node);

          // limit to 4 most recent history only
          for (let i = 0; i < history.length && i < 4; i++) {
              let historyNode = {};
              historyNode.dropDownTitle = history[i];
              historyNode.displayName = history[i];
              me.classList.push(historyNode);
          }
          
          this.setShowClasses(true);
          this.setAriaExpanded(true);

          // put focus back to search input field, otherwise drop down won't go away even clicking elsewhere if clear search is clicked
          // causing the history drop down to be displayed
          this.$refs.search.$refs.input.focus();
        }
      }
    },
    onFocus() {
      if (this.isSelected) { this.isSelected = false;}
      document.querySelector(".class-search .cv-search").style.width = "100%";

      // this.$refs.search.$refs.input.classList.add("add-input-outline");
      // this.$refs.search.$refs.input.classList.remove("remove-input-outline");

      // Since latest carbon vue won't allow changing a prop passed in, use a local data instead.
      this.clusterColorMap = apis.getCurrentViewsClusterColorMap();
      this.onInput();
    },
    onClassMousedown(event) {
      // this is needed so that clicking on class is honored, otherwise blur event on filter will take over
      event.preventDefault();
    },
    onClassBlur(ev) {
      if (!ev.relatedTarget || !this.$el.contains(ev.relatedTarget)) {
        this.setShowClasses(false);
        // this.$refs.search.$refs.input.classList.remove("add-input-outline");
        document.querySelector(".class-search .cv-search").style.width = "68%";
      }
    },
    setSelectedItem(selectedItem) {
      if (selectedItem.partitionColor) {
        this.$store.commit("addSearchItem", selectedItem.name);
        this.setSelectClass(selectedItem);
      } else {
        this.searchFilter = selectedItem.displayName;
        this.onInput();
        if (!this.search.graphView) {
          // close the dropdown after clicking on one of the search history items
          this.setShowClasses(false);
          this.setAriaExpanded(false);
        }
      }

    },
    setSelectClass(selectedClass) {
      this.searchFilter = selectedClass.name;

      // notify table view the selected class name
      eventBus.$emit("TableView.search", this.searchFilter);

      this.selectedClass = selectedClass;
      this.setShowClasses(false);
      this.setAriaExpanded(false);
      const node = apis.getNodeVisibility(selectedClass.name);
      let options = {
        expandPartition: true,
        unfilterPartition: true,
      };
      if (node.visibility > 4) {
        options.panToCenter = true;
      }
      apis.cleanupAllHighlights();
      if (node.classNode) {
        apis.searchClass(node.classNode, options);        
        // show filter indicator
        eventBus.$emit("Toolbar.updateFilterIndicator");
        // update filter options
        // let partitionNode = utils.getNodeParent(node.classNode);
        // let partitionName = partitionNode ? partitionNode[0]._private.data.name : "";
        // eventBus.$emit("GraphSummaryTopPanel.selectFilterGraph", partitionName);
        eventBus.$emit("FilterPanel.updateFilterSelections", {
          filterTypes: utils.getAllFilterClassesForNodes(), // we don't know what node filters have changed during searching, 
                                                            // notify FilterPanel to update all filter selections
        });
        // update side panel
        eventBus.$emit("SidePanel.showMicroservicePanel", node.classNode);
        // remove focus in the filter
        this.$refs.search.$refs.input.blur();

        this.isSelected = true;

        // notify table to reload data to pick up selected/highlighted node for graph to table view transition
        eventBus.$emit("TableView.resetTable", false);
      } else {
        // open unobserved table
        this.$refs.search.$refs.input.blur();
        this.isSelected = true;
        eventBus.$emit("UnobservedTable.show");
      }
    },
    onDown() {
      if (!this.showClasses) {
        this.setShowClasses(true);
      }
      this.doMove(false);
    },
    onUp() {
      if (this.showClasses) {
        this.doMove(true);
      }
    },
    onEsc() {
      //this.$refs.search.$refs.input.focus();
      this.$nextTick(() => {
        this.setShowClasses(false, true);
      });
    },
    doMove(up) {
      if (up) {
        if (this.activeIndex === 0) {
          // recycle back to the bottom of the list
          this.activeIndex = this.$refs.items.length - 1;
        } else {
          this.activeIndex -= 1;
        }
        this.$refs.items[this.activeIndex].focus();
      } else {
        if (this.activeIndex === this.$refs.items.length - 1) {
          // recycle back to the top of the list
          this.activeIndex = 0;
          this.$refs.items[this.activeIndex].focus();
        } else {
          this.activeIndex += 1;
          setTimeout(
            () => {
              this.$refs.items[this.activeIndex].focus();
            },
            this.activeIndex === 0 ? 100 : 0
          );
        }
      }
    },
    getNodeColor(node) {
      let category = node.category;
      if (node[constants.originalPartitionId]) {
        if (this.utilityPartitions === null) {
          this.utilityPartitions = utils.getAllUtilityPartitionNodes().map(node => node.data('name'));
        }
        if (this.utilityPartitions.includes(category)) {
          category = node[constants.originalPartitionId];
        }
      }
      return this.clusterColorMap[category];
    }
  },
  mounted() {
    eventBus.$on("SearchWithFilter.resetSearch", () => {
      this.searchFilter = "";
    });
  },
  beforeDestroy() {
    eventBus.$off("SearchWithFilter.resetSearch");
  },
};
</script>

<style>
.class-search .bx--search-input,
.class-search .bx--form-item,
.class-search .bx--search  {
  height: 100% !important;
}

.class-search .cv-search {
  flex: unset;
  bottom: 0;
  float: right;
  width: 100% !important;
}

.class-search .bx--search-input {
  border-bottom: unset;
}

/* .cv-search .bx--search-input:focus {
  outline: 2px solid #ffffff;
}

.cv-search .bx--search-input:hover {
  background-color: #4c4c4c;
} */

.cv-search .bx--search-close {
  width: 48px;
  height: 100%;
}

.cv-search .bx--search-close::before {
  width: 0px;
}

.cv-search .bx--search-close:hover {
  background-color: transparent;
}

.cv-search .bx--search-close:active {
  outline: none;
}

.search-dropdown-list {
  margin-top: 3rem;
  min-width: 300px;
  max-height: 14.5rem; /* to show 4 recent searches + the search title */
  overflow-y: auto;
  background-color: var(--app-carbon-dropdown-list-background-color);
  /* color: #f4f4f4; */
  /* box-shadow: 0 10px 15px -3px var(--app-carbon-dropdown-list-box-shadow-color),
    0 4px 6px -2px var(--app-carbon-dropdown-list-box-shadow-color); */
  box-shadow: 0 2px 6px 0 var(--app-panel-box-shadow-color);
  z-index: 4;
  /* filter: drop-shadow(rgba(0, 0, 0, 0.3) 0px 2px 6px); */
  position: absolute;
  width: 100%;
  top: 0;
}

.search-dropdown-item {
  width: 100%;
  padding: 0.875rem 0.625rem;
  color: var(--app-carbon-dropdown-item-02-color);
  border: none;
  font-size: 14px;
  letter-spacing: 0.16px;
  line-height: 18px;
  cursor: pointer;
  z-index: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 3rem;
}

.search-dropdown-item:focus {
  /* outline: 2px solid #ffffff; */
  outline-offset: -2px;
  margin: 0;
  /* padding: 0.6875rem 1rem; */
}

.search-dropdown-item:hover {
  background-color: var(--app-carbon-dropdown-item-hover-color);
}

.search-dropdown-item-class-color {
  max-width: 16px;
  max-height: 16px;
  margin-left: 6px;
  margin-right: 8px;
  vertical-align: bottom;
  /* fill: #696969; */
}

.search-highlight-text {
  color: var(--app-carbon-dropdown-item-01-color);
  font-weight: 600;
}

/* .add-input-outline {
  outline: 2px solid #f4f4f4 !important;
}

.remove-input-outline {
  outline: none !important;
} */

.class-selected .bx--search-input {
  outline: 2px solid #ffffff;
}

.class-search .bx--search--xl svg {
  width: 1.25rem;
  height: 1.25rem;
  position: absolute;
  left: 1rem;
  /* top: 1rem; */
  align-items: center;
}

/* override the default from .bx--toolbar .bx--search-input */
.bx--toolbar .bx--search-input:focus {
  outline: 2px solid var(--app-toolbar-search-input-focus-color);
}

svg.search-history-icon {
  vertical-align: middle;
}

.search-history-title {
  padding-top: 0.8125rem;
  padding-bottom: 0.8125rem;
  height: 2.5rem;
}

.search-history-title > span {
  margin-left: 0.375rem;
  font-size: 12px;
  letter-spacing: 0.32px;
  line-height: 16px;
  font-weight: 400;
}

.search-history-title:hover {
  background-color: transparent;
  cursor: unset;
}

.search-dropdown-item:not(.search-history-title) span {
  font-size: 14px;
  color: #161616;
  letter-spacing: 0.16px;
  line-height: 18px;
  font-weight: 400;
}
</style>
