<!--
# *****************************************************************
#
# Licensed Materials - Property of IBM
#
# (C) Copyright IBM Corp. 2019, 2020, 2021, 2022, 2023. All Rights Reserved.
#
# US Government Users Restricted Rights - Use, duplication or
# disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
#
# *****************************************************************
-->
<template>
  <div class="graph">
    <!-- <cv-loading id="app-busy-icon" :active="isLoading" overlay></cv-loading> -->
    <div id="cy-container">
      <div id="cy" class="cy"></div>
      <graph-navigator
        id="minimap"
        class="m2m-minimap"
        v-bind:class="minimapRightClass"
        v-show="showNavigator"
        @mouseover.native="minimapHover = true"
        @mouseleave.native="minimapHover = false"
      ></graph-navigator>
      <graph-legend id="legend"
        v-bind:class="legendRightClass"
        v-show="showNavigator"
        @mouseover.native="legendHover = true"
        @mouseleave.native="legendHover = false"
        ></graph-legend>
    </div>
    <cv-modal
      :visible="showUnobservedNewPartitionDialog"
      id="unobserved-new-partition-dialog"
      @secondary-click="onUnobservedNewPartitionDialogCancel"
      @primary-click="onUnobservedNewPartitionDialogConfirm"
      :close-aria-label="$t('close-button-aria')"
      :auto-hide-off="false"
      size="small"
      >
      <template slot="title">{{ $t("unobserved-create-new-partition-dialog-title")}}</template>
      <template slot="content">
        <p>{{ $t("unobserved-create-new-partition-dialog-content")}}</p>
      </template>
      <template slot="secondary-button">{{ $t("unobserved-create-new-partition-dialog-button-cancel") }}</template>
      <template slot="primary-button">{{ $t("unobserved-create-new-partition-dialog-button-yes") }}</template>
    </cv-modal>
  </div>

</template>
<script>
import constants from "../../lib/constants";
import utils from "../../lib/graph/graphUtil";
import apis from "../../lib/graph/graphAPIHandler";
import stats from "../../lib/graph/stats.js";

import { eventBus } from "../../main.js";
// import { CvLoading } from "@carbon/vue";
import graphNavigator from "./Navigator"
import graphLegend from "./Legend"

// const _edgeColor = "#6F6F6F";
// const _heighLightedEdgeColor = "#C6C6C6";
// const _hoveredEdgeColor = "#4589FF";
// const _labelColor = "#C6C6C6";
// const _labelRGBColor = utils.hexColorToRgbString(_labelColor);
// const _parentNodeBackgroundColor = "#171717";
// //const _greyoutColor = "#444444";
// //const _greyoutRGBColor = utils.hexColorToRgbString(_greyoutColor);
// const _heighLightedColor = "white";

const _defaultNodeSize = 32;
const _selectedNodeSize = 48;
const _classLabelSize = 16;
//const _partitionArrowScale = _nodeArrowScale; // * 2.5;
const _doubleClickMS = 500;
const _partitionLabelSize = 24;
const _partitionClassNumberLabelSize = 34;
const _partitionLabelWeight = 600;
const _nodeBorderWidth = 2;
const _closedPartitionBorderWidth = 8;
// const _parentBorderWidth = 7;
const _minZoomLevelForPartitionLabel = 0.12;
const _maxPartitionLabelSize = 10 / _minZoomLevelForPartitionLabel;
const _minZoomedFontSize = window.devicePixelRatio * 12;
const _classNumberForSimpleApp = 500;
const _minDevicePixelRatio = 1.4; // browser 100% zoom on windows
const _maxDevicePixelRatio = 2;   // browser 100% zoom on Mac
const _minWheelSensitivity = 0.1;
const _maxWheelSensitivity = 1;
const _distanceFromNode = 2;
const _fadeoutOpacity = 0.25;

let colorInfo = {};
// let labelRGBColor;
let cyInstanceMap;
let previousTapStamp;
let previousTappedNodeId;
let movingOutPartition;
let previousLabelSize;
let globalHighlightTimeout;
let globalClickResponseTimeout;
let hoveronElement;
let unobservedNewPartitionDialogData;
let edgeArrowScale = 1;
let ddGrabbedNode = undefined;

export default {
  components: {
    // CvLoading,
    graphNavigator,
    graphLegend,
  },
  data: function() {
    return {
      minimapRightClass: "m2m-minimap-sidepanel-closed",
      legendRightClass: "legend-panel-closed",
      // isLoading: false,
      selectedView: "",
      showNavigator: false,
      isClassDependencyOn: true,
      showUnobservedNewPartitionDialog: false,
      legendHover: false,
      minimapHover: false,
      isLegendOpen: false
    };
  },
  watch: {
    minimapHover: function() {
      if (this.minimapHover) {
        document.getElementById("minimap").style.opacity = "1";
        document.getElementById("legend").style.opacity = "1";
      } else {
        document.getElementById("minimap").style.opacity = "0.5";
        if ( this.isLegendOpen ) {
          document.getElementById("legend").style.opacity = "1";
        }
        else {
          document.getElementById("legend").style.opacity = "0.5";
        }
      }
    },

    legendHover: function() {
      if (this.legendHover) {
        document.getElementById("minimap").style.opacity = "1";
        document.getElementById("legend").style.opacity = "1";

      } else {
        document.getElementById("minimap").style.opacity = "0.5";
        if ( this.isLegendOpen ) {
          document.getElementById("legend").style.opacity = "1";
        }
        else {
          document.getElementById("legend").style.opacity = "0.5";
        }
      }
    },


  },
  methods: {
    // resetSelectedView is set when a new json is loaded
    async drawIfRequired(options) {
      console.log("in drawIfRequired", options);
      var resetSelectedView = false;
      if (options) {
        resetSelectedView = options.resetSelectedView;
      }
      if (
        !resetSelectedView &&
        this.selectedView == this.$store.getters.getKey
      ) {
        return;
      }
      cyInstanceMap = this.$store.getters.getCYInstanceMap;
      if (
        Object.prototype.hasOwnProperty.call(cyInstanceMap, this.selectedView)
      ) {
        cyInstanceMap[this.selectedView].unmount();
      }
      this.selectedView = this.$store.getters.getKey;

      if (
        resetSelectedView ||
        !Object.prototype.hasOwnProperty.call(cyInstanceMap, this.selectedView)
      ) {
        this.$parent.isLoading = true;
        // wait for busy icon to appear
        await utils.sleep(500);
        this.drawGraph(options);
      } else {
        var cy = cyInstanceMap[this.selectedView];
        cy.mount(document.getElementById("cy"));
        this.updateGraphSummaryPanel(true);
        this.updateSidePanel();
        if (options && options.event && options.event.name) {
          eventBus.$emit(options.event.name, options.event.params);
        }
        this.updateNavigator(cy);
      }
    },
    onUnobservedNewPartitionDialogCancel() {
      this.showUnobservedNewPartitionDialog = false;
      if ( unobservedNewPartitionDialogData) { 
        const category = unobservedNewPartitionDialogData.event.target.data('category');
        if (unobservedNewPartitionDialogData.event.target.isChild() === true || 
            (category && utils.isUnobservedCategory(category) !== true)) {
          // update model
          apis.changeClassPartition(unobservedNewPartitionDialogData.event.target, {
            partition: null,
            updateCY: true,
          });
        }
      }

      // update UI filter menu
      this.updateGraphSummaryPanel(false, true);
      // update custom view side panel
      this.updateCustomViewSidePanel();
    },
    onUnobservedNewPartitionDialogConfirm() {
      if ( unobservedNewPartitionDialogData ) {
        apis.addDropTargetToNewPartition(unobservedNewPartitionDialogData.event, 
          unobservedNewPartitionDialogData.cy, movingOutPartition); 
        // update UI filter menu
        this.updateGraphSummaryPanel(false, true);
        // update custom view side panel
        this.updateCustomViewSidePanel();
      }
      unobservedNewPartitionDialogData = undefined;
      this.showUnobservedNewPartitionDialog = false;
      movingOutPartition = undefined; // clean up the moving out partition
    },

    drawGraph(options) {
      console.log("drawGraph, options=", options);
      const me = this;
      var elements = JSON.parse(
        JSON.stringify(this.$store.getters.getElements)
      );
      // console.log(elements);
      // elements.nodes.forEach((ele) => {
      //   console.log(ele.data.category);
      // });

      var layout = "grid";
      if (
        elements &&
        elements.nodes &&
        elements.nodes.length > 0 &&
        Object.hasOwnProperty.call(elements.nodes[0], "position")
      ) {
        layout = "preset";
      }
      const startTime = new Date().getTime();
      let cy;

      if (
        Object.prototype.hasOwnProperty.call(
          cyInstanceMap,
          me.$store.getters.getKey
        )
      ) {
        var oldCy = cyInstanceMap[me.$store.getters.getKey];
        oldCy.unmount();
        oldCy.destroy();
        console.log(oldCy.destroyed());
        delete cyInstanceMap[me.$store.getters.getKey];
      }
      // this call may take a long time
      cy = this.$cytoscape({
        boxSelectionEnabled: true,
        container: document.getElementById("cy"),
        ready: function() {
          // init expand and collapse extension
          this.expandCollapse({
            fisheye: false,
            animate: false,
            undoable: false,
            cueEnabled: false,
            zIndex: 0, // have to set it for control-panel
          });
          cyInstanceMap[me.$store.getters.getKey] = this;

          me.updateGraphSummaryPanel(true);
          me.updateSidePanel();
          const currentCY = this;
          if (layout !== "preset") {
            const fcose = utils.getDefaultFcoseLayout();
            fcose.stop = () => {
              console.log("======================= Done rendering:", new Date().getTime() - startTime);
              const depEdges = apis.injectDependencyEdges();
              console.log(`======================= Done injecting dep edges [${depEdges.edges.length}, ${depEdges.errors.length}]:`, new Date().getTime() - startTime);
              if (this.nodes().length > _classNumberForSimpleApp) {
                apis.collapseAllPartition(0);
                console.log("======================= Done closing all partitions:", new Date().getTime() - startTime);
              }
              me.afterLayout(currentCY);
              me.$parent.isLoading = false;
            },
            this.layout(fcose).run();
          } else {
            console.log("======================= Done rendering:", new Date().getTime() - startTime);
            const callback = function () {
              const depEdges = apis.injectDependencyEdges();
              console.log(`======================= Done injecting dep edges [${depEdges.edges.length}, ${depEdges.errors.length}]:`, new Date().getTime() - startTime);
              // should collapse partition as what the file saved
              // if (currentCY.nodes().length > _classNumberForSimpleApp) {
                apis.collapsePresetPartitions();
                console.log("======================= Done closing all partitions:", new Date().getTime() - startTime);
              // }
              me.afterLayout(currentCY);
              me.$parent.isLoading = false;
              // launch walkme if in demo. Wait for 2.5 seconds for loading icon to finish
              // setTimeout(function(){ eventBus.$emit("TopNav.launchWalkMeIfInDemo"); }, 2500);
            };
            me.relayoutOverlaidNodes(this, callback);
          }
        },

        // demo your layout
        layout: {
          name: layout,
        },

        style: [
          {
            selector: "node",
            style: {
              "label": function(ele) {
                return utils.getNodeLabel(
                  ele.data("name"),
                  ele.data("construct")
                );
              },
              "color": colorInfo.label,
              "text-valign": "bottom",
              "text-margin-y": "10px",
              "font-size": _classLabelSize,
              "font-family": constants.fontFamily,
              "min-zoomed-font-size": _minZoomedFontSize,
              width: _defaultNodeSize,
              height: _defaultNodeSize,
              "background-color": function(ele) {
                return utils.getNodeColor(ele);
              },
              "background-opacity": function(ele) {
                if (ele.data(constants.unobservedId)) {
                  return "0";
                }
                return "1";
              },
              "border-color": function(ele) {
                return /*ele.data("ui_partitionColor") ||*/ utils.getNodeColor(ele);
              },
              "border-width": _nodeBorderWidth,
              "shape": function(ele) { //"ellipse",
                if (utils.hasUtilityTag(ele.data("tags")) === true) {
                  return "diamond";
                }
                return "ellipse";
              },
            },
          },

          {
            selector: 'node:active',
            style: {
              'overlay-color': colorInfo.actived_node,
            },
          },

          {
            selector: "node.cy-expand-collapse-collapsed-node",
            style: {
              "background-opacity": function(ele) {
                if (ele.hasClass(constants.m2mHighlightedFocusedClass) === true) {
                  return 1;
                }
                return 0;
              },
              // "font-family": constants.fontFamily,
              "font-size": function(ele) {
                const size = 10 / ele.cy().zoom();
                return size < _partitionLabelSize
                  ? _partitionLabelSize
                  : size > _maxPartitionLabelSize
                  ? _maxPartitionLabelSize
                  : size;
              },
              "font-weight": _partitionLabelWeight,
              "min-zoomed-font-size": function(ele) {
                if (ele.cy().zoom() > _minZoomLevelForPartitionLabel) {
                  return 0;
                } else {
                  return _maxPartitionLabelSize;
                }
              },
              width: 'data(ui_symbolSize)',
              height: 'data(ui_symbolSize)',
              "border-width": _closedPartitionBorderWidth,
              "background-fit": "cover",
              "shape": "square",
              "background-image": function(ele) {
                const classNumber = ele.data("ui_classNumber");
                const imageSize = 100;
                const fontSize = (imageSize / ele.data("ui_symbolSize")) * _partitionClassNumberLabelSize;
                // const ringColor =
                //   ele.hasClass(constants.m2mHighlightedFocusedClass) === true ? 
                //     colorInfo.selected_ring_rgb_color
                //     : ((ele.hasClass(constants.m2mHoveredFocusedClass) === true) ? 
                //         colorInfo.hovered_ring_rgb_color
                //         : ele.style("background-color"));
                const classNumberColor = ele.hasClass(constants.m2mHighlightedFocusedClass) === true ? 
                                            colorInfo.selected_label_rgb_color : colorInfo.label_rgb_color;
                // const ringFillColor =
                //   ele.hasClass(constants.m2mHighlightedFocusedClass) === true 
                //     ? colorInfo.selected_ring_fill_rgb_color
                //     : "transparent";
                // const svg = `<svg width="100" height="100" xmlns="http://www.w3.org/2000/svg">
                //     <circle cx="50%" cy="50%" r="43" stroke="${ringColor}" stroke-width="15" fill="${ringFillColor}"></circle>
                //     <text x="50%" y="50%" dy=".3em" text-anchor="middle" fill="${classNumberColor}"
                //         style="font-family:'IBM Plex Sans', Helvetica, sans-serif; font-size: ${fontSize}px; overflow: visible;">
                //       ${classNumber}
                //     </text>
                //   </svg>`;
                const svg = `<svg width="${imageSize}" height="${imageSize}" xmlns="http://www.w3.org/2000/svg">
                    <text x="50%" y="50%" dy=".3em" text-anchor="middle" fill="${classNumberColor}"
                        style="font-family:'IBM Plex Sans', Helvetica, sans-serif; font-size: ${fontSize}px; overflow: visible;">
                      ${classNumber}
                    </text>
                  </svg>`;
                return encodeURI(`data:image/svg+xml;utf-8,${svg}`);
              },
            },
          },

          {
            selector: ":parent",
            style: {
              // "background-opacity": function(ele) {
              //   if (ele.selected() === true) {
              //     return 1;
              //   }
              //   return 0;
              // },
              "background-opacity": 0,
              // "font-family": constants.fontFamily,
              "font-size": function(ele) {
                const size = 10 / ele.cy().zoom();
                return size < _partitionLabelSize
                  ? _partitionLabelSize
                  : size > _maxPartitionLabelSize
                  ? _maxPartitionLabelSize
                  : size;
              },
              "font-weight": _partitionLabelWeight,
              "min-zoomed-font-size": function(ele) {
                if (ele.cy().zoom() > _minZoomLevelForPartitionLabel) {
                  return 0;
                } else {
                  return _maxPartitionLabelSize;
                }
              },
              "display": function(ele) {
                // do not show the node if all children are invisible (filtered out)
                // this could be called multiple times for some scenarios
                return utils.getParentNodeDisplayValue(ele);
              }
              // "border-color": 'data(ui_partitionColor)',              
              // "border-color": function(ele) {
              //   if (ele.selected() === true) {
              //     return colorInfo.selected;
              //   }
              //   return ele.data("ui_partitionColor");
              // },
              // "z-index": function(ele) {
              //   if (ele.selected() === true) {
              //     return 10;
              //   }
              //   return 0;
              // },
              // "border-width": function(ele) {
              //   if (ele.selected() && !ele.hasClass("cy-expand-collapse-collapsed-node")) {
              //     return _parentBorderWidth;
              //   } else {
              //     return _nodeBorderWidth;
              //   }
              // },
            },
          },

          {
            selector: "node:parent:selected",
            style: {
              "background-color": colorInfo.selected_parent_node_background,
              "background-opacity": 1,
              "border-color": colorInfo.selected,
              "z-index": 10,              
            },
          },

          {
            selector: "edge",
            style: {
              "width": 2,
              "line-color": colorInfo.edge,
              "curve-style": "bezier",
              "line-style": function(ele) {
                if (ele.data("dep")) {
                  return "dashed";
                }
                return "solid";
              },
              "source-distance-from-node": _distanceFromNode,
              "target-distance-from-node": _distanceFromNode,
            },
          },

          {
            selector: 'edge:active',
            style: {
              'overlay-opacity': 0,
            },
          },

          {
            selector: "edge.invisible",
            style: {
              "display": "none",
            },
          },

          // {
          //   selector: "edge.cy-expand-collapse-collapsed-edge",
          //   style: {
          //     // 'width': function(edge){
          //     //   return 1 + Math.round(((Math.log(edge.data("collapsedEdges").length) / Math.log(3) + Number.EPSILON)) * 100) / 100;
          //     // },
          //     "line-color": colorInfo.edge,
          //     // "line-style": "dashed",
          //     // "line-dash-pattern": function() {
          //     //   // if (edge.data("directionType") === "bidirection") {
          //     //   //   return [60, 10, 10, 10, 10, 10, 10, 10];
          //     //   // } else {
          //     //   return [20, 20];
          //     //   // }
          //     // },
          //     // "line-dash-offset": 30,
          //     // "target-arrow-shape": function() {
          //     //   return "chevron";
          //     // },
          //     "curve-style": "bezier",
          //     // "arrow-scale": function(ele) {
          //     //   if (utils.isCollapsedNode(ele.target()) === true) {
          //     //     return _partitionArrowScale;
          //     //   } else {
          //     //     return _nodeArrowScale;
          //     //   }
          //     // },
          //     // "source-arrow-shape": function(edge) {
          //     //   if (edge.data("directionType") == "bidirection") {
          //     //     return "chevron";
          //     //   } else {
          //     //     return "none";
          //     //   }
          //     // },
          //     // "source-arrow-color": _edgeColor,
          //     // "mid-target-arrow-shape": function(edge) {
          //     //   if (edge.data("directionType") === "bidirection") {
          //     //     return "none";
          //     //   }
          //     //   return "chevron";
          //     // },
          //     // "mid-target-arrow-color": _edgeColor,
          //     // "mid-source-arrow-shape": function(edge) {
          //     //   if (edge.data("directionType") !== "bidirection") {
          //     //     return "none";
          //     //   }
          //     //   return "diamond";
          //     // },
          //     // "mid-source-arrow-color": _edgeColor
          //   },
          // },

          // {
          //   selector: "edge.cy-expand-collapse-meta-edge",
          //   style: {
          //     "arrow-scale": function(ele) {
          //       if (utils.isCollapsedNode(ele.target()) === true) {
          //         return _partitionArrowScale;
          //       } else {
          //         return _nodeArrowScale;
          //       }
          //     },
          //     "mid-target-arrow-color": _edgeColor,
          //     "mid-target-arrow-shape": function() {
          //       return "triangle-backcurve";
          //     }
          //   }
          // },

          {
            selector: `${this.getFilterElementSelector()}`,
            style: {
              // "background-color": _greyoutColor,
              // "border-color": _greyoutColor,
              "display": "none",
            },
          },

          {
            selector: `node.${constants.m2mHoveredFocusedClass}`,
            style: {
              "background-color": colorInfo.hovered,
              "border-color": colorInfo.hovered,
              "z-index": 10,
            },
          },

          {
            selector: `node.${constants.m2mHighlightedFocusedClass}`,
            style: {
              "background-color": function(ele) {
                if (utils.isParentNode(ele) !== true) {
                  return colorInfo.selected;
                }
                return colorInfo.selected_parent_node_background;
              },
              color: colorInfo.selected_label,
              width: function(ele) {
                if (utils.isParentNode(ele) !== true) {
                  return _selectedNodeSize;
                } else if (ele.hasClass("cy-expand-collapse-collapsed-node") === true) {
                  return ele.data('ui_symbolSize');
                }
                return ele.width();
              },
              height: function(ele) {
                if (utils.isParentNode(ele) !== true) {
                  return _selectedNodeSize;
                } else if (ele.hasClass("cy-expand-collapse-collapsed-node") === true) {
                  return ele.data('ui_symbolSize');
                }
                return ele.height();
              },
              "border-color": function(ele) {
                if (utils.isParentNode(ele) !== true) {
                  return colorInfo.highlighted;
                }
                return colorInfo.selected;
              },
              "z-index": 10,
            },
          },

          {
            selector: `node.${constants.m2mSearchedClass}`,
            style: {
              "background-color": colorInfo.selected,
              color: colorInfo.selected_label,
              width: _selectedNodeSize,
              height: _selectedNodeSize,
              "border-color": colorInfo.highlighted,
              "z-index": 10,
              // "font-family": constants.fontFamily,
              // "font-size": function(ele) {
              //   const size = 10 / ele.cy().zoom();
              //   const fontS =
              //     size < _partitionLabelSize ? _partitionLabelSize : size;
              //   return fontS;
              // },
              // "min-zoomed-font-size": 0,
            },
          },

          // {
          //   selector: `node.${constants.m2mHighlightedClass}, node.${constants.m2mHoverClass},
          //              node.${constants.m2mSearchedClass}, node.${constants.m2mHighlightedFocusedClass},
          //              node.${constants.m2mHoveredFocusedClass}`,
          //   style: {
          //     color: colorInfo.highlighted,
          //     "border-color": colorInfo.highlighted,
          //   },
          // },

          {
            selector: `${utils.getClassSelectorForElement('edge', utils.getHighlightClassesForEdge())}`,
            style: {
              "line-color": function(ele) {
                if (ele.hasClass(constants.m2mHoveredFocusedClass) === true) {
                  return colorInfo.hovered_edge;
                } else {
                  return colorInfo.selected_edge;
                }
              },
              "target-arrow-color": function(ele) {
                return ele.style("line-color");
              },
              // "mid-source-arrow-color": function(ele) {
              //   return ele.style("line-color");
              // },
              // "mid-target-arrow-color": function(ele) {
              //   return ele.style("line-color");
              // },
              "source-arrow-color": function(ele) {
                return ele.style("line-color");
              },
              "arrow-scale": function(ele) {
                return me.getEdgeArrowScale(ele.cy());
              },
              "target-arrow-shape": "triangle",
              // "mid-target-arrow-shape": function(edge) {
              //   if (edge.data("directionType") !== "bidirection") {
              //     return "triangle";
              //   }
              //   return "diamond";
              // },
              "source-arrow-shape": function(edge) {
                if (edge.data("directionType") === "bidirection") {
                  return "triangle";
                }
                return "none";
              },
              "source-distance-from-node": function(ele) {
                if (utils.isHighlightedFocusedElement(ele.source()) === true) {
                  return 0;
                }
                return _distanceFromNode;
              },
              "target-distance-from-node": function(ele) {
                if (utils.isHighlightedFocusedElement(ele.target()) === true) {
                  return 0;
                }
                return _distanceFromNode;
              },
              "z-index": 10,
            },
          },

          // {
          //   selector: ".cdnd-grabbed-node",
          //   style: {
          //     "background-color": "red"
          //   }
          // },

          // {
          //   selector: ".cdnd-drop-sibling",
          //   style: {
          //     "background-color": _heighLightedColor
          //   }
          // },

          {
            selector: ".cdnd-drop-target",
            style: {
              "border-color": colorInfo.highlighted,
              "border-style": "dashed",
            },
          },

          {
            selector: `node.${constants.m2mPartitionFadeOutClass}`,
            style: {
              "border-opacity": _fadeoutOpacity,  // fade out partition border
              "text-opacity": _fadeoutOpacity,    // fade out partition label
              "z-index": 0,
            },
          },

          {
            selector: `.${constants.m2mFadeOutClass}`,
            style: {
              "opacity": _fadeoutOpacity,
              "z-index": 0,
            },
          },

          // {
          //   selector: `edge.${constants.m2mFadeOutClass}`,
          //   style: {
          //     "opacity": 0.3,
          //     "z-index": 0,
          //   },
          // },
        ],

        wheelSensitivity: this.getWheelSensitivity(window.devicePixelRatio),
        fit: true,

        elements: elements,
      });

      const cdndOptions = {
        grabbedNode: (node) => {
          if (!node.hasClass("cy-expand-collapse-collapsed-node")) {
            ddGrabbedNode = node;
            return true;
          }
          return false;
        }, // filter function to specify which nodes are valid to grab and drop into other nodes
        dropTarget: (dropTargetNode) => {
          if (!dropTargetNode.hasClass("cy-expand-collapse-collapsed-node") && ddGrabbedNode && dropTargetNode.data("category")) {
            return (ddGrabbedNode.data(constants.unobservedId) === true || utils.isUnobservedCategory(dropTargetNode.data("category")) === false );
          }
          return false;
          // return (
          //   !dropTargetNode.hasClass("cy-expand-collapse-collapsed-node") 
          //   && 
          //   (false === utils.isUnobservedCategory(dropTargetNode.data().category) )
          // );
        }, // filter function to specify which parent nodes are valid drop targets
        dropSibling: (node) => {
          if (!node.hasClass("cy-expand-collapse-collapsed-node") && ddGrabbedNode) {
            return (ddGrabbedNode.data(constants.unobservedId) === true || node.data(constants.unobservedId) !== true);
          }
          return false;
        }, // filter function to specify which orphan nodes are valid drop siblings
        newParentNode: () => {
          return {}; // should never happen, since we will create default partition on orphan node on cdnddrop event
        }, // specifies element json for parent nodes added by dropping an orphan node on another orphan (a drop sibling)
        overThreshold: 0, // make dragging over a drop target easier by expanding the hit area by this amount on all sides
        outThreshold: 10, // make dragging out of a drop target a bit harder by expanding the hit area by this amount on all sides
      };

      var cdnd = cy.compoundDragAndDrop(cdndOptions);
      cdnd.disable();
      const extMap = me.$store.getters.getCYExtensionMap;
      extMap[me.$store.getters.getKey] = cdnd;

      /*
      var contextMenu = cy.contextMenus({
        menuItems: [
          {
            id: "enable-node-drag-drop-mode",
            content: "Enable node drag and drop",
            selector: "node",
            onClickFunction: function() {
              cdnd.enable();
              contextMenu.showMenuItem("disable-node-drag-drop-mode");
              contextMenu.hideMenuItem("enable-node-drag-drop-mode");
            },
            disabled: false
          },
          {
            id: "disable-node-drag-drop-mode",
            content: "Disable node drag and drop",
            selector: "node",
            show: false,
            onClickFunction: function() {
              cdnd.disable();
              contextMenu.showMenuItem("enable-node-drag-drop-mode");
              contextMenu.hideMenuItem("disable-node-drag-drop-mode");
            },
            disabled: false
          },

          {
            id: "rename-partition",
            content: "Rename partition",
            selector: "node[filepath='Cluster']",
            show: true,
            onClickFunction: function() {
              alert("Rename");
            },
            disabled: false
          }
        ]
      });
      */

      // custom handler to remove parents with only 1 child on drop
      cy.on("cdndout", function(event, grabbedPartition) {
        movingOutPartition = grabbedPartition;
      });

      cy.on("cdnddrop", function(event, dropTarget, dropSibling) {
        console.log(event);
        var createNewPartition = false;
        var clearMovingPartition = true;
        const isMovingOutPartitionEmpty = (movingOutPartition && movingOutPartition.data("name")) ? 
            movingOutPartition && movingOutPartition.children().length < 1 : false;
        
        if (event.target.data().unobserved === false && dropTarget.length && dropTarget.data().category && 
            utils.isUnobservedCategory(dropTarget.data().category) === true) {
          createNewPartition = true;
        }
        else {
          if (
            event &&
            event.target &&
            dropTarget.length &&
            event.target.data().category !== dropTarget.data().category
          ) {
            // move node to an existing partition
            apis.changeClassPartition(event.target, {
              partition: dropTarget.data("category"),
              removeEmptyPartition: true,
            });
            // partition of moving from is empty
            if (isMovingOutPartitionEmpty) {
              // update UI filter menu
              me.updateGraphSummaryPanel(false, true);
            }
            // always need to update custom view side panel
            me.updateCustomViewSidePanel();
            // also force to create new partition if movig observed node to unobserved partition
          } else if (event && event.target && dropTarget.length === 0 ) {
            // move to new partition
            // prevent moving if grabbed node is the only one child.
            if (isMovingOutPartitionEmpty ) {
              var originalParentName = event.target.data("category");
              var originalParentId = movingOutPartition.data("id");
              // yes, prevent moving
              apis.addPartition(originalParentName, cy, {
                updateModel: false,
                data: movingOutPartition.data(),
              });
              event.target.move({ parent: originalParentId });
            } else {
              // create a new partition
              createNewPartition = true;
            }
          }
        }
        if (createNewPartition === true ) {
          if ( constants.keepUnobserved !== true && event.target.data(constants.unobservedId) === true )
          {
            clearMovingPartition = false;
            me.showUnobservedNewPartitionDialog = true;
            unobservedNewPartitionDialogData={};
            unobservedNewPartitionDialogData.event = event;
            unobservedNewPartitionDialogData.cy = cy;
            
            me.$nextTick(() => {
              // has to wait to set focus on the reset dialog even with $nextTick
              setTimeout(function(){
                document.querySelector("#unobserved-new-partition-dialog .bx--modal-footer .bx--btn--secondary").focus();
              }, 100);
            });
          } else {
            apis.addDropTargetToNewPartition(event, cy, movingOutPartition);
            // update UI filter menu
            me.updateGraphSummaryPanel(false, true);
            // update custom view side panel
            me.updateCustomViewSidePanel(); 
          }
        }
        // notify table view component for data changed
        eventBus.$emit("TableView.resetTable", false);        
        if ( clearMovingPartition )
        {
          movingOutPartition = undefined; // clean up the moving out partition
        }
        console.log(dropSibling);
      });

      cy.on("doubleTap", "node", (evt) => {
        const ele = evt.target;
        if (utils.isParentNode(ele) === true) {
          (async () => {
            await apis.expandCollapsePartition(ele);
            eventBus.$emit("SidePanel.showMicroserviceClassButton", ele);
            eventBus.$emit("Toolbar.updateMicorserviceToolbarButton", ele);
          })();
        }
      });

      cy.on("tap", (evt) => {
        const ele = evt.target;
        var currentTapStamp = evt.timeStamp;

        if (globalClickResponseTimeout !== null) {
          clearTimeout(globalClickResponseTimeout);
        }

        apis.hideTooltip();
        // clean up search field for a better transition between graph and table views
        eventBus.$emit("SearchWithFilter.resetSearch");
        // notify TableView to clean up search info
        eventBus.$emit("TableView.search", "");
        hoveronElement = null;
        if (ele && ele !== cy && ele.isNode() === true) {
          const isParent = utils.isParentNode(ele) === true;
          if (ele.data("id") === previousTappedNodeId && isParent && this.isDoubleClick(ele, currentTapStamp - previousTapStamp) === true) {
            apis.cleanupAllHighlights({ unselect: false });
            evt.target.trigger("doubleTap", evt);
          } else {
            const delay = (isParent && utils.getFunctionCallDelay(cy) > 0) ? constants.minFunctionCallTimeout : 0;
            globalClickResponseTimeout = setTimeout(function() {
              globalClickResponseTimeout = null;
              // put 'cleanupAllHighlights' and 'highligh' together in order not to blink the page
              apis.cleanupAllHighlights({ unselect: false });
              // if (me.isClassDependencyOn) {
              //   apis.showNodeDependencies(ele);
              // 
              //}
              // apis.enableConnectedEdges(ele);
              // 
              apis.highlight(ele);
            }, delay);
            //if (!isParent) {  // node is selected, notify table view component
            // make the call even when partition is highligted to force a reload of the table data.
            // Otherwise any previously highlighted class will still in the loaded table data.
            eventBus.$emit("TableView.resetTable", false);
            //}
          }
          previousTappedNodeId = ele.data("id");
          previousTapStamp = currentTapStamp;
          this.setCustomViewData();
          eventBus.$emit("SidePanel.showMicroservicePanel", ele);
          // only show collapse/expand partition toolbar button when it is a partition
          if (isParent) {
            eventBus.$emit("Toolbar.showMicroserviceToolbarButton", ele);
          } else {
            eventBus.$emit("Toolbar.hideMicroserviceToolbarButton");
          }
          eventBus.$emit("Toolbar.showMicroserviceToolbarButton", ele);
        } else {
          if (utils.hasFocusedNodes() === true) { // has nodes being highlighted and will be clean up, notify tableView
            eventBus.$emit("TableView.resetTable", false);
          }
          apis.cleanupAllHighlights({ unselect: false });
          eventBus.$emit("SidePanel.hideMicroservicePanel");
          eventBus.$emit("Toolbar.hideMicroserviceToolbarButton");
        }
      });

      // mouse over on nodes and edges
      // cy.on("mouseover", "node, edge", function(evt) {

      // mouse moves on nodes and edges
      cy.on("mousemove", "node, edge", function(evt) {
        const target = evt.target;

        if (globalHighlightTimeout !== null) {
          clearTimeout(globalHighlightTimeout);
        }
        apis.hideTooltip();
        if (evt.originalEvent.buttons === 0) {
          if (hoveronElement !== target) {
            apis.hoverOff(hoveronElement);
            hoveronElement = target;
            apis.hoverOn(target);
          }
          globalHighlightTimeout = setTimeout(function() {
            globalHighlightTimeout = null;
            if (target.hasClass(constants.m2mHoveredFocusedClass) === true) {
              apis.showTooltip(evt);
              if (utils.hasFocusedNodes(target.cy()) !== true) {
                apis.highlightAll(target, {
                  classes: constants.m2mHoverClass,
                });
              }
            }
          }, constants.tooltipsDelayTime);
        }
      });

      // mouse out from nodes and edges
      cy.on("mouseout", "node, edge", function(evt) {
        if (globalHighlightTimeout !== null) {
          clearTimeout(globalHighlightTimeout);
        }
        apis.hideTooltip();
        apis.hoverOff(evt.target);
        if (hoveronElement === evt.target) {
          hoveronElement = null;
        }
      });

      // calculate stats, leave it here for now, if takes too much time, we can move it after layout is finished
      var json = this.$store.getters.getJson;
      stats.getUseCases(json[constants.runtimeKey], null);

      var natural_seam_partition_data = stats.getCrossPartitionStats(
        json["micro_detail_partition_by_natural_seam"],
        json[constants.dependendyKey]
      );

      var business_logic_partition_data = stats.getCrossPartitionStats(
        json["micro_detail_partition_by_business_logic"],
        json[constants.dependendyKey]
      );

      this.$store.commit("setPartitionDataNaturalSeam", {
        data: natural_seam_partition_data,
      });

      this.$store.commit("setPartitionDataBusinessLogic", {
        data: business_logic_partition_data,
      });

      this.setCustomViewData();

      // now ready.
      if (options && options.event && options.event.name) {
        eventBus.$emit(options.event.name, options.event.params);
      }
    },

    afterLayout(cy) {
      const me = this;
      previousLabelSize = null;
      hoveronElement = null;
      previousTapStamp = 0;
      previousTappedNodeId = null;
      movingOutPartition = null;

      // listen to zoom in or out
      cy.on("zoom", function(evt) {
        evt.cy.startBatch();

        // arrow scale
        me.updateEdgeArrowScale(evt.cy);

        // font size
        var fontSize = 10 / evt.cy.zoom();
        var mzfs = 0;
        if (fontSize > _maxPartitionLabelSize) {
          mzfs = _maxPartitionLabelSize;
          fontSize = _maxPartitionLabelSize;
        } else if (fontSize < _partitionLabelSize) {
          fontSize = _partitionLabelSize;
        }
        if (fontSize !== previousLabelSize) {
          previousLabelSize = fontSize;
          const nodes = cy.nodes(`.cy-expand-collapse-collapsed-node, :parent, .${constants.m2mSearchedClass}`);
          // const searchedNodes = nodes.filter(`.${constants.m2mSearchedClass}`);
          // nodes.difference(searchedNodes).css({
          nodes.css({
            "min-zoomed-font-size": mzfs,
            "font-size": fontSize,
            // 'width': function(ele) {
            //   return fontSize / _partitionLabelSize * ele.data("ui_symbolSize");
            // },
            // 'height': function(ele) {
            //   return fontSize / _partitionLabelSize * ele.data("ui_symbolSize");
            // },
          });
        }
        //        searchedNodes.css({ 'font-size': zoomFontSize, });

        evt.cy.endBatch();
      });

      cy.fit(); // fit after clossing partitions
      window.scrollTo(0, 0);
      this.updateNavigator(cy);
    },

    relayoutOverlaidNodes(cy, callback) {
      // only check nodes at (0, 0) position for now
      let uoNodes = cy.nodes().filter(node => {
        const pos = node.position();
        return (pos.x === 0 && pos.y === 0);
      });
      if (uoNodes.length > 1) {
        const fcose = utils.getDefaultFcoseLayout();
        fcose.stop = () => {
          callback();
        };
        uoNodes.layout(fcose).run();
      } else {
        callback();
      }
    },

    updateNavigator(cy) {
      eventBus.$emit("GraphNavigator.updateNavigator", {cy: cy});
      if (!this.showNavigator) {
      if (window && window.WalkMeAPI) {
        if ( this.$store.getters.getDemoMode ) {
          window.WalkMeAPI.startFlowById('1247588');
        }
        else {
          window.WalkMeAPI.startFlowById('1240313');
        }
      }
        this.showNavigator = true;
      }
    },

    getWheelSensitivity(currentRatio) {
      const scale = Math.log(_minDevicePixelRatio) / Math.log(_maxDevicePixelRatio);
      const ratio = Math.max(_minDevicePixelRatio, Math.min(_maxDevicePixelRatio, currentRatio));
      const p = Math.log(ratio) / Math.log(_maxDevicePixelRatio);
      const percent = 100 - (1 - (p - scale) / (1 - scale)) * 100;
      const sensitivity = _minWheelSensitivity + (_maxWheelSensitivity - _minWheelSensitivity) / 100 * percent;
      return sensitivity;
    },

    getFilterElementSelector() {
      return `${utils.getClassSelectorForElement('node', utils.getAllFilterClassesForNodes())},
              ${utils.getClassSelectorForElement('edge', utils.getAllFilterClassesForEdges())}`;
    },

    getEdgeArrowScale(cy) {
      const zoomLevel = cy.zoom();
      if (zoomLevel >= 0.7) {
        return 1;
      } else if (zoomLevel <= 0.18) {
        return 1.8;
      }
      return 1.4;
    },

    updateEdgeArrowScale(cy) {
      const aScale = this.getEdgeArrowScale(cy);
      if (aScale !== edgeArrowScale) {
        edgeArrowScale = aScale;
        const edges = cy.edges(`${utils.getClassSelectorForElement('edge', utils.getHighlightClassesForEdge())}`);
        if (edges.length > 0) {
          edges.css({
            'arrow-scale': edgeArrowScale
          });
        }
      }
        // cy.style().selector(`${utils.getClassSelectorForElement('edge', utils.getHighlightClassesForEdge())}`).style({
        //   'arrow-scale': edgeArrowScale
        // }).update();
    },

    isDoubleClick(ele, timeSpent) {
      let ratio = 1;
      const count = ele.cy().$().length;
      if (count > 3500) {
        ratio = count / 3500;
      }

      console.log("===== isDoubleClick: ", timeSpent, ratio);

      return timeSpent < _doubleClickMS * ratio;
    },

    setCustomViewData() {
      let custom_view_partition_data = "";
      if (
        this.$store.getters.getApps.includes("custom_view") ||
        this.selectedView === "custom_view"
      ) {
        let json = this.$store.getters.getJson;
        custom_view_partition_data = stats.getCrossPartitionStats(
          json["custom_view"],
          json[constants.dependendyKey]
        );

        this.$store.commit("setPartitionDataCustomView", {
          data: custom_view_partition_data,
        });
      }
    },

    updateGraphSummaryPanel(reset/*, isChangePartition*/) {
      // const allPartitions = apis.getPartitionNamesArray();
      // // format the options to the required format for multiSelect
      // const filterOptions = allPartitions.map((item) => {
      //   //const nameVal = item.replace(/\W/, "_").toLowerCase();
      //   return {
      //     name: item,
      //     label: item, // used for display
      //     value: item, // used for selected
      //   };
      // });

      // let selectedOption = apis.getSelectedPartiions();
      // // remove the unknown partition - to be enabled after finalizing with the team
      // // const index = selectedOption.indexOf("unknown");
      // // if (index > -1) {
      // //   selectedOption.splice(index, 1);
      // // }

      // let summaryTopPanelUpdate = {
      //   microservicesDropdown: {
      //     //initialValue: selectedOption,
      //     //selectedOption: selectedOption,
      //     options: filterOptions,
      //     clusterColorMap: apis.getCurrentViewsClusterColorMap(),
      //   },
      // };
      // if (reset && allPartitions.length === selectedOption.length) {
      //   // reset the graph view with no selection at the beginning
      //   summaryTopPanelUpdate.microservicesDropdown.initialValue = [];
      //   summaryTopPanelUpdate.microservicesDropdown.selectedOption = [];
      // } else {
      //   summaryTopPanelUpdate.microservicesDropdown.initialValue = selectedOption;
      //   summaryTopPanelUpdate.microservicesDropdown.selectedOption = selectedOption;
      // }
      // if (isChangePartition) {
      //   summaryTopPanelUpdate.microservicesDropdown.changePartition = isChangePartition;
      // } else {
      //   summaryTopPanelUpdate.microservicesDropdown.changePartition = false;
      // }
      // eventBus.$emit("GraphSummaryTopPanel.updatePanel", summaryTopPanelUpdate);

      const updateData = {
        filtersToBeUpdated: [constants.filterTypePartition],
      }
      if (reset === true) {
        updateData.filtersToBeUpdated = utils.getAllFilterClasses();
        updateData.reset = true;
      }
      eventBus.$emit("FilterPanel.updatePanel", updateData);
    },

    updateSidePanel() {
      // should not unconditionally open the side panel with the new UI design. This call will still update
      // the side panel whether it is already opened or not.
      eventBus.$emit("SidePanel.showPanel");
      // reset to show the structural overview
      eventBus.$emit("SidePanel.hideMicroservicePanel");
    },

    updateCustomViewSidePanel() {
      if (this.selectedView === "custom_view") {
        this.setCustomViewData();
        eventBus.$emit("SidePanel.updateCustomView");
      }
    },

    updateMinimapRightPositionClass (isOpen){
      if ( isOpen ) {
        this.minimapRightClass = "m2m-minimap-sidepanel-open";
        this.legendRightClass = "legend-panel-open";
      } else {
        this.minimapRightClass = "m2m-minimap-sidepanel-closed";
        this.legendRightClass = "legend-panel-closed";
      }
    },


    updateCyTheme() {
      // const cy = this.$store.getters.getCYInstance();
      // colorInfo = this.$store.getters.getColorInfo;
      // // labelRGBColor = utils.hexColorToRgbString(colorInfo.label);
      // cy.startBatch();
      
      // const style = cy.style();
      // style.selector('node').style({
      //   'color': colorInfo.label,
      // });
      // style.selector("node.cy-expand-collapse-collapsed-node").style({
      //   "background-image": function(ele) {
      //     const classNumber = ele.data("ui_classNumber");
      //     const fontSize =
      //       (constants.defaultPartitionSize / ele.data("ui_symbolSize")) *
      //       _partitionLabelSize;
      //     const ringColor =
      //       ele.hasClass(constants.m2mHighlightedFocusedClass) === true ? 
      //         colorInfo.selected_ring_rgb_color
      //         : ((ele.hasClass(constants.m2mHoveredFocusedClass) === true) ? 
      //             colorInfo.hovered_ring_rgb_color
      //             : ele.style("background-color"));
      //     const classNumberColor =
      //       ele.hasClass(constants.m2mHighlightedFocusedClass) === true 
      //         ? colorInfo.selected_label_rgb_color
      //         : colorInfo.label_rgb_color;
      //     const ringFillColor =
      //       ele.hasClass(constants.m2mHighlightedFocusedClass) === true 
      //         ? colorInfo.selected_ring_fill_rgb_color
      //         : "transparent";
      //     const svg = `<svg width="100" height="100" xmlns="http://www.w3.org/2000/svg">
      //         <circle cx="50%" cy="50%" r="43" stroke="${ringColor}" stroke-width="15" fill="${ringFillColor}"></circle>
      //         <text x="50%" y="50%" dy=".3em" text-anchor="middle" fill="${classNumberColor}"
      //             style="font-family:'IBM Plex Sans', Helvetica, sans-serif; font-size: ${fontSize}px; overflow: visible;">
      //           ${classNumber}
      //         </text>
      //       </svg>`;
      //     return encodeURI(`data:image/svg+xml;utf-8,${svg}`);
      //   }
      // });
      // style.selector(':parent').style({
      //   'background-color': colorInfo.parent_node_background,
      //   "border-color": function(ele) {
      //     if (ele.selected() && !ele.hasClass("cy-expand-collapse-collapsed-node")) {
      //       return colorInfo.highlighted;
      //     } else {
      //       return ele.data("ui_partitionColor");
      //     }
      //   },        
      // });
      // style.selector("edge").style({
      //   "line-color": colorInfo.edge,
      // });
      // style.selector("edge.cy-expand-collapse-collapsed-edge").style({
      //   "line-color": colorInfo.edge,
      // });
      // style.selector(`node.${constants.m2mHighlightedFocusedClass}, node.${constants.m2mHoveredFocusedClass}`).style({
      //   "background-color": function(ele) {
      //     if (ele.isParent() !== true) {
      //       return colorInfo.highlighted;
      //     } else {
      //       return colorInfo.parent_node_background;
      //     }
      //   },
      // });
      // style.selector(`node.${constants.m2mSearchedClass}`).style({
      //   "background-color": colorInfo.highlighted,
      // });
      // style.selector(`node.${constants.m2mHighlightedClass}, node.${constants.m2mHoverClass},
      //                 node.${constants.m2mSearchedClass}, node.${constants.m2mHighlightedFocusedClass},
      //                 node.${constants.m2mHoveredFocusedClass}`).style({
      //   color: colorInfo.highlighted,
      //   "border-color": colorInfo.highlighted,
      // });
      // style.selector(`edge.${constants.m2mHighlightedClass}, edge.${constants.m2mHoverClass},
      //                 edge.${constants.m2mHighlightedFocusedClass}, edge.${constants.m2mHoveredFocusedClass}`).style({
      //   "line-color": function(ele) {
      //     if (ele.hasClass(constants.m2mHoveredFocusedClass) === true) {
      //       return colorInfo.hovered_edge;
      //     } else {
      //       return colorInfo.highlighted_edge;
      //     }
      //   },
      //   "target-arrow-color": colorInfo.highlighted,
      //   "mid-source-arrow-color": colorInfo.highlighted,
      //   "mid-target-arrow-color": colorInfo.highlighted,
      //   "source-arrow-color": colorInfo.highlighted,
      // });
      // style.selector(".cdnd-drop-target").style({
      //   "border-color": colorInfo.highlighted,
      // });

      // style.update();
      // cy.endBatch();
    }
  },
  mounted() {},
  created() {
    eventBus.$on("Graph.drawGraph", (options) => {
      this.drawIfRequired(options);
    });
    eventBus.$on("Graph.legendAction", (options) => {
      this.isLegendOpen = options.isLegendOpen;
    });
    eventBus.$on("Graph.updateGraphTheme", (dark) => {
      this.updateCyTheme(dark);
    });
    eventBus.$on("Graph.sidePanelOpen", (isOpen) => {
      this.updateMinimapRightPositionClass(isOpen);
    });
    colorInfo = this.$store.getters.getColorInfo;
    // labelRGBColor = utils.hexColorToRgbString(colorInfo.label);
  },
  beforeDestroy() {
    eventBus.$off("Graph.drawGraph");
    eventBus.$off("Graph.updateGraphTheme");
    eventBus.$off("Graph.sidePanelOpen");
    eventBus.$off("Graph.legendAction");
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/graph-view.scss";
</style>
