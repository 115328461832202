/********************************************************************
# Licensed Materials - Property of IBM
#
# (C) Copyright IBM Corp. 2020, 2021, 2022, 2023. All Rights Reserved.
#
# US Government Users Restricted Rights - Use, duplication or
# disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
**********************************************************************/
import constants from "../constants";
import store from "../../store";

const _entityMap = {
  "&": "&amp;",
  "<": "&lt;",
  ">": "&gt;",
  '"': "&quot;",
  "'": "&#39;",
  "/": "&#x2F;",
};

const graphUtil = {
  splitUnobservedSource(source) {
    
    var links = [];
    var nodes = [];
    var overview = {};
    overview[constants.nodeId]=[];
    overview[constants.linkId]=[];

    var unobserved = {};
    unobserved[constants.nodeId]=[];
    unobserved[constants.linkId]=[];
    unobserved[constants.overviewId]={};
    unobserved[constants.overviewId][constants.nodeId]=[];
    unobserved[constants.overviewId][constants.linkId]=[];

    var obj = {};
    
    obj[constants.overviewId]=overview;
    obj[constants.unobservedId]=unobserved;

    links = source.links.filter((edge) => {
      if (this.isUnobservedCategory(edge.source) !== true && this.isUnobservedCategory(edge.target) !== true) {
        return true;
      } else {
        unobserved[constants.linkId].push(edge);
        return constants.keepUnobserved;
      }
    });

    nodes = source.nodes.filter((node) => {
      if (this.isUnobservedCategory(node.category) !== true) {
        return true;
      } else {
        unobserved[constants.nodeId].push(node);
        return constants.keepUnobserved;
      }
    });

    overview[constants.linkId] = source.overview.links.filter((edge) => {
      if (this.isUnobservedCategory(edge.source) !== true && this.isUnobservedCategory(edge.target) !== true) {
        return true;
      } else {
        unobserved[constants.overviewId][constants.linkId].push(edge);
        return constants.keepUnobserved;
      }
    });

    overview[constants.nodeId] = source.overview.nodes.filter((node) => {
      if (this.isUnobservedCategory(node.category) !== true) {
        return true;
      } else {
        unobserved[constants.overviewId][constants.nodeId].push(node);
        return constants.keepUnobserved;
      }
    });
    obj[constants.nodeId]=nodes;
    obj[constants.linkId] = links;
    
    return this.clone(obj);

  },
  getVisualIndex(indices, index) {
    if (!indices) {
      return index;
    }
    var r = index;
    var found = false;
    for (var i = 0; !found && i < indices.length; i++) {
      if (indices[i] === index) {
        r = i;
        found = true;
      }
    }

    return r;
  },

  isEmpty(input) {
    if (
      input === undefined ||
      input === constants.emptyString ||
      input === null
    ) {
      return true;
    }
    return false;
  },

  isNodeInView(x1, y1, x2, y2, px, py, x, y) {
    // side bar width is 300px
    x1 = x1 + 300;
    x = x + px;
    y = y + py;

    if (px < 0) {
      x2 = x2 + px;
    } else if (px > 0) {
      x1 = x1 + px;
    }

    if (py < 0) {
      y2 = y2 + py;
    } else if (py > 0) {
      y1 = y1 + py;
    }
    return x <= x2 && x >= x1 && y <= y2 && y >= y1;
  },

  // getNodeVirtualStatus(nodeIndex) {
  //   const series = Variables.chart.getModel().getSeriesByIndex(0);
  //   const layoutData = series.getData();
  //   const indices = layoutData._indices;
  //   const itemVisuals = layoutData._itemVisuals;
  //   const viewRect = series.coordinateSystem._viewRect;
  //   const x2 = viewRect.x + viewRect.width;
  //   const y2 = viewRect.y + viewRect.height;
  //   const x1 = viewRect.x;
  //   const y1 = viewRect.y;
  //   const x = layoutData._itemLayouts[nodeIndex][0];
  //   const y = layoutData._itemLayouts[nodeIndex][1];
  //   const px = series.coordinateSystem.position[0];
  //   const py = series.coordinateSystem.position[1];

  //   if (this.isEmpty(indices) === true) {
  //     if (itemVisuals[nodeIndex].symbolSize === 0) {
  //       return isNodeInView(x1, y1, x2, y2, px, py, x, y)
  //         ? constants.dataNodeIsFolded
  //         : constants.dataNodeIsOutOfViewPortAndFolded;
  //     }
  //     return isNodeInView(x1, y1, x2, y2, px, py, x, y)
  //       ? constants.dataNodeIsShown
  //       : constants.dataNodeIsOutOfViewPort;
  //   } else {
  //     let dataIndex = getVisualIndex(indices, nodeIndex);
  //     if (indices[dataIndex] === nodeIndex) {
  //       // found it
  //       if (itemVisuals[dataIndex].symbolSize === 0) {
  //         return isNodeInView(x1, y1, x2, y2, px, py, x, y)
  //           ? constants.dataNodeIsFolded
  //           : constants.dataNodeIsOutOfViewPortAndFolded;
  //       } else {
  //         return isNodeInView(x1, y1, x2, y2, px, py, x, y)
  //           ? constants.dataNodeIsShown
  //           : constants.dataNodeIsOutOfViewPortAndHidden;
  //       }
  //     } else {
  //       // hidden
  //       return isNodeInView(x1, y1, x2, y2, px, py, x, y)
  //         ? constants.dataNodeIsHidden
  //         : constants.dataNodeIsOutOfViewPortAndHidden;
  //     }
  //   }
  // },

  // showConfirmDialog(header, message) {
  //   $("#modal-confirm-radio-group").hide();
  //   $("#modal-confirm-dialog-heading").text(header);
  //   $("#modal-confirm-dialog-content").text(message);
  //   $("#modal-confirm-dialog").show();

  //   return new Promise((resolve, reject) => {
  //     // listeners
  //     $("#modal-confirm-dialog").on("click", ".bx--modal-close", function () {
  //       $("#modal-confirm-dialog").off("click", ".bx--modal-close");
  //       $("#modal-confirm-dialog").hide();
  //       reject({});
  //     });
  //     $("#modal-confirm-dialog").on("click", ".bx--btn--secondary", function () {
  //       $("#modal-confirm-dialog").off("click", ".bx--btn--secondary");
  //       $("#modal-confirm-dialog").hide();
  //       reject({});
  //     });
  //     $("#modal-confirm-dialog").on("click", ".bx--btn--primary", function () {
  //       $("#modal-confirm-dialog").off("click", ".bx--btn--primary");
  //       $("#modal-confirm-dialog").hide();
  //       resolve({});
  //     });
  //   });
  // },

  isClusterNode(node) {
    return node.filepath === constants.clusterIdentifier;
  },

  isPartitionNameValid(name) {
    // reg_exp: starts with A-z
    //          any characters between A-z, _, -, 0-9 and .
    //          ends with A-z and 0-9
    if (
      this.isEmpty(name) !== true &&
      /^[a-zA-Z]([A-z0-9.-]*[a-zA-Z0-9])?$/.test(name) !== true
    ) {
      return false;
    } else {
      return true;
    }
  },

  // setWarningMessage(parentId, message) {
  //   const warningEle = $(parentId).find('.m2m-form-warning');
  //   let html = '';
  //   if (isEmpty(message) !== true) {
  //     html += '<span>' + message + '</span?>';
  //   }
  //   warningEle.html(html);
  // },

  // JSON format:
  //  {
  //     nodes: [
  //       {
  //         data: {
  //           id: name,
  //           parent: category or cluster,
  //           ...
  //         }
  //       },
  //       ...
  //     ],
  //     edges: [
  //       {
  //         id: array index,
  //         source: ,
  //         target:
  //         ...
  //       },
  //       ...
  //     ]
  //  }
  convertData(nodes, links) {
    const elements = {
      nodes: [],
      edges: [],
    };

    nodes.forEach((node) => {
      var ele = {
        data: node,
      };
      var collapse;
      if (Object.hasOwnProperty.call(ele.data, "cydata")) {
        for (var attr in ele.data.cydata) {
          if (attr == "collapse") {
            collapse = ele.data.cydata[attr];
          }
          if (Object.hasOwnProperty.call(ele.data.cydata, attr)) {
            ele[attr] = ele.data.cydata[attr];
          }
        }
        delete ele.data.cydata;
      }
      ele.data.name = node.name || node.id;
      ele.data.id = node.id || node.name;
      if (collapse) {
        ele.data.collapse = collapse;
      }
      ele.data.category = node.category || node.group_label;
      if (this.isClusterNode(node) !== true) {
        ele.data.parent = ele.data.category;
        if (ele.data.id !== ele.data.category) {
          elements.nodes.push(ele);
        }
      } else {
        elements.nodes.push(ele);
      }
    });
    links.forEach((link, idx) => {
      var ele = {
        data: link,
      };
      ele.data.id = idx;
      elements.edges.push(ele);
    });

    return elements;
  },

  getUnobservedNodes(cy) {
    var cynodes = this.getAllNodes(cy);
    var nodes = [];
    cynodes.forEach((n) => {
      var node = n.json();
      const data = node.data;
      if ( data[constants.unobservedId] ) {
        nodes.push(data);
      }
    });
    return nodes;
  },

  /** convert data back to original format, need to remove overview nodes back to overview property 
   * All partitions must be expanded before calling this method.
   * node positions and other cytoscape attributes are be saved to cydata property.
   * 
   * Example:
   * 
   * utils.unconvertData(
                "myview",
                cy,
                me.$store.getters.getJson[me.$store.getters.getKey]["overview"][
                  "links"
                ],
                true
              );
  */
  unconvertData(viewName, cy, overviewLinksJSON, saveToLocalFile) {
    var overviewLinks = JSON.parse(JSON.stringify(overviewLinksJSON));

    var data = { nodes: [], links: [], overview: { nodes: [], links: [] } };

    var cynodes = this.getAllNodes(cy);
    var partitionPositionDelta = {};

    //var collapsedNodes = cynodes.filter(".cy-expand-collapse-collapsed-node");
    //collapsedNodes.data("collapse", true);

    // restore nodes
    cynodes.forEach((n) => {
      var node = n.json();
      var nodeData = node.data;
      var element = {};
      var cydata = {};

      for (var attr in nodeData) {
        if (
          Object.prototype.hasOwnProperty.call(nodeData, attr) &&
          attr !== "collapsedChildren"
        ) {
          if (
            attr == "collapse" &&
            n.hasClass("cy-expand-collapse-collapsed-node")
          ) {
            cydata["collapse"] = true;
            partitionPositionDelta[nodeData.name] =
              {x: node.position.x - nodeData["position-before-collapse"].x, y: node.position.y - nodeData["position-before-collapse"].y};
          } else if (
            attr !== "position-before-collapse" &&
            attr !== "size-before-collapse"
          ) {
            element[attr] = nodeData[attr];
          }
        }
      }

      for (attr in node) {
        if (
          Object.prototype.hasOwnProperty.call(node, attr) &&
          attr !== "data"
        ) {
          if (attr == "classes") {
            var array = node[attr].split(" ");
            array = array.filter(function(item) {
              return item !== "cy-expand-collapse-collapsed-node";
            });
            cydata[attr] = array.join(" ");
          } else {
            cydata[attr] = node[attr];
          }
        }
      }
      element["cydata"] = cydata;
      delete element.id;
      if (element.category === element.name) {
        if (element.category !== undefined) {
          console.log("save to overview " + element.name);
          data.overview.nodes.push(JSON.parse(JSON.stringify(element)));
        }
      } else {
        // we may have unobserved displayed in the BL or NS views, so we should exclude these nodes
        // and the unobserved nodes will be added as whole from source json later in this function
        if ( constants.keepUnobserved === false && viewName!=="custom_view" ) {
          // BL or NS view
          // Check if it is unobserved and if it has no parent
          if ( element[constants.unobservedId] !== true ) {
            // fix parent
            delete element.parent;
            data.nodes.push(JSON.parse(JSON.stringify(element)));
          }
        } else {
          // fix parent
          delete element.parent;
          data.nodes.push(JSON.parse(JSON.stringify(element)));
        }
        
      }
    });

    // fix collapsed nodes positions by adding delat to the children
    if ( Object.keys(partitionPositionDelta).length>0) {
      data.nodes.forEach((n) => {
        const category = n.category;
        const delta = partitionPositionDelta[category];
        if (delta) {
          n.cydata.position.x = n.cydata.position.x + delta.x;
          n.cydata.position.y = n.cydata.position.y + delta.y;
        }
      });
    }

    // restore links
    //const cylinks = cy.edges().jsons();
    const cylinks = this.getAllEdges(cy, {includePartitionEdges: false});
    console.log("edge size=" + cylinks.length);
    cylinks.forEach((n) => {
      if ( true === n.data("dep")) {
        //console.log("dep node, skip");
        return;
      }
      const node = n.json();
      //console.log(node);

      var nodeData = node.data;
      var element = {};

      for (var attr in nodeData) {
        if (Object.prototype.hasOwnProperty.call(nodeData, attr)) {
          if (attr !== "originalEnds") {
            if (attr == "source" || attr === "target") {
              if (!element[attr]) {
                element[attr] = nodeData[attr];
              }
            } else {
              element[attr] = nodeData[attr];
            }
          } else if (attr === "originalEnds" && nodeData[attr]) {
            // originalEnds
            const source = nodeData[attr].source.data("name");
            const target = nodeData[attr].target.data("name");
            element["source"] = source;
            element["target"] = target;
          }
        }
      }

      var cydata = {};
      for (attr in node) {
        if (
          Object.prototype.hasOwnProperty.call(node, attr) &&
          attr !== "data"
        ) {
          if (attr == "classes") {
            cydata[attr] = node[attr].replace(
              "cy-expand-collapse-meta-edge",
              ""
            );
          } else {
            cydata[attr] = node[attr];
          }
        }
        element["cydata"] = cydata;
      }
      data.links.push(JSON.parse(JSON.stringify(element)));
    });

    // copy overview data back
    data.overview.links = overviewLinks;

    // add back unobserved from original json data if keep unobserved is false

    if ( constants.keepUnobserved === false && store.getters.getJson[viewName] ) {
      // copy the unobserved data over
      const originalData = store.getters.getJson[viewName];
      const splitedData = this.clone( this.splitUnobservedSource(originalData) );
      var unobserved = splitedData.unobserved;
      data[constants.nodeId] = data[constants.nodeId].concat(unobserved[constants.nodeId]);
      data[constants.linkId] = data[constants.linkId].concat(unobserved[constants.linkId]);
      data[constants.overviewId][constants.nodeId] = data[constants.overviewId][constants.nodeId].concat(unobserved[constants.overviewId][constants.nodeId]);
      data[constants.overviewId][constants.linkId] = data[constants.overviewId][constants.linkId].concat(unobserved[constants.overviewId][constants.linkId]);
    }
    console.log(data);
    if (saveToLocalFile) {
      var jsondata = {};
      jsondata[viewName] = data;
      var a = document.createElement("a");
      var file = new Blob([JSON.stringify(jsondata)], {
        type: "application/json",
      });
      a.href = URL.createObjectURL(file);
      a.download = "saved.json";
      a.click();
    } else {
      return data;
    }
  },

  /**
   * Get all edges from current view
   *
   * @param {Object} cy CY object
   */
  getAllEdges(cy, options) {
    let elements = cy.collection();
    if (cy) {
      let shownElements = cy.edges();
      if (!options || options.includeDataDependencyEdges !== true) {
        shownElements = shownElements.filter((edge) => {
          return edge.data("dep") !== true;
        });
      }
      elements = elements.union(shownElements);
      if (options && options.includePartitionEdges === false) {
        elements = elements.not(".cy-expand-collapse-collapsed-edge");
      }
      shownElements.filter(".cy-expand-collapse-collapsed-edge").forEach((edge) => {
        const collapsedEdges = edge.data().collapsedEdges;
        if (collapsedEdges) {
          elements = elements.add(collapsedEdges);
        }
      });
      const allCollapsedEdges = this.getAllCollapsedPartitionsChildren(cy).filter("edge");
      elements = elements.union(allCollapsedEdges);
      if (!options || options.includeDataDependencyEdges !== true) {
        elements = elements.filter((edge) => {
          return edge.data("dep") !== true;
        });
      }
    }
    return elements;
  },

  getAllNodes(cy) {
    return cy.nodes().union(this.getAllCollapsedPartitionsChildren(cy).filter("node"));
  },

  getAllElements(cy, options) {
    const opts = (options && options.includeDataDependencyEdges === true) ? {includeDataDependencyEdges: true} : null;
    return this.getAllNodes(cy).union(this.getAllEdges(cy, opts));
  },

  getAllCollapsedPartitionsChildren(cy) {
    var collapsedChildren = cy.collection();
    var collapsedNodes = cy.nodes(".cy-expand-collapse-collapsed-node");
    collapsedNodes.forEach(node => {
      collapsedChildren = collapsedChildren.union(node.data('collapsedChildren') || []);
    });
    return collapsedChildren;
  },

  getCollapsedPartitionChildren(partition) {
    return partition.data('collapsedChildren') || partition.cy().collection();
  },

  getAllCrossPartitionEdges(cy) {
    let edges = cy.edges().filter((edge) => {
      return ((edge.data("dep") !== true) && (
        edge.hasClass("cy-expand-collapse-collapsed-edge") ||
        edge.hasClass("cy-expand-collapse-meta-edge") ||
        edge.source().data("parent") !== edge.target().data("parent")
      ));
    });
    let crossEdges = edges.not(".cy-expand-collapse-collapsed-edge");
    edges.filter(".cy-expand-collapse-collapsed-edge").each((edge) => {
      const collapsedEdges = edge.data().collapsedEdges;
      if (collapsedEdges) {
        crossEdges = crossEdges.add(collapsedEdges);
      }
    });
    return crossEdges;
  },

  getVisibleNodeByNameOrVisibleParent(cy, nodeName) {
    const selector = `[name="${nodeName}"]`;
    let nodeEles = cy.nodes(selector);
    if (!nodeEles || nodeEles.length < 1) {
      var allCollapsedChildren = this.getAllCollapsedPartitionsChildren(cy).filter("node");
      nodeEles = allCollapsedChildren.filter(selector);
      if ( nodeEles && nodeEles.length > 0 ) {
        return this.getNodeParent(nodeEles[0]);
      }
      return null;
    } else {
      return nodeEles && nodeEles.length > 0 ? nodeEles[0] : null;
    }
  },

  getNodeByName(cy, nodeName) {
    const selector = `[name="${nodeName}"]`;
    let nodeEles = cy.nodes(selector);
    if (!nodeEles || nodeEles.length < 1) {
      var allCollapsedChildren = this.getAllCollapsedPartitionsChildren(cy).filter("node");
      nodeEles = allCollapsedChildren.filter(selector);
    }
    return nodeEles && nodeEles.length > 0 ? nodeEles[0] : null;
  },

  getNodesByNames(cy, nodeNames) {
    if (nodeNames.length > 0) {
      let selector = '';
      nodeNames.forEach((name, idx) => {
        if (idx !== 0) {
          selector = selector + ', ';
        }
        selector = selector + `node[name="${name}"]`;
      });
      return this.getAllNodes(cy).filter(selector);
    }
    return null;
  },

  getNodeParent(node) {
    let parentNode = node.parent();
    if (!parentNode || parentNode.length < 1) {
      const expandCollapseExtension = node.cy().expandCollapse("get");
      parentNode = expandCollapseExtension.getParent(node.data("id"));
    }
    return parentNode && parentNode.length > 0 ? parentNode[0] : null;
  },

  isCollapsedNode(nodeEle) {
    // the class should be added by expand-collapse extension when the compound is collapsed
    return nodeEle.hasClass("cy-expand-collapse-collapsed-node");
  },

  isExpandedPartition(nodeEle) {
    return (
      nodeEle.isParent() === true && this.isCollapsedNode(nodeEle) === false
    );
  },

  isChildlessPartition(nodeEle) {
    // no parent, no child, not collapsed
    return (
      nodeEle.parent().length < 1 &&
      nodeEle.isChildless() === true &&
      this.isCollapsedNode(nodeEle) !== true
    );
  },

  isParentNode(nodeEle) {
    return (
      nodeEle.isParent() === true ||
      nodeEle.hasClass("cy-expand-collapse-collapsed-node") === true
    );
  },

  processStringForHtml(string) {
    return string.replace(/[&<>"'/]/g, function (s) {
      return _entityMap[s];
    });
  },

  getIsolatedClasses(nodes, links) {
    let isolatedClasses = [];
    nodes.forEach((node) => {
      let match = false;
      const name = node.name;
      links.every((link) => {
        const source = link.source;
        const target = link.target;
        if (source === name || target === name) {
          //console.log('------- a match is found; break');
          match = true;
          // return false to stop the loop
          return false;
        } else {
          // reture true to continue the loop
          return true;
        }
      });
      if (!match && this.isClusterNode(node) !== true) {
        isolatedClasses.push(node);
      }
    });

    return isolatedClasses;
  },

  isGreyedOutNode(nodeEle) {
    return (
      nodeEle.hasClass(constants.filterTypePartition) ||
      nodeEle.hasClass(constants.m2mFilteredCollapsedNodeClass)
    );
  },

  isGreyedOutElement(ele) {
    if (ele.isEdge() === true) {
      return (
        this.isGreyedOutNode(ele.source()) || this.isGreyedOutNode(ele.target())
      );
    } else if (ele.isNode() === true) {
      return this.isGreyedOutNode(ele);
    }
    return false;
  },

  isFilteredNode(nodeEle) {
    const filterClasses = this.getAllFilterClassesForNodes(); 
    return nodeEle.classes().some(c=> filterClasses.indexOf(c) >= 0);
  },
  
  isFilteredElement(ele) {
    if (ele.isEdge() === true) {
      const filterClasses = this.getAllFilterClassesForEdges();
      if (ele.classes().some(c=> filterClasses.indexOf(c) >= 0) === true) {
        return true;
      }
      return (this.isFilteredNode(ele.source()) || this.isFilteredNode(ele.target()));
    } else if (ele.isNode() === true) {
      return this.isFilteredNode(ele);
    }
    return false;
  },

  getAllFilterClasses() {
    return this.mergeArrays(this.getAllFilterClassesForNodes(), this.getAllFilterClassesForEdges());
  },

  getAllFilterClassesForNodes() {
    return [
      constants.m2mFilteredCollapsedNodeClass, 
      constants.filterTypePartition,
      constants.filterTypeUseCase,
      constants.filterTypeLabel,
    ];
  },

  getAllFilterClassesForEdges() {
    return [
      constants.filterTypeRuntimeCall,
      constants.filterTypeShowEdge,
    ];
  },

  getPartitionSymbolSize(classNumber) {
    return Math.sqrt(classNumber) * 10 + constants.defaultPartitionSize;
  },

  getChildrenNode(partitionEle) {
    if (this.isCollapsedNode(partitionEle) === true) {
      return partitionEle.data("collapsedChildren").filter("node");
    } else {
      return partitionEle.children();
    }
  },

  drawClassNode(width, height, r, color) {
    return `<svg width="${width}" height="${height}"><circle cx="50%" cy="50%" r="${r}" fill="${color}"/></svg>`;
  },

  drawPartitionNode(width, height, r, sw, color) {
    return `<svg width="${width}" height="${height}">
              <circle cx="50%" cy="50%" r="${r}" stroke="${color}" stroke-width="${sw}" fill="transparent"></circle>
            </svg>`;
  },

  getNodeLabel(name, construct) {
    // let label = "";
    // if (name && name.length > 0) {
    // let label = name;
      if (construct && construct.trim().length > 0 && construct !== "Class") {
        return `${name} (${construct})`;
      }
    // }
    return name;
  },

  getHighlightClasses() {
    const classes = this.getHighlightClassesForEdge();
    classes.push(constants.m2mSearchedClass);
    return classes;
  },

  getHighlightClassesForEdge() {
    return [
      constants.m2mHighlightedClass, 
      constants.m2mHoverClass, 
      constants.m2mHighlightedFocusedClass,
      constants.m2mHoveredFocusedClass,
    ];
  },

  isHighlightedElement(ele) {
    const highlightClasses = this.getHighlightClasses();
    return ele.classes().some(s => highlightClasses.indexOf(s) >= 0);
  },

  isFocusedNode(node) {
    return (
      node.hasClass(constants.m2mHighlightedFocusedClass) ||
      node.hasClass(constants.m2mSearchedClass) ||
      node.hasClass(constants.m2mHoveredFocusedClass)
    );
  },

  isFocusedElement(ele) {
    return this.isFocusedNode(ele);
  },

  isHighlightedFocusedElement(node) {
    return (
      node.hasClass(constants.m2mHighlightedFocusedClass) ||
      node.hasClass(constants.m2mSearchedClass)
    );
  },

  getFadeOutClasses() {
    return [
      constants.m2mFadeOutClass, 
      constants.m2mPartitionFadeOutClass, 
    ];
  },

  hasFocusedNodes(cyObj) {
    const cy = cyObj || store.getters.getCYInstance();
    return cy.nodes(`.${constants.m2mHighlightedFocusedClass}, .${constants.m2mSearchedClass}`).length > 0;
  },

  fadeOutElements(cy) {
    const visibleElements = cy.elements().filter(ele => this.isVisible(ele));
    const highlightedEles = visibleElements.filter(`.${constants.m2mHighlightedClass}, .${constants.m2mSearchedClass}, 
                                       .${constants.m2mHighlightedFocusedClass}`);
    let highlightedNodes = highlightedEles.filter("node");
    const highlightedEdges = highlightedEles.filter("edge");
    const highlightedParentNodes = highlightedNodes.parent();
    const highlightedCategory = []; 
    highlightedParentNodes.forEach(parent => {
      highlightedCategory.push(parent.data("category"));
    });
    const parentNodes = visibleElements.nodes(':parent, .cy-expand-collapse-collapsed-node');
    // parent nodes and "closed&unhighlighted" nodes 
    let fadeOutElements = parentNodes.difference(highlightedParentNodes).difference(highlightedNodes);  // add fadeout parent nodes
    fadeOutElements = fadeOutElements.add(visibleElements.edges().difference(highlightedEdges)); // add fadeout edges
    highlightedNodes = highlightedNodes.add(parentNodes);
    fadeOutElements = fadeOutElements.add(visibleElements.nodes().difference(highlightedNodes).filter(node => {  // each class node
      return highlightedCategory.indexOf(node.data("category")) !== -1;
    }));
    highlightedParentNodes.addClass(constants.m2mPartitionFadeOutClass);
    fadeOutElements.addClass(constants.m2mFadeOutClass); //.ungrabify().unselectify();

    // highlightedEles = highlightedEles.add(highlightedNodes.parent());
    // cy.elements().difference(highlightedEles).addClass(constants.m2mFadeOutClass); //.ungrabify().unselectify();
  },

  fadeInElements(cy) {
    const fadeoutClasses = this.getFadeOutClasses();
    cy.elements(this.getClassSelectorForElement('', fadeoutClasses)).removeClass(fadeoutClasses); //.grabify().selectify();
  },

  hexColorToRgbString(hex) {
    if (hex.indexOf("#") === 0) {
      hex = hex.substring(1);
    }
    const int = parseInt(hex, 16);
    var r = (int >> 16) & 255;
    var g = (int >> 8) & 255;
    var b = int & 255;

    return `rgb(${r},${g},${b})`;
  },

  getNodeColor(node) {
    const data = node.data();
    if (data.ui_partitionColor) {
      return data.ui_partitionColor;
    }
    const cy = node.cy();
    let parentNode = node.parent();
    if (parentNode && parentNode.length > 0) {
      // utility class in utility partition, return the color from origin partition 
      if (data[constants.originalPartitionId] && this.hasUtilityTag(parentNode.data('tags')) === true) {
        let nodeEles = cy.nodes(`[name="${data[constants.originalPartitionId]}"]`);
        if (nodeEles && nodeEles.length > 0) {
          return this.getNodeColor(nodeEles);
        }
      }
      return this.getNodeColor(parentNode);
    }
    if ( /*parentNode && parentNode.length === 0 &&*/ data[constants.unobservedId] === true) {
      return store.getters.getColorInfo.unobserved;
    }
    const categoryElement = cy.nodes().filter(function (e) {
        return e.data("name") === data.category;
      });
    return categoryElement.data("ui_partitionColor");
  },

  debugVisibility(visibility) {
    if (visibility === constants.dataNodeIsCollapsed) {
      console.log(">>>>>> Node is collapsed <<<<<<");
    } else if (visibility === constants.dataNodeIsFilteredOut) {
      console.log(">>>>>> Node is filtered out <<<<<<");
    } else if (visibility === constants.dataNodeIsFilteredOutAndCollapsed) {
      console.log(">>>>>> Node is filtered out and collapsed <<<<<<");
    } else if (visibility === constants.dataNodeIsNotFound) {
      console.log(">>>>>> Node is not found <<<<<<");
    } else if (visibility === constants.dataNodeIsOutOfViewPort) {
      console.log(">>>>>> Node is out of viewport <<<<<<");
    } else if (visibility === constants.dataNodeIsOutOfViewPortAndCollapsed) {
      console.log(">>>>>> Node is out of viewport and collapsed <<<<<<");
    } else if (visibility === constants.dataNodeIsOutOfViewPortAndFilteredOut) {
      console.log(">>>>>> Node is out of viewport and filtered out <<<<<<");
    } else if (
      visibility === constants.dataNodeIsOutOfViewPortAndFilteredOutAndCollapsed
    ) {
      console.log(
        ">>>>>> Node is out of viewport and filtered out and collapsed <<<<<<"
      );
    } else if (visibility === constants.dataNodeIsShown) {
      console.log(">>>>>> Node is shown <<<<<<");
    }
  },

  sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  },

  clone(obj) {
    return JSON.parse(JSON.stringify(obj));
  },


  // mergeInUnobservedData(data, unobserved) {
  //   data.nodes = data.nodes.concat(unobserved.nodes);
  //   data.links = data.links.concat(unobserved.links);
  //   return data;
  // },

  mergeArrays(array1, array2) {
    return array1.concat(array2.filter((item) => array1.indexOf(item) < 0))
  },

  getScratch (cy, name) {
    if (cy.scratch('_cyM2M') === undefined) {
      cy.scratch('_cyM2M', {});
    }
    var scratch = cy.scratch('_cyM2M');
    if (name && name.length > 0) {
      scratch[name] = scratch[name] || {};
      return scratch[name];
    } else {
      return scratch;
    }
  },

  shiftElements(eles) {
    if (eles.empty() !== true) {
      const shiftX = Math.max(0.43, Math.min(1, 1 / (eles[0].cy().zoom() / 0.13)));
      eles.shift({ x: shiftX, y: 0 });
    }
  },

  shiftPartitions(cyObj) {
    const cy = cyObj || store.getters.getCYInstance();
    const partitions = cy.nodes().filter(node => this.isParentNode(node) && this.isVisible(node));
    this.shiftElements(partitions);
  },

  filterElements(allElements, shownElements, filterClass) {
    shownElements.filter(`.${filterClass}`).removeClass(filterClass);
    // work around: have to do a small shift in order to make all edges shown properly
    // this.shiftElements(shownNodes.filter(node => !node.removed() && this.isVisible(node)));
    if (allElements.length !== shownElements.length) {
      allElements.difference(shownElements).difference(`.${filterClass}`).addClass(filterClass);
    }
  },

  applyPartitionFilters(nodes, filters) {
    let shownNodes = nodes;
    if (filters.length > 0) {
      var nodeSelector = '';
      var groupSelector = '';
      filters.forEach((filter, idx) => {
        if (idx !== 0) {
          nodeSelector += ', ';
          groupSelector += ', ';
        }
        nodeSelector += `node[category="${filter}"]`;
        groupSelector += `node[name="${filter}"]`;
      });
      // all partition nodes and expanded nodes,  not including collapsed child nodes
      shownNodes = nodes.filter(`${nodeSelector}, ${groupSelector}`);
      if (constants.keepUnobserved !== true) {
        const unobservedNodes = nodes.filter((node) => {
          return !node.data("parent") && true === node.data("unobserved");
        });
        shownNodes = shownNodes.union(unobservedNodes); // always show unobserved nodes which are in graph
      } 
    }
    // shownNodes = shownNodes.add(this.getParentNodes(shownNodes)); // make sure to show the parent partition
    this.filterElements(nodes, shownNodes, constants.filterTypePartition);
    return shownNodes;
  },

  applyUseCaseFilters(nodes, filters) {
    let shownNodes = (filters.length === 0) ? nodes : nodes.filter(node => {
      const semantics = node.data('semantics');
      if (semantics && Array.isArray(semantics) && semantics.length > 0) {
        return semantics.some(s=> filters.indexOf(s) >= 0)
      }
      return false;
    });
    // shownNodes = shownNodes.add(this.getParentNodes(shownNodes)); // make sure to show the parent partition
    this.filterElements(nodes, shownNodes, constants.filterTypeUseCase);
    return shownNodes;
  },

  applyRuntimeCallFilters(edges, filters) {
    const shownEdges = edges.filter(edge => {
      const calls = this.getEdgeCallNumber(edge);
      return (calls >= filters[0] && calls <= filters[1]);
    });
    this.filterElements(edges, shownEdges, constants.filterTypeRuntimeCall);
    return shownEdges;
  },

  applyLabelFilters(nodes, filters) {
    const labelInfo = store.getters.getLabels;
    let shownClasses = new Set();
    labelInfo.forEach(label => {
      if (filters.indexOf(label.name) >= 0) {
        shownClasses = new Set([ ...shownClasses, ...label.assignedClasses ]);
      }
    });
    shownClasses = Array.from(shownClasses);
    const showUOLabel = (filters.indexOf(constants.unobservedLabelFilterValue) >= 0);
    const showUtilityLabel = (filters.indexOf(constants.utilityLabelFilterValue) >= 0);
    let shownNodes = (shownClasses.length === 0 && !showUOLabel && !showUtilityLabel) ? nodes : nodes.filter(node => {
      const data = node.data();
      return (shownClasses.indexOf(data.name) >= 0 || 
          (showUOLabel && data[constants.unobservedId] === true) || 
          (showUtilityLabel && this.hasUtilityTag(data.tags) === true && this.isClusterNode(data) !== true)); // ignore Utility partition
                                                                                                              // Utility partition can have '0' classes
    });
    shownNodes = shownNodes.add(this.getParentNodes(shownNodes)); // make sure to show the parent partition
    this.filterElements(nodes, shownNodes, constants.filterTypeLabel);
    return shownNodes;
  },

  /**
   * Apply 'Show/Hide' dependency filter to edges from current CY
   *
   * @param {collection} edges collection of edge elements
   * @param {string[]} filters filters of edge
   */
  applyShowEdgeFilters(edges, filters) {
    const cy = (edges.empty() !== true) ? edges[0].cy() : store.getters.getCYInstance();
    let shownEdges;
    const showDataDependencies = filters.indexOf(constants.showDataDependencies) > -1;
    if (filters.length < 1) { // no edges to be shown
      shownEdges = cy.collection();
    } else {
      const showUtilityCalls = filters.indexOf(constants.showUtilityCalls) > -1;
      const showRuntimeCalls = filters.indexOf(constants.showRuntimeCalls) > -1;
      const utilityPartitions = this.getAllUtilityPartitionNodes().map(partition => partition.data('name'));
      shownEdges = edges.filter(edge => {
        const data = edge.data();
        if (data.dep === true) {  // data dependency edge
          return showDataDependencies;
        }
        const source = edge.source();
        const target = edge.target();
        if (source.data('category') !== target.data('category') &&  // cross partition calls
            (utilityPartitions.includes(data.source) || utilityPartitions.includes(data.target) ||
             utilityPartitions.includes(source.data('category')) || utilityPartitions.includes(target.data('category')))) {
          return showUtilityCalls;
        }
        return showRuntimeCalls;
      });
    }
    this.filterElements(edges, shownEdges, constants.filterTypeShowEdge);
    store.commit("setDynamicDependencyEdgesForEntireCY", showDataDependencies);
    return shownEdges;
  },

  applyAllFilters(elements) {
    const cy = elements[0].cy();
    const nodes = elements.filter("node");
    const filterStore = this.getScratch(cy, constants.m2mScratchFilters);
    const partitionFilters = filterStore[constants.filterTypePartition];
    if (partitionFilters) {
      this.applyPartitionFilters(nodes, partitionFilters);
    }
    const usecaseFilters = filterStore[constants.filterTypeUseCase];
    if (usecaseFilters) {
      this.applyUseCaseFilters(nodes, usecaseFilters);
    }
    const edges = elements.filter("edge");
    const runtimeCallRange = filterStore[constants.filterTypeRuntimeCall];
    if (runtimeCallRange) {
      this.applyRuntimeCallFilters(edges, runtimeCallRange);
    }
    const labelFilters = filterStore[constants.filterTypeLabel];
    if (labelFilters) {
      this.applyLabelFilters(nodes, labelFilters);
    }
    const showEdgeFilters = filterStore[constants.filterTypeShowEdge] || this.getDefaultShowEdgeFilter();
    if (showEdgeFilters) {
      this.applyShowEdgeFilters(edges, showEdgeFilters);
    }    
  },

  getFunctionCallDelay(cy) {
    const store = this.getScratch(cy);
    let totalEle = store[constants.m2mScratchTotalElements];
    if (!totalEle) {
      totalEle = this.getAllElements(cy, {includeDataDependencyEdges: true}).length;
      store[constants.m2mScratchTotalElements] = totalEle;
    }
    if (totalEle < constants.minElementNumberForBusyIcon) {
      return 0;
    }
    const cyTotalEle = cy.elements().length;
    const delay = Math.max(totalEle / 100, cyTotalEle / 10);
    // delay = Math.min(350, Math.floor(totalEle1 / 100));
    return delay;
  },

  isUnobservedCategory(category) {
    return constants.unobservedKeys.indexOf(category.toLowerCase()) !== -1
  },

  arraysEqual(a, b, sort) {
    if (!a && !b) return true;
    if ((!a && b) || (a && !b) || !Array.isArray(a) || !Array.isArray(b) || a.length !== b.length) return false;
    if (sort === true) {
      a.sort();
      b.sort();
    }
    return a.toString() === b.toString();
  },

  getEdgeCallNumber(edge) {
    if (edge.hasClass("cy-expand-collapse-collapsed-edge") === true) {  // edge connects 2 partition nodes
      let calls = 0;
      const collapsedEdges = edge.data('collapsedEdges');
      if (collapsedEdges) {
        collapsedEdges.forEach((edgeEle) => {
          calls += this.getClassEdgeCallNumber(edgeEle);
        });
      }
      return calls;
    } else {  // edge connects 2 class nodes or connects to class and partition nodes
      return this.getClassEdgeCallNumber(edge);
    }
  },

  getClassEdgeCallNumber(edge) {
    /** 
     * can't use 'value' to calculate # of method calls, it's not accurate enough 
     */
    // const value = edge.data('value');
    // if (value) {
    //   return Math.round(Math.exp(value - 1));
    // } else {
        return this.sumRuntimeCalls(edge.data('method'));
    // }
  },

  sumRuntimeCalls(calls) {
    if (calls) {
      return Object.keys(calls).reduce((acc, key) => {
        acc += calls[key];
        return acc;
      }, 0);
    } else {
      return 0;
    }
  },

  getClassSelectorForElement(ele, classes) {
    let selector = '';
    classes.forEach((clazz, idx) => {
      if (idx === 0) {
        selector = `${ele}.${clazz}`;
      } else {
        selector = `${selector},${ele}.${clazz}`;
      }
    });
    return selector;
  },

  getSelectorForNodeNames(names) {
    let selector = '';
    names.forEach((name, idx) => {
      selector += (idx === 0) ? `[name="${name}"]` : `, [name="${name}"]`;
    });
    return selector;    
  },
 
  isVisible(ele) {
    return ele.style('display') !== 'none';
  },

  isShowingDataDependencies() {
    const filterStore = this.getScratch(store.getters.getCYInstance(), constants.m2mScratchFilters);
    return (Object.prototype.hasOwnProperty.call(filterStore, constants.filterTypeShowEdge) && filterStore[constants.filterTypeShowEdge].includes(constants.showDataDependencies));
  },

  getLabelsByClassName(name) {
    const labels = [];
    store.getters.getLabels.forEach(label => {
      if (label.assignedClasses.indexOf(name) >= 0) {
        labels.push(label.name);
      }
    });
    return labels;
  },

  getParentNodeDisplayValue(parent) {
    if (parent.children().some(child => this.isFilteredNode(child) !== true)) {
      return "element";
    }
    return "none"; 
  },

  /**
   * Get parent nodes from giving nodes. 
   *  (nodes.parent() don't work for "removed" nodes)
   *
   * @param {collection} nodes collection of node elements
   */
  getParentNodes(nodes) {
    const cy = (nodes.empty() !== true) ? nodes[0].cy() : store.getters.getCYInstance();
    let parents = cy.collection();
    const parentNames = {};
    nodes.forEach(node => {
      let parentNode = node.parent();
      if (!parentNode || parentNode.empty()) {
        const parent = node.data('parent');
        if (parent && parentNames[parent] !== true) {
          parentNames[parent] = true;
          parentNode = cy.getElementById(parent);
        }
      } else {
        parentNames[parentNode.id()] = true;
      }
      parents = parents.add(parentNode);
    });
    return parents;
  },

  /**
   * Apply 'Show/Hide' dependency filter to edges from current CY
   *
   * @param {collection} edges collection of edge elements
   */
  applyShowDependencyFilter(edges) {
    const depEdges = edges.filter(edge => edge.data("dep") === true);
    if (this.isShowingDataDependencies() === true) {
      depEdges.removeClass("invisible");
      edges.difference(depEdges).addClass("invisible");
    } else {
      depEdges.addClass("invisible");
      edges.difference(depEdges).removeClass("invisible");
    }
  },

  /**
   * Apply 'Show/Hide' dependency filter to edges from current CY
   *
   * @param {object} cyObj cy object or undefined or null
   * @param {object} resetZoomRange true to reset cy zoom level range (if true, GraphNavigator.updateZoomConfig needs to be called to update zoom slider)
   */
  cyFit(cyObj, resetZoomRange) {
    const cy = cyObj || store.getters.getCYInstance();
    // reset cy zoom level range
    if (resetZoomRange === true) {
      cy.minZoom(1e-50);
      cy.maxZoom(1e+50);
    }
    cy.fit();
  },

  /**
   * Check whether the given string array contains 'utility'.
   *
   * @param {string array} tags class node tags 
   */
  hasUtilityTag(tags) {
    return (tags || []).findIndex(tag => tag.toLowerCase() === constants.utilityTag) !== -1;
  },

  /**
   * Get all utility class nodes.
   *
   * @param {object} cy CY object (optional) 
   */
  getAllUtilityNodes(cy) {
    return this.getAllNodes(cy || store.getters.getCYInstance()).filter(node => this.hasUtilityTag(node.data("tags")));
  },

  getDefaultFcoseLayout() {
    return {
      name: "fcose",
      quality: "default",
      randomize: false,
      animate: false,
      animationEasing: "ease-out",
      uniformNodeDimensions: true,
      //packComponents: true,
      //            tile: true,
      nodeRepulsion: 4500,
      idealEdgeLength: 200,
      edgeElasticity: 0.7,
      nestingFactor: 0.2,
      gravity: 0.25,
      gravityRange: 3.8,
      gravityCompound: 1,
      gravityRangeCompound: 1.5,
      numIter: 2500,
      // tilingPaddingVertical: 10,
      // tilingPaddingHorizontal: 10,
      initialEnergyOnIncremental: 0.3,
      avoidOverlap: true, // prevents node overlap, may overflow boundingBox if not enough space
      nodeDimensionsIncludeLabels: true,
      // textureOnViewport: true,
      // hideEdgesOnViewport: true,
      stop: null,
    };
  },

  getCommonItems(arr1, arr2, base) {
    const common = arr1.filter(item => arr2.includes(item));
    return (base) ? common.filter(item => base.includes(item)) : common;
  },

  getDefaultShowEdgeFilter() {
    return [constants.showRuntimeCalls];
  },

  getAllPartitionNodes(cyObj) {
    return (cyObj || store.getters.getCYInstance()).nodes(':parent, .cy-expand-collapse-collapsed-node');
  },

  getAllUtilityPartitionNodes(cyObj) {
    return this.getAllPartitionNodes(cyObj).filter(node => this.hasUtilityTag(node.data('tags')));
  },

};

export default graphUtil;
