/********************************************************************
# Licensed Materials - Property of IBM
#
# (C) Copyright IBM Corp. 2020, 2021, 2022, 2023. All Rights Reserved.
#
# US Government Users Restricted Rights - Use, duplication or
# disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
**********************************************************************/

import tippy from "tippy.js";
import "tippy.js/dist/tippy.css";
import utils from "./graphUtil";
import i18n from "@/plugins/i18n";
import constants from "../constants";

const _replace_me = "__REPLACE_ME__";
const _MAX_WIDTH = 600;

var tooltipDiv;
var tooltipInstance;
var dummyDomEle = document.createElement("div"); // unfortunately, a dummy element must be passed
// as tippy only accepts a dom element as the target
// https://github.com/atomiks/tippyjs/issues/661

const tooltipHandler = {
  show(evt) {
    var ele = evt.target;
    clearTooltip();
    const html = generateTooltip(ele);
    if (utils.isEmpty(html) !== true) {
      tooltipInstance = createTooltipInstance(evt, html);
      tooltipInstance.show();
    }
  },

  hide() {
    clearTooltip();
  },
};

function clearTooltip() {
  if (tooltipInstance) {
    // tooltipInstance.hide();
    tooltipInstance.destroy();
    tooltipInstance = undefined;
  }
}

function createTooltipInstance(evt, html) {
  //  var ref = ele.popperRef();
  var ref = evt.cy.popperRef({
    renderedPosition: () => evt.renderedPosition,
  });
  return tippy(dummyDomEle, {
    onCreate: function(instance) {
      // mandatory
      instance.popperInstance.reference = ref; // patch the tippy's popper reference so positioning works
      // https://atomiks.github.io/tippyjs/misc/#custom-position
    },
    lazy: false, // have to set it 'false'
    trigger: "manual", // mandatory
    // dom element inside the tippy:
    content: function() {
      if (!tooltipDiv) {
        tooltipDiv = document.createElement("div");
      }
      tooltipDiv.innerHTML = html;
      return tooltipDiv;
    },
    // own preferences:
    arrow: true,
    placement: "auto-start",
    hideOnClick: true,
    multiple: true,
    theme: "m2m-tooltip",
    maxWidth: _MAX_WIDTH,
    appendTo: document.body, // or append dummyDomEle to document.body
    distance: 16,

    //delay: [10000, 0],
    //sticky: false,
    //offset: "8, 8",
    // followCursor: true,
    // inlinePositioning: true,
    // if interactive:
    // interactive: true,
  });
}

function generateTooltip(ele) {
  var html = "<div>";
  if (ele.isEdge() === true) {  // edges
    var sourceNode = ele.source();
    var targetNode = ele.target();
    var title = i18n.t("runtimeCalls");
    const dep = ele.data().dep;
    if (dep === true ) {
      title = i18n.t("dataDependencies");
    }
    
    html += `<div><span class="m2m-tooltip__heading">${title}</span><span class="m2m-tooltip__node-type">${_replace_me}</span></div>`;
    var calls = "";
    const tData = targetNode.data();
    const sData = sourceNode.data()
    if (utils.isClusterNode(tData) !== true && utils.isClusterNode(sData) !== true) {
      const callInfo = getMethodsAndCalls(ele.data().method);
      callInfo.source = sourceNode;
      callInfo.target =  targetNode;
      html += generateRuntimeCallsHtml([callInfo], dep);
      // if (0 != methodInfo.methods) {
      //   html += '<table style="max-width: 500px; margin: 3px;">';
      //   html += `<tr><td>Methods:&nbsp</td><td>${methodInfo.methods}</td></tr></table>`;
      // }
      if (0 != callInfo.calls) {
        calls = `(${callInfo.calls})`;
      }
    } else {
      // link between (class and cluster) or (cluster and cluster)
      const info = getRuntimeCallInfo(ele);
      if (info !== null) {
        html += generateRuntimeCallsHtml(info.callInfo, dep);
        // if (info.totalMethods != 0) {
        //   html += '<table style="max-width: 500px; margin: 3px;">';
        //   html += `<tr><td>Methods:&nbsp</td><td>${info.totalMethods}</td></tr></table>`;
        // }
        if (info.totalCalls > 0) {
          calls = `(${info.totalCalls})`;
        }
      }
    }
    html = html.replace(_replace_me, calls);
  } else if (ele.isNode() === true) { // nodes
    var data = ele.data();
    var classes = "";
    if (utils.isClusterNode(data) === true) { // partition nodes
      if (utils.isCollapsedNode(ele) === true || utils.isChildlessPartition(ele) === true) {
        html += `<div class="m2m-tooltip__wrap_text">${generateNodeIcon(ele)}`;
          // '<div><span style="display: inline-block; vertical-align: middle; padding-right:2px;">' +
          // `${utils.drawClassNode(14, 14, 6, getNodeBackgroundColor(ele))}</span>`;
        html += `<span class="m2m-tooltip__heading_node">${utils.processStringForHtml(
            data.name
          )}</span><span class="m2m-tooltip__node-type">${_replace_me}</span></div>` +
          `<table class="m2m-tooltip__table" style="max-width: ${_MAX_WIDTH}px;">`;
        const number = ele.data("ui_classNumber");
        classes = number == 1 
          ? `(${i18n.t('one-class')})`
          : `(${i18n.t('numbered-classes', {numberClasses: number})})`;
      } else {
        return ""; // only show tooltip for collapsed or "empty" partition nodes
      }
    } else {  // class nodes
      html += `<div style="display:inline-flex; align-items: center; vertical-align: top;">${generateNodeIcon(ele)}`;
        // '<div><span class="m2m-tooltip__callinfo_svg" style="vertical-align: middle; padding-right:2px;">' +
        // `${utils.drawClassNode(14, 14, 6, getNodeBackgroundColor(ele))}</span>`;
      html += `<div class="m2m-tooltip__wrap_text"><span class="m2m-tooltip__heading_node">${utils.processStringForHtml(
        data.name
      )}</span><span class="m2m-tooltip__node-type">${_replace_me}</span>`;
      if (data.construct && data.construct.trim().length > 0 && data.construct !== "Class") {
        html += `<span>(type: ${utils.processStringForHtml(
          data.construct
        )})</span>`;
      }
      html += `</div></div><table class="m2m-tooltip__table" style="max-width: ${_MAX_WIDTH}px;">`;
      // html += `<tr><td>Partition:&nbsp</td><td>${data.category}</td></tr>`;
      // if (utils.isEmpty(data.construct) !== true) {
      //   html += `<tr><td>Construct:&nbsp</td><td>${data.construct}</td></tr>`;
      // }
      // if (utils.isEmpty(data.FQCN) !== true) {
      //   const fq = data.FQCN.replace(/\./g, ".&#8203;");
      //   html += `<tr><td>FQCN:</td><td>${fq}</td></tr>`;
      // }
      // const path = data.filepath.replace(/\/|\\/g, "/&#8203;");
      // html += `<tr><td>Path:</td><td>${path}</td></tr>`;
    }
    if (data.semantics && data.semantics.length > 0) {
      let useCases = "";
      if (data.semantics.length > 5) {
        useCases = `${data.semantics.slice(0, 5).join(", ")}...`;
      } else {
        useCases = data.semantics.join(", ");
      }
      html += `<tr><td style="white-space: nowrap;">${i18n.t('use-cases-with-length', {length: data.semantics.length})}&nbsp</td><td style="word-break: break-word">${useCases}</td></tr>`;
      // html +=
      //   '<tr><td class="m2m-table-td" colspan="2"><span style="font-weight: bold;">Business use cases:&nbsp</span><span>' +
      //   data.semantics.join(", ");
      // +"</span></td></tr>";
      // html += `<tr><td colspan="2"><span>No. of use cases:&nbsp</span><span>${data.semantics.length}</span></td></tr>`;
      // if (utils.isClusterNode(data) !== true) {
      //   // show number of business cases
      //   const number = data.semantics.length;
      //   classes = number < 2 ? `(${number} use case)` : `(${number} use cases)`;
      // }
    }
    html += "</table>";
    html = html.replace(_replace_me, classes);
  } else {
    return "";
  }
  html += "</div>";

  return html;
}

function getRuntimeCallInfo(ele) {
  const info = {
    callInfo: [],
  };
  if (ele.hasClass("cy-expand-collapse-meta-edge") === true) {
    // edges from/to collapsed nodes
    info.callInfo.push(getElementRuntimeCallInfo(ele));
  } else if (ele.hasClass("cy-expand-collapse-collapsed-edge") === true) {
    // collapsed edges
    const collapsedEdges = ele.data().collapsedEdges;
    if (collapsedEdges) {
      collapsedEdges.forEach((edgeEle) => {
        info.callInfo.push(getElementRuntimeCallInfo(edgeEle));
      });
    }
  } else {
    return null;
  }

  if (info.callInfo.length > 0) {
    info.callInfo.sort(function(a, b) {
      return b.calls - a.calls;
    });
    const methods = new Set();
    var count = 0;
    info.callInfo.forEach((call) => {
      count += call.calls;
      call.methods.forEach((name) => {
        methods.add(name);
      });
    });
    info.totalCalls = count;
    info.totalMethods = methods.size;
  }

  return info;
}

function getElementRuntimeCallInfo(ele) {
  const info = { methods: [], calls: 0 };
  const data = ele.data();
  const originalEnds = data.originalEnds;
  info.source = originalEnds ? originalEnds.source : ele.source();
  info.target = originalEnds ? originalEnds.target : ele.target();
  if (data.method) {
    info.methods = Object.keys(data.method);
  }
  // info.methods.forEach((key) => {
  //   info.calls += data.method[key];
  // });
  info.calls = utils.sumRuntimeCalls(data.method);
  return info;
}

function getMethodsAndCalls(method) {
  const info = {
    methods: 0,
    calls: 0,
  };
  if (method) {
    const keys = Object.keys(method);
    info.methods = keys.length;
    // keys.forEach(function(key) {
    //   info.calls += method[key];
    // });
    info.calls = utils.sumRuntimeCalls(method);
  }
  return info;
}

function generateRuntimeCallsHtml(runtimeCalls, isDep) {
  var html = '<tr><td colspan="2"><div style="margin-top:0.5rem;">';
  var count = runtimeCalls.length;
  if (count > 3) {
    if (isDep === true) {
      html += `<div style="padding-bottom:0.5rem;">${i18n.t('top-three-data-dependencies')}</div>`;
    } else {
      html += `<div style="padding-bottom:0.5rem;">${i18n.t('top-three-runtime-calls')}</div>`;
    }
    count = 3;
  }
  var sName, tName;
  for (var i = 0; i < count; ++i) {
    sName = utils.processStringForHtml(runtimeCalls[i].source.data('name'));
    tName = utils.processStringForHtml(runtimeCalls[i].target.data('name'));
    html += `<div><div class="m2m-tooltip__wrap_text">${generateNodeIcon(runtimeCalls[i].source)}`;
      // '<div><span class="m2m-tooltip__callinfo_svg" style="vertical-align: middle; padding-right:2px;">' +
      // `${utils.drawClassNode(14, 14, 6, call.sColor)}</span>`;
    html += `<div class="m2m-tooltip__div_align"><span class="m2m-tooltip__callinfo_name">${sName}</span>
              <div style="vertical-align:text-top;height:100%"><span style="margin-left:0.5rem;margin-right:0.5rem;white-space:nowrap;">-></span></div>`;
    html += `<div style="display:inline-flex; align-items: center;">${generateNodeIcon(runtimeCalls[i].target)}`;
      // '<div><span class="m2m-tooltip__callinfo_svg" style="vertical-align: middle; padding-right:2px;">' +
      // `${utils.drawClassNode(14, 14, 6, call.tColor)}</span>`;
    if (count < 2 || runtimeCalls[i].calls === 0) {
      html += `<span class="m2m-tooltip__callinfo_name">${tName}</span></div></div></div></div>`;
    } else {
      html += `<span class="m2m-tooltip__callinfo_name">${tName} (${runtimeCalls[i].calls})</span></div></div></div></div>`;
    }
  }
  html += "</div></td></tr>";
  return html;
}

function getNodeBackgroundColor(node) {
  if (utils.isFocusedNode(node) !== true) {
    return node.style("background-color");
  } else {
    return utils.getNodeColor(node);
  }
}

function generateNodeIcon(node) {
  const data = node.data();
  const color = getNodeBackgroundColor(node);
  let style = `border: 0.125rem solid ${color}`;
  let icon;
  if (utils.isClusterNode(data) === true) {
    icon = 'm2m-tooltip__partitionnode_icon';
  } else {
    icon = (utils.hasUtilityTag(data.tags) === true) ? 'm2m-tooltip__classnode_icon_diamond' : 'm2m-tooltip__classnode_icon';
    if (data[constants.unobservedId] !== true) {  // observed class
      style = `background-color:${color};`
    }
  }
  return `<div class="${icon}" style="${style}"></div>`;
}

export default tooltipHandler;
