/********************************************************************
# Licensed Materials - Property of IBM
#
# (C) Copyright IBM Corp. 2020, 2021, 2022. All Rights Reserved.
#
# US Government Users Restricted Rights - Use, duplication or
# disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
**********************************************************************/
const expandCollapseUtil = {
  collapse (_eles, opts) {
    let nodes;
    if (_eles && _eles.length > 0) {
      const cy = _eles[0].cy();
      var eles = this.collapsibleNodes(cy, _eles);
      var options = this.getScratch(cy, 'options');
      var tempOptions = this.extendOptions(options, opts);
      this.evalOptions(tempOptions);

      //const st = new Date().getTime();
      nodes = this.collapseGivenNodes(cy, eles, tempOptions);
      //console.log("======= collapse: ", nodes.length, new Date().getTime() - st);  
    }

    return nodes;
  },

  collapsibleNodes (cy, _nodes) {
    var nodes = _nodes ? _nodes : cy.nodes();
    let me = this;
    return nodes.filter(function (ele, i) {
      if(typeof ele === "number") {
        ele = i;
      }
      return me.isCollapsible(ele);
    });
  },

  isCollapsible (node) {
    return !this.isExpandable(node) && node.isParent();
  },

  isExpandable (node) {
    return node.hasClass('cy-expand-collapse-collapsed-node');
  },

  getScratch (cyOrEle, name) {
    if (cyOrEle.scratch('_cyExpandCollapse') === undefined) {
      cyOrEle.scratch('_cyExpandCollapse', {});
    }
    var scratch = cyOrEle.scratch('_cyExpandCollapse');
    var retVal = ( name === undefined ) ? scratch : scratch[name];
    return retVal;
  },

  extendOptions(options, extendBy) {
    var tempOpts = {};
    for (let key in options) {
      tempOpts[key] = options[key];
    }

    for (let key in extendBy) {
      if (Object.prototype.hasOwnProperty.call(tempOpts, key)) {
        tempOpts[key] = extendBy[key];
      }
    }
    return tempOpts;
  },
  
  evalOptions(options) {
    var animate = typeof options.animate === 'function' ? options.animate.call() : options.animate;
    var fisheye = typeof options.fisheye === 'function' ? options.fisheye.call() : options.fisheye;
    
    options.animate = animate;
    options.fisheye = fisheye;
  },

  collapseGivenNodes(cy, nodes, options) {
    /*
      * In collapse operation there is no fisheye view to be applied so there is no animation to be destroyed here. We can do this 
      * in a batch.
      */ 
    cy.startBatch();
    this.simpleCollapseGivenNodes(cy, nodes/*, options*/);
    cy.endBatch();

    nodes.trigger("position"); // position not triggered by default when collapseNode is called
    this.endOperation(cy, options.layoutBy, nodes);

    // Update the style
    cy.style().update();

    /*
      * return the nodes to undo the operation
      */
    return nodes;
  },

  simpleCollapseGivenNodes(cy, nodes) {
    nodes.data("collapse", true);
    var roots = this.getTopMostNodes(nodes);
    for (var i = 0; i < roots.length; i++) {
      var root = roots[i];        
      // Collapse the nodes in bottom up order
      this.collapseBottomUp(cy, root);
    }
    
    return nodes;
  },

  endOperation(cy, layoutBy, nodes) {
    let me = this;
    cy.ready(function () {
      setTimeout(function() {
        me.rearrange(cy, layoutBy);
        if(cy.scratch('_cyExpandCollapse').selectableChanged){
          nodes.selectify();
          cy.scratch('_cyExpandCollapse').selectableChanged = false;
        }
      }, 0);
      
    });
  },

  rearrange(cy, layoutBy) {
    if (typeof layoutBy === "function") {
      layoutBy();
    } else if (layoutBy != null) {
      var layout = cy.layout(layoutBy);
      if (layout && layout.run) {
        layout.run();
      }
    }
  },

  getTopMostNodes(nodes) {
    var nodesMap = {};
    for (var i = 0; i < nodes.length; i++) {
      nodesMap[nodes[i].id()] = true;
    }
    var roots = nodes.filter(function (ele, i) {
      if(typeof ele === "number") {
        ele = i;
      }
      
      var parent = ele.parent()[0];
      while (parent != null) {
        if (nodesMap[parent.id()]) {
          return false;
        }
        parent = parent.parent()[0];
      }
      return true;
    });

    return roots;
  },

  collapseBottomUp(cy, root) {
    var children = root.children();
    for (var i = 0; i < children.length; i++) {
      var node = children[i];
      this.collapseBottomUp(cy, node);
    }
    //If the root is a compound node to be collapsed then collapse it
    if (root.data("collapse") && root.children().length > 0) {
      this.collapseNode(cy, root);
      root.removeData("collapse");
    }
  },

  collapseNode(cy, node) {
    if (node._private.data.collapsedChildren == null) {
      node.data('position-before-collapse', {
        x: node.position().x,
        y: node.position().y
      });

      node.data('size-before-collapse', {
        w: node.outerWidth(),
        h: node.outerHeight()
      });

      var children = node.children();

      children.unselect();
      children.connectedEdges().unselect();

      node.trigger("expandcollapse.beforecollapse");
      
      this.barrowEdgesOfcollapsedChildren(cy, node);
      this.removeChildren(cy, node, node);
      node.addClass('cy-expand-collapse-collapsed-node');

      node.trigger("expandcollapse.aftercollapse");
      
      node.position(node.data('position-before-collapse'));

      //return the node to undo the operation
      return node;
    }
  },

  barrowEdgesOfcollapsedChildren(cy, node) {
    var relatedNodes = node.descendants();
    var edges = relatedNodes.edgesWith(cy.nodes().not(relatedNodes.union(node)));
    
    var relatedNodeMap = {};
    
    relatedNodes.each(function(ele, i) {
      if(typeof ele === "number") {
        ele = i;
      }
      relatedNodeMap[ele.id()] = true;
    });
    
    for (var i = 0; i < edges.length; i++) {
      var edge = edges[i];
      var source = edge.source();
      var target = edge.target();
      
      if (!this.isMetaEdge(edge)) { // is original
        var originalEndsData = {
          source: source,
          target: target
        };
        
        edge.addClass("cy-expand-collapse-meta-edge");
        edge.data('originalEnds', originalEndsData);
      }
      
      edge.move({
        target: !relatedNodeMap[target.id()] ? target.id() : node.id(),
        source: !relatedNodeMap[source.id()] ? source.id() : node.id()
      });
    }
  },

  isMetaEdge(edge) {
    return edge.hasClass("cy-expand-collapse-meta-edge");
  },

  removeChildren(cy, node, root) {
    var children = node.children();
    for (var i = 0; i < children.length; i++) {
      var child = children[i];
      this.removeChildren(cy, child, root);
      var parentData = cy.scratch('_cyExpandCollapse').parentData;
      parentData[child.id()] = child.parent();
      cy.scratch('_cyExpandCollapse').parentData = parentData;
      // var removedChild = child.remove();
      // if (root._private.data.collapsedChildren == null) {
      //   root._private.data.collapsedChildren = removedChild;
      // }
      // else {
      //   root._private.data.collapsedChildren = root._private.data.collapsedChildren.union(removedChild);
      // }
    }
    if (children.length > 0) {
      const removedChildren = children.remove();
      if (root._private.data.collapsedChildren == null) {
        root._private.data.collapsedChildren = removedChildren;
      }
      else {
        root._private.data.collapsedChildren = root._private.data.collapsedChildren.union(removedChildren);
      }
    } 
  },

  /* -------------------------------------- start section edge expand collapse -------------------------------------- */
  /* This is to fix a runtime error in Expand-Collapse extension
  /* ExpandCollapseUtil.collapseGivenEdges(...), line 745: "edgesTypeField = options.edgeTypeInfo instanceof Function ? edgesTypeField : options.edgeTypeInfo;"
  /*
  /* Once the extension fixes the problem, we can remove the section of code */

  collapseEdgesBetweenNodes(nodes, opts) {
    const cy = nodes[0].cy();
    var options = this.getScratch(cy, 'options');
    var tempOptions = this.extendOptions(options, opts);
    function pairwise(list) {
      var pairs = [];
      list
        .slice(0, list.length - 1)
        .forEach(function (first, n) {
          var tail = list.slice(n + 1, list.length);
          tail.forEach(function (item) {
            pairs.push([first, item])
          });
        })
      return pairs;
    }
    var nodesPairs = pairwise(nodes);
    // for self-loops
    nodesPairs.push(...nodes.map(x => [x, x]));
    var result = { edges: cy.collection(), oldEdges: cy.collection() };
    nodesPairs.forEach(function (nodePair) {
      const id1 = nodePair[1].id();
      var edges = nodePair[0].connectedEdges('[source = "' + id1 + '"],[target = "' + id1 + '"]');
      // edges for self-loops
      if (nodePair[0].id() === id1) {
        edges = nodePair[0].connectedEdges('[source = "' + id1 + '"][target = "' + id1 + '"]');
      }
      if (edges.length >= 2) {
        var operationResult = this.collapseGivenEdges(cy, edges, tempOptions)
        result.oldEdges = result.oldEdges.add(operationResult.oldEdges);
        result.edges = result.edges.add(operationResult.edges);
      }

    }.bind(this));

    return result;
  },
  
  collapseGivenEdges(cy, edges, options) {
    edges.unselect();
    var nodes = edges.connectedNodes();
    var edgesToCollapse = {};
    // group edges by type if this option is set to true
    if (options.groupEdgesOfSameTypeOnCollapse) {
      edges.forEach(function (edge) {
        var edgeType = "unknown";
        if (options.edgeTypeInfo !== undefined) {
          edgeType = options.edgeTypeInfo instanceof Function ? options.edgeTypeInfo.call(edge) : edge.data()[options.edgeTypeInfo];
        }
        if (Object.prototype.hasOwnProperty.call(edgesToCollapse, edgeType)) {//edgesToCollapse.hasOwnProperty(edgeType)) {
          edgesToCollapse[edgeType].edges = edgesToCollapse[edgeType].edges.add(edge);

          if (edgesToCollapse[edgeType].directionType == "unidirection" && (edgesToCollapse[edgeType].source != edge.source().id() || edgesToCollapse[edgeType].target != edge.target().id())) {
            edgesToCollapse[edgeType].directionType = "bidirection";
          }
        } else {
          var edgesX = cy.collection();
          edgesX = edgesX.add(edge);
          edgesToCollapse[edgeType] = { edges: edgesX, directionType: "unidirection", source: edge.source().id(), target: edge.target().id() }
        }
      });
    } else {
      edgesToCollapse["unknown"] = { edges: edges, directionType: "unidirection", source: edges[0].source().id(), target: edges[0].target().id() }
      for (var i = 0; i < edges.length; i++) {
        if (edgesToCollapse["unknown"].directionType == "unidirection" && (edgesToCollapse["unknown"].source != edges[i].source().id() || edgesToCollapse["unknown"].target != edges[i].target().id())) {
          edgesToCollapse["unknown"].directionType = "bidirection";
          break;
        }
      }
    }

    var result = { edges: cy.collection(), oldEdges: cy.collection() }
    var newEdges = [];
    for (const edgeGroupType in edgesToCollapse) {
      if (edgesToCollapse[edgeGroupType].edges.length < 2) {
        continue;
      }
      edges.trigger('expandcollapse.beforecollapseedge');
      result.oldEdges = result.oldEdges.add(edgesToCollapse[edgeGroupType].edges);
      var newEdge = {};
      newEdge.group = "edges";
      newEdge.data = {};
      newEdge.data.source = edgesToCollapse[edgeGroupType].source;
      newEdge.data.target = edgesToCollapse[edgeGroupType].target;
      var id1 = nodes[0].id();
      var id2 = id1;
      if (nodes[1]) {
          id2 = nodes[1].id();
      }
      newEdge.data.id = "collapsedEdge_" + id1 + "_" + id2 + "_" + edgeGroupType + "_" + Math.floor(Math.random() * Date.now());
      newEdge.data.collapsedEdges = cy.collection();
      edgesToCollapse[edgeGroupType].edges.forEach(function (edge) {
        newEdge.data.collapsedEdges = newEdge.data.collapsedEdges.add(edge);
      });
      newEdge.data.collapsedEdges = this.check4nestedCollapse(cy, newEdge.data.collapsedEdges, options);
      var edgesTypeField = "edgeType";
      if (options.edgeTypeInfo !== undefined) {
        edgesTypeField = options.edgeTypeInfo instanceof Function ? edgesTypeField : options.edgeTypeInfo;
      }
      newEdge.data[edgesTypeField] = edgeGroupType;
      newEdge.data["directionType"] = edgesToCollapse[edgeGroupType].directionType;
      newEdge.classes = "cy-expand-collapse-collapsed-edge";
      newEdges.push(newEdge);
      cy.remove(edgesToCollapse[edgeGroupType].edges);
      edges.trigger('expandcollapse.aftercollapseedge');
    }
    
    result.edges = cy.add(newEdges);
    return result;
  },
  
  check4nestedCollapse(cy, edges2collapse, options){
    if (options.allowNestedEdgeCollapse) {
      return edges2collapse;
    }
    let r = cy.collection();
    for (let i = 0; i < edges2collapse.length; i++) {
      let curr = edges2collapse[i];
      let collapsedEdges = curr.data('collapsedEdges');
      if (collapsedEdges && collapsedEdges.length > 0) {
        r = r.add(collapsedEdges);
      } else {
        r = r.add(curr);
      }
    }
    return r;
  },
  
  /* -------------------------------------- end section edge expand collapse -------------------------------------- */
  
}

export default expandCollapseUtil;
