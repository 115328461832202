<!--
# *****************************************************************
#
# Licensed Materials - Property of IBM
#
# (C) Copyright IBM Corp. 2019, 2020, 2021, 2022, 2023. All Rights Reserved.
#
# US Government Users Restricted Rights - Use, duplication or
# disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
#
# *****************************************************************
-->
<template>
  <cv-header class="header">
    <cv-skip-to-content href="#main-content">
      {{ $t("skip-to-content") }}
    </cv-skip-to-content>
    <cv-header-name tabindex="0" v-on:keyup.enter="setExitDialog(true && !demoMode)" class="header-title" role="button" prefix="IBM" @click="setExitDialog(true && !demoMode)">
      <strong>Mono2Micro</strong>
    </cv-header-name>

    <template slot="header-global" >
      <!--
      <search-with-filter
        v-show="showHeaderAction"
        id="classSearchFilter"
        class="class-search"
        :search="search"
      ></search-with-filter>
      <cv-tooltip alignment="center" direction="bottom" :tip="unobservedTitle" class="tooltip header-menu-item">
        <cv-button v-show="showHeaderAction" :icon="TableIcon" class="unobserved-table-button-expanded" @click="showUnobservedTable"></cv-button>
      </cv-tooltip>
      <cv-button v-show="showHeaderAction" :icon="launch_icon" class="unobserved-table-button" @click="showUnobservedTable">
          <span>{{ $t("unobserved-classes") }}</span>
      </cv-button>
      <cv-tooltip alignment="center" direction="bottom" :tip="overviewTitle" class="tooltip header-menu-item">
        <cv-button v-show="showHeaderAction" :icon="chart_icon" class="overview-button-expanded" v-bind:class="[isOpen ? 'overview-button-expanded-open' : 'overview-button-expanded']" @click="open_side_panel" ></cv-button>
      </cv-tooltip>
      <cv-button v-show="showHeaderAction" :icon="chart_icon" class="overview-button" v-bind:class="[isOpen ? 'overview-button-open' : 'overview-button']" @click="open_side_panel" ><span>{{ $t("structuralOverview") }}</span></cv-button>
      <cv-header-global-action v-show="showHeaderAction" class="header-menu-item-icon" :aria-label="uploadTitle" :label="uploadTitle" @click="uploadJson">
        <upload-icon-20 />
      </cv-header-global-action>
      -->
      <cv-header-global-action v-show="showHeaderAction" class="header-menu-item-icon walkme-button" :aria-label="walkMeTitle" :label="walkMeTitle" @click="launchWalkMe">
      </cv-header-global-action>

      <cv-header-global-action v-show="showHeaderAction" class="header-menu-item-icon help-tooltip" :aria-label="helpTitle" :label="helpTitle" @click="onShowInformation">
        <help-icon-20 />
      </cv-header-global-action>
    </template>

    <!--
    <cv-toast-notification v-if="notificationVisible" class="notification-location" :kind=notify.kind :title=notify.title :sub-title=notify.subTitle :low-contrast=false @close=hideNotification :close-aria-label="$t('close-button-aria')" >
    </cv-toast-notification>
    -->

    <cv-modal
      :visible="!on_home && exit_show_dialog"
      id="exit-dialog"
      kind="danger"
      @modal-hidden="onCancel"
      @primary-click="goHome"
      :close-aria-label="$t('close-button-aria')"
      :auto-hide-off="false"
      size="small"
      >
      <template slot="title">{{ $t("exit-dialog-title")}}</template>
      <template slot="content">
        <p>{{ $t("exit-dialog-body")}}</p>
      </template>
      <template slot="secondary-button">{{ $t("cancel-button-label") }}</template>
      <template slot="primary-button">{{ $t("exit-button-label") }}</template>
    </cv-modal>
  </cv-header>
</template>

<script>
// import UploadIcon20 from "@carbon/icons-vue/es/upload/20";
import messageUtils from "@/js/messageUtils";
//import SearchWithFilter from "../SearchWithFilter";
import { eventBus } from "../../main.js";
import launch_icon from "@carbon/icons-vue/es/launch/16";
import chart_icon from "@carbon/icons-vue/es/chart--bar/16";
import HelpIcon20 from "@carbon/icons-vue/lib/help/20";
import TableIcon from "@carbon/icons-vue/lib/table--split/20";
import $ from "jquery";

export default {
  name: "TopNav",
  components: {
    // UploadIcon20,
    //SearchWithFilter,
    HelpIcon20
  },
  data: () => ({
    demoMode: false,
    showHeaderAction: false,
    walkMeTitle: messageUtils.getMessage('walk-me-tooltip'),
    uploadTitle: messageUtils.getMessage('upload-icon-tooltip'),
    helpTitle: messageUtils.getMessage('help-icon-tooltip'),
    // unobservedTitle: messageUtils.getMessage('unobserved-classes'),
    overviewTitle: messageUtils.getMessage('structuralOverview'),
    notificationVisible: false,
    launch_icon,
    chart_icon,
    TableIcon,
    showPanel: false,
    numOfClasses: 0,
    // show_unobserved_table: false,
    isOpen: false, /* do not open the overview panel by default */
    notify: {
      kind: "info",
      title: "",
      subTitle: ""
    },
    exit_show_dialog: false,
    on_home: false,
    next: null,  // this is the next function of beforeRouteLeave in GraphPage.vue,
    m2mVersion: 'Development',
    search: {
        id: "classSearchFilter",
        label: messageUtils.getMessage("search-classes"),
        placeholder: messageUtils.getMessage("search-classes"),
        clearAriaLabel: messageUtils.getMessage("clear-search")
      },
  }),
  methods: {
    getVersion: function () {   
            var me = this;
            $.ajax({
                url:  '/version'
            }).done(function (data) {
                  if (data.version) {
                    me.m2mVersion = data.version;
                  } else {
                    me.m2mVersion = 'Development';
                  }
                });
    },
    // showUnobservedTable: function() {
    //   if (!this.show_unobserved_table) {
    //     eventBus.$emit("UnobservedTable.show");
    //   } else {
    //     eventBus.$emit("UnobservedTable.hide");
    //   }
    // },
    uploadJson() {
      if(!this.demoMode) eventBus.$emit("GraphSummaryTopPanel.showUploadDialog");
    },
    launchWalkMe() {
      if (window && window.WalkMeAPI) {
        if ( this.demoMode ) {
          window.WalkMeAPI.startFlowById('1247588');
        }
        else {
          window.WalkMeAPI.startFlowById('1240313');
        }
      }
    },
    onShowInformation(e) {
      e.preventDefault();
      e.stopPropagation();
      eventBus.$emit("GraphSummaryTopPanel.viewInformation", this.m2mVersion);
    },
    open_side_panel: function(e) {
      e.preventDefault();
      this.isOpen = !this.isOpen;
      if (this.showPanel) {
        eventBus.$emit("SidePanel.displayPanel", false);
        this.showPanel = false;
      } else {
        eventBus.$emit("SidePanel.displayPanel", true);
        this.showPanel = true;
      }
    },
    // dismissNotificationInFive() {
    //   setTimeout(() => this.notificationVisible = false, 5000);
    // },
    // hideNotification() {
    //   this.notificationVisible = false;
    // },
    setExitDialogFocus() {
      document.querySelector("#exit-dialog .bx--modal-footer .bx--btn--secondary").focus();
    },
    setExitDialog(state) {
      // show the exit dialog only if not on home page
      if (!this.on_home) {
        this.exit_show_dialog = state;
        if (state) {
          this.$nextTick(() => {
            // has to wait to set focus on the reset dialog even with $nextTick
            setTimeout(this.setExitDialogFocus, 100);
          });
        }
      } else if (state) {
        this.$router.go('/');
      }
    },
    goHome() {
      this.$router.push('/')
      this.exit_show_dialog = false;
      if (this.next) {
        this.next();
        this.next = null;
      }
    },
    onCancel() {
      this.setExitDialog(false);
      if (this.next) {
        this.next(false);
      }
      this.next = null;
    }
  },
  mounted() {
    this.getVersion();

    eventBus.$on("TopNav.demoMode", (demo) => {
      // The edit class toggle button is available for Custom view only
      this.demoMode = demo;
      eventBus.$emit("PageHeader.setDemoMode", demo);
    });

    eventBus.$on("TopNav.updateOverviewButton", (panelState) => {
      this.isOpen = panelState;
      this.showPanel = panelState;
    });

    eventBus.$on("TopNav.closePanel", () => {
      this.isOpen = !this.isOpen;
      eventBus.$emit("SidePanel.showPanel", false);
      this.showPanel = false;
    });

    eventBus.$on("TopNav.showHeaderAction", showGlobalAction => {
      this.showHeaderAction = showGlobalAction;
    })
    eventBus.$on("TopNav.getNumberOfClasses", numOfClasses => {
      this.numOfClasses = numOfClasses;
      let ptext = this.$t("search-classes-number", {
          numClasses: this.numOfClasses,
      });
      this.search.placeholder = ptext;
    })
    eventBus.$on("TopNav.showExitDialog", next => {
      this.next = next;
      // shown dialog only if it is not already displayed and triggered from scenario other than back arrow
      // Note: changing the router to / in goHome will trigger GraphPage.beforeRouteLeave
      if (!this.exit_show_dialog) {
        this.exit_show_dialog = true;
        this.$nextTick(() => {
          // has to wait to set focus on the reset dialog even with $nextTick
          setTimeout(this.setExitDialogFocus, 100);
        });
      }
    })
    this.on_home = this.$router.currentRoute.path  === "/"
  },
  beforeDestroy() {
    // remove the event listeners as Vue doesn't clean them up
    eventBus.$off('TopNav.showHeaderAction');
    eventBus.$off("TopNav.showExitDialog");
  }
};
</script>

<style lang="scss" scoped>
.header {
  height: 48px !important;
  border-bottom: 1px solid var(--app-header-border-color);
}

.header-title {
  font-size: 14px;
  // color: var(--app-header-title-color);
  letter-spacing: 0.1px;
  text-align: left;
  line-height: 18px;
  font-weight: 400;
  cursor: pointer;
}

// .notification-location {
//   z-index: 3;
//   position: fixed;
//   right: 0;
//   top: 30px;
//   width: fit-content;
//   max-width: 50%;
// }

// .notification-location .bx--inline-notification__text-wrapper {
//   flex-direction: column;
// }

// .bx--header__global .bx--assistive-text {
//   transform: translate(-10%, 100%) !important;
// }

.bx--header__global .help-tooltip span {
  margin-left: -20px;
}

// .bx--header__global .bx--tooltip--bottom::before {
//   bottom: -6px;
// }

.bx--header__global .tooltip {
  display: none;
}

.bx--assistive-text .unobserved-table-button-expanded {
  display: none;
}

.class-search {
  width: 100%;
  min-width: 300px;
  position: relative;
  display: block;
  margin: 2.8rem var(--space-between) 0 auto;
  margin-top: 15px;
  margin-bottom: 65px;
  // border-bottom: 1px solid #393939;
  font-size: 14px;
  // color: #6F6F6F;
  letter-spacing: 0.16px;
  line-height: 18px;
  font-weight: 400;
}

#classSearchFilter {
  margin-top: 0px;
  margin-left: 256px;
  height: 100%;
  // border-bottom: 1px solid #393939;
  margin-right: 1px;
}

.walkme-button {
  background-image: url("../../assets/img/crossroads.svg");
  background-position-y: center;
  background-repeat: no-repeat;
  height: 100%;
}

.walkme-button:hover {
  filter: brightness(80%);
}

.unobserved-table-button {
  background-color: transparent;
  font-size: 14px;
  color: #78A9FF;
  letter-spacing: 0.16px;
  line-height: 18px;
  font-weight: 400;
  /* width: 185px; see #1143 */
  height: 48px;
  border-bottom: 1px solid #393939;
}

.unobserved-table-button span {
  white-space: nowrap;
}

.overview-button {
  background-color: transparent;
  font-size: 14px;
  color: #78A9FF;
  letter-spacing: 0.16px;
  line-height: 18px;
  font-weight: 400;
  /* width: 116px; see #1143 */
  height: 48px;
  border-bottom: 1px solid #393939;
}

.overview-button:hover, 
 .unobserved-table-button:hover {
  color: #a6c8ff;
  background-color: #393939;
}

// .bx--header__global button:hover {
//   background-color: #393939;
// }

.header .bx--tooltip__trigger:hover svg {
  fill: var(--app-header-title-color);
}

.overview-button-open {
  background-color: #171717;
  font-size: 14px;
  color: #78A9FF;
  letter-spacing: 0.16px;
  line-height: 18px;
  font-weight: 400;
  /* width: 116px; see #1143 */
  height: 48px;
  border-bottom: 2px solid #4589FF;
}

@media (max-width: 1225px) {
 #classSearchFilter {
    margin-top: 0px;
    margin-left: 0px;
  }

  .class-search {
    width: 300px;
  }

  .header-title {
    margin-right: 40px;
  }
}

@media (max-width: 960px) {
 #classSearchFilter {
   display: none;
  }
}

@media (max-width: 672px) {
  .unobserved-table-button-expanded,
  .overview-button-expanded-open,
  .overview-button-expanded {
    width: 50px !important;
    padding-left: 0px;
    padding-right: 0px;
  }

  .unobserved-table-button,
  .overview-button,
  .overview-button-open {
    display: none;
  }

  .unobserved-table-button span {
    display: none;
    width: 0px;
  }

  .overview-button span,
  .overview-button-open span {
    display: none;
    width: 0px;
  }

  .bx--header__global .overview-button-expanded-open:hover, 
  .bx--header__global .unobserved-table-button-expanded:hover,
  .bx--header__global .overview-button-expanded:hover {
    color: #a6c8ff;
    background-color: #393939;
  }

  .bx--header__global .tooltip {
    display: inline;
  }

  .bx--header__global .unobserved-table-button-expanded,
  .bx--header__global .overview-button-expanded {
    background-color: #171717;
    color: #78A9FF;
    border-bottom: 1px solid #393939;
    justify-content: center;
  }

  .bx--header__global .overview-button-expanded-open {
    background-color: #171717;
    color: #78A9FF;
    border-bottom: 2px solid #4589FF;
  }

  .header-title {
    margin-right: 0px;
  }
}

// by default it's 3rem, but to keep consistent with the nav height, changing it to 48px
.bx--header__global button {
  min-height: 48px;
}
</style>

<style>
#classSearchFilter .bx--search-input {
  font-size: 14px;
}

.content-mt-3 svg {
  margin-top: 3px;
}

.overview-button-expanded svg {
  right: unset !important;
}
.unobserved-table-button-expanded svg {
  right: unset !important;
}

/* .header-menu-item .bx--assistive-text {
  transform: translate(-50%, 150%) !important;
} */
</style>