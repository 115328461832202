<!--
# *****************************************************************
#
# Licensed Materials - Property of IBM
#
# (C) Copyright IBM Corp. 2021, 2022. All Rights Reserved.
#
# US Government Users Restricted Rights - Use, duplication or
# disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
#
# *****************************************************************

The checkbox implementation in the table with pagination is extremely sensitive to what is used to show the checked and indeterminate states.
- to get the checkmark input to show for the current page and not carry over to the previous or next page, the followings are used
  - an unique key value for each label using the key prop in cv-data-table-row
  - v-model to control the value of the checkbox in cv-checkbox. Using checked prop will not work with pagination.
- to get the initial indeterminate state to show up
  + not able to use the mixed prop provided by carbon vue in cv-checkbox
  + instead conditionally add the edit-labels-checkbox-indeterminate class based on row.isMixed 
  + remove the edit-labels-checkbox-indeterminate class when the checkbox changes from indeterminate to checked state

In addition, there is a bug I encountered occasionally when a checked checkbox is clicked to uncheck, the checkbox still shows the checked in it.
To workaround this problem, I add codes to change the aria-checked and data-contained-checkbox-state attributes in onCheckboxChange method.
-->
<template>
  <div>
  <div class="edit-labels-container" v-show="showEditLabelsPanel" ref="editLabelsContainer">
    <div class="edit-labels-content-container">
    <div class="edit-labels-heading">
      <h4>{{ $t("edit-labels-title") }}</h4>
      <cv-button aria-label="Close" class="edit-labels-close" kind="ghost" :icon="Close16" @click="onClosePanel(true)" />
    </div>
    <div class="edit-labels-content-top">
      <div class="edit-labels-description">
        {{ $t("edit-labels-description") }}
      </div>
      <div class="edit-labels-input-and-button-content">
        <cv-search
          :placeholder="$t('edit-labels-search-placeholder')"
          size="large"
          v-model="searchValue"
          ref="editLabelsSearchInput"
        />
        <cv-button kind="ghost" @click="onManageLabels" :title="$t('manage-labels-title')">
          <div class="edit-labels-input-and-button-content-button">{{ $t("manage-labels-title") }}</div>
        </cv-button>
      </div>
    </div>
    <cv-data-table
      v-if="!reRenderTable"
      class="edit-labels-table-data"
      :columns="columns"
      :pagination="tablePagination"
      @pagination="onPagination"
      :borderless=true
      ref="table"
    >
      <template slot="data">
        <cv-data-table-row
          v-for="row in labelData"
          :key="getLabelIndexByName(row.name)"
          :value="`${row.name}`"
        >
          <cv-data-table-cell
          >
            <div class="edit-labels-table-row">
            <span 
            >
              {{ row.name }}
            </span>
            <!--
              :mixed="labelsInTable[getLabelIndexByName(row.name)].isMixed"
            -->
            <cv-checkbox
              :value="`checkbox-${row.name}`"
              v-model="labelStateWithSelectedClasses[getLabelIndexByName(row.name)].isChecked"
              v-bind:class="{'edit-labels-checkbox-indeterminate': labelStateWithSelectedClasses[getLabelIndexByName(row.name)].isMixed}"
              @change="onCheckboxChange($event, row.name)"
            >
            </cv-checkbox>
            </div>
          </cv-data-table-cell>
        </cv-data-table-row>
      </template>
      <template slot="of-n-pages">
        {{ $t("table-pagination-of-pages",
          {total: Math.max(1, Math.ceil(tablePagination.numberOfItems / pagination.length)) }) }}
      </template>
    </cv-data-table>
    <div class="edit-labels-selected">{{ $t("edit-labels-selected-classes", {numOfSelectedClasses: selectedClasses.length}) }}</div>
    <div class="edit-labels-selected-list" ref="selectedClassesList">
      <span
        v-for="(selectedClass, rowIndex) in selectedClasses"
          :key="`${rowIndex}`"
          :value="`${selectedClass}`"
          class="edit-labels-selected-class"
      >
        {{ selectedClass }}
      </span>
    </div>
    </div>
    <cv-button
      size="lg"
      :disabled="disabledEdit"
      class="edit-labels-edit-button"
      @click="onEditLabels"
    >
      {{ $t("edit-labels-apply-labels") }}
    </cv-button>
  </div>
  </div>
</template>

<script>
import { eventBus } from "../../main.js";
import Close16 from "@carbon/icons-vue/es/close/20";
import apis from "../../lib/graph/graphAPIHandler";

export default {
  name: "EditLabelsPanel",
  components: {
  },
  props: [
    "onEditLabelsEvent"
  ],
  data() {
    return {
      Close16,
      showEditLabelsPanel: false,
      showCheckbox: false,
      disabledEdit: true,
      searchValue: '',
      hasChanges: false,
      resetLabels: true,
      reRenderTable: false,
      labelsInTable: [],
      origLabelsInTable: [],
      selectedClasses: [],
      fromView: "table",
      labelStateWithSelectedClasses: [],
      labelsChangeStatus: {},
      // changeLabels: [],
      pagination: {
        start: 0,
        page: 0,
        length: 5
      },
      tablePagination: {
        page: 1,
        numberOfItems: 0,
        pageSizes: [5]
      },
      columns: [
        {
          label: this.$t("edit-labels-table-label-column")
        }
      ]
    }
  },
  watch: {
    selectedClasses: function(newValue) {
      if (this.showEditLabelsPanel) {
        this.setCheckmarkForSelectedClasses(newValue);
        this.applyChangeLabelCheckMarkToLabelState();
        // this.reRenderTable = true;

        this.$nextTick(function() {
          // force rendering, otherwise current page may not be refreshed correctly even though the isChecked of each row used in v-model is set correctly
          //this.reRenderTable = false;
          this.disableEdit = (this.labelsChangeStatus.length === 0);
        })
      }
    },
    searchValue: function() {
      this.resetTableToFirstPage();

      this.$nextTick(function() {
        this.setTableBottomBorder();
      });
    },
    labelsInTable: function() {
      // console.log("----======--- labelsInTable is updated: show panel is " + this.showEditLabelsPanel + "; length equals - " + (this.labelStateWithSelectedClasses.length !== this.labelsInTable.length) + "; reset labels - " + this.resetLabels);
      // console.log("resetLabels in labelsInTable is " + this.resetLabels);
      // The watch is triggered only if there is new/deleted label.
      if (this.showEditLabelsPanel && this.resetLabels) {
        this.resetLabels = false;
        // redo local data for label state
        this.labelStateWithSelectedClasses = [];
        this.labelsInTable.forEach(() => {
          this.labelStateWithSelectedClasses.push({});
        });

        this.setPageNumberOfItems(this.labelsInTable);

        if (this.selectedClasses?.length > 0) {
          this.setCheckmarkForSelectedClasses();
          this.applyChangeLabelCheckMarkToLabelState();
        }
      }
    }
  },
  computed: {
    labelData: function() {
      // console.log("-------------- in labelData", this.labelsInTable);
      // console.log("---------- resetLabels is " + this.resetLabels);
      let searchLabels = this.labelsInTable;
      if (this.searchValue.length > 0) {
        const regex = new RegExp(this.searchValue, 'i');
        searchLabels = searchLabels.filter(label => {
          return label.name.search(regex) >= 0;
        });
        this.setPageNumberOfItems(searchLabels);
        const startIndex = this.pagination.start - 1;
        return searchLabels.slice(startIndex, startIndex + this.pagination.length);
      } else if (this.pagination) {
        // this.showPaginationElement();
        const startIndex = this.pagination.start - 1;
        return this.labelsInTable.slice(startIndex, startIndex + this.pagination.length);
      } else {
        return this.labelsInTable;
      }
    }
  },
  methods: {
    getLabelIndexByName(labelName) {
      let nameFoundIndex = -1;
      this.labelsInTable.filter((label, index) => {
        if (label.name === labelName) {
          nameFoundIndex = index;
          return true;
        }
      });
      // console.log("finding " + labelName + " in index " + nameFoundIndex + ":", this.labelsInTable[nameFoundIndex]);
      return nameFoundIndex;
    },
    setPageNumberOfItems(labels) {
      this.tablePagination.numberOfItems = labels.length;
      // this.showPaginationElement();
      // const paginationElement = document.querySelector(".edit-labels-container .cv-pagination");
      // if (paginationElement) {
      //   if (labels.length === 0) {
      //     // do not show page number
      //     paginationElement.style.display = "none";
      //   } else {
      //     paginationElement.style.display = "flex";
      //   }
      // }
    },
    showPaginationElement() {
      const paginationElement = document.querySelector(".edit-labels-container .cv-pagination");
      if (paginationElement) {
        if (this.labelsInTable.length === 0) {
          // do not show page number
          paginationElement.style.display = "none";
        } else {
          paginationElement.style.display = "flex";
        }
      }
    },
    onClosePanel(deselectEditButton) {
      this.showEditLabelsPanel = false;
      if (deselectEditButton) {
        eventBus.$emit("TableView.deselectEditLabelsButton");
      }
      if (this.hasChanges) {
        eventBus.$emit("TableView.resetTable");
      }
      if (this.fromView === "sidePanel") {
        // move the minimap back to its original position
          // since we are coming from sidepanel, 
          // this.showEditLabelsPane is set to false in the begining of the message,
          // we need to relaunch the details page
          eventBus.$emit("SidePanel.displayPanel", true);
      }
    },
    onPagination(paginationInfo) {
      this.pagination = paginationInfo;
      this.tablePagination.page = paginationInfo.page;

      this.$nextTick(function() {
        this.setTableBottomBorder();
      });
    },
    onCheckboxChange(isChecked, labelName) {
      // console.log("on checkbox change for " + labelName + " and checkbox is " + isChecked);
      // update change label checked status
      this.labelsChangeStatus[labelName] = isChecked;
      // console.log("--------------- labelsChangeStatus is now ", this.labelsChangeStatus);

      // change the mixed state if true and remove the indeterminate css class
      const rowIndexInTable = this.getLabelIndexByName(labelName);
      const labelElement = document.querySelector(".edit-labels-container tbody tr[value='" + labelName + "'] .cv-checkbox");
      if (this.labelStateWithSelectedClasses[rowIndexInTable].isMixed) {
        this.labelStateWithSelectedClasses[rowIndexInTable].isMixed = false;
      
        if (labelElement) {
          labelElement.classList.remove("edit-labels-checkbox-indeterminate");
        }
      }

      // workaround for aria-checked and data-contained-checkbox-state not updated for unknown reason when checkbox is clicked to uncheck
      if (labelElement) {
        const checkboxInputElement = labelElement.querySelector('input');
        const checkboxLabelElement = labelElement.querySelector('label');
        if (checkboxInputElement) {
          const checkboxInputElementAriaChecked = checkboxInputElement.getAttribute('aria-checked');
          // console.log("existing aria-checked is " + checkboxInputElementAriaChecked);
          if (checkboxInputElementAriaChecked !== isChecked) {
            checkboxInputElement.setAttribute('aria-checked', isChecked);
          }
        }
        if (checkboxLabelElement) {
          const checkboxLabelElementCheckboxState = checkboxLabelElement.getAttribute('data-contained-checkbox-state');
          // console.log("existing data-contained-checkbox-state is " + checkboxLabelElementCheckboxState);
          if (checkboxLabelElementCheckboxState !== isChecked) {
            checkboxLabelElement.setAttribute('data-contained-checkbox-state', isChecked);
          }
        }
      }
      
      // console.log("after handling checkbox, labelsInTable is ", this.labelsInTable);
      // for now enable edit button unless we keep track of original checked vs updated checked
      this.disabledEdit = false;
    },
    onManageLabels() {
      eventBus.$emit("TableView.selectManageLabels");
    },
    onEditLabels() {
      //this.hasChanges = true;
      //this.resetLabels = true;
      // console.log("change labels to be processed", this.labelsChangeStatus);
      this.$store.commit("addRemoveClassesInLabel", {labels: this.labelsChangeStatus, classNames: this.selectedClasses});
      if (this.onEditLabelsEvent) {
        eventBus.$emit(this.onEditLabelsEvent, {changes: this.labelsChangeStatus, fromView: this.fromView}); // , this.labelsInTable);
      }
      // send the label to details page
      eventBus.$emit("SidePanel.updateSelectedClassLabelAfterApplyLabelsAction",
         {labels: this.labelsChangeStatus, classNames: this.selectedClasses});
      // apply label filters to nodes
      // e.g. when label is removed from a class, the node can become invisible
      apis.applyLabelFilterToNodesByNames(this.selectedClasses);
      this.onClosePanel(false);
      // eventBus.$emit("TableView.resetTable", true); // TBD: check whether a reset is needed for labels
    },
    setTableBottomBorder() {
      // determine whether to have border-bottom on the last row of the table to avoid double border with pagination top border
      const theadElementHeight = document.querySelector('.edit-labels-table-data .bx--data-table thead')?.getBoundingClientRect().height;
      const tbodyElement = document.querySelector('.edit-labels-table-data .bx--data-table tbody');
      const tbodyHeight = tbodyElement?.getBoundingClientRect().height;
      const tableHeight = document.querySelector('.edit-labels-table-data .bx--data-table-container')?.getBoundingClientRect().height;
      // console.log("table height is " + tableHeight + "; tbody height is " + tbodyHeight);

      // add a class to set border-bottom of last row of the table to 0 if table total height (head + body) needs scrolling
      if (tbodyElement) {
        if (tbodyHeight + theadElementHeight >= tableHeight) {
          tbodyElement.className = "overflow-table-body";
        } else {
          tbodyElement.className = "";
        }
      }
    },
    setPanelFocus() {
      this.$refs.editLabelsSearchInput?.focus();
    },
    resetTableToFirstPage() {
      if (this.pagination.page != 1) {
        this.tablePagination.page = 1;
        this.pagination.page = 1;
        this.pagination.start = 1;
      }

      this.tablePagination.numberOfItems = this.labelsInTable.length;
    },
    adjustPanelHeight(toView) {
      // anchor this panel below the batch action toolbar in table view, otherwise anchor the panel right below the
      // toolbar in graph view
      let toolbarHeight = "3.0625rem";

      const containerElement = document.getElementsByClassName("edit-labels-container")[0];
      const containerHeight = containerElement?.style.height;
      const containerTop = containerElement?.style.top;
      if (this.$refs.editLabelsContainer && containerHeight && containerTop) {
        if (toView === "table") {
          this.$refs.editLabelsContainer.style.top = `calc(${containerTop} + ${toolbarHeight})`;
          this.$refs.editLabelsContainer.style.height = `calc(${containerHeight} - ${toolbarHeight})`;
        } else {
          this.$refs.editLabelsContainer.style.top = `calc(${containerTop} - ${toolbarHeight})`;
          this.$refs.editLabelsContainer.style.height = `calc(${containerHeight} + ${toolbarHeight})`;
        }
      }
    },
    // adjustTableAndClassesListHeight() {
    //   // adjust the table height and selected class list height
    //   const containerElement = document.getElementsByClassName("edit-labels-container");
    //   const headingElement = document.getElementsByClassName("edit-labels-heading");
    //   const contentTopElement = document.getElementsByClassName("edit-labels-content-top");
    //   const selectedTitleElement = document.getElementsByClassName("edit-labels-selected");
    //   const editLabelsButtonElement = document.getElementsByClassName("edit-labels-edit-button");
    //   const containerHeight = containerElement[0].getBoundingClientRect().height;
    //   const headingHeight = headingElement[0].getBoundingClientRect().height;
    //   const contentTopHeight = contentTopElement[0].getBoundingClientRect().height + 16; // add the bottom margin
    //   const selectedTitleHeight = selectedTitleElement[0].getBoundingClientRect().height;
    //   const editButtonHeight = editLabelsButtonElement[0].getBoundingClientRect().height;
    //   const remainingHeight = containerHeight - headingHeight - contentTopHeight - selectedTitleHeight - editButtonHeight;
    //   let tableHeight = 48 * 7; // full table height with heading row + 5 data rows + pagination
    //   let selectedClassListHeight = 18 + 8; // minimum height with one class name + paddingBottom
    //   // console.log("remaining height is " + remainingHeight + "; tableHeight is " + tableHeight + "; selectedClassListHeight is " + selectedClassListHeight);
    //   if (remainingHeight < tableHeight + selectedClassListHeight) {
    //     // show only one class in the selected list and assign the rest to the table
    //     tableHeight = `calc(${remainingHeight}px - ${selectedClassListHeight}px - 48px)`; // actual table height not including pagination
    //     selectedClassListHeight = "1.125rem"; // the actual list for one element only, padding bottom is outside the list
    //   } else {
    //     selectedClassListHeight = `calc(${remainingHeight}px - ${tableHeight}px - 8px)`; // 8px is for padding bottom outside the list
    //     tableHeight = "18rem"; // not including pagination
    //   }
    //   // console.log("after calcuation, table height is " + tableHeight + " and class list height is " + selectedClassListHeight);

    //   const labelsTableElement = document.querySelector(".edit-labels-table-data .bx--data-table-container");
    //   if (labelsTableElement) {
    //     labelsTableElement.style.height = tableHeight; //`calc(${tableHeight})`; //`calc(${containerElementHeight} - ${headingElementHeight} - ${contentTopElementHeight})`; // - 3.75rem account for border width
    //     labelsTableElement.style.maxHeight = "18rem";
    //   }
    //   if (this.$refs.selectedClassesList) {
    //     this.$refs.selectedClassesList.style.height = selectedClassListHeight; //`calc(${selectedClassListHeight})`; //`calc(100% - ${headingElementHeight} - ${contentTopElementHeight} - ${moveButtonElementHeight})`;
    //   }
    // },
    setCheckmarkForSelectedClasses() {
      // if (this.labelStateWithSelectedClasses.length !== this.labelsInTable.length || this.resetLabels) {
      //   this.labelStateWithSelectedClasses = [];
      //   this.labelsInTable.forEach(() => {
      //     this.labelStateWithSelectedClasses.push({});
      //   });
      // }
      this.labelsInTable.forEach((label, index) => {
        // check whether each label includes all selected classes, partial list of selected classes, or none
        const classesInLabel = label.assignedClasses;
        const includedClasses = this.selectedClasses.filter((selectedClass) => {
          return classesInLabel.includes(selectedClass);
        });
        // this label includes all selected classes if length of the array matches. 
        // Otherwise if match is positive, then some classes but not all contains this label.
        if (includedClasses.length === this.selectedClasses.length) {
          this.labelStateWithSelectedClasses[index].isChecked = true;
          this.labelStateWithSelectedClasses[index].isMixed = false;
        } else if (includedClasses.length > 0) {
          this.labelStateWithSelectedClasses[index].isChecked = false;
          this.labelStateWithSelectedClasses[index].isMixed = true;
        } else {
          this.labelStateWithSelectedClasses[index].isChecked = false;
          this.labelStateWithSelectedClasses[index].isMixed = false;
        }
      });
      //console.log("after filtering all labels to set the status ", this.labelsInTable);
    },
    applyChangeLabelCheckMarkToLabelState() {
      // apply current checkmark updated by the user to all selected (including the new) classes
      Object.keys(this.labelsChangeStatus).forEach((label) => {
        const index = this.getLabelIndexByName(label);
        if (index > -1) {
          this.labelStateWithSelectedClasses[index].isChecked = this.labelsChangeStatus[label];
          // console.log("---------------- updating " + label + " in index " + index + " with isChecked to " + this.labelsInTable[index].isChecked);
          this.labelStateWithSelectedClasses[index].isMixed = false;
        } else {
          //delete this.labelsChangeStatus[label];
        }
      });
    }
  },
  mounted() {
    // console.log("mounted: numberOfItems is " + this.tablePagination.numberOfItems);
    // console.log("mounted: elements in labelsInTable " + this.labelsInTable.length);
    eventBus.$on("EditLabelsPanel.showPanel", (params) => {
      const showPanel = params?.show;
      this.selectedClasses = params?.selectedClasses;

      this.showEditLabelsPanel = showPanel;
      // reset fields
      this.searchValue = "";
      this.disabledEdit = true;
      this.hasChanges = false;
      this.labelsChangeStatus = {};
      if (showPanel) {
         // adjust the panel height if the previous height is from a different view point
        if (params.fromView && (this.fromView !== params.fromView)) {
          this.fromView = params.fromView;
          this.adjustPanelHeight(this.fromView);
        }

        // always read from store so that this copy will get updated when the store copy is updated
        this.labelsInTable = this.$store.getters.getLabels;
        this.labelStateWithSelectedClasses = [];
        this.labelsInTable.forEach(() => {
          this.labelStateWithSelectedClasses.push({});
        });

        this.resetLabels = false;
        this.setCheckmarkForSelectedClasses();
        this.resetTableToFirstPage();

        this.$nextTick(function () {
          // use one scroll bar for the entire panel, no need to adjust table and selected class list height anymore.
          // this.adjustTableAndClassesListHeight();
          this.setTableBottomBorder();

          setTimeout(this.setPanelFocus, 100);
        });
      }
      //}
    }),
    eventBus.$on("EditLabelsPanel.setPanelHeight", (heights) => {
      // This event is called
      // - during normal path to setup the various panel heights
      // - when page header is collapsed/expanded thru the toolbar
      // - when browser height is changed

      // default to anchor this side panel below the batch action toolbar for table view
      let toolbarHeight = "3.0625rem";
      // if (this.fromView !== "table") {
      //   toolbarHeight = "0";
      // }

      if (this.$refs.editLabelsContainer) {
        if (heights.topHeight) {
          this.$refs.editLabelsContainer.style.top = `calc(${heights.topHeight} + ${toolbarHeight})`;
        }
        if (heights.panelHeight) {
          this.$refs.editLabelsContainer.style.height = `calc(${heights.panelHeight} - ${toolbarHeight})`;
        }

        // if panel is already opened, 
        // - if the view is not from table, adjust the panel height to the existing view it is from
        // - adjust the table and class list heights
        if (this.showEditLabelsPanel) {
          // For graph view, need to readjust the height here, otherwise the height adjustment in graph view won't set correctly
          if (this.fromView !== "table") {
            this.adjustPanelHeight(this.fromView);
          }

          // use one scroll bar for the entire panel, no need to adjust table and selected class list height anymore.
          // this.$nextTick(function() {
          //   this.adjustTableAndClassesListHeight();
          // })
        } else {
          this.fromView = "table";
        }
      }

    }),
    eventBus.$on("EditLabelsPanel.resetLabels", (changeLabel) => {
      // console.log("edit labels resetLabel is called", changeLabel);
      // this event is called from ManageLabelsPanel whenever there is change in any label.
      // if a label has changed its name and checkmark has applied to it previously, update the label name in 
      // labelChangeStatus.
      if (this.showEditLabelsPanel && changeLabel?.oldLabelName && changeLabel?.newLabelName &&
          (this.labelsChangeStatus[changeLabel.oldLabelName] !== undefined)) {
        this.labelsChangeStatus[changeLabel.newLabelName] = this.labelsChangeStatus[changeLabel.oldLabelName];
        delete this.labelsChangeStatus[changeLabel.oldLabelName];
        // console.log("after replacing " + changeLabel.oldLabelName + " with " + changeLabel.newLabelName, this.labelsChangeStatus);
      } else {
        this.resetLabels = true;
      }
    }),
    eventBus.$on("EditLabelsPanel.setFocus", () => {
      this.$nextTick(function() {
        this.setPanelFocus();
      })
    }),
    eventBus.$on("EditLabelsPanel.updateSelectedClasses", (selectedClasses) => {
      this.selectedClasses = selectedClasses;
    })
  },
  beforeDestroy() {
    eventBus.$off("EditLabelsPanel.showPanel");
    eventBus.$off("EditLabelsPanel.setPanelHeight");
    eventBus.$off("EditLabelsPanel.resetLabels");
    eventBus.$off("EditLabelsPanel.setFocus");
    eventBus.$off("EditLabelsPanel.updateSelectedClasses");
  }
}
</script>

<style lang="scss" scoped>
.edit-labels-container {
  position: fixed;
  right: 0;
  width: 30rem;
  z-index: 2;
  background: var(--app-edit-labels-background-color);
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.30);
  padding-left: 1rem;
  // padding-right: 1rem;

  .edit-labels-content-container {
    max-height: calc(100% - 4rem); // subtract the apply edit label button
    padding-right: 1rem;
    overflow-y: auto;
  }

  .edit-labels-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    //padding: 0.5rem 0.5rem 1rem 1rem;
    padding-top: 0.5rem;
    padding-bottom: 1rem;
    margin-right: -0.5rem;

    h4 {
      padding-top: 0.5rem;
      align-self: flex-start;
    }

    .edit-labels-close {
      &.bx--btn--ghost {
        color: var(--app-edit-labels-close-button-color);
        min-height: 2rem;;
        min-width: 2rem;
        padding: 0;
        margin-top: -0.125rem; // to achieve 0.5rem top padding per design
      }
    }
  }

  .edit-labels-content-top {
    //padding: 0 1rem;
    padding: 0;

    .edit-labels-description {
      padding-bottom: 1.5rem;
      padding-right: 4rem;
    }

    .edit-labels-input-and-button-content {
      display: flex;
      justify-items: baseline;
      justify-content: space-around;
      margin-bottom: 1rem;
      .edit-labels-input-and-button-content-button {
        max-width: 5rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
      }
    }
  }

  .edit-labels-table-data {
    //padding: 0 1rem 1rem 1rem;
    padding: 0;

    .bx--data-table-container {
      td {
        border-top: 0;

        .edit-labels-table-row {
          display: flex;
          align-items: center;
          justify-content: space-around;

          .cv-checkbox {
            align-items: flex-end;
          }
        }
      }
    }
  }

  .edit-labels-selected {
    padding-top: 1.5rem;
    padding-bottom: 0.5rem;
    font-weight: 600;
    line-height: 1.125rem;
  }

  .edit-labels-selected-list {
    // padding: 0 1rem;
    padding: 0 1rem 1rem 0;
    // overflow: auto;
  
    .edit-labels-selected-class {
      display: block;
      line-height: 1.125rem;

      // &:last-of-type {
      //   padding-bottom: 0.5rem;
      // }
    }
  }

  .edit-labels-edit-button {
    bottom: 0;
    position: fixed;
    width: 100%;
    max-width: unset;
    margin-left: -1rem;
  }
}
</style>

<style lang="scss">
.edit-labels-container {
  .edit-labels-close {
    &.bx--btn--ghost .bx--btn__icon {
      width: 1.25rem;
      height: 1.25rem;
      margin-left: 0.25rem;
    }
  }

  .edit-labels-input-and-button-content {
    .cv-search {
      //padding-bottom: 1rem;

      .bx--search {
        height: 2rem;
        width: 19.75rem; // this would reduce it to about 18.75rem which is 300px

        .bx--search-input {
          position: relative;
          // left: -1rem; // to include search icon inside the input instead of outside the input by carbon-vue
          height: 2rem;
          padding-right: 2rem;
        }

        .bx--search-magnifier {
          z-index: 2;
          position: relative;
          // left: 0.5rem;
          // top: 25%;
        }

        .bx--search-close {
          height: 2rem;
          width: 2rem;
          right: 1rem;
          z-index: 1; // FF won't show the search close button unless z-index > 0
        }
      }
    }

    .bx--btn--ghost {
      min-height: 2rem;
      height: 2rem;
    }
  }

  .bx--data-table-container {
    height: 18rem;
    background-color: var(--app-managed-labels-table-background-color);
    padding-top: 0;

    .bx--data-table-content {
      height: 100%;

      .bx--data-table {
        thead {
          position: sticky;
          z-index: 1;
          top: 0;
          width: 100%;
          will-change: transform;

          .edit-labels-table-name-column {
            padding-right: 0;

            span {
              display: block;
            }
          }
        }

        tbody {
          &.overflow-table-body tr:last-of-type {
            td {
              border-bottom: 0;
            }
          }

          .edit-labels-checkbox-indeterminate .bx--checkbox-label::before {
            border-width: 1px;
            border-color: var(--app-edit-labels-indeterminate-checkmark-background-color);
            background-color: var(--app-edit-labels-indeterminate-checkmark-background-color);
          }

          .edit-labels-checkbox-indeterminate .bx--checkbox-label::after {
            top: 0.6875rem;
            width: 0.5rem;
            transform: scale(1) rotate(0deg);
            border-bottom: 2px solid var(--app-edit-labels-indeterminate-checkmark);
            border-left: 0px solid var(--app-edit-labels-indeterminate-checkmark);
          }
        }
      }
    }
  }
    
  .edit-labels-table-data .bx--pagination {
    height: 3rem;
    justify-content: flex-end;
    // position: sticky;
    // bottom: 0;
    border-top: 1px solid var(--app-table-pagination-top-border-color);

    .bx--pagination__left {
      display: none;
    }
  }
}
</style>